import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Loader from 'modules/PageLoader/components/Loader'
import Blocks from 'shared/styled/Blocks'
import Fade from 'shared/components/Fade'
import Filters from 'shared/components/Filters'
import theme from 'config/theme'
import { parsePrice } from 'utils/global'
import shortid from 'shortid'
import NoRecords from 'shared/components/DataTable/NoRecords'
import Table from 'shared/styled/Table'
import CollapseArrow from 'shared/components/CollapseArrow'
import Pagination from 'shared/components/Pagination'
import AnimateHeight from 'react-animate-height'
import BalanceHistoryElement from './BalanceHistoryElement'

const BalanceHistory = ({
  isDataLoading,
  balance,
  userBalance,
  getBalance,
  activeFilters,
  filterBalanceHistory,
  loggedUser,
  transactionsTypes,
  balanceCodes,
  isOwnBalance
}) => {
  
  const [collapsedRow, setCollapsedRow] = useState(null)

  const collapse = id => {
    if (id === collapsedRow) {
      setCollapsedRow(null)
    } else {
      setCollapsedRow(id)
    }
  }

  const columns = [
    { name: 'Kwota', field: row => <HistoryItemValue value={row.value}>{ row.value > 0 ? `+${parsePrice(row.value)}` : parsePrice(row.value) } zł</HistoryItemValue>, withCollapse: true},
    { name: 'Typ transakcji', field: row => <TransactionType>{ row.calculable_type_translated }</TransactionType>},
    { name: 'Kod rabatowy', field: row => <div>{ row.code }</div> },
    { name: 'Data', field: row => <div>{ row.date }</div> },  
  ]

  const filters = [
    {
      id: shortid.generate(),
      label: 'Typ:',
      options: transactionsTypes,
      query: 'calculable_types',
      isClearable: true,
      isMulti: true
    },
    {
      id: shortid.generate(),
      label: 'Kod klienta:',
      options: balanceCodes,
      query: 'codes',
      isClearable: true,
      isMulti: true
    },
    {
      id: shortid.generate(),
      label: 'Pokaż z zakresu:',
      type: 'daterange',
      isClearable: true
    }
  ]
  
  return (
    <Blocks.Block>
      <Loader isPageLoading={isDataLoading}>
        <Fade>
          <Filters 
            loggedUser={loggedUser}
            filters={filters}
            activeFilters={activeFilters}
            filterData={filterBalanceHistory} />
          { !balance || !balance.data.length
            ? <NoRecords>Brak rekordów do wyświetlenia!</NoRecords>
            : (
              <Table.TableWrapper>
                <Table.Table cellspacing='0'>
                  <Table.TableHead>
                    <Table.TableRow>
                      { columns.map((column, i) => (
                        <Table.TableHeadCell key={i}>{column.name}</Table.TableHeadCell>
                      ))}
                    </Table.TableRow>
                  </Table.TableHead>
                  <Table.TableBody collapsible={true} twoColors={true}>
                    { balance.data.map((row, rowIndex) => {
                      const number = balance.meta.from + rowIndex
                      return (
                        <Fragment key={number} >
                          <Table.AnimatedRow 
                            onClick={() => collapse(rowIndex)}
                            collapsible={row.info !== undefined ? true : false}
                            i={rowIndex} 
                            initialPose='hidden' 
                            pose='visible'>
                            { columns.map((column, columnIndex) => (
                              <Table.TableCell key={columnIndex}>
                                { column.withCollapse
                                  ? (
                                    <CollapseArrow 
                                      isVisible={row.info !== undefined ? true : false}
                                      isCollapsed={rowIndex === collapsedRow ? true : false} 
                                      text={column.field instanceof Function ? column.field(row, rowIndex, columnIndex) : row[column.field]} />
                                  ) : (
                                    column.field instanceof Function ? column.field(row, rowIndex, columnIndex) : row[column.field]
                                  )
                                }
                              </Table.TableCell>
                            ))}
                          </Table.AnimatedRow>
                          <Table.CollapsedRow>
                            <Table.CollapsedRowCell colSpan={'100%'}>
                              <AnimateHeight
                                duration={ 200 }
                                height={collapsedRow === rowIndex ? 'auto' : '0%'}>
                                <BalanceHistoryElement info={row.info} />
                              </AnimateHeight>
                            </Table.CollapsedRowCell>
                          </Table.CollapsedRow>
                        </Fragment>
                      )
                    })}
                  </Table.TableBody>
                </Table.Table>
              </Table.TableWrapper>
            )
          }
          {
            balance && balance.links && balance.meta.total > 0 &&
            <Pagination 
              callApi={param => getBalance(isOwnBalance, null, param)}
              links={balance.links}
              from={balance.meta.from}
              to={balance.meta.to}
              total={balance.meta.total} />
          }
          {/* <DataTable
            callApi={param => getBalance(isOwnBalance, null, param)}
            visibleCounter={false}
            data={isOwnBalance ? balance : userBalance}
            twoColors={false}
            columns={[
              { name: 'Kwota', field: row => <HistoryItemValue value={row.value}>{ row.value > 0 ? `+${parsePrice(row.value)}` : parsePrice(row.value) } zł</HistoryItemValue>},
              { name: 'Typ transakcji', field: row => <TransactionType>{ row.calculable_type_translated }</TransactionType>},
              { name: 'Kod rabatowy', field: row => <div>{ row.code }</div> },
              { name: 'Data', field: row => <div>{ row.date }</div> },  
            ]} /> */}
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default BalanceHistory

const HistoryItemValue = styled.div`
  min-width: 100px;
  font-size: ${rem('22px')};
  font-weight: bold;
  color: ${props => {
    if (props.value > 0) {
      return '#00CC66'
    } else if (props.value < 0) {
      return '#CC3333'
    } else return theme.colors.black
  }};
`

const TransactionType = styled.div`
  border-radius: 10px;
  background-color: ${theme.colors.lightGray};
  border: 1px solid ${theme.colors.sub};
  padding: 5px 15px;
  font-size: ${rem('12px')};
  display: inline-block;
`