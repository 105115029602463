import styled, { css } from 'styled-components'
import { rem } from 'polished'
import theme from '../../config/theme'

const baseHeading = css`
  color: ${theme.colors.black};
  font-weight: bold;
  line-height: 1.2;
`;

export const BlockHeading = styled.h2`
  ${baseHeading};
  font-size: ${rem('20px')};
  position: relative;
  margin: 0;
  /* padding: 0 20px; */
`

export const SubHeading = styled.h3`
  font-size: ${rem('14px')};
  font-weight: normal;
  margin: 10px 0 10px 0;
`