import React, { useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from 'config/theme'
import { FaLongArrowAltRight } from 'react-icons/fa'
import PartiallyDoneOrderForm from './PartiallyDoneOrderForm'
import Fade from 'shared/components/Fade'
import { statusesIds } from 'config/orders'

const CollapsedOrderDetails = ({
  order,
  pageId,
  setOrderComplete,
  isParentChecked,
  unselectOrder,
  canUserSetOrderCompletePartially
}) => {

  console.log(order)

  const [isPartiallyOrder, setIsPartiallyOrder] = useState(false)

  if (!order) return null

  return (
    <OrderDetailsWrapper>
      <OrderDetailsBox>
        { order.status !== statusesIds.done && canUserSetOrderCompletePartially &&
          <PartiallyDoneOrderForm
            isPartiallyOrder={isPartiallyOrder}
            setIsPartiallyOrder={setIsPartiallyOrder}
            order={order}
            pageId={pageId}
            isParentChecked={isParentChecked}
            unselectOrder={unselectOrder} />
        }
        { isPartiallyOrder === false &&
          <Fade>
            { order.items &&
              order.items.map(o => {
                return (
                  <OrderDetail key={o.id}>
                    <OrderedProductValue>{ o.amount_completed }/{ o.amount_ordered }</OrderedProductValue>
                      <OrderedProductName>{ o.name } x <ItemSummary>{ o.price }zł</ItemSummary></OrderedProductName>
                  </OrderDetail>
                )
              })
            }
            <OrderSum>
              <OrderedProductValue>{ order.items_completed_count }/{ order.items_count }</OrderedProductValue>
                <OrderedProductName>Ilość wszystkich produktów <ItemSummary>({ order.items_value_ordered }zł)</ItemSummary></OrderedProductName>
            </OrderSum>
          </Fade>
        }
      </OrderDetailsBox>
      <OrderArrow size={28} />
      <OrderDetailsBox>
        <ShopName>{ order.shop.name } </ShopName>
        <ShopDetails>{ order.shop.street } { order.shop.suite_number }</ShopDetails>
        <ShopDetails>{ order.shop.postcode } { order.shop.city }</ShopDetails>
        <ShopDetails>tel. { order.shop.phone }</ShopDetails>
      </OrderDetailsBox>
      {order.comments &&
        <>
          <OrderArrow size={28} />
          <OrderDetailsBox>
            <ShopName>Uwagi do zamówienia: </ShopName>
            <ShopDetails>{ order.comments }</ShopDetails>
          </OrderDetailsBox>
        </>
      }

        { order.items_completed_count > 0 && order.items_completed_count !== order.items_count &&
            <OrderNoteBox>{order.note}</OrderNoteBox>
        }
    </OrderDetailsWrapper>
  )
}

export default CollapsedOrderDetails

const OrderDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 15px 15px 30px;
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    /* align-items: ; */
  }
`

const OrderDetailsBox = styled.div`
  padding: 25px;
  background-color: ${theme.colors.lightGray};
  border: 1px solid ${theme.colors.light};
  border-radius: 5px;
  flex: 1;
  max-width: 370px;
  @media (max-width: ${theme.breakpoints.md}) {
    flex: none;
    width: 100%;
    max-width: 100%; 
  }
`

const OrderNoteBox = styled.div`
  padding: 25px;
  margin: 0 20px;
  background-color: ${theme.colors.lightGray};
  border: 1px solid ${theme.colors.light};
  border-radius: 5px;
  flex: 1;
  max-width: 370px;
  @media (max-width: ${theme.breakpoints.md}) {
    flex: none;
    width: 100%;
    max-width: 100%; 
  }
`

const OrderDetail = styled.div`
  display: flex;
`

const OrderSum = styled(OrderDetail)`
  padding-top: 5px;
  border-top: 1px solid black;
  margin-top: 7px;
`

const OrderedProductName = styled.div`
  font-size: ${rem('14px')};
`

const OrderedProductValue = styled.div`
  font-size: ${rem('14px')};
  font-weight: bold;
  margin-right: 7px;
  text-align: right;
`

const ShopName = styled.div`
  font-weight: bold;
  font-size: ${rem('14px')};
  margin-bottom: 5px;
`

const ShopDetails = styled.div`
  font-size: ${rem('14px')};
`

const OrderArrow = styled(FaLongArrowAltRight)`
  margin: 0 20px;
  @media (max-width: ${theme.breakpoints.md}) { 
    margin: 20px;
    transform: rotate(90deg);
  }
`
const ItemSummary = styled.span`
  font-weight: bold;
`
