import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'

// PAGES
import LogsPage from './LogsPage'

const LogsRoutes = (match, loggedUser) => [
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/historia`} 
    component={LogsPage} 
    permissions={['history.viewAny']} />,
]

export default LogsRoutes