import * as types from './types'

export const setRepresentativesList = representatives => {
  return {
    type: types.SET_REPRESENTATIVES,
    payload: representatives
  }
}

export const setRepresentativesForSelect = representatives => {
  return {
    type: types.SET_REPRESENTATIVES_FOR_SELECT,
    payload: representatives
  }
}

export const setRepresentativesForFilter = representatives => {
  return {
    type: types.SET_REPRESENTATIVES_FOR_FILTER,
    payload: representatives
  }
}

export const setActiveFilters = filters => {
  return {
    type: types.SET_REPRESENTATIVES_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setCurrentRepresentative = representative => {
  return {
    type: types.SET_CURRENT_REPRESENTATIVE,
    payload: representative
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_REPRESENTATIVES_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_REPRESENTATIVES_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_REPRESENTATIVES_LOADING,
    payload: isLoading
  }
}

export const setStatisticsLoading = isLoading => {
  return {
    type: types.SET_REPRESENTATIVES_STATISTICS_LOADING,
    payload: isLoading
  }
}

export const setStatistics = statistics => {
  return {
    type: types.SET_REPRESENTATIVES_STATISTICS,
    payload: statistics
  }
}

export const setStatisticsActiveFilters = filters => {
  return {
    type: types.SET_REPRESENTATIVES_STATISTICS_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setStatisticsProductsForFilter = products => {
  return {
    type: types.SET_REPRESENTATIVES_STATISTICS_PRODUCTS_FOR_FILTER,
    payload: products
  }
}

export const setNotificationsList = notifications => {
  return {
    type: types.SET_REPRESENTATIVE_NOTIFICATIONS_LIST,
    payload: notifications
  }
}

export const setNotificationsLoading = areLoading => {
  return {
    type: types.SET_REPRESENTATIVES_NOTIFICATIONS_LOADING,
    payload: areLoading
  }
}

export const setNotificationsActiveFilters = filters => {
  return {
    type: types.SET_REPRESENTATIVES_NOTIFICATIONS_ACTIVE_FILTERS,
    payload: filters
  }
}