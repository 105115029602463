import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rem } from 'polished'
import styled from 'styled-components'
import theme from 'config/theme'
import Forms from 'shared/styled/Forms'
import Checkbox from 'shared/components/Checkbox'
import { PrimaryButton } from 'shared/styled/Buttons'
import Fade from 'shared/components/Fade'
import { validateNumberInput } from 'utils/global'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import { setOrderPartiallyCompleted } from '../thunks'
import { setFormValidationErrors } from '../actions'
import { statusesIds } from 'config/orders'

const PartiallyDoneOrderForm = ({ 
  order,
  pageId,
  isPartiallyOrder,
  setIsPartiallyOrder,
  unselectOrder,
  isParentChecked
}) => {

  const [partiallyDoneProducts, setPartiallyDoneProducts] = useState(null)
  const [note, setNote] = useState('')
  const [errors, setErrors] = useState(null)

  const activeFilters = useSelector(({ orders }) => orders.activeFilters)
  const isFormSubmitting = useSelector(({ orders }) => orders.isFormSubmitting)
  const formValidationErrors = useSelector(({ orders }) => orders.formValidationErrors)

  const dispatch = useDispatch()

  useEffect(() => {
    setErrors(formValidationErrors)
  }, [formValidationErrors])

  useEffect(() => {
    return () => dispatch(setFormValidationErrors())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isParentChecked) {
      setIsPartiallyOrder(false)
    }
  }, [isParentChecked, setIsPartiallyOrder])

  useEffect(() => {
    if (order && order.items) {
      let products = {}
      order.items.forEach(item => {
        products = {
          ...products,
          [item.id]: item.amount_completed.toString()
        }
      })
      setNote(order.note)
      setPartiallyDoneProducts(products)
    }
  }, [order])

  const handleAmountChange = (id, amount, min, max) => {
    setErrors(null)
    let products = {...partiallyDoneProducts}
    if (products[id] !== undefined) {
      products[id] = validateNumberInput(amount, min, max)
      setPartiallyDoneProducts(products)
    }
  }

  const handleNoteChange = value => {
    setErrors(null)
    setNote(value)
  }

  const onSubmit = e => {
    e.preventDefault()
    dispatch(setOrderPartiallyCompleted(       
      order.id, 
      {
        status: statusesIds.done_partially,
        items: partiallyDoneProducts,
        note
      },
      pageId,
      activeFilters
    ))
  }

  const enablePartiallyOrder = e => {
    if (e.target.checked) {
      unselectOrder(order.id)
    }
    setIsPartiallyOrder(!isPartiallyOrder)
  }

  return (
    <Wrapper>
      <CheckboxWrapper>
        <Checkbox 
          checked={isPartiallyOrder}
          handleChange={e => enablePartiallyOrder(e) }
          label='Zrealizuj zamówienie częściowo' />
      </CheckboxWrapper>
      { isPartiallyOrder &&
        <Fade>
          <FormWrapper onSubmit={onSubmit}>
            <Forms.Form>
              { order.items.map(product => {
                return (
                  <OrderDetail key={product.id}>
                    <OrderDetailContent>
                      <Forms.RoundedInput 
                        type='number' 
                        min='0'
                        max={product.amount_ordered}
                        onChange={e => handleAmountChange(product.id, e.target.value, 0, product.amount_ordered)}
                        value={partiallyDoneProducts[product.id] ? partiallyDoneProducts[product.id] : ''}/>
                      <OrderedProductValue>/ { product.amount_ordered }x</OrderedProductValue>
                      <OrderedProductName>{ product.name }</OrderedProductName>
                    </OrderDetailContent>
                    { errors && errors[`items.${product.id}`] &&
                      <Forms.InputError>{ errors[`items.${product.id}`][0] }</Forms.InputError>
                    }
                  </OrderDetail>
                )
              })}
              <Note 
                onChange={e => handleNoteChange(e.target.value)} 
                value={note !== null ? note : ''}
                placeholder='Notatka do zamówienia'/>
               { errors && errors['note'] &&
                  <Forms.InputError>{ errors['note'][0] }</Forms.InputError>
                }
              <PrimaryButton disabled={ isFormSubmitting } type='submit'>
                { isFormSubmitting ? <ButtonLoader /> : 'Zrealizuj częściowo' }
              </PrimaryButton>
            </Forms.Form>
          </FormWrapper>
        </Fade>
      }
    </Wrapper>
  )
}

export default PartiallyDoneOrderForm 

const Wrapper = styled.div``

const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`

const Note = styled.textarea`
  resize: none;
  border-radius: 5px;
  border: 2px solid ${theme.colors.mediumGray};
  padding: 10px;
  margin-top: 10px;
  min-height: 80px;
  background: none;
  outline: none;
  width: 100%;
  font-family: ${theme.font.family};
  font-size: ${rem('14px')};
  color: ${theme.colors.black};
  &::placeholder {
    font-family: ${theme.font.family};
    font-size: ${rem('14px')};
    color: ${theme.colors.mediumGray};
    font-style: italic;
  }
`

const FormWrapper = styled.div``

const OrderDetailContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const OrderDetail = styled.div``

const OrderedProductName = styled.div`
  font-size: ${rem('14px')};
`

const OrderedProductValue = styled.div`
  font-size: ${rem('14px')};
  font-weight: bold;
  margin-right: 7px;
  text-align: right;
`