import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'

// PAGES
import CodesPage from './CodesPage'
import CodeConfiguratorPage from './CodeConfiguratorPage'

const CodesRoutes = (match, loggedUser) => [
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/kody-rabatowe`} 
    component={CodesPage} 
    permissions={['code.viewAny']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/kody-rabatowe/:code_id`} 
    component={CodeConfiguratorPage} 
    permissions={['code.view', 'code.viewAny']} />,
]

export default CodesRoutes