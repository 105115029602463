import React, { useEffect } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { getBeneficiaries, generatePayouts, getBeneficiariesFromPagination } from '../thunks'
import GeneratePayouts from '../components/GeneratePayouts'
import { actions as modalActions} from 'modules/Modal'
import { payoutTypes } from 'config/payouts'
import moment from 'moment'

const GenerateAgreementPayoutsContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ payouts }) => payouts.isDataLoading)
  const isPayoutGenerating = useSelector(({ payouts }) => payouts.isPayoutGenerating)
  const beneficiaries = useSelector(({ payouts }) => payouts.beneficiaries)
  const dispatch = useDispatch()

  const currentDate = new Date()
  const previousMonthDate = currentDate.setMonth(currentDate.getMonth() - 1)

  const modalContent = {
    title: 'Czy na pewno chcesz wygenerować paczkę wypłat dla tych beneficjentów?',
    description: 'Niektóre operacje są nieodwracalne',
    confirmButtonText: 'Generuj',
    cancelButtonText: 'Anuluj'
  }

  useEffect(() => {
    dispatch(getBeneficiaries(payoutTypes.agreement, moment(previousMonthDate).format('YYYY-MM')))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GeneratePayouts 
      loggedUser={loggedUser}
      getList={getBeneficiaries}
      beneficiaries={beneficiaries}
      isPageLoading={isDataLoading}
      enableModal={modalActions.enableModal}
      generateButtonText='Generuj paczkę płatności' 
      submitApiCall={generatePayouts} 
      payoutType={payoutTypes.agreement}
      modalContent={modalContent} 
      isPayoutGenerating={isPayoutGenerating}
      previousMonthDate={previousMonthDate}
      tabType={payoutTypes.agreement}
      getListOnPagination={getBeneficiariesFromPagination} />
  )
}

export default GenerateAgreementPayoutsContainer