import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_BENEFICIARIES, 
  URL_GET_CONFIRMED_BENEFICIARIES,
  URL_GENERATE_PAYOUTS,
  URL_GENERATE_PAYOUTS_FOR_INVOICES,
  URL_GET_HISTORY_PAYOUTS,
  URL_DOWNLOAD_PAYOUT_PACKAGE,
  URL_GET_BENEFICIARIES_WITH_NEGATIVE_BALANCE
} from 'config/api/payouts'
import queryString from 'query-string'

export const getBeneficiaries = (type, month, url = URL_GET_BENEFICIARIES.url) => {
  const query = {
    type, 
    month
  }
  const finalUrl = `${url}?${queryString.stringify(query, {arrayFormat: 'bracket'})}`
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_BENEFICIARIES.method, url: finalUrl }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setBeneficiaries }
    })
  }
}

export const getBeneficiariesFromPagination = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_BENEFICIARIES.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setBeneficiaries }
    })
  }
}

export const getInvoiceConfirmations = (month, url = URL_GET_CONFIRMED_BENEFICIARIES.url) => {
  const query = { month }
  const finalUrl = `${url}?${queryString.stringify(query, {arrayFormat: 'bracket'})}`
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_CONFIRMED_BENEFICIARIES.method, url: finalUrl }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setBeneficiaries }
    })
  }
}

export const getInvoiceConfirmationsFromPagination = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_CONFIRMED_BENEFICIARIES.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setBeneficiaries }
    })
  }
}

export const getPayoutsHistory = (url = URL_GET_HISTORY_PAYOUTS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_HISTORY_PAYOUTS.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setBeneficiaries }
    })
  }
}

export const getBeneficiariesWithNegativeBalance = (url = URL_GET_BENEFICIARIES_WITH_NEGATIVE_BALANCE.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_BENEFICIARIES_WITH_NEGATIVE_BALANCE.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setBeneficiaries }
    })
  }
}

export const generatePayoutsForInvoices = (month, items) => {
  return async dispatch => {
    request({
      dispatch,
      queryParams: {
        month,
        items
      },
      endpoint: URL_GENERATE_PAYOUTS_FOR_INVOICES, 
      onStartLoading: () => actions.setPayoutGenerating(true),
      onEndLoading: () => actions.setPayoutGenerating(false),
      callback: payout => {
        window.open(payout.link)
        dispatch(getInvoiceConfirmations(month))
      }
    })
  }
}

export const generateNotifications = (type, month, items) => {
  return async dispatch => {
    request({
      dispatch,
      queryParams: {
        type,
        month,
        items
      },
      endpoint: URL_GENERATE_PAYOUTS, 
      onStartLoading: () => actions.setPayoutGenerating(true),
      onEndLoading: () => actions.setPayoutGenerating(false),
      onSuccess: { action: () => getBeneficiaries(type, month), shouldShowMessage: true }
    })
  }
}

export const generatePayouts = (type, month, items) => {
  return async dispatch => {
    request({
      dispatch,
      queryParams: {
        type,
        month,
        items
      },
      endpoint: URL_GENERATE_PAYOUTS, 
      onStartLoading: () => actions.setPayoutGenerating(true),
      onEndLoading: () => actions.setPayoutGenerating(false),
      callback: payout => {
        window.open(payout.link)
        dispatch(getBeneficiaries(type, month))
      }
    })
  }
}

export const downloadPayoutPackage = id => {
  return async dispatch => {
    request({
      dispatch,
      pathParams: id,
      endpoint: URL_DOWNLOAD_PAYOUT_PACKAGE, 
      onStartLoading: () => actions.setPayoutGenerating(true),
      onEndLoading: () => actions.setPayoutGenerating(false),
      callback: payout => {
        window.open(payout.data.link)
      }
    })
  }
}

export const filterPayoutsHistory = activeFilters => {
  return async dispatch => {
    const finalUrl = `${URL_GET_HISTORY_PAYOUTS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setActiveFilters(activeFilters))
    dispatch(getPayoutsHistory(finalUrl))
  }
}