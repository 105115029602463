import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getRepresentativeNotifications, getSingleRepresentativeNotifications, filterNotifications } from '../thunks'
import { setNotificationsActiveFilters } from '../actions'
import RepresentativeNotifications from '../components/RepresentativeNotifications'

const RepresentativesNotificationsContainer = ({ areOwnNotifications = true, representativeId = null }) => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const areNotificationsLoading = useSelector(({ representatives }) => representatives.areNotificationsLoading)
  const notificationsActiveFilters = useSelector(({ representatives }) => representatives.notificationsActiveFilters)
  const notifications = useSelector(({ representatives }) => representatives.notifications)
  const dispatch = useDispatch()

  useEffect(() => {
    areOwnNotifications ? dispatch(getRepresentativeNotifications()) : dispatch(getSingleRepresentativeNotifications(representativeId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => dispatch(setNotificationsActiveFilters(null))
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
   <RepresentativeNotifications 
    loggedUser={loggedUser}
    areNotificationsLoading={areNotificationsLoading}
    getRepresentativeNotifications={getRepresentativeNotifications}
    notifications={notifications}
    notificationsActiveFilters={notificationsActiveFilters}
    filterNotifications={filters => filterNotifications(filters, areOwnNotifications, representativeId)} />
  )
}

export default RepresentativesNotificationsContainer

