import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_SALES_DISTRICTS, 
  URL_GET_SALES_DISTRICTS_FOR_SELECT, 
  URL_REMOVE_SALES_DISTRICT, 
  URL_GET_SINGLE_DISTRICT,
  URL_UPDATE_SINGLE_DISTRICT,
  URL_ADD_SALES_DISTRICT,
  URL_GET_SALES_DISTRICTS_FOR_FILTER,
  URL_GET_DISTRICTS_STATISTICS,
  URL_GET_PRODUCTS_FOR_DISTRICTS_STATISTICS
} from 'config/api/districts'
import queryString from 'query-string'

export const getSalesDistricts = (url = URL_GET_SALES_DISTRICTS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_SALES_DISTRICTS.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setSalesDistricts }
    })
  }
}

export const getSalesDistrictsForSelect = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SALES_DISTRICTS_FOR_SELECT, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setSalesDistrictsForSelect }
    })
  }
}

export const getSalesDistrictsForFilter = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SALES_DISTRICTS_FOR_FILTER,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setSalesDistrictsForFilter }
    })
  }
}

export const getDistrict = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SINGLE_DISTRICT, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setCurrentDistrict }
    })
  }
}

export const updateDistrict = (data, id) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_UPDATE_SINGLE_DISTRICT, 
      queryParams: data,
      pathParams: id,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/okregi', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const addNewDistrict = data => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_ADD_SALES_DISTRICT, 
      queryParams: data,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/okregi', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const removeSalesDistrict = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REMOVE_SALES_DISTRICT, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: getSalesDistricts, shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const getDistrictStatistics = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: 'GET', url},
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatistics }
    })
  }
}

export const getProductsForDistrictStatistics = districtId => {
  return async dispatch => {
    request({
      dispatch,
      pathParams: districtId,
      endpoint: URL_GET_PRODUCTS_FOR_DISTRICTS_STATISTICS,
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatisticsProductsForFilter }
    })
  }
}

export const filterStatistics = (activeFilters, districtId = null) => {
  return async dispatch => {
    const finalUrl = `${URL_GET_DISTRICTS_STATISTICS.url(districtId)}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setStatisticsActiveFilters(activeFilters))
    dispatch(getDistrictStatistics(finalUrl))
  }
}