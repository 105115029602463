import React from 'react'
import { useDispatch } from 'react-redux'
import Form from 'shared/components/Form'
import shortid from 'shortid'
import Fade from 'shared/components/Fade'

const RepresentativesForm = ({ 
  hasPermission,
  loggedUser,
  currentRepresentative, 
  salesDistrictsForSelect,
  isFormSubmitting, 
  callApi, 
  formValidationErrors, 
  setFormValidationErrors,
  submitBtnText,
  editing = false
}) => {

  const dispatch = useDispatch()
  const onSubmit = formData => {
    if (editing) {
      dispatch(callApi(formData, currentRepresentative.id))
    } else {
      dispatch(callApi(formData))
    }
    dispatch(setFormValidationErrors(null))
  }

  const salesDistrictsData = salesDistrictsForSelect ? salesDistrictsForSelect : []

  const initialData = {
    full_name: currentRepresentative && currentRepresentative.full_name ? currentRepresentative.full_name : '',
    user_email: currentRepresentative && currentRepresentative.user_email ? currentRepresentative.user_email : '',
    password: currentRepresentative && currentRepresentative.password ? currentRepresentative.password : '',
    password_confirmation: currentRepresentative && currentRepresentative.password_confirmation ? currentRepresentative.password_confirmation : '',
    phone: currentRepresentative && currentRepresentative.phone ? currentRepresentative.phone : '',
    bank_account_number: currentRepresentative && currentRepresentative.bank_account_number ? currentRepresentative.bank_account_number : '',
    status: currentRepresentative && currentRepresentative.status ? currentRepresentative.status : false,
    company_name: currentRepresentative && currentRepresentative.company_name ? currentRepresentative.company_name : '',
    nip: currentRepresentative && currentRepresentative.nip ? currentRepresentative.nip : '',
    city: currentRepresentative && currentRepresentative.city ? currentRepresentative.city : '',
    postcode: currentRepresentative && currentRepresentative.postcode ? currentRepresentative.postcode : '',
    street: currentRepresentative && currentRepresentative.street ? currentRepresentative.street : '',
    suite_number: currentRepresentative && currentRepresentative.suite_number ? currentRepresentative.suite_number : '',
    sales_district: currentRepresentative && currentRepresentative.sales_district ? currentRepresentative.sales_district : null,
    agreement_number: currentRepresentative && currentRepresentative.agreement_number ? currentRepresentative.agreement_number : '',
    partnership_since: currentRepresentative && currentRepresentative.partnership_since ? new Date(currentRepresentative.partnership_since) : '',
    optima_warehouse_nr: currentRepresentative && currentRepresentative.optima_warehouse_nr ? currentRepresentative.optima_warehouse_nr : ''
  }

  const switcher = {
    title: 'Status aktywności',
    field_name: 'status',
    activeText: 'Aktywny',
    inactiveText: 'Nieaktywny'
  }

  const formLayout = [
    {
      key: shortid.generate(),
      title: 'Dane podstawowe',
      fields: [
        {
          name: 'full_name',
          type: 'text',
          label: 'Imię i nazwisko*'
        },
        {
          name: 'user_email',
          type: 'text',
          label: 'Adres email*'
        },
        {
          name: 'password',
          type: 'password',
          label: 'Hasło*'
        },
        {
          name: 'password_confirmation',
          type: 'password',
          label: 'Powtórz hasło*'
        },
        {
          name: 'phone',
          type: 'text',
          label: 'Numer telefonu*'
        },
        {
          name: 'agreement_number',
          type: 'text',
          label: 'Numer umowy'
        },
        {
          name: 'partnership_since',
          type: 'date',
          label: 'Data rozpoczęcia współpracy*'
        },
        {
          name: 'sales_district',
          type: 'select',
          label: 'Okręg sprzedaży*',
          options: salesDistrictsData,
          warningText: '* Przy zmianie okręgu sprzedaży wszystkie kody rabatowe, z których aktualny przedstawiciel dostaje bonifikaty zostaną przypisane do nowo wybranego okręgu.'
        }
      ]
    },
     {
      key: shortid.generate(),
      title: 'Dane firmy',
      fields: [
        {
          name: 'company_name',
          type: 'text',
          label: 'Nazwa firmy'
        },
        {
          name: 'nip',
          type: 'text',
          label: 'NIP',
          mask: '9999999999'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Adres',
      fields: [
        {
          name: 'street',
          type: 'text',
          label: 'Ulica*'
        },
        {
          name: 'suite_number',
          type: 'text',
          label: 'Numer lokalu*'
        },
        {
          name: 'postcode',
          type: 'text',
          label: 'Kod pocztowy*'
        },
        {
          name: 'city',
          type: 'text',
          label: 'Miejscowość*'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Dane rozliczeniowe',
      fields: [
        {
          name: 'bank_account_number',
          type: 'text',
          label: 'Numer konta bankowego*',
          mask: "P\L99999999999999999999999999" // eslint-disable-line
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Dane integracji',
      fields: [
        {
          name: 'optima_warehouse_nr',
          type: 'text',
          label: 'Magazyn, do którego przypisywane będą zamówienia hurtowe, jeśli JG jest obsługiwana przez tego PH',
        },
      ]
    }
  ]

  return (
    <Fade>
      <Form
        editing
        hasPermission={hasPermission}
        loggedUser={loggedUser}
        switcher={switcher}
        initialData={initialData}
        formLayout={formLayout}
        isFormSubmitting={isFormSubmitting} 
        onSubmit={onSubmit}
        submitBtnText={submitBtnText}
        setFormValidationErrors={setFormValidationErrors}
        formValidationErrors={formValidationErrors} />
    </Fade>
  )
}

export default RepresentativesForm