import styled from 'styled-components'
import { rem } from 'polished'
import theme from 'config/theme'

export const StatusIndicator = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 15px;
  border-radius: 50%;
  background-color: ${props => props.active ? theme.colors.green : theme.colors.gray};
`

export const TableLinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Badge = styled.div`
  border-radius: 13px;
  background-color: ${props => props.color ? props.color : theme.colors.lightGray};
  border: 1px solid ${props => props.color ? props.color : theme.colors.sub};
  padding: 4px 15px;
  font-size: ${rem('12px')};
  color: ${props => props.textColor ? props.textColor : theme.colors.black};
  display: inline-block;
`