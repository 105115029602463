import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'shared/styled/Table'
import Checkbox from 'shared/components/Checkbox'
import Pagination from 'shared/components/Pagination'
import NoRecords from 'shared/components/DataTable/NoRecords'
import { PrimaryButton } from 'shared/styled/Buttons'
import roles from 'config/roles'
import { parsePrice } from 'utils/global'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import moment from 'moment'
import { payoutTypes } from 'config/payouts'

const PayoutsTable = ({
  loggedUser,
  beneficiaries,
  getListOnPagination,
  enableModal,
  modalContent,
  selectBeneficiaryForPayout,
  beneficiariesToPayout,
  generateButtonText,
  submitApiCall,
  payoutType,
  isPayoutGenerating,
  month,
  checkThatAllRecordsSelected
}) => {

  const dispatch = useDispatch()

  return (
    <>
      { !beneficiaries || !beneficiaries.data.length
        ? <NoRecords>Brak rekordów do wyświetlenia!</NoRecords>
        : (
          <Table.TableWrapper>
            <Table.Table cellspacing='0'>
              <Table.TableHead>
                <Table.TableRow>
                  <Table.TableHeadCell width={60}>
                    <Checkbox 
                      handleChange={e => selectBeneficiaryForPayout(null, true)}
                      isEditable={beneficiaries.data.length !== 0}
                      checked={checkThatAllRecordsSelected()} />
                  </Table.TableHeadCell>
                  <Table.TableHeadCell>Klient</Table.TableHeadCell>
                  <Table.TableHeadCell>Kwota bonifikat</Table.TableHeadCell>
                </Table.TableRow>
              </Table.TableHead>
              <Table.TableBody collapsible={true} twoColors={true}>
                { beneficiaries.data.map((row, rowIndex) => {
                  const number = beneficiaries.meta.from + rowIndex
                  return (
                    <Fragment key={number}>
                      { row.beneficiary &&
                        <Table.AnimatedRow 
                          i={rowIndex} 
                          initialPose='hidden' 
                          pose='visible'>
                          { [roles.representative.slug, roles.admin.slug].includes(loggedUser.account_type) &&
                            <Table.TableCell>
                              <Checkbox 
                                isEditable={true}
                                handleChange={e => selectBeneficiaryForPayout(row.id)} 
                                checked={beneficiariesToPayout.includes(row.id)}
                                value={row.id} />
                            </Table.TableCell>
                          }
                          <Table.TableCell>{ row.beneficiary.name }</Table.TableCell>
                          <Table.TableCell>{ parsePrice(row.balance) } zł</Table.TableCell>
                        </Table.AnimatedRow>
                      }
                    </Fragment>
                  )
                })}
              </Table.TableBody>
            </Table.Table>
          </Table.TableWrapper>
        )
      }
      <Table.TableFooter spacedOut={generateButtonText}>
        { generateButtonText && 
          <Table.TableGroupActionsWrapper>
            <Table.TableGroupActionsText>
              { beneficiariesToPayout.length } zaznaczonych
            : </Table.TableGroupActionsText>
            <PrimaryButton 
              disabled={ isPayoutGenerating || beneficiariesToPayout.length === 0 }
              type='button' 
              onClick={() =>
                dispatch(enableModal({
                  content: modalContent,
                  onConfirm: submitApiCall,
                  onConfirmParams: payoutType === payoutTypes.confirmations 
                    ? [moment(month).format('YYYY-MM'), beneficiariesToPayout] 
                    : [payoutType, moment(month).format('YYYY-MM'), beneficiariesToPayout] 
                }))
              }>{ isPayoutGenerating ? <ButtonLoader /> : generateButtonText }</PrimaryButton>
          </Table.TableGroupActionsWrapper>
        }
        { beneficiaries && beneficiaries.links && beneficiaries.meta.total > 0 && 
          <Pagination 
            callApi={getListOnPagination}
            links={beneficiaries.links}
            from={beneficiaries.meta.from}
            to={beneficiaries.meta.to}
            total={beneficiaries.meta.total} />
          }
      </Table.TableFooter>
    </>
  )
}

export default PayoutsTable