import React from 'react'
import Blocks from 'shared/styled/Blocks'
import SellersForm from './SellersForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import { goBack } from 'utils/global'

const SellersNew = ({
  loggedUser,
  history,
  isPageLoading,
  shopsForSelect,
  addNewSeller,
  isFormSubmitting,
  formValidationErrors,
  setFormValidationErrors
}) => {

  return (
    <Blocks.Block>
      <Blocks.Head>
        <Blocks.BackButton onClick={() => goBack('/panel/pracownicy')}>
          <TiArrowLeftThick size={28} />
        </Blocks.BackButton>
        <BlockHeading>Dodaj nowego pracownika</BlockHeading>
      </Blocks.Head>
      <Loader isPageLoading={isPageLoading}>
        <SellersForm 
          hasPermission={true}
          shopsForSelect={shopsForSelect}
          submitBtnText={'Dodaj'}
          isFormSubmitting={isFormSubmitting}
          callApi={addNewSeller}
          formValidationErrors={formValidationErrors}
          setFormValidationErrors={setFormValidationErrors} />
      </Loader>
    </Blocks.Block>
  )
}

export default SellersNew