import * as types from './types'

const initialState = {
  isPageLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ENABLE_PAGE_LOADER:
      return {
        ...state,
        isPageLoading: true
      }
    case types.DISABLE_PAGE_LOADER:
      return {
        ...state,
        isPageLoading: false
      }
    default: 
      return state
  }
}
