import * as types from './types'

const initialState = {
  balance: null,
  userBalance: null,
  transactionsTypes: null,
  balanceCodes: null,
  activeFilters: null,
  isDataLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BALANCE:
      return {
        ...state,
        balance: action.payload
      }
    case types.SET_USER_BALANCE:
      return {
        ...state,
        userBalance: action.payload
      }
    case types.SET_TRANSACTION_TYPES:
      return {
        ...state,
        transactionsTypes: action.payload
      }
    case types.SET_BALANCE_CODES_FOR_FILTER:
      return {
        ...state,
        balanceCodes: action.payload
      }
    case types.SET_BALANCE_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_BALANCE_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    default: 
      return state
  }
}
