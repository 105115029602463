import React from 'react'
import { SellersDetailsContainer } from 'modules/Sellers'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const SellerDetailsPage = ({ match }) => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Pracownicy'}/>
      <SellersDetailsContainer match={match}/>
    </Fade>
  )
}

export default SellerDetailsPage