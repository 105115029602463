import React, { useEffect } from 'react'
import { useSelector , useDispatch} from 'react-redux'
import history from 'config/history'
import { getDistrict, updateDistrict } from '../thunks'
import { setFormValidationErrors } from '../actions'
import SalesDistrictsDetails from '../components/SalesDistrictsDetails'

const SalesDistrictsDetailsContainer = ({ match }) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ sales_districts }) => sales_districts.isDataLoading)
  const currentDistrict = useSelector(({ sales_districts }) => sales_districts.currentDistrict)
  const isFormSubmitting = useSelector(({ sales_districts }) => sales_districts.isFormSubmitting)
  const formValidationErrors = useSelector(({ sales_districts }) => sales_districts.formValidationErrors)
  const dispatch = useDispatch()

  const districtId = match.params.district_id

  useEffect(() => {
    dispatch(getDistrict(districtId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SalesDistrictsDetails
      loggedUser={loggedUser}
      editing
      isPageLoading={isDataLoading}
      history={history}
      currentDistrict={currentDistrict}
      isFormSubmitting={isFormSubmitting}
      updateDistrict={updateDistrict}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors}/>
  )
}

export default SalesDistrictsDetailsContainer

