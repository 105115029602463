import React from 'react'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import { BalanceContainer } from 'modules/Balance'
import Fade from 'shared/components/Fade'

const BalancePage = () => {
  return (
     <Fade>
      <Breadcrumbs linkLabel={'Saldo'}/>
      <BalanceContainer isOwnBalance/>
    </Fade>
  )
}

export default BalancePage 