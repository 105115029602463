import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import history from 'config/history'
import { addNewSeller } from '../thunks'
import { setFormValidationErrors } from '../actions'
import { thunks as shopsThunks } from 'modules/Shops'
import SellersNew from '../components/SellersNew'

const SellersNewContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isFormSubmitting = useSelector(({ sellers }) => sellers.isFormSubmitting)
  const isDataLoading = useSelector(({ sellers }) => sellers.isDataLoading)
  const formValidationErrors = useSelector(({ sellers }) => sellers.formValidationErrors)
  const shopsForSelect = useSelector(({ shops }) => shops.shopsForSelect)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(shopsThunks.getShopsForSelect())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SellersNew
      loggedUser={loggedUser}
      history={history}
      isPageLoading={isDataLoading}
      shopsForSelect={shopsForSelect}
      isFormSubmitting={isFormSubmitting}
      addNewSeller={addNewSeller}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default SellersNewContainer

