import React from 'react'
import { useSelector } from 'react-redux'
import { generateNewCode } from '../thunks'
import { enableModal } from 'modules/Modal/actions'
import UserCodes from '../components/UserCodes'

const UserCodesContainer = ({ currentUser, currentRole, redirectUrl }) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ codes }) => codes.isDataLoading)
  const isFormSubmitting = useSelector(({ codes }) => codes.isFormSubmitting)
  const currentCode = useSelector(({ codes }) => codes.currentCode)

  return (
    <UserCodes
      currentUser={currentUser}
      currentRole={currentRole}
      enableModal={enableModal}
      loggedUser={loggedUser} 
      redirectUrl={redirectUrl}
      isPageLoading={isDataLoading}
      isFormSubmitting={isFormSubmitting} 
      currentCode={currentCode}
      generateNewCode={generateNewCode} />
  )
}

export default UserCodesContainer