import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { checkPermissions } from 'utils/auth'

const PermissionRoute = ({
  component: Component,
  ...rest
}) => (
  <Route {...rest} render={(props) => {
    return  (
      <>
        {
          rest.loggedUser &&
          rest.loggedUser.permissions && 
          checkPermissions(rest.permissions, rest.loggedUser) 
          ? <Component {...rest} {...props} /> 
          : <Redirect to={'/panel/kokpit'}/>
        }
      </>
    )}} />
  )

export default PermissionRoute