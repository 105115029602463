import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'

// PAGES
import SalesDistrictsPage from './SalesDistrictsPage'
import AddDistrictPage from './AddDistrictPage'
import DistrictDetailsPage from './DistrictDetailsPage'

const SalesDistrictsRoutes = (match, loggedUser) => [
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/okregi`} 
    component={SalesDistrictsPage} 
    permissions={['salesDistrict.viewAny']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/okregi/nowy`} 
    component={AddDistrictPage} 
    permissions={['salesDistrict.create']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/okregi/:district_id`} 
    component={DistrictDetailsPage} 
    permissions={['salesDistrict.update', 'salesDistrict.view']} />
]

export default SalesDistrictsRoutes