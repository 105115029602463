import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterStatistics, getProductsForSellerOwnStatistics, getProductsForSellerStatistics } from '../thunks'
import Statistics from 'shared/components/Statistics'

const SellersStatisticsContainer = ({
  sellerId = null, 
  areOwnStatistics = true
}) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const statisticsActiveFilters = useSelector(({ sellers }) => sellers.statisticsActiveFilters)
  const statistics = useSelector(({ sellers }) => sellers.statistics)
  const statisticsProducts = useSelector(({ sellers }) => sellers.statisticsProducts)
  const areStatisticsLoading = useSelector(({ sellers }) => sellers.areStatisticsLoading)
  const dispatch = useDispatch()
  
  useEffect(() => {
    areOwnStatistics 
    ? dispatch(filterStatistics(statisticsActiveFilters, true)) 
    : dispatch(filterStatistics(statisticsActiveFilters, false, sellerId)) 

    areOwnStatistics 
    ? dispatch(getProductsForSellerOwnStatistics()) 
    : dispatch(getProductsForSellerStatistics(sellerId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const filters = [
    {
      label: 'Typ danych:',
      options: [
        { label: 'Wygenerowany obrót', value: 'sales_value' },
        { label: 'Uzyskanych bonifikat', value: 'bonus' }
      ],
      query: 'type',
      isMulti: false,
      dependentFilters: {
        products: { defaultValue: [], mainFilterDependentValues: ['sales_value'] }
      }
    },
    {
      label: 'Rodzaj sprzedaży:',
      options: [
        { label: 'Stacjonarna', value: 'stationary' },
        { label: 'Internetowa', value: 'shop' },
        { label: 'Wszystkie', value: 'all' },
      ],
      query: 'scope',
      isMulti: false,
      dependentFilters: {
        products: { defaultValue: [], mainFilterDependentValues: ['stationary'] }
      }
    },
    {
      label: 'Produkty:',
      options: statisticsProducts,
      query: 'products',
      isMulti: true,
      isClearable: true,
      visibleIf: {
        type: 'sales_value',
        scope: 'stationary'
      }
    },
  ]

  return (
    <Statistics 
      loggedUser={loggedUser} 
      statistics={statistics}
      isDataLoading={areStatisticsLoading}
      activeFilters={statisticsActiveFilters}
      areOwnStatistics={areOwnStatistics}
      statisticsProducts={statisticsProducts}
      filters={filters}
      filterStatistics={chosenFilters => areOwnStatistics 
        ? filterStatistics(chosenFilters, areOwnStatistics) 
        : filterStatistics(chosenFilters, areOwnStatistics, sellerId)
      } />
  )
}

export default SellersStatisticsContainer