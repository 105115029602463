import * as types from './types'

export const setBeneficiaries = beneficiaries => {
  return {
    type: types.SET_BENEFICIARIES,
    payload: beneficiaries
  }
}

export const setSelectedBeneficiaries = beneficiaries => {
  return {
    type: types.SET_SELECTED_BENEFICIARIES,
    payload: beneficiaries
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_PAYOUTS_LOADING,
    payload: isLoading
  }
}

export const setPayoutGenerating = isGenerating => {
  return {
    type: types.SET_PAYOUT_GENERATING,
    payload: isGenerating
  }
}

export const setActiveFilters = filters => {
  return {
    type: types.SET_PAYOUTS_ACTIVE_FILTERS,
    payload: filters
  }
}