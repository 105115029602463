import React from 'react'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useDispatch } from 'react-redux'
import { PrimaryButton , OutlineButton} from 'shared/styled/Buttons'

const ConfirmModal = ({
  visible,
  disableModal,
  modalContent,
  onConfirm
}) => {

  const dispatch = useDispatch()

  return (
    <ModalVignette visible={visible}>
      <ModalBox>
        <Title>{ modalContent.title }</Title>
        <Description>{ modalContent.description }</Description>
        <ButtonsWrapper>
          <CancelButton onClick={() => dispatch(disableModal())}>{ modalContent.cancelButtonText }</CancelButton>
          <ConfirmButton onClick={() => {
            dispatch(onConfirm())
            dispatch(disableModal())
          }}>{ modalContent.confirmButtonText }</ConfirmButton>
        </ButtonsWrapper>
      </ModalBox>
    </ModalVignette>
  )
}

export default ConfirmModal

const ModalVignette = styled.div`
  background-color: rgba(255, 255, 255, .83);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  transition: visibility .2s ease, opacity .2s ease;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  opacity: ${props => props.visible ? 1 : 0};
`

const ModalBox = styled.div`
  max-width: 400px;
  min-width: 400px;
  width: 100%;
  border-radius: 7px;
  padding: 25px;
  text-align: center;
  position: relative;
  background-color: white;
  box-shadow: 0 0 33px 10px rgba(0,0,0,0.1);
`

const Title = styled.h2`
  font-size: ${rem('20px')};
  font-weight: bold;
  margin: 5px;
`

const Description = styled.div`
  font-size: ${rem('14px')};
  margin-top: 15px;
`

const ButtonsWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const modalButtonStyles = css`
  margin: 5px;
`

const ConfirmButton = styled(PrimaryButton)`
  ${modalButtonStyles};
`

const CancelButton = styled(OutlineButton)`
  ${modalButtonStyles};
`
