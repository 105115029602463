import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from 'config/theme'
import ReactSelect, { components } from 'react-select'
import { GraySelectStyles } from 'shared/styled/Select'
import Checkbox from 'shared/components/Checkbox'

const MultiSelect = ({ value, onChange, options, isClearable }) => {

  return (
    <FilterSelect
      value={value}
      allowSelectAll={true}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={onChange}
      options={options}
      className='select'
      classNamePrefix='select'
      placeholder='Wybierz...' 
      isClearable={isClearable}
      noOptionsMessage={() => 'Brak wyników...'}
      isMulti
      components={{ Option, ValueContainer, MultiValue }}/>
  )
}

export default MultiSelect

const Option = props => {
  return (
    <NewOption {...props}>
      <OptionWrapper>
        <Checkbox
          stopPropagation={false}
          checked={props.isSelected}
          readOnly={true} />
        <div>{ props.label }</div>
      </OptionWrapper>
    </NewOption>
  )
}

const MultiValue = props => {
  return (
    <SelectedValue>{ props.data.label }</SelectedValue>
  )
}

const ValueContainer = ({ children, getValue, ...props }) => {
  const length = getValue().length
  return (
    <components.ValueContainer {...props}>
      { !props.selectProps.inputValue &&
        <SelectedValue>{ `${length} zaznaczonych` }</SelectedValue>
      }
      { React.Children.map(children, child => {
        return child && child.type === components.Input ? child : null;
      })}
    </components.ValueContainer>
  )
}

export const SelectedValue = styled.div`
  font-size: ${rem('13px')};
  color: ${theme.colors.black};
`

export const FilterSelect = styled(ReactSelect)`
  ${GraySelectStyles};
  max-width: 180px;
  min-width: 180px;
  .select {
    &__option {
      text-align: left;
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.white};
        color: ${theme.colors.black};
      }
      &--is-selected {
        background-color: ${theme.colors.white};
        color: ${theme.colors.black};
      }
      &--is-focused {
        background-color: ${theme.colors.white};
        color: ${theme.colors.black};
      }
    }
    &__value-container {
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      display: initial;
      > div {
        display: inline-block;
      }
    }
  }
`

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`

const NewOption = styled(components.Option)`
  &:hover {
    background-color: ${theme.colors.main} !important;
    ${OptionWrapper} {
      color: ${theme.colors.white};
    }
  }
  input {
    pointer-events: none !important;
    position: relative;
    z-index: -1;
  }
`