import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Blocks from 'shared/styled/Blocks'
import theme from 'config/theme'

const CurrentRepresentative = ({ 
  dashboardInfo
}) => {

  return (
    <Wrapper>
      <Label>Pytania? Problemy?</Label>
      <Label>Skontaktuj się ze swoim opiekunem</Label>
      <Name>{ dashboardInfo && dashboardInfo.representative && dashboardInfo.representative.full_name }</Name>
      {
        dashboardInfo && dashboardInfo.representative && dashboardInfo.representative.phone &&
        <AdditionalInfo>
          <AdditionalInfoLabel>Telefon: </AdditionalInfoLabel>
          <AdditionalInfoValue href={`tel:${dashboardInfo.representative.phone}`}>{ dashboardInfo.representative.phone }</AdditionalInfoValue>
        </AdditionalInfo>
      }
      {
        dashboardInfo && dashboardInfo.representative && dashboardInfo.representative.user_email &&
        <AdditionalInfo>
          <AdditionalInfoLabel>Adres email: </AdditionalInfoLabel>
          <AdditionalInfoLink href={`mailto:${dashboardInfo.representative.user_email}`} >{ dashboardInfo.representative.user_email }</AdditionalInfoLink>
        </AdditionalInfo>
      }
    </Wrapper>
  )
}

export default CurrentRepresentative

const Wrapper = styled(Blocks.Block)`
  width: auto;
  margin: 15px;
  @media (max-width: ${theme.breakpoints.xs}) {
    margin: 7px 15px;
  }
`

const Label = styled.div`
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
  margin: 0;
`

const Name = styled.div`
  color: ${theme.colors.black};
  font-size: ${rem('22px')};
  margin: 15px 0 20px 0;
  line-height: 1.3;
  font-weight: bold;
`

const AdditionalInfo = styled.div`
  margin: 0;
  display: block;
`

const AdditionalInfoLabel = styled.div`
  font-weight: normal;
  margin-right: 10px;
  font-size: ${rem('14px')};
  display: inline-block;
`

const AdditionalInfoValue = styled.a`
  font-weight: bold;
  font-size: ${rem('14px')}
  display: inline-block;
  color: ${theme.colors.black};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const AdditionalInfoLink = styled.a`
  font-weight: bold;
  font-size: ${rem('14px')}
  display: inline-block;
  color: ${theme.colors.black};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`