import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Blocks from 'shared/styled/Blocks'
import Loader from 'modules/PageLoader/components/Loader'
import PayoutsTable from './PayoutsTable'
import DateFilter from 'shared/components/DateFilter'
import { FiltersWrapper, FilterBlock, FilterLabel } from 'shared/styled/Filters'
import { payoutTypes } from 'config/payouts'
import moment from 'moment'
import { FiDownload } from 'react-icons/fi'
import DataTable from 'shared/components/DataTable'
import { DataTableAction } from 'shared/components/DataTable/DataTableAction'
import { parsePrice } from 'utils/global'
import Filters from 'shared/components/Filters'
import { Badge } from 'shared/components/DataTable/DataTableElements'

const GeneratePayouts = ({
  loggedUser,
  getList,
  beneficiaries,
  isPageLoading,
  enableModal,
  generateButtonText,
  submitApiCall,
  payoutType,
  modalContent,
  isPayoutGenerating,
  previousMonthDate,
  tabType,
  getListOnPagination,
  downloadPayoutPackage,
  filterPayoutsHistory,
  activeFilters
}) => {

  const [month, setMonth] = useState(previousMonthDate)
  const [beneficiariesToPayout, setBeneficiariesToPayout] = useState([])
  const dispatch = useDispatch()

  const filters = [
    {
      label: 'Pokaż z zakresu:',
      type: 'daterange',
      isClearable: true
    }
  ]

  const selectBeneficiaryForPayout = (selectedBeneficiaryId, all = false) => {
    let newBeneficiariesToPayout = [...beneficiariesToPayout]

    if (all) {
      let pageBeneficiariesIds = beneficiaries.data.map(ben => ben.id)
      if (checkThatAllRecordsSelected()) {
        newBeneficiariesToPayout = beneficiariesToPayout.filter(beneficiary => !pageBeneficiariesIds.includes(beneficiary))
      } else {
        newBeneficiariesToPayout = [...newBeneficiariesToPayout, ...pageBeneficiariesIds.filter(beneficiary => !newBeneficiariesToPayout.includes(beneficiary))]
      }
      setBeneficiariesToPayout(newBeneficiariesToPayout)
    } else {
      if (newBeneficiariesToPayout.includes(selectedBeneficiaryId)) {
        newBeneficiariesToPayout = newBeneficiariesToPayout.filter(beneficiaryId => selectedBeneficiaryId !== beneficiaryId)
      } else {
        newBeneficiariesToPayout = [...newBeneficiariesToPayout, selectedBeneficiaryId]
      }
      setBeneficiariesToPayout(newBeneficiariesToPayout)
    }
  }

  const changeMonth = (tabType, date) => {
    setBeneficiariesToPayout([])
    setMonth(date)
    switch(tabType) {
      case payoutTypes.invoice:
        dispatch(getList(payoutTypes.invoice, moment(date).format('YYYY-MM')))
        break
      case payoutTypes.agreement:
        dispatch(getList(payoutTypes.agreement, moment(date).format('YYYY-MM')))
        break
      case payoutTypes.confirmations:
        dispatch(getList(moment(date).format('YYYY-MM')))
        break
      default:
        dispatch(getList(payoutTypes.invoice, moment(date).format('YYYY-MM')))
        break
    }   
  }

  const checkThatAllRecordsSelected = () => {
    if (beneficiaries && beneficiaries.data) {
      const beneficiariesIds = beneficiaries.data.map(beneficiary => beneficiary.id)
      return beneficiariesIds.every(beneficiary => beneficiariesToPayout.includes(beneficiary))
    } else return false
  }

  return (
    <>
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <>
            { payoutType !== payoutTypes.history &&
              <FiltersWrapper>
                <FilterBlock>
                  <FilterLabel>Miesiąc</FilterLabel>
                  <DateFilter 
                    onChange={e => changeMonth(tabType, e)}
                    showMonthYearPicker 
                    value={month} 
                    maxDate={previousMonthDate}
                    dateFormat='yyyy-MM' />
                </FilterBlock>
              </FiltersWrapper>
            }
            { payoutType === payoutTypes.history
              ? 
                <>
                  <Filters 
                    loggedUser={loggedUser}
                    filters={filters}
                    activeFilters={activeFilters}
                    filterData={filterPayoutsHistory} />
                  <DataTable
                    twoColors={false}
                    callApi={getListOnPagination}
                    data={beneficiaries}
                    columns={[
                      { name: 'Data generowania', field: 'created_at' },
                      { name: 'Status', field: row => <Badge>{ row.status }</Badge> },
                      { name: 'Wygenerował', field: 'creator_name' },
                      { name: 'Kwota', field: row => row.amount ? `${parsePrice(row.amount)} zł` : ''},
                      { name: 'Akcje', field: row => (
                        <DataTableAction 
                          tooltiptext='Pobierz'
                          onClick={() => dispatch(downloadPayoutPackage(row.id))} >
                          <FiDownload size={22}/>
                        </DataTableAction>
                      )},
                    ]}
                  />
                </>
              : <PayoutsTable
                  loggedUser={loggedUser}
                  getListOnPagination={getListOnPagination}
                  beneficiaries={beneficiaries}
                  enableModal={enableModal}
                  modalContent={modalContent}
                  selectBeneficiaryForPayout={selectBeneficiaryForPayout} 
                  beneficiariesToPayout={beneficiariesToPayout}
                  generateButtonText={generateButtonText}
                  submitApiCall={submitApiCall}
                  payoutType={payoutType} 
                  isPayoutGenerating={isPayoutGenerating} 
                  month={month}
                  checkThatAllRecordsSelected={checkThatAllRecordsSelected} /> 
            }
          </>
        </Loader> 
      </Blocks.Block>
    </>
  )
}

export default GeneratePayouts
