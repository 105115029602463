import * as types from './types'

export const setUserProfile = profile => {
  return {
    type: types.SET_USER_PROFILE,
    payload: profile
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_PROFILE_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_PROFILE_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_PROFILE_LOADING,
    payload: isLoading
  }
}