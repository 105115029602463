import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'
import pageLoaderReducer from 'modules/PageLoader'
import modalReducer from 'modules/Modal'
import permissionsReducer from 'modules/Permissions'
import administratorsReducer from 'modules/Administrators'
import salesDistrictsReducer from 'modules/SalesDistricts'
import representativesReducer from 'modules/Representatives'
import shopsReducer from 'modules/Shops'
import sellersReducer from 'modules/Sellers'
import freelancersReducer from 'modules/Freelancers'
import profileReducer from 'modules/Profile'
import codesReducer from 'modules/Codes'
import productsReducer from 'modules/Products'
import dashboardReducer from 'modules/Dashboard'
import salesReducer from 'modules/Sales'
import returnsReducer from 'modules/Returns'
import ordersReducer from 'modules/Orders'
import balanceReducer from 'modules/Balance'
import warehouseReducer from 'modules/Warehouse'
import payoutsReducer from 'modules/Payouts'
import logsReducer from 'modules/Logs'
import rootReducer from 'modules/App'

export const configureStore = (initialState = {}) => {
  return createStore(
    combineReducers({
      page_loader: pageLoaderReducer,
      modal: modalReducer,
      permissions: permissionsReducer,
      sales_districts: salesDistrictsReducer,
      administrators: administratorsReducer,
      representatives: representativesReducer,
      shops: shopsReducer,
      sellers: sellersReducer,
      freelancers: freelancersReducer,
      profile: profileReducer,
      user: rootReducer,
      codes: codesReducer,
      products: productsReducer,
      dashboard: dashboardReducer,
      sales: salesReducer,
      returns: returnsReducer,
      orders: ordersReducer,
      balance: balanceReducer,
      warehouse: warehouseReducer,
      payouts: payoutsReducer,
      logs: logsReducer
    }),
    initialState,
    applyMiddleware(thunk)
  )
}
