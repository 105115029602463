import history from '../config/history'

const notRedirectableUrls = [
  '/logowanie',
  '/przypomnij-haslo',
  '/resetuj-haslo'
]

export const isUser = () => {
  let redirectUrl = null

  if (!notRedirectableUrls.includes(window.location.pathname)) {
    redirectUrl = window.location.pathname
  }

  try {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user === null || user === undefined) {
      if (redirectUrl) localStorage.setItem('redirectUrl', redirectUrl)
      return false
    } else {
      return true
    }
  } catch (err) {
    if (redirectUrl) localStorage.setItem('redirectUrl', redirectUrl)
    return false
  }
}

export const getUser = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      return {
        success: true,
        user
      }
    } else {
      return {
        success: false
      }
    }
  } catch (err) {
    return {
      success: false
    }
  }
}

export const saveUser = user => {
  try {
    localStorage.setItem('user', JSON.stringify(user))
  } catch (err) {
    throw new Error(err)
  }
}

export const logout = () => {
  localStorage.removeItem('user')
  history.push('/login')
}

export const hasAccess = (allowedRoles, loggedUser) => {
  if (loggedUser && allowedRoles && allowedRoles.includes(loggedUser.account_type)) {
    return true
  } else {
    return false
  }
}

export const hasPermission = (necessaryPermission, loggedUser) => {
  if (loggedUser && necessaryPermission && loggedUser.permissions && loggedUser.permissions.includes(necessaryPermission)) {
    return true
  } else {
    return false
  }
}

export const checkPermissions = (permissions, loggedUser) => {
  let hasPermission = false
  if (permissions && loggedUser && loggedUser.permissions) {
    permissions.forEach(permission => {
      if (loggedUser.permissions.includes(permission)) {
        hasPermission = true
      }
    })
  }
  return hasPermission
}