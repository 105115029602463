import React from 'react'
import Blocks from 'shared/styled/Blocks'
import DistrictForm from './DistrictForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import { hasPermission } from 'utils/auth'
import { goBack } from 'utils/global'
import Fade from 'shared/components/Fade'
import { BalanceContainer } from 'modules/Balance'
import roles from 'config/roles'
import Tabs from 'shared/components/Tabs'
import SalesDistrictsStatisticsContainer from '../containers/SalesDistrictsStatisticsContainer'

const SalesDistrictsDetails = ({
  loggedUser,
  editing,
  isPageLoading,
  currentDistrict,
  isFormSubmitting,
  updateDistrict,
  history,
  formValidationErrors,
  setFormValidationErrors
}) => {
  return (
    <Blocks.Block>
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <Blocks.Head>
            <Blocks.BackButton onClick={() => goBack('/panel/okregi')}>
              <TiArrowLeftThick size={28} />
            </Blocks.BackButton>
            <BlockHeading>{ currentDistrict ? currentDistrict.name : 'Okręg sprzedaży' }</BlockHeading>
          </Blocks.Head>
          <Tabs>
            <DistrictForm 
              tabLabel={'Ustawienia'}
              hasPermission={hasPermission('salesDistrict.update', loggedUser)}
              loggedUser={loggedUser}
              editing
              submitBtnText={'Zaktualizuj'}
              currentDistrict={currentDistrict}
              isFormSubmitting={isFormSubmitting}
              callApi={updateDistrict}
              formValidationErrors={formValidationErrors}
              setFormValidationErrors={setFormValidationErrors} />
            <BalanceContainer 
              tabLabel={'Saldo'}
              userRole={roles.district.slug}
              userId={currentDistrict ? currentDistrict.id : ''} />
            <SalesDistrictsStatisticsContainer 
              tabLabel={'Statystyki'}
              areOwnStatistics={false}
              districtId={currentDistrict ? currentDistrict.id : ''} />
          </Tabs>
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default SalesDistrictsDetails