import React, { useEffect } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { getPayoutsHistory, downloadPayoutPackage, filterPayoutsHistory } from '../thunks'
import { setActiveFilters } from '../actions'
import GeneratePayouts from '../components/GeneratePayouts'
import { actions as modalActions} from 'modules/Modal'
import { payoutTypes } from 'config/payouts'

const PayoutsHistoryContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ payouts }) => payouts.isDataLoading)
  const beneficiaries = useSelector(({ payouts }) => payouts.beneficiaries)
  const activeFilters = useSelector(({ payouts }) => payouts.activeFilters)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPayoutsHistory())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setActiveFilters(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GeneratePayouts 
      loggedUser={loggedUser}
      getList={getPayoutsHistory}
      beneficiaries={beneficiaries}
      isPageLoading={isDataLoading}
      enableModal={modalActions.enableModal}
      payoutType={payoutTypes.history}
      downloadPayoutPackage={downloadPayoutPackage}
      filterPayoutsHistory={filterPayoutsHistory}
      activeFilters={activeFilters}
      getListOnPagination={getPayoutsHistory} />
  )
}

export default PayoutsHistoryContainer