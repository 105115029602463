import React from 'react'
import styled from 'styled-components'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import SearchBanner from 'shared/components/SearchBanner'
import Loader from 'modules/PageLoader/components/Loader'
import { StatusIndicator } from 'shared/components/DataTable/DataTableElements'
import Fade from 'shared/components/Fade'

const WarehouseList = ({
  loggedUser,
  searchUrl,
  getWarehouse,
  warehouseProducts,
  isPageLoading,
  areOwnOrders,
  searchWarehouseProducts,
  getShopWarehouseFromPagination
}) => {

  return (
    <WarehouseListWrapper areOwnOrders={areOwnOrders}>
      <SearchBanner 
        dark={!areOwnOrders}
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={searchWarehouseProducts} />
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <Fade>
            <DataTable
              callApi={getShopWarehouseFromPagination}
              data={warehouseProducts}
              columns={[
                { name: 'Zdjęcie', field: row => <ProductThumbnail src={row.default_image_link_small} alt={row.name} />},
                { name: 'Nazwa', field: 'name' },
                { name: 'Status', field: row => <StatusIndicator active={row.active}/> },
                { name: 'Sztuk na magazynie', field: 'stock' },
                { name: 'Cena detaliczna netto', field: row => `${row.price} zł` },
                { name: '% ceny', field: 'wholesale_lower_by_percent' }   
              ]}
            />
          </Fade>
        </Loader>
      </Blocks.Block>
    </WarehouseListWrapper>
  )
}

export default WarehouseList

const ProductThumbnail = styled.img`
  max-width: 50px;
`

const WarehouseListWrapper = styled.div`
  > ${Blocks.Block} {
    box-shadow: ${props => props.areOwnOrders ? '0px 2px 1px rgba(0,0,0,0.05)' : 'none'};
    padding: ${props => props.areOwnOrders ? '25px' : '25px 0'};
  }
`
