import React, { useEffect } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { URL_GET_SALES_DISTRICTS } from 'config/api/districts'
import { getSalesDistricts, removeSalesDistrict } from '../thunks'
import SalesDistrictsList from '../components/SalesDistrictsList'
import { actions as modalActions} from 'modules/Modal'

const SalesDistrictsListContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ sales_districts }) => sales_districts.isDataLoading)
  const salesDistricts = useSelector(({ sales_districts }) => sales_districts.salesDistricts)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSalesDistricts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SalesDistrictsList 
      loggedUser={loggedUser}
      searchUrl={URL_GET_SALES_DISTRICTS.url} 
      removeSalesDistrict={removeSalesDistrict}
      getSalesDistricts={getSalesDistricts}
      salesDistricts={salesDistricts}
      isPageLoading={isDataLoading}
      enableModal={modalActions.enableModal}
    />
  )
}

export default SalesDistrictsListContainer