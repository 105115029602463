import * as types from './types'

export const setCodesList = codes => {
  return {
    type: types.SET_CODES,
    payload: codes
  }
}

export const setCurrentCode = code => {
  return {
    type: types.SET_CURRENT_CODE,
    payload: code
  }
}

export const setActiveFilters = filters => {
  return {
    type: types.SET_CODES_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_CODES_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_CODES_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_CODES_LOADING,
    payload: isLoading
  }
}