import React, { Fragment } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from 'config/theme'

const LogDetails = ({ logDetails }) => {

  const displayProperLabel = key => {
    if (key === 'old') {
      return 'Wartość przed zmianą'
    } else if (key === 'new') {
      return 'Wartośc po zmianie'
    } else return key
  }

  return (
    <LogDetailsWrapper>
      { logDetails && 
        <LogDetailsBox>
          { Object.keys(logDetails).map(detailsKey => {
            return (
              <LogDetailsElement key={detailsKey}>
                <LogDetailsTitle>
                  <LogDetailsTitleLabel>Pole: </LogDetailsTitleLabel>
                  <LogDetailsTitleValue>{ logDetails[detailsKey].label }</LogDetailsTitleValue>
                </LogDetailsTitle>
                { logDetails[detailsKey] && 
                  Object.keys(logDetails[detailsKey]).map(value => (
                    <Fragment key={value}>
                      {
                        value !== 'label' &&
                        <LogDetailsChangedElement>
                          <LogDetailsChangedKey>{ displayProperLabel(value) }:</LogDetailsChangedKey>
                          <LogDetailsChangedValue>{ logDetails[detailsKey][value]}</LogDetailsChangedValue>
                        </LogDetailsChangedElement>
                      }
                    </Fragment>
                  ))
                }
              </LogDetailsElement>
            )
          })}
        </LogDetailsBox>
      }
    </LogDetailsWrapper>
  )
}

export default LogDetails

const LogDetailsWrapper = styled.div`
  padding: 10px 10px 10px 40px;
`

const LogDetailsBox = styled.div`
  border-radius: 7px;
  background-color: ${theme.colors.lightGray};
  padding: 25px;
  font-size: ${rem('14px')};
  border: 1px solid ${theme.colors.light};
  display: inline-block;
`

const LogDetailsElement = styled.div`
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const LogDetailsTitleLabel = styled.div`
  margin-right: 5px;
`

const LogDetailsTitle = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const LogDetailsTitleValue = styled.div`
  font-weight: bold;
`

const LogDetailsChangedElement = styled.div`
  display: flex;
  align-items: center;
`

const LogDetailsChangedKey = styled.div``

const LogDetailsChangedValue = styled.div`
  margin-left: 5px;
  font-weight: bold;
  word-break: break-word;
`
