import React from 'react'
import { OrdersListContainer } from 'modules/Orders'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const OrdersPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Zamówienia'}/>
      <OrdersListContainer />
    </Fade>
  )
}

export default OrdersPage