import styled, { keyframes } from 'styled-components'
import theme from 'config/theme'

const spin = keyframes`
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
`

export const ButtonLoader = styled.div`
  margin: 0 auto;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: ${props => props.dark ? `4px solid ${theme.colors.black}` : `4px solid ${theme.colors.white}`};
  border-top-color: transparent;
  animation: ${spin} .9s ease infinite;
`