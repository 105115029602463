import React from 'react'
import ReactSelect, { components } from 'react-select'
import styled from 'styled-components'
import theme from 'config/theme'
import { rem } from 'polished'

const Control = ({ children, ...props }) => {

  return (
    <components.Control {...props }>
      { children }
      <StyledSelectLabel className="select-label">{ props.selectProps.label }</StyledSelectLabel>
    </components.Control>
  )
}

const Select = ({ value, options, onChange, label, isDisabled, isMulti = false }) => {

  return (
    <StyledSelectWrapper>
      <StyledSelect 
        styles={{container: (base, state) => {
          return ({
            ...base,
            zIndex: state.isFocused ? "999" : "1"
          })
        }}}
        isDisabled={isDisabled}
        isMulti={isMulti}
        value={value}
        onChange={onChange}
        options={options}
        placeholder=''
        className='select'
        classNamePrefix='select'
        label={label}
        noOptionsMessage={() => 'Brak wyników...'}
        components={{ Control }} />
    </StyledSelectWrapper>
  )
}

export default Select

const StyledSelectWrapper = styled.div`
  position: relative;
`

const StyledSelectLabel = styled.div`
  font-size: ${rem('14px')};
  color: ${theme.colors.gray};
  position: absolute;
  z-index: 0;
  left: 0;
  top: 10px;
  transition: font-size .2s ease, top .2s ease;
`

const StyledSelect = styled(ReactSelect)`
  height: 100%;
  z-index: 2;
  .select {
    &__multi {
      &-value {
        &__remove {
          cursor: pointer;
          &:hover {
            background-color: #ff7b5b;
            color: #ffffff;
          }
        }
      }
    }
    &__indicators {
      display: ${props => props.isDisabled ? 'none' : 'flex'}
    }
    &__control {
      border-radius: 0;
      background-color: transparent;
      border: none;
      min-height: 41px;
      border-bottom: 2px solid ${theme.colors.inputGray};
      &:hover {
        border-color: ${theme.colors.main};
      }
      &--is-focused {
        box-shadow: none;
        border-color: ${theme.colors.inputGray};
        ${StyledSelectLabel} {
          top: -10px;
          font-size: ${rem('12px')};
        }
      }
    }
    &__placeholder {
      font-size: ${rem('14px')};
      color: ${theme.colors.gray};
      font-family: ${theme.font.family};
    }
    &__value {
      &-container {
        padding: 0;   
        overflow-x: hidden;
        &--has-value {
          color: ${theme.colors.main};
          & ~ div.select-label {
            font-size: ${rem('12px')};
            top: -10px;
          }
        }
      }
    }
    &__input {
      font-size: ${rem('14px')};
      input {
        font-family: ${theme.font.family};
      }
    }
    &__menu {
      border-radius: 0;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      border: 0;
      * {
        font-size: ${rem('14px')};
      }
    }
    &__single-value {
      font-size: ${rem('14px')};
      margin: 0;
      color: ${theme.colors.black};
    }
    &__option {
      text-align: left;
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.main};
        color: ${theme.colors.white};
      }
      &--is-selected {
        background-color: ${theme.colors.main};
         color: ${theme.colors.white};
      }
      &--is-focused {
        background-color: ${theme.colors.white};
        color: ${theme.colors.black};
      }
    }
  }
`

