import React from 'react'
import { AdministratorsListContainer } from 'modules/Administrators'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const AdministratorsPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Administratorzy'}/>
      <AdministratorsListContainer />
    </Fade>
  )
}

export default AdministratorsPage