import * as types from './types'

export const setLogsList = logs => {
  return {
    type: types.SET_LOGS,
    payload: logs
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_LOGS_LOADING,
    payload: isLoading
  }
}

export const setActiveFilters = filters => {
  return {
    type: types.SET_LOGS_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setLogsUsersForFilter = users => {
  return {
    type: types.SET_LOGS_USERS_FOR_FILTER,
    payload: users
  }
}

export const setLogsTypesForFilter = logsTypes => {
  return {
    type: types.SET_LOGS_TYPES_FOR_FILTER,
    payload: logsTypes
  }
}