import React, { useEffect } from 'react'
import CodesList from '../components/CodesList'
import { useDispatch, useSelector } from 'react-redux'
import { getCodes, filterCodes } from '../thunks'
import { setActiveFilters } from '../actions'
import { thunks as districtThunks } from 'modules/SalesDistricts'
import { thunks as representativeThunks } from 'modules/Representatives'
import { URL_GET_CODES } from 'config/api/codes'
import { actions as modalActions} from 'modules/Modal'

const CodesListContainer = () => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const codes = useSelector(({ codes }) => codes.codes)
  const activeFilters = useSelector(({ codes }) => codes.activeFilters)
  const salesDistrictsForFilter = useSelector(({ sales_districts }) => sales_districts.salesDistrictsForFilter)
  const representativesForFilter = useSelector(({ representatives }) => representatives.representativesForFilter)
  const isDataLoading = useSelector(({ codes }) => codes.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCodes())
    dispatch(districtThunks.getSalesDistrictsForFilter())
    dispatch(representativeThunks.getRepresentativesForFilter())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

   useEffect(() => {
    return () => dispatch(setActiveFilters())
  }, [dispatch])

  return (
    <CodesList
      loggedUser={loggedUser}
      searchUrl={URL_GET_CODES.url}
      filterUrl={URL_GET_CODES.url}
      codes={codes} 
      isPageLoading={isDataLoading}
      enableModal={modalActions.enableModal}
      getCodes={getCodes} 
      filterCodes={filterCodes}
      activeFilters={activeFilters}
      salesDistrictsForFilter={salesDistrictsForFilter}
      representativesForFilter={representativesForFilter} />
  )
}

export default CodesListContainer