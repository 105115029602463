import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'config/theme'

export default styled(Link)`
  text-decoration: none;
  color: ${theme.colors.black};
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`