import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProducts } from '../thunks'
import { URL_GET_PRODUCTS } from 'config/api/products'
import ProductsList from '../components/ProductsList'

const ProductsListContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const products = useSelector(({ products }) => products.products)
  const isDataLoading = useSelector(({ products }) => products.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProducts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ProductsList 
      loggedUser={loggedUser}
      products={products}
      isPageLoading={isDataLoading} 
      searchUrl={URL_GET_PRODUCTS.url}
      getProducts={getProducts} />
  )
}

export default ProductsListContainer