import * as types from './types'

const initialState = {
  products: null,
  currentProduct: null,
  isFormSubmitting: false,
  formValidationErrors: null,
  isDataLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case types.SET_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: action.payload
      }
    case types.SET_PRODUCTS_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_PRODUCTS_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_PRODUCTS_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    default: 
      return state
  }
}
