import React, { useEffect } from 'react'
import WarehouseList from '../components/WarehouseList'
import { useDispatch, useSelector } from 'react-redux'
import { getWarehouse, getShopWarehouse, searchWarehouseProducts, getShopWarehouseFromPagination } from '../thunks'
import { URL_GET_WAREHOUSE_PRODUCTS, URL_GET_SHOP_WAREHOUSE_PRODUCTS } from 'config/api/warehouse'

const WarehouseListContainer = ({ shopId = null, isOwnWarehouse = true }) => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const warehouseProducts = useSelector(({ warehouse }) => warehouse.warehouseProducts)
  const isDataLoading = useSelector(({ warehouse }) => warehouse.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    isOwnWarehouse ? dispatch(getWarehouse()) : dispatch(getShopWarehouse(shopId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WarehouseList
      loggedUser={loggedUser}
      warehouseProducts={warehouseProducts} 
      isPageLoading={isDataLoading}
      getWarehouse={isOwnWarehouse ? getWarehouse : params => getShopWarehouse(shopId, params)} 
      searchUrl={isOwnWarehouse ? URL_GET_WAREHOUSE_PRODUCTS.url : URL_GET_SHOP_WAREHOUSE_PRODUCTS.url(shopId)} 
      searchWarehouseProducts={isOwnWarehouse ? getWarehouse : searchWarehouseProducts}
      areOwnOrders={isOwnWarehouse}
      getShopWarehouseFromPagination={getShopWarehouseFromPagination} />
  )
}

export default WarehouseListContainer