import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_ADMINISTRATORS,
  URL_GET_SINGLE_ADMINISTRATOR,
  URL_REMOVE_ADMINISTRATOR,
  URL_ADD_ADMINISTRATOR,
  URL_UPDATE_ADMINISTRATOR,
  URL_GET_OWN_ADMINISTRATORS_STATISTICS,
  URL_GET_ADMINISTRATORS_STATISTICS
} from 'config/api/administrators'
import queryString from 'query-string'
import moment from 'moment'

export const getAdministrators = (url = URL_GET_ADMINISTRATORS.url) => {
   return async dispatch => {
      request({
        dispatch,
        endpoint: { method: URL_GET_ADMINISTRATORS.method, url }, 
        onStartLoading: () => actions.setDataLoading(true),
        onEndLoading: () => actions.setDataLoading(false),
        onSuccess: { action: actions.setAdministratorsList }
      })
   }
}

export const getAdministrator = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SINGLE_ADMINISTRATOR, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setCurrentAdministrator },
      onFailure: { redirectTo: '/panel/administratorzy' }
    })
  }
}

export const addNewAdministrator = formData => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_ADD_ADMINISTRATOR, 
      queryParams: {
        ...formData,
        is_superadmin: false,
        sales_districts: formData.sales_districts ? formData.sales_districts : [],
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null
      },
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/administratorzy', shouldShowMessage: true },
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const updateAdministrator = (formData, id) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_UPDATE_ADMINISTRATOR, 
      queryParams: {
        ...formData,
        is_superadmin: false,
        sales_districts: formData.sales_districts ? formData.sales_districts : [],
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null
      },
      pathParams: id,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/administratorzy', shouldShowMessage: true },
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const removeAdministrator = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REMOVE_ADMINISTRATOR, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: getAdministrators, shouldShowMessage: true }
    })
  }
}

export const getRepresentativeStatistics = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: 'GET', url},
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatistics }
    })
  }
}

export const filterStatistics = (activeFilters, areOwnStatistics, representativeId = null) => {
  return async dispatch => {
    const finalUrl = areOwnStatistics 
                      ? `${URL_GET_OWN_ADMINISTRATORS_STATISTICS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
                      : `${URL_GET_ADMINISTRATORS_STATISTICS.url(representativeId)}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setStatisticsActiveFilters(activeFilters))
    dispatch(getRepresentativeStatistics(finalUrl))
  }
}
  