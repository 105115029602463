import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterStatistics, getProductsForRepresentativeOwnStatistics, getProductsForRepresentativeStatistics } from '../thunks'
import Statistics from 'shared/components/Statistics'

const RepresentativesStatisticsContainer = ({
  representativeId = null, 
  areOwnStatistics = true
}) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const statisticsActiveFilters = useSelector(({ representatives }) => representatives.statisticsActiveFilters)
  const statistics = useSelector(({ representatives }) => representatives.statistics)
  const statisticsProducts = useSelector(({ representatives }) => representatives.statisticsProducts)
  const areStatisticsLoading = useSelector(({ representatives }) => representatives.areStatisticsLoading)
  const dispatch = useDispatch()
  
  useEffect(() => {
    areOwnStatistics 
    ? dispatch(filterStatistics(statisticsActiveFilters, true)) 
    : dispatch(filterStatistics(statisticsActiveFilters, false, representativeId)) 

    areOwnStatistics 
    ? dispatch(getProductsForRepresentativeOwnStatistics()) 
    : dispatch(getProductsForRepresentativeStatistics(representativeId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const filters = [
    {
      label: 'Typ danych:',
      options: [
        { label: 'Wygenerowany obrót', value: 'sales_value' },
        { label: 'Uzyskanych bonifikat', value: 'bonus' }
      ],
      query: 'type',
      isMulti: false,
      dependentFilters: {
        products: { defaultValue: [], mainFilterDependentValues: ['sales_value'] }
      }
    },
    {
      label: 'Rodzaj sprzedaży:',
      options: [
        { label: 'Stacjonarna', value: 'stationary' },
        { label: 'Internetowa', value: 'shop' },
        { label: 'Wszystkie', value: 'all' },
      ],
      query: 'scope',
      isMulti: false,
      dependentFilters: {
        products: { defaultValue: [], mainFilterDependentValues: ['stationary'] },
        source: { defaultValue: 'all', mainFilterDependentValues: ['shop', 'all'] }
      }
    },
    {
      label: 'Źrodło:',
      options: [
        { label: 'Własne', value: 'own' },
        { label: 'Podwładnych', value: 'sub' },
        { label: 'Wszystkie', value: 'all' },
      ],
      query: 'source',
      isMulti: false,
      visibleIf: {
        scope: ['shop', 'all'],
      },
    },
    {
      label: 'Produkty:',
      options: statisticsProducts,
      query: 'products',
      isMulti: true,
      isClearable: true,
      visibleIf: {
        type: ['sales_value'],
        scope: ['stationary']
      }
    },
  ]

  return (
    <Statistics 
      loggedUser={loggedUser} 
      statistics={statistics}
      isDataLoading={areStatisticsLoading}
      activeFilters={statisticsActiveFilters}
      areOwnStatistics={areOwnStatistics}
      filters={filters}
      filterStatistics={chosenFilters => areOwnStatistics 
        ? filterStatistics(chosenFilters, areOwnStatistics) 
        : filterStatistics(chosenFilters, areOwnStatistics, representativeId)
      } />
  )
}

export default RepresentativesStatisticsContainer