export const statuses = [
  { id: 0, name: 'Oczekujące'},
  { id: 1, name: 'W realizacji' },
  { id: 2, name: 'Częściowo zrealizowane' },
  { id: 3, name: 'Zrealizowane' }
]

export const statusesIds = {
  waiting: 0,
  in_progress: 1,
  done_partially: 2,
  done: 3 
}