import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'

// PAGES
import ProductsPage from './ProductsPage'
import ProductDetailsPage from './ProductDetailsPage'

const ProductRoutes = (match, loggedUser) => [
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/produkty`} 
    component={ProductsPage} 
    permissions={['product.viewAny']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/produkty/:product_id`} 
    component={ProductDetailsPage} 
    permissions={['product.view', 'product.update']} />,
]

export default ProductRoutes