import React from 'react'
import { WarehouseListContainer } from 'modules/Warehouse'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const WarehousePage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Magazyn'}/>
      <WarehouseListContainer />
    </Fade>
  )
}

export default WarehousePage