import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import Fade from 'shared/components/Fade'
import Checkbox from 'shared/components/Checkbox'
import Forms from 'shared/styled/Forms'
import Switcher from 'shared/components/Switcher'
import { PrimaryButton } from 'shared/styled/Buttons'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import history from 'config/history'
import Select from 'shared/components/Select'
import Datepicker from 'shared/components/Datepicker'
import InputMask from 'react-input-mask'

const AdministratorForm = ({ 
  hasPermission,
  loggedUser,
  currentAdministrator, 
  salesDistrictsForSelect,
  isFormSubmitting, 
  callApi, 
  formValidationErrors, 
  setFormValidationErrors,
  submitBtnText,
  editing = false
}) => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState(null)
  const [allDistrictsSelected, setAllDistrictsSelected] = useState(false)

  useEffect(() => {
    setErrors(formValidationErrors)
  }, [formValidationErrors])

  useEffect(() => {
    return () => dispatch(setFormValidationErrors())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

   useEffect(() => {
    if (currentAdministrator && currentAdministrator.all_districts) {
      setAllDistrictsSelected(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = formData => {
    if (editing) {
      dispatch(callApi(formData, currentAdministrator.id))
    } else {
      dispatch(callApi(formData))
    }
    dispatch(setFormValidationErrors(null))
  }

  const selectAllDistricts = checkBoxValue => {
    setAllDistrictsSelected(!allDistrictsSelected)
    formik.setFieldValue('all_districts', checkBoxValue)
    formik.setFieldValue('sales_districts', [])
  }

  const initialData = {
    full_name: currentAdministrator && currentAdministrator.full_name ? currentAdministrator.full_name : '',
    user_email: currentAdministrator && currentAdministrator.user_email ? currentAdministrator.user_email : '',
    password: currentAdministrator && currentAdministrator.password ? currentAdministrator.password : '',
    password_confirmation: currentAdministrator && currentAdministrator.password_confirmation ? currentAdministrator.password_confirmation : '',
    phone: currentAdministrator && currentAdministrator.phone ? currentAdministrator.phone : '',
    bank_account_number: currentAdministrator && currentAdministrator.bank_account_number ? currentAdministrator.bank_account_number : '',
    status: currentAdministrator && currentAdministrator.status ? currentAdministrator.status : false,
    sales_districts: currentAdministrator && currentAdministrator.sales_districts ? currentAdministrator.sales_districts : null,
    agreement_number: currentAdministrator && currentAdministrator.agreement_number ? currentAdministrator.agreement_number : '',
    percent_web: currentAdministrator && currentAdministrator.percent_web ? currentAdministrator.percent_web : '',
    partnership_since: currentAdministrator && currentAdministrator.partnership_since ? new Date(currentAdministrator.partnership_since) : '',
    all_districts: currentAdministrator && currentAdministrator.all_districts ? true : false,
    pesel: currentAdministrator && currentAdministrator.pesel ? currentAdministrator.pesel : ''
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit
  })

  const switcher = {
    title: 'Status aktywności',
    field_name: 'status',
    activeText: 'Aktywny',
    inactiveText: 'Nieaktywny'
  }

  return (
    <Fade>
      <Forms.FormWrapper>
        <Forms.Form noValidate onSubmit={formik.handleSubmit} onChange={() => setErrors(null)}>
          { switcher
            ? <Switcher 
                hasPermission={hasPermission}
                value={formik.values[switcher.field_name]}
                switcher={switcher}
                onChange={(event) => {
                  const value = event.target.checked ? true : false
                  formik.setFieldValue(switcher.field_name, value)
                }} />
            : null
          }
          <Forms.FormContent darken>
            <Fragment>
              <Forms.Preheader>Dane podstawowe</Forms.Preheader>
              <Forms.FieldsWrapper>
                <Forms.FormGroup>
                  <Forms.Input 
                    type={'text'}
                    name={'full_name'}
                    darken 
                    autocomplete="off" 
                    required 
                    onChange={formik.handleChange} 
                    value={formik.values['full_name'] } 
                    disabled={!hasPermission} />
                  <Forms.InputBar />
                  <Forms.Label darken disabled={!hasPermission}>Imię i nazwisko*</Forms.Label>
                  { errors && errors['full_name']
                    ? <Forms.InputError>{ errors && errors['full_name'] && errors['full_name'][0] }</Forms.InputError>
                    : null
                  }
                </Forms.FormGroup> 
                <Forms.FormGroup>
                  <Forms.Input 
                    type={'text'}
                    name={'user_email'}
                    darken 
                    autocomplete="off" 
                    required 
                    onChange={formik.handleChange} 
                    value={formik.values['user_email'] } 
                    disabled={!hasPermission} />
                  <Forms.InputBar />
                  <Forms.Label darken disabled={!hasPermission}>Adres email*</Forms.Label>
                  { errors && errors['user_email']
                    ? <Forms.InputError>{ errors && errors['user_email'] && errors['user_email'][0] }</Forms.InputError>
                    : null
                  }
                </Forms.FormGroup> 
                <Forms.FormGroup>
                  <Forms.Input 
                    type={'password'}
                    name={'password'}
                    darken 
                    autocomplete="off" 
                    required 
                    onChange={formik.handleChange} 
                    value={formik.values['password'] } 
                    disabled={!hasPermission} />
                  <Forms.InputBar />
                  <Forms.Label darken disabled={!hasPermission}>Hasło*</Forms.Label>
                  { errors && errors['password']
                    ? <Forms.InputError>{ errors && errors['password'] && errors['password'][0] }</Forms.InputError>
                    : null
                  }
                </Forms.FormGroup> 
                <Forms.FormGroup>
                  <Forms.Input 
                    type={'password'}
                    name={'password_confirmation'}
                    darken 
                    autocomplete="off" 
                    required 
                    onChange={formik.handleChange} 
                    value={formik.values['password_confirmation'] } 
                    disabled={!hasPermission} />
                  <Forms.InputBar />
                  <Forms.Label darken disabled={!hasPermission}>Powtórz hasło*</Forms.Label>
                  { errors && errors['password_confirmation']
                    ? <Forms.InputError>{ errors && errors['password_confirmation'] && errors['password_confirmation'][0] }</Forms.InputError>
                    : null
                  }
                </Forms.FormGroup> 
                <Forms.FormGroup>
                  <Forms.Input 
                    type={'text'}
                    name={'pesel'}
                    darken 
                    autocomplete="off" 
                    required 
                    onChange={formik.handleChange} 
                    value={formik.values['pesel'] } 
                    disabled={!hasPermission} />
                  <Forms.InputBar />
                  <Forms.Label darken disabled={!hasPermission}>PESEL*</Forms.Label>
                  { errors && errors['pesel']
                    ? <Forms.InputError>{ errors && errors['pesel'] && errors['pesel'][0] }</Forms.InputError>
                    : null
                  }
                </Forms.FormGroup>
                <Forms.FormGroup>
                  <Forms.Input 
                    type={'text'}
                    name={'phone'}
                    darken 
                    autocomplete="off" 
                    required 
                    onChange={formik.handleChange} 
                    value={formik.values['phone'] } 
                    disabled={!hasPermission} />
                  <Forms.InputBar />
                  <Forms.Label darken disabled={!hasPermission}>Numer telefonu*</Forms.Label>
                  { errors && errors['phone']
                    ? <Forms.InputError>{ errors && errors['phone'] && errors['phone'][0] }</Forms.InputError>
                    : null
                  }
                </Forms.FormGroup> 
                <Forms.FormGroup>
                  <AllDistrictCheckbox allDistrictsSelected={allDistrictsSelected}>
                    <Checkbox 
                      isEditable={hasPermission}
                      label={'Zarządzaj wszystkimi okręgami'}
                      value={formik.values['all_districts']}
                      checked={formik.values['all_districts']}
                      handleChange={e => selectAllDistricts(e.target.checked ? true : false)}  />
                  </AllDistrictCheckbox>
                  { !allDistrictsSelected &&
                    <Select 
                      isMulti={true}
                      value={formik.values['sales_district']} 
                      label={'Okręgi sprzedaży*'}
                      options={salesDistrictsForSelect}
                      isDisabled={!hasPermission}
                      onChange={option => {
                        formik.setFieldValue('sales_districts', option)
                      }} />
                  }
                </Forms.FormGroup>
                <Forms.FormGroup>
                  <Forms.Input 
                    type={'text'}
                    name={'agreement_number'}
                    darken 
                    autocomplete="off" 
                    required 
                    onChange={formik.handleChange} 
                    value={formik.values['agreement_number'] } 
                    disabled={!hasPermission} />
                  <Forms.InputBar />
                  <Forms.Label darken disabled={!hasPermission}>Numer umowy</Forms.Label>
                  { errors && errors['agreement_number']
                    ? <Forms.InputError>{ errors && errors['agreement_number'] && errors['agreement_number'][0] }</Forms.InputError>
                    : null
                  }
                </Forms.FormGroup> 
                <Forms.FormGroup>
                  <Datepicker 
                    field={{
                      name: 'partnership_since',
                      type: 'date',
                      label: 'Data rozpoczęcia współpracy*'
                    }} 
                    value={formik.values['partnership_since']}
                    setDate={formik.setFieldValue}
                    isDisabled={!hasPermission} />
                </Forms.FormGroup>
                <Forms.FormGroup>
                  <Forms.Input 
                    type={'number'}
                    name={'percent_web'}
                    darken 
                    autocomplete="off" 
                    required 
                    onChange={formik.handleChange} 
                    value={formik.values['percent_web'] } 
                    disabled={!hasPermission} />
                  <Forms.InputBar />
                  <Forms.Label darken disabled={!hasPermission}>Procent bonifikaty za sprzedaż internetową (kod własny)</Forms.Label>
                  { errors && errors['percent_web']
                    ? <Forms.InputError>{ errors && errors['percent_web'] && errors['percent_web'][0] }</Forms.InputError>
                    : null
                  }
                </Forms.FormGroup> 
              </Forms.FieldsWrapper>
            </Fragment>
            <Fragment>
              <Forms.Preheader>Dane rozliczeniowe</Forms.Preheader>
              <Forms.FieldsWrapper>
                <Forms.FormGroup>
                  <InputMask 
                    mask={"P\L99999999999999999999999999"} // eslint-disable-line
                    value={formik.values['bank_account_number']} 
                    onChange={formik.handleChange}
                    autocomplete="off"  
                    required
                    disabled={!hasPermission}>
                      {(inputProps) => <Forms.Input {...inputProps} type={'text'} name={'bank_account_number'} darken disabled={!hasPermission}/>}
                  </InputMask>
                  <Forms.InputBar />
                  <Forms.Label darken disabled={!hasPermission}>Numer konta bankowego*</Forms.Label>
                  { errors && errors['bank_account_number']
                    ? <Forms.InputError>{ errors && errors['bank_account_number'] && errors['bank_account_number'][0] }</Forms.InputError>
                    : null
                  }
                </Forms.FormGroup> 
              </Forms.FieldsWrapper>
            </Fragment>
            { hasPermission &&
              <Forms.FormButtonsWrapper>
                <PrimaryButton disabled={ isFormSubmitting } type="submit">
                  { isFormSubmitting ? <ButtonLoader /> : submitBtnText }
                </PrimaryButton>
                <Forms.CancelButton type="button" onClick={() => history.goBack()}>Anuluj</Forms.CancelButton>
              </Forms.FormButtonsWrapper>
            }
          </Forms.FormContent>
        </Forms.Form>
      </Forms.FormWrapper>
    </Fade>
  )
}

export default AdministratorForm

const AllDistrictCheckbox = styled.div`
  margin-bottom: ${props => props.allDistrictsSelected ? '0' : '10px'};
`