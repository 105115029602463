import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProduct, updateProduct } from '../thunks'
import { setFormValidationErrors } from '../actions'
import ProductDetails from '../components/ProductDetails'

const ProductDetailsContainer = ({ match }) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const currentProduct = useSelector(({ products }) => products.currentProduct)
  const isDataLoading = useSelector(({ products }) => products.isDataLoading)
  const isFormSubmitting = useSelector(({ products }) => products.isFormSubmitting)
  const formValidationErrors = useSelector(({ products }) => products.formValidationErrors)
  const dispatch = useDispatch()

  const productId = match.params.product_id

  useEffect(() => {
    dispatch(getProduct(productId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ProductDetails 
      loggedUser={loggedUser}
      currentProduct={currentProduct}
      updateProduct={updateProduct}
      isPageLoading={isDataLoading} 
      isFormSubmitting={isFormSubmitting}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default ProductDetailsContainer