import React from 'react'
import { useDispatch } from 'react-redux'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import SearchBanner from 'shared/components/SearchBanner'
import Loader from 'modules/PageLoader/components/Loader'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa'
import DataTableLink from 'shared/components/DataTable/DataTableLink'
import { DataTableAction, DataTableActionLink } from 'shared/components/DataTable/DataTableAction'
import { StatusIndicator, TableLinkWrapper } from 'shared/components/DataTable/DataTableElements'
import { hasPermission } from 'utils/auth'

const AdministratorsList = ({
  loggedUser,
  searchUrl,
  getAdministrators,
  removeAdministrator,
  administrators,
  isPageLoading,
  enableModal
}) => {

  const dispatch = useDispatch()
  const modalContent = {
    title: 'Czy na pewno chcesz usunąć użytkownika?',
    description: 'Operacja jest nieodwracalna',
    confirmButtonText: 'Usuń',
    cancelButtonText: 'Anuluj'
  }

  return (
    <>
      <SearchBanner 
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={getAdministrators}
        createPermission='operator.create'
        buttonText='Dodaj nowego'
        buttonLink='/panel/administratorzy/nowy'/>
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <DataTable
            callApi={getAdministrators}
            data={administrators}
            columns={[
              { name: 'Imię i nazwisko', field: row => {
                if (hasPermission('operator.view', loggedUser)) {
                  return (
                    <TableLinkWrapper>
                      <StatusIndicator active={row.status}/><DataTableLink to={`/panel/administratorzy/${row.id}`}>{row.full_name}</DataTableLink>
                    </TableLinkWrapper>
                  )
                } else {
                  return (
                    <TableLinkWrapper>
                      <StatusIndicator active={row.status}/> 
                      { row.full_name } 
                    </TableLinkWrapper>
                  )
                }
              }},
              { name: 'Email', field: 'user_email' },
              { name: 'Data rozp. współpracy', field: 'partnership_since' },
              { name: 'Akcje', field: row => (
                <>
                  {
                    hasPermission('operator.update', loggedUser) &&
                    <DataTableActionLink 
                      tooltiptext='Edytuj'
                      to={`/panel/administratorzy/${row.id}`}>
                      <FaRegEdit size={18}/>
                    </DataTableActionLink>
                  }
                  {
                    hasPermission('operator.delete', loggedUser) &&
                    <DataTableAction 
                      tooltiptext='Usuń'
                      onClick={() => {
                        dispatch(enableModal({
                          content: modalContent,
                          onConfirm: removeAdministrator,
                          onConfirmParams: [row.id]
                        }))
                      }}><FaRegTrashAlt size={18}/>
                    </DataTableAction>
                  }
                </>
              )},
            ]}
          />
        </Loader>
      </Blocks.Block>
    </>
  )
}

export default AdministratorsList
