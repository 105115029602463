import React from 'react'
import { SalesDistrictsDetailsContainer } from 'modules/SalesDistricts'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const DistrictDetailsPage = ({ match }) => {

  return (
    <Fade>
      <Breadcrumbs linkLabel={'Okręgi sprzedaży'}/>
      <SalesDistrictsDetailsContainer match={match} />
    </Fade>
  )
}

export default DistrictDetailsPage