
const colors = {
  main: '#4073cb',
  mainHover: '#6595ea',
  sub: '#b7bdc5',
  light: '#e0e0e2',
  textOnWhite: '#6e707b',
  inputText: '#656f73',
  black: '#333333',
  white: '#FFFFFF',
  bronze: '#CDB7A0',
  inputGray: '#e7e9f1',
  lightGray: '#F2F3F8',
  gray: '#A1A1A1',
  mediumGray: '#C7CBE0',
  darkGray: '#333333',
  orange: '#FF8F1C',
  red: '#dc2f2f',
  green: '#2c9837',
  purple: '#631D78',
  yellow: '#FFF761'
}

const breakpoints = {
  xs: '480px',
	sm: '768px',
	md: '992px',
  lg: '1200px',
  xl: '1400px'
}

const font = {
  family: 'Open Sans, sans-serif',
  weight: 'normal' 
}

const theme = {
  colors,
  breakpoints,
  font
}

export default theme;
