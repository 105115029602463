import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from '../../../config/theme'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdLastPage, MdFirstPage } from 'react-icons/md'

const Pagination = ({
  callApi,
  links,
  from,
  to,
  total
}) => {

  const dispatch = useDispatch()
  
  return (
    <PaginationWrapper>
      <PagesAmount>
          {from ? from : '0'}-{to} z {total}
      </PagesAmount>
      <ArrowsWrapper>
        <ArrowButton disabled={links.first ? false : true} onClick={() => links.first ? dispatch(callApi(links.first)) : {}}><MdFirstPage size={22} /></ArrowButton>
        <ArrowButton disabled={links.prev ? false : true} onClick={() => links.prev ? dispatch(callApi(links.prev)) : {}}><MdKeyboardArrowLeft size={22}/></ArrowButton>
        <ArrowButton disabled={links.next ? false : true} onClick={() => links.next ? dispatch(callApi(links.next)) : {}}><MdKeyboardArrowRight size={22}/></ArrowButton>
        <ArrowButton disabled={links.last ? false : true} onClick={() => links.last ? dispatch(callApi(links.last)) : {}}><MdLastPage size={22}/></ArrowButton>
      </ArrowsWrapper>
    </PaginationWrapper>
  )
}

export default Pagination

const PaginationWrapper = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: ${theme.breakpoints.xs}) {
    flex-direction: column;
    justify-content: center;
  }
`

const PagesAmount = styled.div`
  font-size: ${rem('14px')};
  color: ${theme.colors.gray};
  @media (max-width: ${theme.breakpoints.xs}) {
    margin-bottom: 10px;
  }
`

const ArrowsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ArrowButton = styled.button`
  outline: none;
  background: none;
  border: none;
  padding: 3px;
  margin-left: 10px;
  cursor:  ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.disabled ? theme.colors.gray : theme.colors.darkGray};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.breakpoints.xs}) {
    &:first-of-type {
      margin-left: 0;
    }
  }
`