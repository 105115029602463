
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import DatePicker, { registerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
import 'react-datepicker/dist/react-datepicker.css'
import { PickerWrapper, DatePickerStyles } from 'shared/styled/DatePicker'
import theme from 'config/theme'
import { MdClear } from 'react-icons/md'

registerLocale('pl', pl)

const DateFilter = ({ 
  value, 
  onChange,
  placeholder = null,
  maxDate = null,
  minDate = null,
  showMonthYearPicker = false,
  dateFormat = 'yyyy-MM-dd',
  onClear,
  isClearable = false
}) => {

  return (
    <PickerWrapper>
      <DatePicker  
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '-80px'
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
            boundariesElement: 'viewport'
          }
        }}
        maxDate={maxDate}
        minDate={minDate}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        locale="pl"
        selected={value}
        onChange={onChange}
        showMonthYearPicker={showMonthYearPicker}
        customInput={<CustomDateFilter onClear={onClear} isClearable={isClearable} />} />
    </PickerWrapper>
  )
}

export default DateFilter

const CustomDateFilter = forwardRef((props, ref) => {
  return (
    <>
      <DateFilterSelect 
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onClick}
        onClick={props.onClick}
        isClearable={props.isClearable} />
      { props.isClearable && props.value &&
        <ClearButton onClick={props.onClear}><MdClear size={16} /></ClearButton>
      }
    </>
  )
})

const DateFilterSelect = styled.input`
  ${DatePickerStyles};
  font-family: ${theme.font.family};
  cursor: pointer;
  border: 1px solid ${theme.colors.sub};
  padding: ${props => props.isClearable ? '9px 30px 9px 11px' : '9px 11px'};
  max-width: ${props => props.isClearable ? '110px' : '100px'};
  &:hover {
    border-color: ${theme.colors.main};
  }
`

const ClearButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 4px;
  top: 6px;
  padding: 0;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  color: ${theme.colors.mediumGray};
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${theme.colors.inputGray};
    color: ${theme.colors.gray};
  }
`