import React from 'react'
import { ProductDetailsContainer } from 'modules/Products'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const ProductDetailsPage = ({ match }) => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Produkty'}/>
      <ProductDetailsContainer match={match}/>
    </Fade>
  )
}

export default ProductDetailsPage