import React from 'react'
import { useSelector } from 'react-redux'
import history from 'config/history'
import { addNewFreelancer} from '../thunks'
import { setFormValidationErrors } from '../actions'
import FreelancersNew from '../components/FreelancersNew'

const FreelancersNewContainer = () => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isFormSubmitting = useSelector(({ freelancers }) => freelancers.isFormSubmitting)
  const isDataLoading = useSelector(({ freelancers }) => freelancers.isDataLoading)
  const formValidationErrors = useSelector(({ freelancers }) => freelancers.formValidationErrors)

  return (
    <FreelancersNew
      loggedUser={loggedUser}
      history={history}
      isPageLoading={isDataLoading}
      isFormSubmitting={isFormSubmitting}
      addNewFreelancer={addNewFreelancer}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default FreelancersNewContainer
