import React from 'react'
import { useDispatch } from 'react-redux'
import Form from 'shared/components/Form'
import shortid from 'shortid'
import Fade from 'shared/components/Fade'

const ShopsForm = ({ 
  hasPermission,
  loggedUser,
  currentShop, 
  representativesForSelect,
  isFormSubmitting, 
  callApi, 
  formValidationErrors, 
  setFormValidationErrors,
  submitBtnText,
  editing = false
}) => {
  const dispatch = useDispatch()

  const onSubmit = formData => {
    if (editing) {
      dispatch(callApi(formData, currentShop.id))
    } else {
      dispatch(callApi(formData))
    }
    dispatch(setFormValidationErrors(null))
  }

  const representativesData = representativesForSelect ? representativesForSelect : []

  const initialData = {
    company_name: currentShop && currentShop.company_name ? currentShop.company_name : '',
    user_email: currentShop && currentShop.user_email ? currentShop.user_email : '',
    owner_full_name: currentShop && currentShop.owner_full_name ? currentShop.owner_full_name : '',
    password: currentShop && currentShop.password ? currentShop.password : '',
    password_confirmation: currentShop && currentShop.password_confirmation ? currentShop.password_confirmation : '',
    partnership_since: currentShop && currentShop.partnership_since ? new Date(currentShop.partnership_since) : '',
    bank_account_number: currentShop && currentShop.bank_account_number ? currentShop.bank_account_number : '',
    status: currentShop && currentShop.status ? currentShop.status : false,
    nip: currentShop && currentShop.nip ? currentShop.nip : '',
    city: currentShop && currentShop.city ? currentShop.city : '',
    postcode: currentShop && currentShop.postcode ? currentShop.postcode : '',
    street: currentShop && currentShop.street ? currentShop.street : '',
    phone: currentShop && currentShop.phone ? currentShop.phone : '',
    suite_number: currentShop && currentShop.suite_number ? currentShop.suite_number : '',
    representative: currentShop && currentShop.representative ? currentShop.representative : null,
    agreement_number: currentShop && currentShop.agreement_number ? currentShop.agreement_number : '',
    orders_handled_by_representative: currentShop && currentShop.orders_handled_by_representative ? currentShop.orders_handled_by_representative : false,
    wholesale_percent_lower_default: currentShop && currentShop.wholesale_percent_lower_default ? currentShop.wholesale_percent_lower_default : false,
    wholesale_percent_lower_increased: currentShop && currentShop.wholesale_percent_lower_increased ? currentShop.wholesale_percent_lower_increased : false,
    wholesale_percent_lower_threshold: currentShop && currentShop.wholesale_percent_lower_threshold ? currentShop.wholesale_percent_lower_threshold : false,
    wholesale_percent_lower_current_value: currentShop && currentShop.wholesale_percent_lower_current_value ? currentShop.wholesale_percent_lower_current_value : false,
    wholesale_percent_lower_current_month_name: currentShop && currentShop.wholesale_percent_lower_current_month_name ? currentShop.wholesale_percent_lower_current_month_name : false
  }

  const switcher = {
    title: 'Status aktywności',
    field_name: 'status',
    activeText: 'Aktywny',
    inactiveText: 'Nieaktywny'
  }

  const formLayout = [
    {
      key: shortid.generate(),
      title: 'Zarządzanie zamówieniami jednostki',
      fields: [
        {
          name: 'orders_handled_by_representative',
          type: 'checkbox',
          label: 'Zamówienia zarządzane przez przedstawiciela*',
          warningText: 'Jeżeli powyższe pole zostanie zaznaczone, zarządzanie zamówieniami tej jednostki gospodarczej będzie możliwe z poziomu nadrzędnego przedstawiciela handlowego.'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Dane podstawowe',
      fields: [
        {
          name: 'user_email',
          type: 'text',
          label: 'Adres email*'
        },
        {
          name: 'phone',
          type: 'text',
          label: 'Numer telefonu*'
        },
        {
          name: 'password',
          type: 'password',
          label: 'Hasło*'
        },
        {
          name: 'password_confirmation',
          type: 'password',
          label: 'Powtórz hasło*'
        },
        {
          name: 'representative',
          type: 'select',
          label: 'Przedstawiciel handlowy*',
          options: representativesData,
          warningText: '* Przy zmianie przedstawiciela handlowego wszystkie kody rabatowe, z których aktualna jednostka gosp. dostaje bonifikaty zostaną przypisane do nowo wybranego przedstawiciela.'
        },
        {
          name: 'partnership_since',
          type: 'date',
          label: 'Data rozpoczęcia współpracy*'
        },
        {
          name: 'agreement_number',
          type: 'text',
          label: 'Numer umowy'
        }
      ]
    },
     {
      key: shortid.generate(),
      title: 'Dane firmy',
      fields: [
        {
          name: 'company_name',
          type: 'text',
          label: 'Nazwa firmy*'
        },
        {
          name: 'owner_full_name',
          type: 'text',
          label: 'Imię i nazwisko właściciela*'
        },
        {
          name: 'nip',
          type: 'text',
          label: 'NIP',
          mask: '9999999999'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Adres',
      fields: [
        {
          name: 'street',
          type: 'text',
          label: 'Ulica*'
        },
        {
          name: 'suite_number',
          type: 'text',
          label: 'Numer lokalu*'
        },
        {
          name: 'postcode',
          type: 'text',
          label: 'Kod pocztowy*'
        },
        {
          name: 'city',
          type: 'text',
          label: 'Miejscowość*'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Dane rozliczeniowe',
      fields: [
        {
          name: 'bank_account_number',
          type: 'text',
          label: 'Numer konta bankowego*',
          mask: "P\L99999999999999999999999999" // eslint-disable-line
        },
        {
          name: 'wholesale_percent_lower_threshold',
          type: 'number',
          label: 'Minimalna wartość zamówień w poprzednim miesiącu dla upustu podwyższonego (zł)*'
        },
        {
          name: 'wholesale_percent_lower_default',
          type: 'number',
          label: 'Dodatkowy rabat standardowy (poniżej progu) na zakup towaru (+ punktów procentowych)*',
          warningText: 'Uwaga! Finalnie obniżka procentowa będzie sumą tej wartości i wartości obniżki skonfigurowanej dla produktu'
        },
        {
          name: 'wholesale_percent_lower_increased',
          type: 'number',
          label: 'Dodatkowy rabat podwyższony (powyżej progu) na zakup towaru (+ punktów procentowych)*',
          warningText: 'Uwaga! Finalnie obniżka procentowa będzie sumą tej wartości i wartości obniżki skonfigurowanej dla produktu'
        },
      ].concat(editing ? [{
        name: 'wholesale_percent_lower_recalculate_now',
        type: 'checkbox',
        label: 'Zastosuj zmiany rabatu natychmiast, przeliczając wartość dla kolejnych zamówień w obecnym miesiącu (Obecny dodatkowy rabat na miesiąc '
          + initialData.wholesale_percent_lower_current_month_name
          + ' wynosi ' + initialData.wholesale_percent_lower_current_value + '%).',
        warningText: 'Pozostawienie pustego zaznaczenia oznacza, że zmiany będą miały efekt dopiero na kolejny miesiąc.'
      }] : [])
    }
  ]

  return (
    <Fade>
      <Form
        editing={editing}
        loggedUser={loggedUser}
        hasPermission={hasPermission}
        switcher={switcher}
        initialData={initialData}
        formLayout={formLayout}
        isFormSubmitting={isFormSubmitting} 
        onSubmit={onSubmit}
        submitBtnText={submitBtnText}
        setFormValidationErrors={setFormValidationErrors}
        formValidationErrors={formValidationErrors} />
    </Fade>
  )
}

export default ShopsForm