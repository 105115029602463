import React from 'react'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import SearchBanner from 'shared/components/SearchBanner'
import Loader from 'modules/PageLoader/components/Loader'
import DataTableLink from 'shared/components/DataTable/DataTableLink'
import { hasPermission } from 'utils/auth'
import { FaRegEdit } from 'react-icons/fa'
import { DataTableActionLink } from 'shared/components/DataTable/DataTableAction'
import Filters from 'shared/components/Filters'
import Fade from 'shared/components/Fade'

const CodesList = ({
  searchUrl,
  loggedUser,
  getCodes,
  filterCodes,
  activeFilters,
  codes,
  isPageLoading,
  salesDistrictsForFilter,
  representativesForFilter
}) => {

  const codesFilters = [
    {
      label: 'OS:',
      options: salesDistrictsForFilter,
      query: 'sales_districts_ids',
      isMulti: true,
      isClearable: true
    },
    {
      label: 'PH:',
      options: representativesForFilter,
      query: 'representatives_ids',
      isMulti: true,
      isClearable: true
    }
  ]

  return (
    <>
      <SearchBanner 
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={getCodes}
        buttonText='Dodaj nowy'
        createPermission='code.create'
        buttonLink='/panel/kody-rabatowe/nowy'/>
      <Blocks.Block> 
        <Loader isPageLoading={isPageLoading}>
          <Fade>
            <Filters 
              loggedUser={loggedUser}
              filters={codesFilters}
              activeFilters={activeFilters}
              filterData={filterCodes} />
            <DataTable
              callApi={getCodes}
              data={codes}
              columns={[
                { name: 'Kod rabatowy', field: row => {
                  if (hasPermission('code.view', loggedUser)) {
                    return <DataTableLink to={`/panel/kody-rabatowe/${row.id}`}>{row.code}</DataTableLink>
                  } else {
                    return row.code
                  }
                }},
                { name: 'Właściciel', field: row => row.owner.name },
                { name: 'Przedstawiciel', field: row => row.representative ? row.representative.name : '-' },
                { name: 'Okręg sprzedaży', field: row => row.sales_district ? row.sales_district.name : '-' },
                { name: 'Akcje', field: row => (
                  <>
                    {
                      hasPermission('code.update', loggedUser) &&
                      <DataTableActionLink tooltiptext='Edytuj' to={`/panel/kody-rabatowe/${row.id}`}><FaRegEdit size={18}/></DataTableActionLink>
                    }
                  </>
                )},
              ]}
            />
          </Fade>
        </Loader>
      </Blocks.Block>
    </>
  )
}

export default CodesList
