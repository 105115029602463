import * as types from './types'

const initialState = {
  freelancers: null,
  freelancersForSelect: [],
  currentFreelancer: null,
  isFormSubmitting: false,
  formValidationErrors: null,
  isDataLoading: false,
  areStatisticsLoading: false,
  statistics: null,
  statisticsProducts: [],
  statisticsActiveFilters: {
    type: 'sales_value'
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FREELANCERS:
      return {
        ...state,
        freelancers: action.payload
      }
    case types.SET_CURRENT_FREELANCER:
      return {
        ...state,
        currentFreelancer: action.payload
      }
    case types.SET_FREELANCERS_FOR_SELECT:
      return {
        ...state,
        freelancersForSelect: action.payload
      }
    case types.SET_FREELANCERS_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_FREELANCERS_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_FREELANCERS_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_FREELANCERS_STATISTICS_LOADING:
      return {
        ...state,
        areStatisticsLoading: action.payload
      }
    case types.SET_FREELANCERS_STATISTICS:
      return {
        ...state,
        statistics: action.payload
      }
    case types.SET_FREELANCERS_STATISTICS_ACTIVE_FILTERS: 
      return {
        ...state,
        statisticsActiveFilters: action.payload
      }
    default: 
      return state
  }
}
