import * as types from './types'

export const setBalance = balance => {
  return {
    type: types.SET_BALANCE,
    payload: balance
  }
}

export const setUserBalance = balance => {
  return {
    type: types.SET_USER_BALANCE,
    payload: balance
  }
}

export const setTransactionTypesForFilter = transactions => {
  return {
    type: types.SET_TRANSACTION_TYPES,
    payload: transactions
  }
}

export const setBalanceCodesForFilter = codes => {
  return {
    type: types.SET_BALANCE_CODES_FOR_FILTER,
    payload: codes
  }
}

export const setActiveFilters = filters => {
  return {
    type: types.SET_BALANCE_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_BALANCE_LOADING,
    payload: isLoading
  }
}