import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { rem } from 'polished'
import Blocks from 'shared/styled/Blocks'
import theme from 'config/theme'
import { OutlineButtonStyles } from 'shared/styled/Buttons'
import { ButtonLoader } from 'shared/styled/ButtonLoader'

const CurrentCode = ({ 
  generateCodePDF,
  isFormSubmitting,
  dashboardInfo
}) => {

  const dispatch = useDispatch()

  return (
    <CurrentCodeWrapper>
      <Content>
        <CodeText>Twój indywidualny kod</CodeText>
        <Code>{ dashboardInfo && dashboardInfo.last_active_code.code }</Code>
      </Content>
      <GeneratePDFButton disabled={ isFormSubmitting } dark onClick={() => dispatch(generateCodePDF(dashboardInfo ? dashboardInfo.last_active_code.id : ''))}>
        { isFormSubmitting ? <ButtonLoader dark /> : 'Wydrukuj swoje kupony' }
      </GeneratePDFButton>
    </CurrentCodeWrapper>
  )
}

export default CurrentCode

const CurrentCodeWrapper = styled(Blocks.Block)`
  width: auto;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${theme.breakpoints.xs}) {
    margin: 7px 15px;
  }
`

const Content = styled.div``

const CodeText = styled.div`
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
`

const Code = styled.div`
  color: ${theme.colors.black};
  font-size: ${rem('32px')};
  line-height: 1;
  font-weight: bold;
  margin: 5px 0 15px 0;
`

const GeneratePDFButton = styled.button`
  ${OutlineButtonStyles};
  display: inline-block;
`