import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Table from 'shared/styled/Table'
import { FaRegTrashAlt } from 'react-icons/fa'
import { DataTableAction } from 'shared/components/DataTable/DataTableAction'
import Forms from 'shared/styled/Forms'
import { PrimaryButton } from 'shared/styled/Buttons'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import theme from 'config/theme'
import { rem } from 'polished'
import { parsePrice } from 'utils/global'

const ChosenProducts = ({
  chosenProducts,
  removeProduct,
  handleAmountChange,
  isFormSubmitting,
  reportApiCall,
  errors,
  enableModal,
  modalContent,
  isOrder,
  message,
  handleMessage
}) => {

  const dispatch = useDispatch()
  const productsTotalValue = chosenProducts.reduce((accumulator, product) => (accumulator + (Number(product.amount) * Number(product.price))), 0).toFixed(2)
  const productsTotalAmount = chosenProducts.reduce((accumulator, product) => accumulator + Number(product.amount), 0)

  return (
    <>
      { chosenProducts.length
        ? (
          <ChosenProductsWrapper>
            <Forms.Form noValidate>
              <Table.TableWrapper>
                <Table.Table cellspacing='0'>
                  <Table.TableHead>
                    <Table.TableRow>
                      <Table.TableHeadCell>#</Table.TableHeadCell>
                      <Table.TableHeadCell>Zdjęcie</Table.TableHeadCell>
                      <Table.TableHeadCell>Nazwa produktu</Table.TableHeadCell>
                      { isOrder && <Table.TableHeadCell>Cena</Table.TableHeadCell> }
                      <Table.TableHeadCell width={200}>Ilość</Table.TableHeadCell>
                      <Table.TableHeadCell>Akcje</Table.TableHeadCell>
                    </Table.TableRow>
                  </Table.TableHead>
                  <Table.TableBody>
                    { chosenProducts.map((row, rowIndex) => {
                      const number = rowIndex + 1
                      return (
                        <Table.PosedRow key={row.id} i={number} initialPose='hidden' pose='visible'>
                          <Table.TableCell>{number}.</Table.TableCell>
                          <Table.TableCell>
                            <ProductThumbnail src={row.thumbnail} alt={row.name} />
                          </Table.TableCell>
                          <Table.TableCell>{ row.name }</Table.TableCell>
                          { isOrder && <Table.TableCell>{ parsePrice(row.price) } zł</Table.TableCell> }
                          <Table.TableCell width={200}>
                            <CountInput
                              onChange={e => handleAmountChange(row.id, e.target.value)}
                              name={`amount-${row.id}`}
                              type='number'
                              min='1'
                              max={isOrder ? '' : row.stock} />
                            { errors && errors[`products_validation.${row.id}.amount`] &&
                              <Forms.InputError>{ errors[`products_validation.${row.id}.amount`] }</Forms.InputError>
                            }
                          </Table.TableCell>
                          <Table.TableCell>
                            <DataTableAction tooltiptext='Usuń' type='button' onClick={() => removeProduct(row.id)}><FaRegTrashAlt size={18}/></DataTableAction>
                          </Table.TableCell>
                        </Table.PosedRow>
                      )
                    })}
                  </Table.TableBody>
                </Table.Table>
              </Table.TableWrapper>
              <CommentsTextareaWrapper>
                <TotalAmountText>Uwagi:</TotalAmountText>
                <CommentsTextarea
                  onChange={e => handleMessage(e.target.value)}
                  rows={2}
                  type='text'
                  value={message}
                />
              </CommentsTextareaWrapper>
              <ConfirmSaleButtonWrapper>
                { isOrder &&
                <TotalAmountWrapper>
                  <TotalAmountBlock>
                    <TotalAmountText>Łączna ilość produktów:</TotalAmountText>
                    <TotalAmountValue>{ productsTotalAmount }</TotalAmountValue>.
                  </TotalAmountBlock>
                  <TotalAmountBlock>
                    <TotalAmountText>Łączna kwota zamówienia:</TotalAmountText>
                    <TotalAmountValue>{ parsePrice(productsTotalValue) } zł</TotalAmountValue>
                  </TotalAmountBlock>
                </TotalAmountWrapper>
                }
                <PrimaryButton
                  disabled={ isFormSubmitting }
                  type='button'
                  onClick={() => {
                    dispatch(enableModal({
                      content: modalContent,
                      onConfirm: reportApiCall,
                      onConfirmParams: [chosenProducts, message]
                    }))
                  }}>
                  { isFormSubmitting ? <ButtonLoader /> : 'Zatwierdź' }
                </PrimaryButton>
              </ConfirmSaleButtonWrapper>
            </Forms.Form>
          </ChosenProductsWrapper>
        ) : null
      }
    </>
  )
}

export default ChosenProducts

const ChosenProductsWrapper = styled.div`
  margin-top: 50px;
`

const ProductThumbnail = styled.img`
  max-width: 50px;
`

const ConfirmSaleButtonWrapper = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const CommentsTextareaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const CountInput = styled.input`
  outline: none;
  border: 1px solid ${theme.colors.mediumGray};
  width: 100%;
  border-radius: 5px;
  padding: 8px 10px;
  color: ${theme.colors.black};
  font-family: ${theme.font.family};
  max-width: 100px;
`

const CommentsTextarea = styled.textarea`
  outline: none;
  border: 1px solid ${theme.colors.mediumGray};
  width: 100%;
  border-radius: 5px;
  padding: 8px 10px;
  color: ${theme.colors.black};
  font-family: ${theme.font.family};
  resize: none;
  margin-top: 10px;
`

const TotalAmountWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px;
`

const TotalAmountBlock = styled.div`
  padding-right: 5px;
  display: flex;
`

const TotalAmountText = styled.div`
  font-size: ${rem('14px')};
`

const TotalAmountValue = styled.span`
  font-size: ${rem('15px')};
  font-weight: bold;
  margin-left: 5px;
`
