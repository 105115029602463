import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterStatistics, getProductsForShopOwnStatistics, getProductsForShopStatistics } from '../thunks'
import Statistics from 'shared/components/Statistics'

const ShopsStatisticsContainer = ({
  shopId = null, 
  areOwnStatistics = true
}) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const statisticsActiveFilters = useSelector(({ shops }) => shops.statisticsActiveFilters)
  const statistics = useSelector(({ shops }) => shops.statistics)
  const statisticsProducts = useSelector(({ shops }) => shops.statisticsProducts)
  const areStatisticsLoading = useSelector(({ shops }) => shops.areStatisticsLoading)
  const dispatch = useDispatch()
  
  useEffect(() => {
    areOwnStatistics 
    ? dispatch(filterStatistics(statisticsActiveFilters, true)) 
    : dispatch(filterStatistics(statisticsActiveFilters, false, shopId)) 

    areOwnStatistics 
    ? dispatch(getProductsForShopOwnStatistics()) 
    : dispatch(getProductsForShopStatistics(shopId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const filters = [
    {
      label: 'Typ danych:',
      options: [
        { label: 'Wygenerowany obrót', value: 'sales_value' },
        { label: 'Uzyskanych bonifikat', value: 'bonus' }
      ],
      query: 'type',
      isMulti: false,
      dependentFilters: {
        products: { defaultValue: [], mainFilterDependentValues: ['sales_value'] }
      }
    },
    {
      label: 'Rodzaj sprzedaży:',
      options: [
        { label: 'Stacjonarna', value: 'stationary' },
        { label: 'Internetowa', value: 'shop' },
        { label: 'Wszystkie', value: 'all' },
      ],
      query: 'scope',
      isMulti: false,
      dependentFilters: {
        products: { defaultValue: [], mainFilterDependentValues: ['stationary'] }
      }
    },
    {
      label: 'Źrodło:',
      options: [
        { label: 'Własne', value: 'own' },
        { label: 'Podwładnych', value: 'sub' },
        { label: 'Wszystkie', value: 'all' },
      ],
      query: 'source',
      isMulti: false
    },
    {
      label: 'Produkty:',
      options: statisticsProducts,
      query: 'products',
      isMulti: true,
      isClearable: true,
      visibleIf: {
        type: ['sales_value'],
        scope: ['stationary']
      }
    },
  ]

  return (
    <Statistics 
      loggedUser={loggedUser} 
      statistics={statistics}
      isDataLoading={areStatisticsLoading}
      activeFilters={statisticsActiveFilters}
      areOwnStatistics={areOwnStatistics}
      statisticsProducts={statisticsProducts}
      filters={filters}
      filterStatistics={chosenFilters => areOwnStatistics 
        ? filterStatistics(chosenFilters, areOwnStatistics) 
        : filterStatistics(chosenFilters, areOwnStatistics, shopId)
      } />
  )
}

export default ShopsStatisticsContainer