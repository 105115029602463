import * as types from './types'

const initialState = {
  chosenReturnedProducts: [],
  isFormSubmitting: false,
  formValidationErrors: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_RETURNED_PRODUCTS:
      return {
        ...state,
        chosenReturnedProducts: action.payload
      }
    case types.SET_RETURNS_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_RETURNS_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    default: 
      return state
  }
}
