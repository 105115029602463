import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import { rem } from 'polished'
import { Router, Redirect, Switch } from 'react-router-dom'
import theme from 'config/theme'
import Authorize from 'hoc/Authorize'
import IsLogged from 'hoc/IsLogged'
import history from 'config/history'
import { ToastContainer } from 'react-toastify'
import { getUser } from 'utils/auth'
import { setLoggedUser } from './actions'

/* PAGES */
import MainPanelPage from 'pages/MainPanelPage'
import LoginPage from 'pages/auth/LoginPage'
import ForgotPasswordPage from 'pages/auth/ForgotPasswordPage'
import ResetPasswordPage from 'pages/auth/ResetPasswordPage'


const App = () => {
  const dispatch = useDispatch()
  const loggedUser = useSelector(({user}) => user.loggedUser)

  const getUserData = async () => {
    const userData = await getUser()
    if (userData && userData.user) {
      const { user: { data }} = userData
      dispatch(setLoggedUser(data))
    }
  }
  
  useEffect(() => {
    getUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <>
      <GlobalStyles />
      <ToastContainer className="ISSToast" />
      <Router history={history}>
        <Switch>    
          <Authorize path="/panel" loggedUser={loggedUser} component={MainPanelPage} />
          <IsLogged path="/logowanie" component={LoginPage} />
          <IsLogged path="/przypomnij-haslo" component={ForgotPasswordPage} />
          <IsLogged path="/resetuj-haslo" component={ResetPasswordPage} />
          <Redirect from="/*" to="/logowanie" />
        </Switch>
      </Router>
    </>
  )
}

export default App

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 15px;
    @media (max-width: ${theme.breakpoints.md}) {
      font-size: 14px;
    }
  }

  body {
    font-family: ${theme.font.family};
    color: ${theme.colors.black};
    margin: 0;
    overflow-x: hidden;
  }

  .ISSToast {
    .Toastify__toast {
      font-family: ${theme.font.family};
      padding: 20px;
      font-size: ${rem('14px')};
      border-radius: 7px;
    }
    .Toastify__toast--error {
      background: ${theme.colors.red};
    }
    .Toastify__toast--success {
      background: ${theme.colors.green};
    }
  }
`