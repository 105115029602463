import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import ReportProducts from 'shared/components/ReportProducts'
import { thunks as productsThunks } from 'modules/Products'
import { setOrderedProducts, setFormValidationErrors } from '../actions'
import { addNewOrder, addOrderViaAdmin } from '../thunks'
import { useSelector } from 'react-redux'
import { actions as modalActions} from 'modules/Modal'
import { URL_GET_PRODUCTS } from 'config/api/products';

const ReportOrderContainer = ({
  orderViaAdmin = false,
  shopId
}) => {
  const [message, setMessage] = useState('');
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const products = useSelector(({ products }) => products.products)
  const chosenOrderedProducts = useSelector(({ orders }) => orders.chosenOrderedProducts)
  const isProductsDataLoading = useSelector(({ products }) => products.isDataLoading)
  const isFormSubmitting = useSelector(({ orders }) => orders.isFormSubmitting)
  const formValidationErrors = useSelector(({ orders }) => orders.formValidationErrors)
  const dispatch = useDispatch()

  useEffect(() => {
    const url = `${URL_GET_PRODUCTS.url}?limit=10000000&shop_id=`+shopId
    dispatch(productsThunks.getProducts(url))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const modalContent = {
    title: `Czy na pewno chcesz zamówić wybrane produkty?`,
    description: 'Zamówienie zostanie wysłane do realizacji',
    confirmButtonText: 'Zamów',
    cancelButtonText: 'Anuluj'
  }

  return (
    <ReportProducts
      products={products}
      chosenProducts={chosenOrderedProducts}
      setChosenProducts={setOrderedProducts}
      reportApiCall={orderViaAdmin ? params => addOrderViaAdmin(shopId, params, message) : addNewOrder}
      loggedUser={loggedUser}
      formValidationErrors={formValidationErrors}
      isFormSubmitting={isFormSubmitting}
      isDataLoading={isProductsDataLoading}
      setFormValidationErrors={setFormValidationErrors}
      enableModal={modalActions.enableModal}
      modalContent={modalContent}
      reportTitle='Zrób zamówienie'
      reportSubTitle='Złóż zamówienie na konkretne, wybrane przez Ciebie produkty!'
      isOrder={true}
      message={message}
      setMessage={setMessage}
    />
  )
}

export default ReportOrderContainer
