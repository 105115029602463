import React from 'react'
import styled from 'styled-components'
import Chart from './Chart'
import Blocks from 'shared/styled/Blocks'
import { BlockHeading } from 'shared/styled/Headings'
import Fade from 'shared/components/Fade'
import Loader from 'modules/PageLoader/components/Loader'
import Filters from 'shared/components/Filters'

const Statistics = ({ 
  loggedUser,
  isDataLoading,
  statistics,
  activeFilters,
  filterStatistics,
  areOwnStatistics,
  filters
 }) => {

  return (
    <StatisticsWrapper areOwnStatistics={areOwnStatistics}>
      <Loader isPageLoading={isDataLoading}>
        <Fade>
          <>
            <StatisticsHeader>Statystyki</StatisticsHeader>
            <Filters 
              loggedUser={loggedUser}
              filters={filters}
              activeFilters={activeFilters}
              filterData={filterStatistics} />
            <Chart statistics={statistics} />
          </>
        </Fade>
      </Loader>
    </StatisticsWrapper>
  )
}

export default Statistics

const StatisticsHeader = styled(BlockHeading)`
  margin-bottom: 15px;
`

const StatisticsWrapper = styled(Blocks.Block)`
  padding: ${props => props.areOwnStatistics ? '25px' : '25px 0'}
`