import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { rem } from 'polished'
import Blocks from 'shared/styled/Blocks'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import Forms from 'shared/styled/Forms'
import theme from 'config/theme'
import { goBack } from 'utils/global'
import Fade from 'shared/components/Fade'
import { PrimaryButton } from 'shared/styled/Buttons'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import { hasPermission } from 'utils/auth'

const ProductDetails = ({
  loggedUser,
  editing,
  isPageLoading,
  currentProduct,
  isFormSubmitting,
  updateProduct,
  history,
  formValidationErrors,
  setFormValidationErrors
}) => {

  const [pricePercent, setPricePercent] = useState('')
  const [errors, setErrors] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    setErrors(formValidationErrors)
    if (hasPermission('product.update', loggedUser) && currentProduct) {
      setPricePercent(currentProduct.wholesale_lower_by_percent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValidationErrors, currentProduct])

  useEffect(() => {
    return () => dispatch(setFormValidationErrors())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handlePercentChange = value => {
    if (String(value).length <= 4) {
      if (Number(value) > 100) {
        setPricePercent('100')
      } else if (value < 0) {
        setPricePercent('0')
      } else {
        setPricePercent(value)
      }
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    dispatch(updateProduct(pricePercent, currentProduct.id))
  }

  return (
    <Blocks.Block>
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <Blocks.Head>
            <Blocks.BackButton onClick={() => goBack('/panel/produkty')}>
              <TiArrowLeftThick size={28} />
            </Blocks.BackButton>
            <BlockHeading>{ currentProduct ? currentProduct.name : 'Produkt' }</BlockHeading>
          </Blocks.Head>
          { currentProduct &&
            <Forms.Form noValidate onSubmit={onSubmit} onChange={() => setErrors(null)}>
              <Forms.FormContent darken>
                <SidesWrapper>
                  <LeftSide>
                    <ProductInfoGroupWrapper>
                      <ProductInfoGroup>
                        <ProductInfoLabel>Nazwa produktu</ProductInfoLabel>
                        <ProductInfoValue>{ currentProduct.name }</ProductInfoValue>
                      </ProductInfoGroup>
                      <ProductInfoGroup>
                        <ProductInfoLabel>Cena detaliczna netto</ProductInfoLabel>
                        <ProductInfoValue>{ currentProduct.price } zł</ProductInfoValue>
                      </ProductInfoGroup>
                      <ProductInfoGroup>
                        <ProductInfoLabel>Cena hurtowa</ProductInfoLabel>
                        <ProductInfoValue>{ currentProduct.calculated_wholesale_price } zł</ProductInfoValue>
                      </ProductInfoGroup>
                    </ProductInfoGroupWrapper>
                    { hasPermission('product.update', loggedUser)
                      ? (
                        <Forms.FormGroup wide>
                          <Forms.Input 
                            type='number'
                            name={'percent'}
                            darken 
                            autocomplete="off" 
                            required 
                            value={pricePercent}
                            onChange={e => handlePercentChange(e.target.value)} />
                          <Forms.InputBar />
                          <Forms.Label darken>Cena hurtowa niższa od ceny detalicznej netto o (procent)<Forms.Required>*</Forms.Required></Forms.Label>
                          { errors && errors.wholesale_lower_by_percent
                            ? <Forms.InputError>{ errors && errors.wholesale_lower_by_percent }</Forms.InputError>
                            : null
                          }
                        </Forms.FormGroup>
                      )
                      : (
                        <ProductInfoGroup>
                          <ProductInfoLabel>Procent ceny hurtowej</ProductInfoLabel>
                          <ProductInfoValue>{ currentProduct.wholesale_lower_by_percent }</ProductInfoValue>
                        </ProductInfoGroup>
                      )
                    }
                    { hasPermission('product.update', loggedUser) &&
                      <Forms.FormButtonsWrapper>
                        <PrimaryButton disabled={ isFormSubmitting } type="submit">
                          { isFormSubmitting ? <ButtonLoader /> : 'Zaaktualizuj' }
                        </PrimaryButton>
                        <Forms.CancelButton type="button" onClick={() => goBack()}>Anuluj</Forms.CancelButton>
                      </Forms.FormButtonsWrapper>
                    }
                  </LeftSide>
                  <RightSide>
                    <ProductImage src={currentProduct.default_image_link_large} alt={currentProduct.name}/>
                  </RightSide>
                </SidesWrapper>
              </Forms.FormContent>
            </Forms.Form>
          }
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default ProductDetails

const ProductInfoGroupWrapper = styled.div`
  margin-bottom: 35px;
`

const ProductInfoGroup = styled.div`
  position: relative;
  margin: 20px 0;
  width: 100%;
  /* padding-bottom: 10px; */
`

const ProductInfoLabel = styled.div`
  font-size: ${rem('12px')};
  color: ${theme.colors.gray};
  margin-bottom: 5px;
`

const ProductInfoValue = styled.div`
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
  /* font-weight: bold; */
`

const SidesWrapper = styled.div`
  display: flex;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const LeftSide = styled.div`
  flex: 0 1 40%;
  padding-right: 25px;
  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 0;
  }
`

const RightSide = styled.div`
  flex: 0 1 60%;
  padding-left: 25px;
  display: flex;
  justify-content: flex-start;
  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 0;
  }
`

const ProductImage = styled.img`
  max-width: 100%;
  max-height: 400px;
  text-align: left;
`