import { BASE_API_URL } from './base'

export const URL_GET_PRODUCTS = {
  method: 'GET',
  url: `${BASE_API_URL}/product`
}

export const URL_GET_SINGLE_PRODUCT = {
  method: 'GET',
  url: id => `${BASE_API_URL}/product/${id}`
}

export const URL_UPDATE_PRODUCT = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/product/${id}`
}

export const URL_GET_PRODUCTS_FOR_SALE_REPORT = {
  method: 'GET',
  url: `${BASE_API_URL}/product-sale`
}

export const URL_GET_PRODUCTS_FOR_RETURN = {
  method: 'GET',
  url: `${BASE_API_URL}/product-return`
}