import * as actions from './actions'
import { request } from 'utils/api'
import {
  URL_GET_ORDERS,
  URL_REPORT_ORDER,
  URL_REPORT_ORDER_VIA_ADMIN,
  URL_CHANGE_ORDER_STATUS,
  URL_REMOVE_ORDER,
  URL_SET_ORDERS_COMPLETED,
  URL_GET_SHOP_ORDERS
} from 'config/api/orders'
import queryString from 'query-string'

export const getOrders = (url = URL_GET_ORDERS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_ORDERS.method, url },
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setOrdersList }
    })
  }
}

export const searchShopOrders = (url = URL_GET_SHOP_ORDERS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_SHOP_ORDERS.method, url },
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setOrdersList }
    })
  }
}

export const getShopOrders = (id, url = URL_GET_SHOP_ORDERS.url) => {
  return async dispatch => {
    request({
      dispatch,
      pathParams: id,
      endpoint: { method: URL_GET_SHOP_ORDERS.method, url },
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setOrdersList }
    })
  }
}

export const getShopOrdersFromPagination = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_SHOP_ORDERS.method, url },
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setOrdersList }
    })
  }
}

export const addNewOrder = (chosenProducts, message) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REPORT_ORDER,
      queryParams: {
        products: chosenProducts.map(product => {
          return {
            id: product.id,
            amount: Number(product.amount)
          }
        }),
        comments: message
      },
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/zamowienia', action: () => actions.setOrderedProducts([]), shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const addOrderViaAdmin = (id, chosenProducts, message) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REPORT_ORDER_VIA_ADMIN,
      pathParams: id,
      queryParams: {
        products: chosenProducts.map(product => {
          return {
            id: product.id,
            amount: Number(product.amount)
          }
        }),
        comments: message
      },
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { action: () => actions.setOrderedProducts([]), shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const changeOrderStatus = (id, statusObject, pageId, activeFilters) => {
  return async dispatch => {
    const finalUrl = `${URL_GET_ORDERS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}&page=${pageId}`
    request({
      dispatch,
      endpoint: URL_CHANGE_ORDER_STATUS,
      pathParams: id,
      queryParams: statusObject,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => getOrders(finalUrl),
      onSuccess: { shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const setOrderPartiallyCompleted = (id, statusObject, pageId, activeFilters) => {
  return async dispatch => {
    const finalUrl = `${URL_GET_ORDERS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}&page=${pageId}`
    request({
      dispatch,
      endpoint: URL_CHANGE_ORDER_STATUS,
      pathParams: id,
      queryParams: statusObject,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { action: () => getOrders(finalUrl), shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const getOrdersAfterFormSubmit = finalUrl => {
  return async dispatch => {
    dispatch(actions.setFormSubmiting(false))
    dispatch(getOrders(finalUrl))
  }
}

export const removeOrder = (id, pageId, activeFilters) => {
  const finalUrl = `${URL_GET_ORDERS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}&page=${pageId}`
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REMOVE_ORDER,
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => getOrders(finalUrl),
      onSuccess: { shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const setOrdersCompleted = (orders, pageId, activeFilters) => {
  const finalUrl = `${URL_GET_ORDERS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}&page=${pageId}`
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_SET_ORDERS_COMPLETED,
      queryParams: { orders: orders.map(order => Number(order)) },
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => getOrders(finalUrl),
      onSuccess: { shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const filterOrders = (activeFilters, areOwnOrders, shopId = null) => {
  return async dispatch => {
    const finalUrl = `${areOwnOrders ? URL_GET_ORDERS.url : URL_GET_SHOP_ORDERS.url(shopId)}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setActiveFilters(activeFilters))
    dispatch(getOrders(finalUrl))
  }
}
