import React from 'react'
import Blocks from 'shared/styled/Blocks'
import FreelancersForm from './FreelancersForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import { goBack } from 'utils/global'

const FreelancersNew = ({ 
  history,
  isPageLoading,
  addNewFreelancer,
  isFormSubmitting,
  formValidationErrors,
  setFormValidationErrors
}) => {

  return (
    <>
      <Blocks.Block>
        <Blocks.Head>
          <Blocks.BackButton onClick={() => goBack('/panel/operatorzy')}>
            <TiArrowLeftThick size={28} />
          </Blocks.BackButton>
          <BlockHeading>Dodaj nowego niezależnego operatora</BlockHeading>
        </Blocks.Head>
        <Loader isPageLoading={isPageLoading}>
          <FreelancersForm 
            hasPermission={true}
            submitBtnText={'Dodaj'}
            isFormSubmitting={isFormSubmitting}
            callApi={addNewFreelancer}
            formValidationErrors={formValidationErrors}
            setFormValidationErrors={setFormValidationErrors} />
        </Loader>
      </Blocks.Block>
    </>
  )
}

export default FreelancersNew