import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import AdminSidebar from 'shared/components/AdminSidebar'
import Topbar from 'shared/components/Topbar'
import { OuterWrapper, InnerWrapper, Content } from 'shared/styled/Layout'
import DashboardPage from './dashboard/DashboardPage'
import SalesDistrictsRoutes from './districts/SalesDistrictsRoutes'
import AdministratorsRoutes from './administrators/AdministratorsRoutes'
import RepresentativesRoutes from './representatives/RepresentativesRoutes'
import ShopsRoutes from './shops/ShopsRoutes'
import SellersRoutes from './sellers/SellersRoutes'
import FreelancerRoutes from './freelancers/FreelancerRoutes'
import CodesRoutes from './codes/CodesRoutes'
import ProductsRoutes from './products/ProductsRoutes'
import OrderRoutes from './orders/OrderRoutes'
import ProfilePage from './profile/ProfilePage'
import BalancePage from './balance/BalancePage'
import WarehouseRoutes from './warehouse/WarehouseRoutes'
import PayoutsRoutes from './payouts/PayoutsRoutes'
import LogsRoutes from './logs/LogsRoutes'
import { ModalContainer } from 'modules/Modal'
import { hasAccess } from 'utils/auth'
import roles from 'config/roles'
import Loader from 'modules/PageLoader/components/Loader'

const MainPanelPage = ({ match, loggedUser }) => {

  return (
    <OuterWrapper>
      { 
        hasAccess([roles.admin.slug], loggedUser) && 
        <AdminSidebar loggedUser={loggedUser} /> 
      }
      <InnerWrapper isAdmin={hasAccess([roles.admin.slug], loggedUser)}>
        <Topbar loggedUser={loggedUser} />
        <Content>
          <Loader isPageLoading={loggedUser ? false : true}>
            <ModalContainer />
            <Switch>
              { SalesDistrictsRoutes(match, loggedUser) }
              { AdministratorsRoutes(match, loggedUser) }
              { RepresentativesRoutes(match, loggedUser) }
              { ShopsRoutes(match, loggedUser) }
              { SellersRoutes(match, loggedUser) }
              { FreelancerRoutes(match, loggedUser) }
              { CodesRoutes(match, loggedUser) }
              { ProductsRoutes(match, loggedUser) }
              { OrderRoutes(match, loggedUser) }
              { WarehouseRoutes(match, loggedUser)}
              { PayoutsRoutes(match, loggedUser)}
              { LogsRoutes(match, loggedUser)}
              <Route path={`${match.url}/saldo`} component={BalancePage} />
              <Route path={`${match.url}/ustawienia`} component={ProfilePage} />
              <Route path={`${match.url}/kokpit`} component={DashboardPage} />
              <Redirect to={`${match.url}/kokpit`} />
            </Switch>
          </Loader>
        </Content>
      </InnerWrapper>
    </OuterWrapper>
  )
}

export default MainPanelPage 