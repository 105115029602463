import styled from 'styled-components'
import theme from '../../config/theme'

const Block = styled.div`
  width: 100%;
  background-color: ${theme.colors.white};
  padding: 25px;
  margin: 15px 0;
  border-radius: 6px;
  /* box-shadow: 0px 2px 1px rgba(0,0,0,0.05); */
  box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.05);
`

const Head = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid ${theme.colors.lightGray};
  margin-bottom: 20px;
`

const BackButton = styled.button`
  padding: 5px;
  color: ${theme.colors.black};
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  margin-right: 10px;
  transition: color .2s ease;
  &:hover {
    color: ${theme.colors.main};
  }
`

export default {
  Block,
  Head,
  BackButton
}
