import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import { GraySelectStyles } from 'shared/styled/Select'
import { registerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
import 'react-datepicker/dist/react-datepicker.css'
import DateRange from 'shared/components/DateRange'
import { FilterBlock, FilterLabel, FiltersWrapper } from 'shared/styled/Filters'
import MultiSelect from 'shared/components/MultiSelect'

registerLocale('pl', pl)

const Filters = ({ 
  filters, 
  activeFilters,
  filterData,
  loggedUser
}) => {
  
  const dispatch = useDispatch()

  const handleFiltersChange = (filterValue, filterQuery, filter = null) => { 
    
    const shouldBeAList = filter.isMulti !== undefined ? filter.isMulti : false
    let chosenFilters = activeFilters ? {...activeFilters} : {}
    const chosenFiltersKeys = Object.keys(chosenFilters)

    if (chosenFiltersKeys.length) {
      chosenFiltersKeys.forEach(filterKey => {
        if (filterKey === filterQuery) {
          chosenFilters[filterQuery] = listOrSingleValue(shouldBeAList, filterValue)
          if (filter.dependentFilters) {
            chosenFilters = manageDependentFilters(chosenFilters, filter.dependentFilters, filterValue)
          }
        } else {
          chosenFilters[filterQuery] = listOrSingleValue(shouldBeAList, filterValue) // here 'value' equals an empty string or null (depends on filter type)
          if (filter.dependentFilters) {
            chosenFilters = manageDependentFilters(chosenFilters, filter.dependentFilters, filterValue)
          }
        }
      })
      dispatch(filterData(chosenFilters))
    } else {
      chosenFilters[filterQuery] = listOrSingleValue(shouldBeAList, filterValue)
      dispatch(filterData(chosenFilters))
    }
  }

  const manageDependentFilters = (chosenFilters, dependentFilters, mainFilterValue) => {
    let chosenFiltersAfterChange = {...chosenFilters}

    Object.entries(dependentFilters).forEach(([dependentFilterKey, dependentFilterValue]) => {
      if (chosenFiltersAfterChange[dependentFilterKey] && !dependentFilterValue.mainFilterDependentValues.includes(mainFilterValue)) {
        delete chosenFiltersAfterChange[dependentFilterKey]
      } else {
        chosenFiltersAfterChange[dependentFilterKey] = dependentFilterValue.defaultValue
      }
    })

    return chosenFiltersAfterChange
  }

  const listOrSingleValue = (shouldBeAList, value) => {
    if (shouldBeAList) {
      return value ? value.map(item => item.value) : []
    } else return value ? value : ''
  }

  const shouldBeFilterVisible = visibleRules => {
    let shouldBeVisible = [true]
    if (visibleRules) {
      Object.entries(visibleRules).forEach(([key, value]) => {
        if (activeFilters[key] !== undefined && value.includes(activeFilters[key])) {
          shouldBeVisible = [...shouldBeVisible, true]
        } else {
          shouldBeVisible = [...shouldBeVisible, false]
        }
      })
    }

    return shouldBeVisible.every(v => v === true)
  }

  const prepareActiveValue = filter => {
    let activeValue = null

    if (filter && activeFilters && activeFilters[filter.query]) {
      const filterID = filter.isMulti ? activeFilters[filter.query][0] : activeFilters[filter.query]
      let filterLabel = ''
      filter.options.forEach(option => {
        if (option.value === filterID) filterLabel = option.label 
      })
      activeValue = filterID ? {
        value: filterID,
        label: filterLabel
      } : null
    }
    return activeValue
  }

  const prepareMultiSelectActiveValue = filter => {
    let activeValues = []

    if (filter && activeFilters && activeFilters[filter.query]) {
      filter.options.forEach(option => {
        if (activeFilters[filter.query].includes(option.value)) {
          activeValues = [...activeValues, { value: option.value, label: option.label}]
        }
      })
    }
    return activeValues
  }
  
  return (
    <FiltersWrapper>
      {
        filters &&
        filters.map((filter, i) => {
          return (
            <Fragment key={filter.id ? filter.id : i}>
              {
                !filter.role || (filter.role && filter.role !== loggedUser.account_type) 
                ? filter.type === 'daterange'
                  ? (
                    shouldBeFilterVisible(filter.visibleIf ? filter.visibleIf : null) && 
                    <DateRange 
                      zIndex={filters.length - i}
                      activeFilters={activeFilters}
                      filter={filter}
                      handleFiltersChange={handleFiltersChange}
                      isClearable={filter.isClearable} />
                  )
                  : (
                    shouldBeFilterVisible(filter.visibleIf ? filter.visibleIf : null) && 
                    <FilterBlock key={filter.query} zIndex={filters.length - i}>
                      <FilterLabel>{ filter.label }</FilterLabel>
                      { filter.isMulti
                        ? <MultiSelect 
                            onChange={e => handleFiltersChange(e && e.value !== undefined ? e.value : e, filter.query, filter)}
                            options={filter.options}
                            value={prepareMultiSelectActiveValue(filter)}
                            isClearable={filter.isClearable}/>
                        : <FilterSelect 
                            isMulti={false}
                            onChange={e => handleFiltersChange(e && e.value !== undefined ? e.value : e, filter.query, filter)}
                            options={filter.options}
                            placeholder='Wybierz...'
                            className='select'
                            classNamePrefix='select'
                            value={prepareActiveValue(filter)}
                            noOptionsMessage={() => 'Brak wyników...'}
                            isClearable={filter.isClearable}/>
                      }
                    </FilterBlock>
                  )
                : null
              }
            </Fragment>
          )
          
        })
      }
    </FiltersWrapper>
  )
}

export default Filters

export const FilterSelect = styled(ReactSelect)`
  ${GraySelectStyles};
`