import * as types from './types'

export const setWarehouseList = products => {
  return {
    type: types.SET_WAREHOUSE_PRODUCTS,
    payload: products
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_WAREHOUSE_LOADING,
    payload: isLoading
  }
}