import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'

// PAGES
import ShopsPage from './ShopsPage'
import NewShopPage from './NewShopPage'
import ShopDetailsPage from './ShopDetailsPage'

const ShopsRoutes = (match, loggedUser) => [
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/jednostki`} 
    component={ShopsPage} 
    permissions={['shop.viewAny']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/jednostki/nowy`} 
    component={NewShopPage} 
    permissions={['shop.create']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/jednostki/:shop_id`} 
    component={ShopDetailsPage} 
    permissions={['shop.update', 'shop.view']} />
]

export default ShopsRoutes