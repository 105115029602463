import React from 'react'
import { useDispatch } from 'react-redux'
import Form from 'shared/components/Form'
import shortid from 'shortid'
import Fade from 'shared/components/Fade'

const FreelancersForm = ({ 
  hasPermission,
  loggedUser,
  currentFreelancer, 
  isFormSubmitting, 
  callApi, 
  formValidationErrors, 
  setFormValidationErrors,
  submitBtnText,
  editing = false
}) => {
  const dispatch = useDispatch()

  const onSubmit = formData => {
    if (editing) {
      dispatch(callApi(formData, currentFreelancer.id))
    } else {
      dispatch(callApi(formData))
    }
    dispatch(setFormValidationErrors(null))
  }

  const initialData = {
    full_name: currentFreelancer && currentFreelancer.full_name ? currentFreelancer.full_name : '',
    user_email: currentFreelancer && currentFreelancer.user_email ? currentFreelancer.user_email : '',
    password: currentFreelancer && currentFreelancer.password ? currentFreelancer.password : '',
    password_confirmation: currentFreelancer && currentFreelancer.password_confirmation ? currentFreelancer.password_confirmation : '',
    phone: currentFreelancer && currentFreelancer.phone ? currentFreelancer.phone : '',
    bank_account_number: currentFreelancer && currentFreelancer.bank_account_number ? currentFreelancer.bank_account_number : '',
    status: currentFreelancer && currentFreelancer.status ? currentFreelancer.status : false,
    company_name: currentFreelancer && currentFreelancer.company_name ? currentFreelancer.company_name : '',
    nip: currentFreelancer && currentFreelancer.nip ? currentFreelancer.nip : '',
    city: currentFreelancer && currentFreelancer.city ? currentFreelancer.city : '',
    postcode: currentFreelancer && currentFreelancer.postcode ? currentFreelancer.postcode : '',
    street: currentFreelancer && currentFreelancer.street ? currentFreelancer.street : '',
    suite_number: currentFreelancer && currentFreelancer.suite_number ? currentFreelancer.suite_number : '',
    agreement_number: currentFreelancer && currentFreelancer.agreement_number ? currentFreelancer.agreement_number : '',
    partnership_since: currentFreelancer && currentFreelancer.partnership_since ? new Date(currentFreelancer.partnership_since) : '',
    percent_web: currentFreelancer && currentFreelancer.percent_web ? currentFreelancer.percent_web : '',
    pesel: currentFreelancer && currentFreelancer.pesel ? currentFreelancer.pesel : ''
  }

  const switcher = {
    title: 'Status aktywności',
    field_name: 'status',
    activeText: 'Aktywny',
    inactiveText: 'Nieaktywny'
  }

  const formLayout = [
    {
      key: shortid.generate(),
      title: 'Dane podstawowe',
      fields: [
        {
          name: 'full_name',
          type: 'text',
          label: 'Imię i nazwisko*'
        },
        {
          name: 'user_email',
          type: 'text',
          label: 'Adres email*'
        },
        {
          name: 'password',
          type: 'password',
          label: 'Hasło*'
        },
        {
          name: 'password_confirmation',
          type: 'password',
          label: 'Powtórz hasło*'
        },
        {
          name: 'pesel',
          type: 'text',
          label: 'PESEL*'
        },
        {
          name: 'agreement_number',
          type: 'text',
          label: 'Numer umowy'
        },
        {
          name: 'partnership_since',
          type: 'date',
          label: 'Data rozpoczęcia współpracy*'
        },
        {
          name: 'percent_web',
          type: 'number',
          label: 'Procent bonifikaty za sprzedaż internetową (kod własny)'
        },
        {
          name: 'phone',
          type: 'text',
          label: 'Numer telefonu*'
        }
      ]
    },
     {
      key: shortid.generate(),
      title: 'Dane firmy',
      fields: [
        {
          name: 'company_name',
          type: 'text',
          label: 'Nazwa firmy'
        },
        {
          name: 'nip',
          type: 'text',
          label: 'NIP',
          mask: '9999999999'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Adres',
      fields: [
        {
          name: 'street',
          type: 'text',
          label: 'Ulica*'
        },
        {
          name: 'suite_number',
          type: 'text',
          label: 'Numer lokalu*'
        },
        {
          name: 'postcode',
          type: 'text',
          label: 'Kod pocztowy*'
        },
        {
          name: 'city',
          type: 'text',
          label: 'Miejscowość*'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Dane rozliczeniowe',
      fields: [
        {
          name: 'bank_account_number',
          type: 'text',
          label: 'Numer konta bankowego*',
          mask: "P\L99999999999999999999999999" // eslint-disable-line
        }
      ]
    }
  ]

  return (
    <Fade>
      <Form
        loggedUser={loggedUser}
        hasPermission={hasPermission}
        switcher={switcher}
        initialData={initialData}
        formLayout={formLayout}
        isFormSubmitting={isFormSubmitting} 
        onSubmit={onSubmit}
        submitBtnText={submitBtnText}
        setFormValidationErrors={setFormValidationErrors}
        formValidationErrors={formValidationErrors} />
    </Fade>
  )
}

export default FreelancersForm