import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_PROFILE, 
  URL_RESET_PASSWORD
} from 'config/api/profile'

export const getProfile = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_PROFILE, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setUserProfile }
    })
  }
}

export const resetPassword = formData => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_RESET_PASSWORD, 
      queryParams: formData,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}