import React from 'react'
import Blocks from 'shared/styled/Blocks'
import RepresentativesForm from './RepresentativesForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import Tabs from 'shared/components/Tabs'
import { hasPermission } from 'utils/auth'
import { UserCodesContainer } from 'modules/Codes'
import roles from 'config/roles'
import { PermissionsContainer } from 'modules/Permissions'
import { URL_GET_REPRESENTATIVES_PERMISSIONS, URL_UPDATE_REPRESENTATIVES_PERMISSIONS } from 'config/api/representatives'
import { goBack } from 'utils/global'
import Fade from 'shared/components/Fade'
import { BalanceContainer } from 'modules/Balance'
import RepresentativesStatisticsContainer from '../containers/RepresentativesStatisticsContainer'
import RepresentativesNotificationsContainer from '../containers/RepresentativesNotificationsContainer'

const RepresentativesDetails = ({
  loggedUser,
  editing,
  isPageLoading,
  salesDistrictsForSelect,
  currentRepresentative,
  isFormSubmitting,
  updateRepresentative,
  formValidationErrors,
  setFormValidationErrors
}) => {

  const objectId = currentRepresentative ? currentRepresentative.id : null

  return (
    <Blocks.Block>
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <Blocks.Head>
            <Blocks.BackButton onClick={() => goBack('/panel/przedstawiciele')}>
              <TiArrowLeftThick size={28} />
            </Blocks.BackButton>
            <BlockHeading>{ currentRepresentative ? currentRepresentative.full_name : 'Przedstawiciel handlowy' }</BlockHeading>
          </Blocks.Head>
          <Tabs>
            <UserCodesContainer 
              tabLabel={'Kody rabatowe'} 
              currentUser={currentRepresentative}
              redirectUrl={currentRepresentative ? `/panel/przedstawiciele/${currentRepresentative.id}` : ``}
              currentRole={roles.representative.slug}  />
            <RepresentativesForm 
              hasPermission={hasPermission('representative.update', loggedUser)}
              loggedUser={loggedUser}
              tabLabel={'Ustawienia'}
              editing
              submitBtnText={'Zaktualizuj'}
              salesDistrictsForSelect={salesDistrictsForSelect}
              currentRepresentative={currentRepresentative}
              isFormSubmitting={isFormSubmitting}
              callApi={updateRepresentative}
              formValidationErrors={formValidationErrors}
              setFormValidationErrors={setFormValidationErrors} />
            <PermissionsContainer 
              objectId={objectId}
              getPermissionsUrl={URL_GET_REPRESENTATIVES_PERMISSIONS}
              updatePermissionsUrl={URL_UPDATE_REPRESENTATIVES_PERMISSIONS}
              tabLabel={'Uprawnienia'} />
            <RepresentativesStatisticsContainer 
              tabLabel={'Statystyki'}
              areOwnStatistics={false}
              representativeId={currentRepresentative ? currentRepresentative.id : ''} />
            <BalanceContainer 
              tabLabel={'Saldo'}
              userRole={roles.representative.slug}
              userId={currentRepresentative ? currentRepresentative.id : ''} />
            <RepresentativesNotificationsContainer 
              tabLabel={'Powiadomienia'}
              areOwnNotifications={false}
              representativeId={currentRepresentative ? currentRepresentative.id : ''}
            />
          </Tabs>
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default RepresentativesDetails