import React from 'react'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import Loader from 'modules/PageLoader/components/Loader'
import Fade from 'shared/components/Fade'

const BeneficiariesWithNegativeBalance = ({
  loggedUser,
  getBeneficiariesWithNegativeBalance,
  beneficiaries,
  isDataLoading
}) => {
  return (
    <Blocks.Block>
      <Loader isPageLoading={isDataLoading}>
        <Fade>
          <DataTable
            callApi={getBeneficiariesWithNegativeBalance}
            data={beneficiaries}
            columns={[
              { name: 'Beneficjent', field: row => row && row.beneficiary ? row.beneficiary.disp_name : '' },
              { name: 'Wartość salda', field: 'balance' },
            ]}
          />
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default BeneficiariesWithNegativeBalance
