import styled from 'styled-components'
import { Link } from 'react-router-dom'
import theme from 'config/theme'

export const DataTableAction = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 5px;
  color: ${theme.colors.main};
  position: relative;
  display: inline-block;
  &:hover {
    color: ${props => props.color ? props.color : theme.colors.black};
    &:after {
      opacity: 1;
      visibility: visible;
      transition: opacity 0s ease, visibility 0s ease;
      transition-delay: 0.7s;
    }
  }
  &:after {
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
    content: '${props => props.tooltiptext ? props.tooltiptext : ''}';
    height: 13px;
    display: flex;
    align-items: center;
    padding: 5px 7px;
    font-size: 10px;
    position: absolute;
    border-radius: 4px;
    left: 0;
    top: -20px;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition-duration: 0s;
    transition-delay: 0s;
    white-space: nowrap;
  }
`

export const DataTableActionLink = DataTableAction.withComponent(Link)