import React from 'react'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'
import { rem } from 'polished'
import theme from 'config/theme'

const Checkbox = ({ 
  label, 
  value, 
  handleChange,
  checked, 
  isEditable = true,
  readOnly = false,
  stopPropagation = true
}) => {

  return (
    <CheckboxLabel onClick={e => stopPropagation ? e.stopPropagation() : () => {}} isEditable={isEditable}>
      <CheckboxInput 
        type="checkbox"
        checked={checked} 
        disabled={!isEditable}
        onChange={handleChange}
        readOnly={readOnly} />
      <CheckboxThick>
        <Check size={16} />
      </CheckboxThick>
      { label }
    </CheckboxLabel>
  )
}

export default Checkbox

const Check = styled(FaCheck)`
  opacity: 0;
  visibility: hidden;
  color: ${theme.colors.white};
  transition: opacity .2s ease, visibility .2s ease;
`

const CheckboxThick = styled.div`
  width: 1.3em;
  height: 1.3em;
  border: 2px solid ${theme.colors.mediumGray};
  border-radius: 3px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: background-color .2s ease, border-color .2s ease;
`

const CheckboxLabel = styled.label`
  opacity: ${props => props.isEditable ? 1 : 0.37};
  display: inline-flex;
  align-items: flex-start;
  cursor: ${props => props.isEditable ? 'pointer' : 'default'};
  font-size: ${rem('14px')};
  user-select: none;
`

const CheckboxInput = styled.input`
  display: none;
  &:checked + ${CheckboxThick} {
    border-color: ${theme.colors.main};
    background-color: ${theme.colors.main};
    ${Check} {
      opacity: 1;
      visibility: visible;
    }
  }
`