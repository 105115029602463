import { BASE_API_URL } from './base'

export const URL_GET_LOGS = {
  method: 'GET',
  url: `${BASE_API_URL}/history`
}

export const URL_GET_USERS_FOR_FILTERS = {
  method: 'GET',
  url: `${BASE_API_URL}/history/filter/users`
}

export const URL_GET_TYPES_FOR_FILTERS = {
  method: 'GET',
  url: `${BASE_API_URL}/history/filter/types`
}