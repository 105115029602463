import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { PrimaryButton } from 'shared/styled/Buttons'
import Forms from 'shared/styled/Forms'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import InputMask from 'react-input-mask'
import { goBack } from 'utils/global'
import Switcher from 'shared/components/Switcher'
import Select from 'shared/components/Select'
import Datepicker from 'shared/components/Datepicker'
import Checkbox from 'shared/components/Checkbox'

const Form = ({ 
  editing,
  loggedUser,
  hasPermission,
  initialData, 
  isFormSubmitting, 
  onSubmit, 
  formValidationErrors, 
  setFormValidationErrors,
  submitBtnText,
  formLayout,
  switcher
}) => {

  const [errors, setErrors] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    setErrors(formValidationErrors)
  }, [formValidationErrors])

  useEffect(() => {
    return () => dispatch(setFormValidationErrors())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit
  })
  
  const renderInputType = field => {

    if (field.type === 'date') {
      return (
        <Datepicker 
          field={field} 
          value={formik.values[field.name]}
          setDate={formik.setFieldValue}
          isDisabled={!hasPermission} />
      )
    } else if (field.type === 'select') {
      return (
        <>
          <Select 
            isMulti={field.isMulti}
            value={formik.values[field.name]} 
            label={field.label}
            options={field.options}
            isDisabled={!hasPermission}
            onChange={option => {
              formik.setFieldValue(field.name, option)
            }} />
          { field.warningText && hasPermission &&
            <Forms.FieldInfo>{ field.warningText }</Forms.FieldInfo>
          }
        </>
      )
    } else if (field.type === 'checkbox') {
      return (
        <>
          <Checkbox 
            checked={formik.values[field.name]}
            value={formik.values[field.name]} 
            label={field.label}
            isEditable={hasPermission}
            handleChange={e => {
              formik.setFieldValue(field.name, e.target.checked)
            }} />
          { field.warningText && hasPermission &&
            <Forms.FieldInfo>{ field.warningText }</Forms.FieldInfo>
          }
        </>
      )
    } else {
      if (field.mask) {
        return (
          <>
            <InputMask 
              mask={field.mask}
              value={formik.values[field.name]} 
              onChange={formik.handleChange}
              autocomplete="off"  
              required
              disabled={!hasPermission}>
                {(inputProps) => <Forms.Input {...inputProps} type={field.type} name={field.name} darken disabled={!hasPermission}/>}
            </InputMask>
            <Forms.InputBar />
            <Forms.Label darken disabled={!hasPermission}>{ field.label }</Forms.Label>
          </>
        )
      } else {
        return (
          <>
            <Forms.Input 
              type={field.type}
              name={field.name}
              darken 
              autocomplete="off" 
              required 
              onChange={formik.handleChange} 
              value={formik.values[field.name] } 
              disabled={!hasPermission} />
            <Forms.InputBar />
            <Forms.Label darken disabled={!hasPermission}>{ field.label }</Forms.Label>
            { field.warningText && hasPermission &&
              <Forms.FieldInfo>{ field.warningText }</Forms.FieldInfo>
            }
          </>
        )
      } 
    }
  }

  return (
    <Forms.FormWrapper>
      <Forms.Form noValidate onSubmit={formik.handleSubmit} onChange={() => setErrors(null)}>
        { switcher
          ? <Switcher 
              hasPermission={hasPermission}
              value={formik.values[switcher.field_name]}
              switcher={switcher}
              onChange={(event) => {
                const value = event.target.checked ? true : false
                formik.setFieldValue(switcher.field_name, value)
              }} />
          : null
        }
        <Forms.FormContent darken>
          { formLayout &&
            formLayout.map(part => {
              return (
                <Fragment key={part.key}>
                  { part.title ? <Forms.Preheader>{ part.title }</Forms.Preheader> : null }
                  <Forms.FieldsWrapper key={part.title}>
                    { part.fields && part.fields.map(field => {
                      return (
                        !hasPermission && (field.type === 'password' || field.type === 'password_confirmation') 
                        ? null
                        : (
                          <Forms.FormGroup key={field.name}>
                            { renderInputType(field) }
                            { errors && errors[field.name]
                              ? <Forms.InputError>{ errors && errors[field.name] && errors[field.name][0] }</Forms.InputError>
                              : null
                            }
                          </Forms.FormGroup> 
                        )
                      )
                    })}
                  </Forms.FieldsWrapper>
                </Fragment >
              )
            })
          }
          { hasPermission &&
            <Forms.FormButtonsWrapper>
              <PrimaryButton disabled={ isFormSubmitting } type="submit">
                { isFormSubmitting ? <ButtonLoader /> : submitBtnText }
              </PrimaryButton>
              <Forms.CancelButton type="button" onClick={() => goBack()}>Anuluj</Forms.CancelButton>
            </Forms.FormButtonsWrapper>
          }
        </Forms.FormContent>
      </Forms.Form>
    </Forms.FormWrapper>
  )
}

export default Form