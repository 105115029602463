import styled from 'styled-components'
import theme from 'config/theme'

export const OuterWrapper = styled.div`
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
`

export const InnerWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  padding-left: ${props => props.isAdmin ? '70px' : 0};
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: ${theme.breakpoints.sm}) {
    padding-left: 0;
  }
`

export const Content = styled.div`
  flex: 1;
  padding: 85px 25px 25px;
  background-color: rgb(242, 243, 248);
  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 85px 15px 15px;
  }
`