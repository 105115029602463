import React from 'react'
import Blocks from 'shared/styled/Blocks'
import ShopsForm from './ShopsForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import Tabs from 'shared/components/Tabs'
import { hasPermission } from 'utils/auth'
import { UserCodesContainer } from 'modules/Codes'
import roles from 'config/roles'
import { goBack } from 'utils/global'
import Fade from 'shared/components/Fade'
import { BalanceContainer } from 'modules/Balance'
import { ReportOrderContainer, OrdersListContainer } from 'modules/Orders'
import { WarehouseListContainer } from 'modules/Warehouse'
import ShopsStatisticsContainer from '../containers/ShopsStatisticsContainer'

const ShopsDetails = ({
  loggedUser,
  editing,
  isPageLoading,
  representativesForSelect,
  currentShop,
  isFormSubmitting,
  updateShop,
  history,
  formValidationErrors,
  setFormValidationErrors
}) => {
  return (
    <Blocks.Block>
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <Blocks.Head>
            <Blocks.BackButton onClick={() => goBack('/panel/jednostki')}>
              <TiArrowLeftThick size={28} />
            </Blocks.BackButton>
            <BlockHeading>{ currentShop ? currentShop.company_name : 'Przedstawiciel handlowy' }</BlockHeading>
          </Blocks.Head>
          <Tabs>
            <UserCodesContainer 
              tabLabel={'Kody rabatowe'} 
              currentUser={currentShop}
              redirectUrl={currentShop ? `/panel/jednostki/${currentShop.id}` : ``}
              currentRole={roles.shop.slug} />
            <ShopsForm 
              hasPermission={hasPermission('shop.update', loggedUser)}
              loggedUser={loggedUser}
              tabLabel={'Ustawienia'}
              editing
              submitBtnText={'Zaktualizuj'}
              representativesForSelect={representativesForSelect}
              currentShop={currentShop}
              isFormSubmitting={isFormSubmitting}
              callApi={updateShop}
              formValidationErrors={formValidationErrors}
              setFormValidationErrors={setFormValidationErrors} />
            <BalanceContainer 
              tabLabel={'Saldo'}
              userRole={roles.shop.slug}
              userId={currentShop ? currentShop.id : ''} />
            { (hasPermission('wholesaleOrder.viewAny', loggedUser) || hasPermission('wholesaleOrder.create', loggedUser)) && 
              <Tabs 
                horizontal 
                tabLabel={'Zamówienia'}
                stylesForChildren={{
                  padding: '25px 0',
                  boxShadow: 'none'
                }}>
                { hasPermission('wholesaleOrder.create', loggedUser) &&
                  <ReportOrderContainer 
                    orderViaAdmin={true}
                    tabLabel={'Złoż zamówienie'} 
                    shopId={currentShop ? currentShop.id : ''} /> 
                }
                { hasPermission('wholesaleOrder.viewAny', loggedUser) &&
                  <OrdersListContainer 
                    areOwnOrders={false}
                    shopId={currentShop ? currentShop.id : ''}
                    tabLabel={'Zobacz zamówienia'} />
                }
              </Tabs>
            }
            <ShopsStatisticsContainer 
              tabLabel={'Statystyki'}
              areOwnStatistics={false}
              shopId={currentShop ? currentShop.id : ''} />
            { hasPermission('shop.viewStock', loggedUser) && 
              <WarehouseListContainer 
                isOwnWarehouse={false}
                shopId={currentShop ? currentShop.id : ''}
                tabLabel={'Magazyn'} />
            }
          </Tabs>
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default ShopsDetails