import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_WAREHOUSE_PRODUCTS,
  URL_GET_SHOP_WAREHOUSE_PRODUCTS
} from 'config/api/warehouse'

export const getWarehouse = (url = URL_GET_WAREHOUSE_PRODUCTS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_WAREHOUSE_PRODUCTS.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setWarehouseList }
    })
  }
}

export const getShopWarehouse = (id, url = URL_GET_SHOP_WAREHOUSE_PRODUCTS.url) => {
  return async dispatch => {
    request({
      dispatch,
      pathParams: id,
      endpoint: { method: URL_GET_SHOP_WAREHOUSE_PRODUCTS.method, url },
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setWarehouseList }
    })
  }
}

export const getShopWarehouseFromPagination = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_SHOP_WAREHOUSE_PRODUCTS.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setWarehouseList }
    })
  }
}

export const searchWarehouseProducts = (url = URL_GET_SHOP_WAREHOUSE_PRODUCTS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_SHOP_WAREHOUSE_PRODUCTS.method, url },
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setWarehouseList }
    })
  }
}