import React from 'react'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import { CodeConfiguratorContainer } from 'modules/Codes'
import Fade from 'shared/components/Fade'

const CodeConfiguratorPage = ({ match }) => {
  return (
     <Fade>
      <Breadcrumbs linkLabel={'Zarządzanie kodem'}/>
      <CodeConfiguratorContainer match={match}/>
    </Fade>
  )
}

export default CodeConfiguratorPage 