export default {
    "stationary_val": {
        'dataKey': 'stationary_val',
        'name': 'Obrót w sprzedaży stacjonarnej',
        'fill': '#62b191', 
        'stackId': 'stack',
        'sumModifier': 1,
        'unit': ' zł'
    },
    "shop_order_val": {
        'dataKey': 'shop_order_val',
        'name': 'Wartość zamówień sprzedaży internetowej',
        'fill': '#4073cb',
        'stackId': 'stack',
        'sumModifier': 1,
        'unit': ' zł'
    },
    "shop_order_slips_val": {
        'dataKey': 'shop_order_slips_val',
        'name': 'Wartość zwrotów sprzedaży internetowej',
        'fill': '#8a8a8a',
        'stackId': 'stack',
        'sumModifier': -1,
        'unit': ' zł'
    },
    "bonuses_stationary_val": {
        'dataKey': 'bonuses_stationary_val',
        'name': 'Bonifikaty za sprzedaż stacjonarną',
        'fill': '#62b191',
        'stackId': 'stack',
        'sumModifier': 1,
        'unit': ' zł'
    },
    "bonuses_shop_orders_val": {
        'dataKey': 'bonuses_shop_orders_val',
        'name': 'Bonifikaty za sprzedaż internetową',
        'fill': '#4073cb',
        'stackId': 'stack',
        'sumModifier': 1,
        'unit': ' zł'
    },
    "bonuses_shop_order_slips_val": {
        'dataKey': 'bonuses_shop_order_slips_val',
        'name': 'Korekty bonifikat - zwroty ze sprzedaży internetowej',
        'fill': '#8a8a8a',
        'stackId': 'stack',
        'sumModifier': -1,
        'unit': ' zł'
    }
}
