import * as types from './types'

const initialState = {
  representatives: null,
  representativesForSelect: [],
  representativesForFilter: [],
  currentRepresentative: null,
  activeFilters: null,
  isFormSubmitting: false,
  formValidationErrors: null,
  isDataLoading: false,
  areStatisticsLoading: false,
  statistics: null,
  statisticsProducts: [],
  statisticsActiveFilters: {
    type: 'sales_value',
    scope: 'all',
    source: 'all'
  },
  notifications: null,
  areNotificationsLoading: false,
  notificationsActiveFilters: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REPRESENTATIVES:
      return {
        ...state,
        representatives: action.payload
      }
    case types.SET_CURRENT_REPRESENTATIVE:
      return {
        ...state,
        currentRepresentative: action.payload
      }
    case types.SET_REPRESENTATIVES_FOR_SELECT:
      return {
        ...state,
        representativesForSelect: action.payload
      }
    case types.SET_REPRESENTATIVES_FOR_FILTER:
      return {
        ...state,
        representativesForFilter: action.payload
      }
    case types.SET_REPRESENTATIVES_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    case types.SET_REPRESENTATIVES_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_REPRESENTATIVES_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_REPRESENTATIVES_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_REPRESENTATIVES_STATISTICS_LOADING:
      return {
        ...state,
        areStatisticsLoading: action.payload
      }
    case types.SET_REPRESENTATIVES_STATISTICS:
      return {
        ...state,
        statistics: action.payload
      }
    case types.SET_REPRESENTATIVES_STATISTICS_ACTIVE_FILTERS: 
      return {
        ...state,
        statisticsActiveFilters: action.payload
      }
    case types.SET_REPRESENTATIVES_STATISTICS_PRODUCTS_FOR_FILTER:
      return {
        ...state,
        statisticsProducts: action.payload
      }
    case types.SET_REPRESENTATIVE_NOTIFICATIONS_LIST:
      return {
        ...state,
        notifications: action.payload
      }
    case types.SET_REPRESENTATIVES_NOTIFICATIONS_LOADING:
      return {
        ...state,
        areNotificationsLoading: action.payload
      }
    case types.SET_REPRESENTATIVES_NOTIFICATIONS_ACTIVE_FILTERS:
      return {
        ...state,
        notificationsActiveFilters: action.payload
      }
    default: 
      return state
  }
}
