import { BASE_API_URL } from './base'

export const URL_GET_BALANCE = {
  method: 'GET',
  url: `${BASE_API_URL}/balance/own`
}

export const URL_GET_USER_BALANCE = {
  method: 'GET',
  url: (role, id) => `${BASE_API_URL}/balance/${role}/${id}`
}

export const URL_GET_TRANSACTIONS_TYPES_FOR_FILTER = {
  method: 'GET',
  url: `${BASE_API_URL}/balance/own/filter/calculable_type`
}

export const URL_GET_BALANCE_CODES_FOR_FILTER = {
  method: 'GET',
  url: `${BASE_API_URL}/balance/own/filter/code`
}

export const URL_GET_USER_TRANSACTIONS_TYPES_FOR_FILTER = {
  method: 'GET',
  url: (role, id) => `${BASE_API_URL}/balance/${role}/${id}/filter/calculable_type`
}

export const URL_GET_USER_BALANCE_CODES_FOR_FILTER = {
  method: 'GET',
  url: (role, id) => `${BASE_API_URL}/balance/${role}/${id}/filter/code`
}