import React from 'react'
import Blocks from 'shared/styled/Blocks'
import FreelancersForm from './FreelancersForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import Tabs from 'shared/components/Tabs'
import { hasPermission } from 'utils/auth'
import { UserCodesContainer } from 'modules/Codes'
import roles from 'config/roles'
import { goBack } from 'utils/global'
import Fade from 'shared/components/Fade'
import { BalanceContainer } from 'modules/Balance'
import FreelancersStatisticsContainer from '../containers/FreelancersStatisticsContainer'

const FreelancersDetails = ({
  loggedUser,
  editing,
  isPageLoading,
  currentFreelancer,
  isFormSubmitting,
  updateFreelancer,
  formValidationErrors,
  setFormValidationErrors
}) => {

  return (
    <Blocks.Block>
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <Blocks.Head>
            <Blocks.BackButton onClick={() => goBack('/panel/operatorzy')}>
              <TiArrowLeftThick size={28} />
            </Blocks.BackButton>
            <BlockHeading>{ currentFreelancer ? currentFreelancer.full_name : 'Niezależny operator' }</BlockHeading>
          </Blocks.Head>
          <Tabs>
            <UserCodesContainer 
              tabLabel={'Kody rabatowe'} 
              currentUser={currentFreelancer} 
              redirectUrl={currentFreelancer ? `/panel/operatorzy/${currentFreelancer.id}` : ``}
              currentRole={roles.freelancer.slug} />
            <FreelancersForm 
              hasPermission={hasPermission('freelancer.update', loggedUser)}
              loggedUser={loggedUser}
              tabLabel={'Ustawienia'}
              editing
              submitBtnText={'Zaktualizuj'}
              currentFreelancer={currentFreelancer}
              isFormSubmitting={isFormSubmitting}
              callApi={updateFreelancer}
              formValidationErrors={formValidationErrors}
              setFormValidationErrors={setFormValidationErrors} />
            <BalanceContainer 
              tabLabel={'Saldo'}
              userRole={roles.freelancer.slug}
              userId={currentFreelancer ? currentFreelancer.id : ''} />
            <FreelancersStatisticsContainer 
              tabLabel={'Statystyki'}
              areOwnStatistics={false}
              freelancerId={currentFreelancer ? currentFreelancer.id : ''} />
          </Tabs>
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default FreelancersDetails