

import React, { Fragment, useState } from 'react'
import Blocks from 'shared/styled/Blocks'
import Loader from 'modules/PageLoader/components/Loader'
import Table from 'shared/styled/Table'
import NoRecords from 'shared/components/DataTable/NoRecords'
import AnimateHeight from 'react-animate-height'
import CollapseArrow from 'shared/components/CollapseArrow'
import { Badge } from 'shared/components/DataTable/DataTableElements'
import Pagination from 'shared/components/Pagination'
import Fade from 'shared/components/Fade'
import LogDetails from './LogDetails'
import SearchBanner from 'shared/components/SearchBanner'
import Filters from 'shared/components/Filters'

const LogsList = ({
  loggedUser,
  filterLogs,
  activeFilters,
  logs,
  getLogs,
  isPageLoading,
  searchUrl,
  usersForFilter,
  typesForFilter
}) => {
    
  const [collapsedRow, setCollapsedRow] = useState(null)

  const collapse = id => {
    if (id === collapsedRow) {
      setCollapsedRow(null)
    } else {
      setCollapsedRow(id)
    }
  }

  const columns = [
    { name: 'Użytkownik', field: row => row.loginable_name ? row.loginable_name : 'Brak', withCollapse: true},
    { name: 'Typ akcji', field: row => <Badge>{ row.event_name }</Badge>},
    { name: 'Zmodyfikowany obiekt', field: 'type_name'},
    { name: 'Typ użytkownika', field: 'loginable_type'},
    { name: 'Adres IP', field: 'ip' },
    { name: 'Data modyfikacji', field: 'updated_at' },
  ]

  const filters = [
    {
      label: 'Akcja:',
      options: [
        {
          "value": "created",
          "label": "Utworzenie"
        },
        {
          "value": "updated",
          "label": "Aktualizacja"
        },
        {
          "value": "deleted",
          "label": "Usunięcie"
        }
      ],
      query: 'event',
      isClearable: true
    },
    {
      label: 'Typ obiektu',
      options: typesForFilter,
      query: 'type',
      isClearable: true
    },
    {
      label: 'Użytkownicy:',
      options: usersForFilter,
      query: 'user_id',
      isClearable: true
    },
    {
      label: 'Typ użytkownika:',
      options: [
        {
          "value": "operator",
          "label": "Administartor"
        },
        {
          "value": "shop",
          "label": "Jednostka gospodarcza"
        },
        {
          "value": "freelancer",
          "label": "Niezależny operator"
        },
        {
          "value": "sales_district",
          "label": "Okręg sprzedaży"
        },
        {
          "value": "seller",
          "label": "Pracownik"
        },
        {
          "value": "representative",
          "label": "Pzedstawiciel handlowy"
        }
      ],
      query: 'user_type',
      isClearable: true
    },
    {
      label: 'Pokaż z zakresu:',
      type: 'daterange',
      isClearable: true
    },
  ]

  return (
    <>
      <SearchBanner 
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={getLogs} />
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <Fade>
            <>
              <Filters 
                loggedUser={loggedUser}
                filters={filters}
                activeFilters={activeFilters}
                filterData={filterLogs} />
              { !logs || !logs.data.length
                ? <NoRecords>Brak rekordów do wyświetlenia!</NoRecords>
                : (
                  <Table.TableWrapper>
                    <Table.Table cellspacing='0'>
                      <Table.TableHead>
                        <Table.TableRow>
                          { columns.map((column, i) => (
                            <Table.TableHeadCell key={i}>{column.name}</Table.TableHeadCell>
                          ))}
                        </Table.TableRow>
                      </Table.TableHead>
                      <Table.TableBody collapsible={true} twoColors={true}>
                        { logs.data.map((row, rowIndex) => {
                          const number = logs.meta.from + rowIndex
                          return (
                            <Fragment key={number} >
                              <Table.AnimatedRow 
                                onClick={() => collapse(rowIndex)}
                                collapsible={true}
                                i={rowIndex} 
                                initialPose='hidden' 
                                pose='visible'>
                                { columns.map((column, columnIndex) => (
                                  <Table.TableCell minWidth={100} wrapText={column.wrapText ? column.wrapText : false} key={columnIndex}>
                                    { column.withCollapse
                                      ? (
                                        <CollapseArrow 
                                          isCollapsed={rowIndex === collapsedRow ? true : false} 
                                          text={column.field instanceof Function ? column.field(row, rowIndex, columnIndex) : row[column.field]} />
                                      ) : (
                                        column.field instanceof Function ? column.field(row, rowIndex, columnIndex) : row[column.field]
                                      )
                                    }
                                  </Table.TableCell>
                                ))}
                              </Table.AnimatedRow>
                              <Table.CollapsedRow>
                                <Table.CollapsedRowCell colSpan={'100%'}>
                                  <AnimateHeight
                                    duration={ 200 }
                                    height={collapsedRow === rowIndex ? 'auto' : '0%'}>
                                    <LogDetails logDetails={row.modified}/>
                                  </AnimateHeight>
                                </Table.CollapsedRowCell>
                              </Table.CollapsedRow>
                            </Fragment>
                          )
                        })}
                      </Table.TableBody>
                    </Table.Table>
                  </Table.TableWrapper>
                )
              }
              { logs && logs.links && logs.meta.total > 0 && 
                <Pagination 
                  callApi={getLogs}
                  links={logs.links}
                  from={logs.meta.from}
                  to={logs.meta.to}
                  total={logs.meta.total} />
              }
            </>
          </Fade>
        </Loader>
      </Blocks.Block>
    </>
  )
}

export default LogsList
