import * as types from './types'

export const setShopsList = shops => {
  return {
    type: types.SET_SHOPS,
    payload: shops
  }
}

export const setShopsForSelect = shops => {
  return {
    type: types.SET_SHOPS_FOR_SELECT,
    payload: shops
  }
}

export const setShopsForFilter = shops => {
  return {
    type: types.SET_SHOPS_FOR_FILTER,
    payload: shops
  }
}

export const setActiveFilters = filters => {
  return {
    type: types.SET_SHOPS_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setCurrentShop = shop => {
  return {
    type: types.SET_CURRENT_SHOP,
    payload: shop
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_SHOPS_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_SHOPS_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_SHOPS_LOADING,
    payload: isLoading
  }
}

export const setStatisticsLoading = isLoading => {
  return {
    type: types.SET_SHOPS_STATISTICS_LOADING,
    payload: isLoading
  }
}

export const setStatistics = statistics => {
  return {
    type: types.SET_SHOPS_STATISTICS,
    payload: statistics
  }
}

export const setStatisticsActiveFilters = filters => {
  return {
    type: types.SET_SHOPS_STATISTICS_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setStatisticsProductsForFilter = products => {
  return {
    type: types.SET_SHOPS_STATISTICS_PRODUCTS_FOR_FILTER,
    payload: products
  }
}