import React from 'react'
import styled from 'styled-components'
import theme from 'config/theme'
import { rem } from 'polished'

const Switcher = ({ 
  hasPermission,
  switcher,
  value,
  onChange
}) => {
  return (
    <>
      <SwitcherWrapper>
        <SwitcherTitle>{ switcher.title }:</SwitcherTitle>
        <SwitcherContent>
          { !hasPermission 
            ? <SwitcherActiveText checked={value}>{ value ? switcher.activeText : switcher.inactiveText }</SwitcherActiveText>
            : (
              <>
                <SwitcherActiveText checked={value}>{ switcher.activeText }</SwitcherActiveText>
                <Label>
                  <Check 
                    type="checkbox" 
                    onChange={onChange} 
                    name={switcher.field_name} 
                    checked={value} />
                  <Thumb />
                </Label>
                <SwitcherActiveText checked={!value}>{ switcher.inactiveText }</SwitcherActiveText>
              </>
            )
          }
        </SwitcherContent>
      </SwitcherWrapper>
    </>
  )
}

export default Switcher

const Label = styled.label`
  display: inline-block;
  font-size: 20px;
  height: 1em;
  width: 2em;
  background: ${theme.colors.lightGray};
  border-radius: 1em;
  cursor: pointer;
  border: 1px solid ${theme.colors.light};
`

const Thumb = styled.div`
  height: calc(1em - 1px);
  width: calc(1em - 1px);
  border-radius: 1em;
  background: ${theme.colors.gray};
  transition: all 300ms;
  transform: translateX(100%);
  /* box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3); */
`

const Check = styled.input`
  position: absolute;
  opacity: 0;
  &:checked {
    + ${Thumb} {
      transform: translateX(0%);
      background-color: ${theme.colors.main};
    }
  }
`

const SwitcherActiveText = styled.div`
  transition: color .2s ease;
  color: ${props => props.checked ? theme.colors.black : theme.colors.black};
  font-size: ${rem('14px')};
`

const SwitcherWrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const SwitcherContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  > * {
    margin: 0 10px;
  }
  & > :first-child {
    margin-left: 0;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    margin: 15px 0 0 0;
    width: 100%;
  }
`

const SwitcherTitle = styled.div`
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
  margin-right: 10px;
  @media (max-width: ${theme.breakpoints.sm}) {
    margin: 0;
    width: 100%;
  }
`