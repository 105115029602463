let BASE_API_URL = 'https://api.bemokarmy.pl/api/v1'

// let BASE_API_URL = 'http://test-api.bemokarmy.pl/api/v1'

if (process.env.REACT_APP_API_URL !== undefined) {
    BASE_API_URL = process.env.REACT_APP_API_URL
} else if (process.env.NODE_ENV === 'development') {
    BASE_API_URL = 'http://isskod.test/api/v1'
}

export { BASE_API_URL }