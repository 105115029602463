import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterStatistics } from '../thunks'
import Statistics from 'shared/components/Statistics'

const AdministratorsStatisticsContainer = ({
  administratorId = null, 
  areOwnStatistics = true
}) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const statisticsActiveFilters = useSelector(({ administrators }) => administrators.statisticsActiveFilters)
  const statistics = useSelector(({ administrators }) => administrators.statistics)
  const areStatisticsLoading = useSelector(({ administrators }) => administrators.areStatisticsLoading)
  const dispatch = useDispatch()
  
  useEffect(() => {
    areOwnStatistics 
    ? dispatch(filterStatistics(statisticsActiveFilters, true)) 
    : dispatch(filterStatistics(statisticsActiveFilters, false, administratorId)) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filters = [
    {
      label: 'Typ danych:',
      options: [
        { label: 'Wygenerowany obrót', value: 'sales_value' },
        { label: 'Uzyskanych bonifikat', value: 'bonus' }
      ],
      query: 'type',
      isMulti: false
    }
  ]

  return (
    <Statistics 
      loggedUser={loggedUser} 
      statistics={statistics}
      isDataLoading={areStatisticsLoading}
      activeFilters={statisticsActiveFilters}
      areOwnStatistics={areOwnStatistics}
      filters={filters}
      filterStatistics={chosenFilters => areOwnStatistics 
        ? filterStatistics(chosenFilters, areOwnStatistics) 
        : filterStatistics(chosenFilters, areOwnStatistics, administratorId)
      } />
  )
}

export default AdministratorsStatisticsContainer