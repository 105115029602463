import React, { useState } from 'react'
import queryString from 'query-string'
import ResetPasswordForm from './components/ResetPasswordForm'
import SignIn from 'shared/styled/SignIn'
import { callApi } from 'utils/api'
import { URL_RESET_PASSWORD } from 'config/api/auth'
import history from 'config/history'

const ResetPassword = () => {

  const [logging, setLogging] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)

  const resetPassword = async formData => { 
    setLogging(true)

    const parsedQuery = queryString.parse(history.location.search)
    const data = {
      ...formData,
      ...parsedQuery
    }

    try {
      const response = await callApi(URL_RESET_PASSWORD, data)
      const parsed = await response.json()
      setLogging(false)

      if (response.status === 200) {
        setApiResponse({
          isError: false,
          errors: null,
          message: parsed.message
        })
      } else if (response.status === 422 ) {
        setApiResponse({
          isError: true,
          errors: parsed.errors,
          message: parsed.message
        })
      } else {
        setApiResponse({
          isError: false,
          errors: null,
          message: parsed && parsed.message ? parsed.message : 'Wystąpił nieoczekiwany błąd, spróbuj ponownie.'
        })
      }
    } catch (err) {
      setApiResponse({
        isError: true,
        message: 'Wystąpił nieoczekiwany błąd, spróbuj ponownie.'
      })
      setLogging(false)
      console.log(err)
    }
  }
  

  return (
    <SignIn.SignWrapper>
      <SignIn.HeaderWrapper>
        <SignIn.Header>Ustaw hasło</SignIn.Header>
      </SignIn.HeaderWrapper>
      <ResetPasswordForm 
        logging={logging} 
        sendEmail={resetPassword} 
        apiResponse={apiResponse} 
        setApiResponse={setApiResponse} />
    </SignIn.SignWrapper>
  )
}

export default ResetPassword;
