import styled, { css } from 'styled-components'
import theme from 'config/theme'

export const DatePickerStyles = css`
  border-radius: 5px;
  padding: 9px 30px 9px 11px;
  max-width: 110px;
  color: ${theme.colors.black};
  outline: none;
  border: none;
  background-color: ${theme.colors.lightGray};
`

export const PickerWrapper = styled.div`
  cursor: pointer;
  .react-datepicker {
    font-family: ${theme.font.family};
  }
  .react-datepicker{
    &-wrapper {
      width: 100%;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected, 
  .react-datepicker__month-text--keyboard-selected, 
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__month--selected {
    background-color: ${theme.colors.main};
    &:hover {
      background-color: ${theme.colors.main};
    }
  }
`