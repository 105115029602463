import * as types from './types'

export const setPermissions = permissions => {
  return {
    type: types.SET_PERMISSIONS,
    payload: permissions
  }
}

export const enableLoadingPermissions = () => {
  return {
    type: types.ENABLE_LOADING_PERMISSIONS,
  }
}

export const disableLoadingPermissions = () => {
  return {
    type: types.DISABLE_LOADING_PERMISSIONS,
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}