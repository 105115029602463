import { BASE_API_URL } from './base'

export const URL_SIGN_IN = {
  method: 'POST',
  url: `${BASE_API_URL}/login`
}

export const URL_RESET_PASSWORD = {
  method: 'POST',
  url: `${BASE_API_URL}/password/reset`
}

export const URL_FORGOT_PASSWORD = {
  method: 'POST',
  url: `${BASE_API_URL}/password/email`
}