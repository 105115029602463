import { BASE_API_URL } from './base'

export const URL_GET_REPRESENTATIVES = {
  method: 'GET',
  url: `${BASE_API_URL}/representative`
}

export const URL_GET_REPRESENTATIVES_FOR_SELECT = {
  method: 'GET',
  url: `${BASE_API_URL}/select/representatives`
}

export const URL_GET_SINGLE_REPRESENTATIVE = {
  method: 'GET',
  url: id => `${BASE_API_URL}/representative/${id}`
}

export const URL_REMOVE_REPRESENTATIVE = {
  method: 'DELETE',
  url: id => `${BASE_API_URL}/representative/${id}`
}

export const URL_ADD_REPRESENTATIVE= {
  method: 'POST',
  url: `${BASE_API_URL}/representative`
}

export const URL_UPDATE_REPRESENTATIVE = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/representative/${id}`
}

export const URL_GET_REPRESENTATIVES_PERMISSIONS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/permission/representative/${id}`
}

export const URL_UPDATE_REPRESENTATIVES_PERMISSIONS = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/permission/representative/${id}`
}

export const URL_GET_REPRESENTATIVES_FOR_FILTER = {
  method: 'GET',
  url: `${BASE_API_URL}/filter/representatives`
}

export const URL_GET_OWN_REPRESENTATIVES_STATISTICS = {
  method: 'GET',
  url: `${BASE_API_URL}/stats/representative-own`
}

export const URL_GET_REPRESENTATIVES_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/representative/${id}`
}

export const URL_GET_PRODUCTS_FOR_REPRESENTATIVES_OWN_STATISTICS = {
  method: 'GET',
  url: `${BASE_API_URL}/stats/representative-own/filter/products`
}

export const URL_GET_PRODUCTS_FOR_REPRESENTATIVES_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/representative/${id}/filter/products`
}

export const URL_GET_REPRESENTATIVES_OWN_NOTIFICATIONS = {
  method: 'GET',
  url: `${BASE_API_URL}/notifications`
}

export const URL_GET_REPRESENTATIVES_NOTIFICATIONS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/notifications/representative/${id}`
}