import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_FREELANCERS,
  URL_GET_FREELANCERS_FOR_SELECT,
  URL_GET_SINGLE_FREELANCER,
  URL_REMOVE_FREELANCER,
  URL_ADD_FREELANCER,
  URL_UPDATE_FREELANCER,
  URL_GET_OWN_FREELANCERS_STATISTICS,
  URL_GET_FREELANCERS_STATISTICS
} from 'config/api/freelancers'
import queryString from 'query-string'
import moment from 'moment'

export const getFreelancers = (url = URL_GET_FREELANCERS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_FREELANCERS.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setFreelancersList }
    })
  }
}

export const getFreelancersForSelect = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_FREELANCERS_FOR_SELECT, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setFreelancersForSelect }
    })
  }
}

export const getFreelancer = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SINGLE_FREELANCER, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setCurrentFreelancer }
    })
  }
}

export const addNewFreelancer = formData => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_ADD_FREELANCER, 
      queryParams: {
        ...formData,
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null
      },
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/operatorzy', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const updateFreelancer = (formData, id) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_UPDATE_FREELANCER, 
      queryParams: {
        ...formData,
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null
      },
      pathParams: id,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/operatorzy', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const removeFreelancer = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REMOVE_FREELANCER, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: getFreelancers, shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const getFreelancerStatistics = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: 'GET', url},
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatistics }
    })
  }
}

export const filterStatistics = (activeFilters, areOwnStatistics, freelancerId = null) => {
  return async dispatch => {
    const finalUrl = areOwnStatistics 
                      ? `${URL_GET_OWN_FREELANCERS_STATISTICS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
                      : `${URL_GET_FREELANCERS_STATISTICS.url(freelancerId)}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setStatisticsActiveFilters(activeFilters))
    dispatch(getFreelancerStatistics(finalUrl))
  }
} 