import React from 'react'
import shortid from 'shortid'
import RoleRoute from 'hoc/RoleRoute'
import roles from 'config/roles'

// PAGES
import OrdersPage from './OrdersPage'

const OrderRoutes = (match, loggedUser) => [
  <RoleRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/zamowienia`} 
    component={OrdersPage} 
    permissions={['wholesaleOrder.viewAny']}
    availableFor={[roles.shop.slug, roles.seller.slug]} />
]

export default OrderRoutes