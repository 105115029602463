import React from 'react'
import { useFormik } from 'formik'
import Form from 'shared/styled/Forms'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import SignIn from 'shared/styled/SignIn'

const ForgotPasswordForm = ({ logging, sendEmail, apiResponse, setApiResponse }) => {

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: formData => {
      setApiResponse(null)
      sendEmail(formData)
    }
  });

  return (
    <SignIn.InnerWrapper>
      <Form.Form small noValidate onSubmit={formik.handleSubmit} onChange={() => setApiResponse(null)}>
        { apiResponse 
          ? apiResponse.isError ? <Form.Message error>{ apiResponse.message }</Form.Message> : <Form.Message>{ apiResponse.message }</Form.Message>
          : null
        }
        <Form.FormGroup wide>
          <Form.Input 
            type="text" 
            name="email" 
            darken 
            autocomplete="off" 
            required 
            onChange={formik.handleChange} 
            value={formik.values.email} />
          <Form.InputBar />
          <Form.Label darken>Email</Form.Label>
          { apiResponse
            ? <Form.InputError>{apiResponse && apiResponse.errors && apiResponse.errors.email ? apiResponse.errors.email[0] : ''}</Form.InputError>
            : null
          }
        </Form.FormGroup>
        <SignIn.SubmitButtonWrapper>
          <SignIn.RouteLink to={'/logowanie'}>Zaloguj się</SignIn.RouteLink>
          <SignIn.SubmitButton disabled={ logging } type="submit">
            { logging ? 
              <ButtonLoader /> : 'Wyślij email' 
            }
          </SignIn.SubmitButton>
        </SignIn.SubmitButtonWrapper>
      </Form.Form>
    </SignIn.InnerWrapper>
  )
}

export default ForgotPasswordForm