import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_CODES,
  URL_GET_SINGLE_CODE,
  URL_UPDATE_CODE,
  URL_GENERATE_NEW_CODE,
  URL_GENERATE_PDF
} from 'config/api/codes'
import queryString from 'query-string'

export const getCodes = (url = URL_GET_CODES.url) => {
   return async dispatch => {
      request({
        dispatch,
        endpoint: { method: URL_GET_CODES.method, url }, 
        onStartLoading: () => actions.setDataLoading(true),
        onEndLoading: () => actions.setDataLoading(false),
        onSuccess: { action: actions.setCodesList }
      })
   }
}

export const getCode = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SINGLE_CODE, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setCurrentCode },
      fallbackRoute: `/panel/kody-rabatowe`
    })
  }
}

export const updateCode = (formData, id) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_UPDATE_CODE, 
      pathParams: id,
      queryParams: formData,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: `/panel/kody-rabatowe` },
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const generateNewCode = (type, id, redirectUrl) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GENERATE_NEW_CODE, 
      queryParams: { type, id },
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: redirectUrl, shouldShowMessage: true },
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const generateCodePDF = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GENERATE_PDF,
      pathParams: id,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      callback: pdf => {
        window.open(pdf.data.pdf_link)
      },
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const filterCodes = activeFilters => {
  return async dispatch => {
    const finalUrl = `${URL_GET_CODES.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setActiveFilters(activeFilters))
    dispatch(getCodes(finalUrl))
  }
}
  