import React from 'react'
import styled, { keyframes } from 'styled-components'
import theme from 'config/theme'
import { rem } from 'polished'
import Fade from 'shared/components/Fade'

const Loader = ({ 
  children, 
  isPageLoading,
  light = false
}) => {
  return (
    <Fade>
      { isPageLoading
        ? <PageLoaderWrapper>
            <Spinner light={light}/>
            <SpinnerLabel light={light}>Ładowanie...</SpinnerLabel>
          </PageLoaderWrapper>
        : children 
      }
    </Fade>
  )
}

export default Loader

const spinLoader = keyframes`
  from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
`

const PageLoaderWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  min-height: 60px;
  align-items: center;
`

const Spinner = styled.div`
  width: 35px;
	height: 35px;
	border-radius: 50%;
	background: transparent;
	
	border-top: 6px solid ${props => props.light ? 'rgba(255,255,255,0.3)' : theme.colors.main};
	border-right: 6px solid ${props => props.light ? theme.colors.white : '#dde0ef'};
	border-bottom: 6px solid ${props => props.light ? theme.colors.white : '#dde0ef'};
	border-left: 6px solid ${props => props.light ? theme.colors.white : '#dde0ef'};
	
	-webkit-animation: ${spinLoader} 1.2s infinite linear;
	-moz-animation: ${spinLoader} 1.2s infinite linear;
	-ms-animation: ${spinLoader} 1.2s infinite linear;
	animation: ${spinLoader} 1.2s infinite linear;
`

const SpinnerLabel = styled.span`
  font-size: ${rem('11px')};
  color: ${props => props.light ? theme.colors.white : theme.colors.gray};
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-left: 10px;
`