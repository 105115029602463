import history from 'config/history'

export const daysLeft = dateString => {
  const date = new Date(dateString)
  const today = new Date()
  const diff = date - today
  const days = Math.floor(diff / (60*60*24*1000));

  if (days > 1) {
    return {
      value: days,
      label: `${days} dni`
    }
  } else if (days === 1) {
    return {
      value: days,
      label: `1 dzień`
    }
  } else if (days === 0) {
    return {
      value: days,
      label: `DO DZIŚ`
    }
  } else {
    return {
      value: days,
      label: `${days*(-1)} dni temu`
    }
  }
}

export const setFirstAvailableLink = (links, loggedUser) => {
  let returnedLink = ''
  if (links.length && loggedUser && loggedUser.permissions) {
    for (let i=0; i<links.length; i++) {
      if (loggedUser.permissions.includes(links[i].permission)) {
        returnedLink = links[i].link 
        break
      }
    }
    return returnedLink
  } else return returnedLink
}

export const goBack = fallbackRoute => {
  if (history) {
    history.goBack()
  }
  if (fallbackRoute) {
    history.push(fallbackRoute)
  } else {
    history.push('/panel/ustawienia')
  }
}

export const parsePrice = price => {
  const parts = price.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(",");
}

export const validateNumberInput = (value, min, max) => {
  const numberValue = Number(value)
  const numberMin = Number(min)
  const numberMax = Number(max)

  if (numberValue > numberMax) {
    return numberMax.toString()
  } else if (numberValue < numberMin) {
    return numberMin.toString()
  } else return numberValue.toString()
}