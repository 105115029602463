import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import AnimateHeight from 'react-animate-height'
import CollapseArrow from 'shared/components/CollapseArrow'
import Pagination from 'shared/components/Pagination'
import Table from 'shared/styled/Table'
import NoRecords from 'shared/components/DataTable/NoRecords'
import CollapsedOrderDetails from './CollapsedOrderDetails'
import Checkbox from 'shared/components/Checkbox'
import { PrimaryButton } from 'shared/styled/Buttons'
import { statusesIds } from 'config/orders'
import { hasPermission } from 'utils/auth'

const OrdersTable = ({ 
  loggedUser,
  orders, 
  columns,
  getOrders,
  withGroupActions,
  enableModal,
  setOrdersCompleted,
  activeFilters,
  getShopOrdersFromPagination
}) => {

  const [collapsedRow, setCollapsedRow] = useState(null)
  const [completedOrders, setCompletedOrders] = useState([])
  const notCompletedOrders = orders && orders.data.filter(order => order.status !== 3)
  const dispatch = useDispatch()
  
  const modalContent = {
    title: 'Czy na pewno chcesz oznaczyć wybrane zamówienia jako zrealizowane?',
    description: 'Niektóre operacje są nieodwracalne',
    confirmButtonText: 'Oznacz',
    cancelButtonText: 'Anuluj'
  }

  const setOrderComplete = (pickedOrder, all = false) => {
    let completed = [...completedOrders]
    if (all) {
      if (completed.length === notCompletedOrders.length) {
        completed = []
      } else {
        completed = notCompletedOrders.map(order => order.id)
      }
      setCompletedOrders(completed)
    } else {
      if (completedOrders.includes(pickedOrder)) {
        completed = completed.filter(order => pickedOrder !== order)
      } else {
        completed = [...completed, pickedOrder]
      }
      setCompletedOrders(completed)
    }
  }

  const unselectOrder = id => {
    let completed = [...completedOrders]
    if (completedOrders.includes(id)) {
      completed = completed.filter(order => id !== order)
    }
    setCompletedOrders(completed)
  }

  const collapse = id => {
    if (id === collapsedRow) {
      setCollapsedRow(null)
    } else {
      setCollapsedRow(id)
    }
  }

  return (
    <>
      { !orders || !orders.data.length
        ? <NoRecords>Brak rekordów do wyświetlenia!</NoRecords>
        : (
          <Table.TableWrapper>
            <Table.Table cellspacing='0'>
              <Table.TableHead>
                <Table.TableRow>
                  { withGroupActions && hasPermission('wholesaleOrder.update', loggedUser) &&
                    <Table.TableHeadCell>
                      <Checkbox 
                        handleChange={() => setOrderComplete([], true)}
                        isEditable={notCompletedOrders.length !== 0}
                        checked={
                          completedOrders.length === notCompletedOrders.length &&
                          notCompletedOrders.length !== 0
                        } />
                    </Table.TableHeadCell>
                  }
                  { columns.map((column, i) => (
                    <Table.TableHeadCell key={i}>{column.name}</Table.TableHeadCell>
                  ))}
                </Table.TableRow>
              </Table.TableHead>
              <Table.TableBody collapsible={true} twoColors={true}>
                { orders.data.map((row, rowIndex) => {
                  const number = orders.meta.from + rowIndex
                  return (
                    <Fragment key={number} >
                      <Table.AnimatedRow 
                        onClick={() => collapse(rowIndex)}
                        collapsible={true}
                        i={rowIndex} 
                        initialPose='hidden' 
                        pose='visible'>
                        { withGroupActions && hasPermission('wholesaleOrder.update', loggedUser) &&
                          <Table.TableCell>
                            <Checkbox 
                              isEditable={row.status !== statusesIds.done}
                              handleChange={() => setOrderComplete(row.id, false)} 
                              value={row.id} 
                              checked={completedOrders.includes(row.id)} />
                          </Table.TableCell>
                        }
                        { columns.map((column, columnIndex) => (
                          <Table.TableCell key={columnIndex}>
                            { column.withCollapse
                              ? (
                                <CollapseArrow 
                                  isVisible={true}
                                  isCollapsed={rowIndex === collapsedRow ? true : false} 
                                  text={column.field instanceof Function ? column.field(row, rowIndex, columnIndex) : row[column.field]} />
                              ) : column.field instanceof Function ? column.field(row, rowIndex, columnIndex) : row[column.field]
                            }
                          </Table.TableCell>
                        ))}
                      </Table.AnimatedRow>
                      <Table.CollapsedRow>
                        <Table.CollapsedRowCell colSpan={'100%'}>
                          <AnimateHeight
                            duration={ 200 }
                            height={collapsedRow === rowIndex ? 'auto' : '0%'}>
                            <CollapsedOrderDetails 
                              canUserSetOrderCompletePartially={hasPermission('wholesaleOrder.update', loggedUser)}
                              isParentChecked={completedOrders.includes(row.id)}
                              unselectOrder={unselectOrder}
                              setOrderComplete={setOrderComplete}
                              order={row} 
                              pageId={orders.meta.current_page} />
                          </AnimateHeight>
                        </Table.CollapsedRowCell>
                      </Table.CollapsedRow>
                    </Fragment>
                  )
                })}
              </Table.TableBody>
            </Table.Table>
          </Table.TableWrapper>
        )
      }
      <Table.TableFooter spacedOut={true}>
        <Table.TableGroupActionsWrapper>
          <> 
            { hasPermission('wholesaleOrder.update', loggedUser) &&
              <>
               <Table.TableGroupActionsText>Zaznaczone: </Table.TableGroupActionsText>
                <PrimaryButton 
                  type='button' 
                  disabled={completedOrders.length === 0}
                  onClick={() =>
                    dispatch(enableModal({
                      content: modalContent,
                      onConfirm: setOrdersCompleted,
                      onConfirmParams: [completedOrders, orders.meta.current_page, activeFilters] 
                    }))
                  }>Oznacz jako zrealizowane
                </PrimaryButton>
              </>
            }
          </>
        </Table.TableGroupActionsWrapper>
        { orders && orders.links && orders.meta.total > 0 && 
          <Pagination 
            callApi={getShopOrdersFromPagination}
            links={orders.links}
            from={orders.meta.from}
            to={orders.meta.to}
            total={orders.meta.total} />
        }
      </Table.TableFooter>
    </>
  )
}

export default OrdersTable
