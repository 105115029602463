import * as types from './types'

const initialState = {
  sellers: null,
  currentSeller: null,
  activeFilters: null,
  isFormSubmitting: false,
  formValidationErrors: null,
  isDataLoading: false,
  areStatisticsLoading: false,
  statistics: null,
  statisticsProducts: [],
  statisticsActiveFilters: {
    type: 'sales_value',
    scope: 'all',
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELLERS:
      return {
        ...state,
        sellers: action.payload
      }
    case types.SET_CURRENT_SELLER:
      return {
        ...state,
        currentSeller: action.payload
      }
    case types.SET_SELLERS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    case types.SET_SELLERS_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_SELLERS_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_SELLERS_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_SELLERS_STATISTICS_LOADING:
      return {
        ...state,
        areStatisticsLoading: action.payload
      }
    case types.SET_SELLERS_STATISTICS:
      return {
        ...state,
        statistics: action.payload
      }
    case types.SET_SELLERS_STATISTICS_ACTIVE_FILTERS: 
      return {
        ...state,
        statisticsActiveFilters: action.payload
      }
    case types.SET_SELLERS_STATISTICS_PRODUCTS_FOR_FILTER:
      return {
        ...state,
        statisticsProducts: action.payload
      }
    default: 
      return state
  }
}
