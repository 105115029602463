import styled from 'styled-components'
import { rem } from 'polished'
import { PrimaryButton } from './Buttons'
import { Link } from 'react-router-dom'
import theme from '../../config/theme'
import Blocks from 'shared/styled/Blocks'

const SignWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-width: 100vw;
  background-color: ${theme.colors.lightGray};
  padding: 0 20px;
  flex-direction: column;
`

const HeaderWrapper = styled.div`
  padding: 20px;
  @media (max-width: ${theme.breakpoints.sm}) {
    text-align: center;
  }
`

const Header = styled.h1`
  font-size: ${rem('26px')};
  font-weight: normal;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
`

const InnerWrapper = styled(Blocks.Block)`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
  @media (max-width: ${theme.breakpoints.sm}) {
    padding-top: 20px;
  }
`

const SubmitButtonWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SubmitButton = styled(PrimaryButton)`
  margin-left: 5px;
`

const RouteLink = styled(Link)` 
  margin-right: 5px;
  text-decoration: none;
  font-size: ${rem('12px')};
  text-align: left;
  color: ${theme.colors.gray};
  &:hover {
    text-decoration: underline;
    color: ${theme.colors.main};
  }
`

export default {
  SignWrapper,
  HeaderWrapper,
  Header,
  InnerWrapper,
  SubmitButtonWrapper,
  SubmitButton,
  RouteLink
}