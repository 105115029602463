import React from 'react'
import { RepresentativesDetailsContainer } from 'modules/Representatives'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const RepresentativeDetailsPage = ({ match }) => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Przedstawiciele handlowi'}/>
      <RepresentativesDetailsContainer match={match}/>
    </Fade>
  )
}

export default RepresentativeDetailsPage