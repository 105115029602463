export const SET_REPRESENTATIVES = 'SET_REPRESENTATIVES'
export const SET_REPRESENTATIVES_FOR_SELECT = 'SET_REPRESENTATIVES_FOR_SELECT'
export const SET_REPRESENTATIVES_FOR_FILTER = 'SET_REPRESENTATIVES_FOR_FILTER'
export const SET_REPRESENTATIVES_ACTIVE_FILTERS = 'SET_REPRESENTATIVES_ACTIVE_FILTERS'
export const SET_CURRENT_REPRESENTATIVE = 'SET_CURRENT_REPRESENTATIVE'
export const SET_REPRESENTATIVES_FORM_SUBMIT = 'SET_REPRESENTATIVES_FORM_SUBMIT'
export const SET_REPRESENTATIVES_FORM_VALIDATION_ERRORS = 'SET_REPRESENTATIVES_FORM_VALIDATION_ERRORS'
export const SET_REPRESENTATIVES_LOADING = 'SET_REPRESENTATIVES_LOADING'
export const SET_REPRESENTATIVES_STATISTICS = 'SET_REPRESENTATIVES_STATISTICS'
export const SET_REPRESENTATIVES_STATISTICS_ACTIVE_FILTERS = 'SET_REPRESENTATIVES_STATISTICS_ACTIVE_FILTERS'
export const SET_REPRESENTATIVES_STATISTICS_PRODUCTS_FOR_FILTER = 'SET_REPRESENTATIVES_STATISTICS_PRODUCTS_FOR_FILTER'
export const SET_REPRESENTATIVES_STATISTICS_LOADING = 'SET_REPRESENTATIVES_STATISTICS_LOADING'
export const SET_REPRESENTATIVE_NOTIFICATIONS_LIST = 'SET_REPRESENTATIVE_NOTIFICATIONS_LIST'
export const SET_REPRESENTATIVES_NOTIFICATIONS_LOADING = 'SET_REPRESENTATIVES_NOTIFICATIONS_LOADING'
export const SET_REPRESENTATIVES_NOTIFICATIONS_ACTIVE_FILTERS = 'SET_REPRESENTATIVES_NOTIFICATIONS_ACTIVE_FILTERS'