import React from 'react'
import { useDispatch } from 'react-redux'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import SearchBanner from 'shared/components/SearchBanner'
import Loader from 'modules/PageLoader/components/Loader'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa'
import DataTableLink from 'shared/components/DataTable/DataTableLink'
import { DataTableAction, DataTableActionLink } from 'shared/components/DataTable/DataTableAction'
import { StatusIndicator, TableLinkWrapper } from 'shared/components/DataTable/DataTableElements'
import { hasPermission } from 'utils/auth'

const FreelancersList = ({
  searchUrl,
  loggedUser,
  getFreelancers,
  removeFreelancer,
  freelancers,
  isPageLoading,
  enableModal
}) => {

  const dispatch = useDispatch()
  const modalContent = {
    title: 'Czy na pewno chcesz usunąć tego operatora?',
    description: 'Operacja jest nieodwracalna',
    confirmButtonText: 'Usuń',
    cancelButtonText: 'Anuluj'
  }

  return (
    <>
      <SearchBanner 
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={getFreelancers}
        buttonText='Dodaj nowego'
        createPermission='freelancer.create'
        buttonLink='/panel/operatorzy/nowy'/>
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <DataTable
            callApi={getFreelancers}
            data={freelancers}
            columns={[
              { name: 'Imię i nazwisko', field: row => {
                if (hasPermission('freelancer.view', loggedUser)) {
                  return (
                    <TableLinkWrapper>
                      <StatusIndicator active={row.status}/>
                      <DataTableLink to={`/panel/operatorzy/${row.id}`}>{row.name}</DataTableLink>
                    </TableLinkWrapper>
                  )
                } else {
                  return (
                    <TableLinkWrapper>
                      <StatusIndicator active={row.status}/>
                      { row.name }
                    </TableLinkWrapper>
                  )
                }
              }},
              { name: 'Data rozp. współpracy', field: 'partnership_since' },
              { name: 'Akcje', field: row => (
                <>
                  {
                    hasPermission('freelancer.update', loggedUser) &&
                    <DataTableActionLink 
                      tooltiptext='Edytuj' 
                      to={`/panel/operatorzy/${row.id}`}>
                      <FaRegEdit size={18}/>
                    </DataTableActionLink>
                  }
                  {
                    hasPermission('freelancer.delete', loggedUser) &&
                    <DataTableAction 
                      tooltiptext='Usuń'
                      onClick={() => {
                        dispatch(enableModal({
                          content: modalContent,
                          onConfirm: removeFreelancer,
                          onConfirmParams: [row.id]
                        }))
                      }}><FaRegTrashAlt size={18}/>
                    </DataTableAction>
                  }
                </>
              )},
            ]}
          />
        </Loader>
      </Blocks.Block>
    </>
  )
}

export default FreelancersList
