import React from 'react'
import styled from 'styled-components'
import theme from 'config/theme'
import { rem } from 'polished'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import Fade from 'shared/components/Fade'

const NoRecords = ({ children }) => {
  return (
    <Fade>
      <NoRecordsWrapper>
        <NoRecordsIcon size={'30'} />
        <NoRecordsText>{ children }</NoRecordsText>
      </NoRecordsWrapper>
    </Fade>
  )
}

export default NoRecords

const NoRecordsWrapper = styled.div`
  padding: 25px;
  background-color: ${theme.colors.lightGray};
  border: 1px solid ${theme.colors.light};
  display: flex;
  align-items: center;
  border-radius: 10px;
`

const NoRecordsIcon = styled(IoIosInformationCircleOutline)`
  color: ${theme.colors.black};
  margin-right: 10px;
`

const NoRecordsText = styled.div`
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
`