export const SET_FREELANCERS = 'SET_FREELANCERS'
export const SET_FREELANCERS_FOR_SELECT = 'SET_FREELANCERS_FOR_SELECT'
export const SET_CURRENT_FREELANCER = 'SET_CURRENT_FREELANCER'
export const SET_FREELANCERS_FORM_SUBMIT = 'SET_FREELANCERS_FORM_SUBMIT'
export const SET_FREELANCERS_FORM_VALIDATION_ERRORS = 'SET_FREELANCERS_FORM_VALIDATION_ERRORS'
export const SET_FREELANCERS_LOADING = 'SET_FREELANCERS_LOADING'
export const SET_FREELANCERS_STATISTICS = 'SET_FREELANCERS_STATISTICS'
export const SET_FREELANCERS_STATISTICS_ACTIVE_FILTERS = 'SET_FREELANCERS_STATISTICS_ACTIVE_FILTERS'
export const SET_FREELANCERS_STATISTICS_PRODUCTS_FOR_FILTER = 'SET_FREELANCERS_STATISTICS_PRODUCTS_FOR_FILTER'
export const SET_FREELANCERS_STATISTICS_LOADING = 'SET_FREELANCERS_STATISTICS_LOADING'
