import { getUser } from 'utils/auth'
import { toast } from 'react-toastify'
import history from 'config/history'
import { logout } from 'utils/auth'

export const request = async requestObject => {

  const {
    dispatch,
    endpoint, 
    queryParams = null, 
    pathParams = null,
    onStartLoading,
    onEndLoading,
    onSuccess,
    onFailure = null,
    onFormFailure = null,
    fallbackRoute = null,
    callback = null
  } = requestObject

  if (onStartLoading) dispatch(onStartLoading())

  try {

    const user = await getUser()
    if (user.success) {
      const response = await callAuthApi(endpoint, user.user.access_token, queryParams, pathParams)
      const parsed = await response.json()
      
      switch (response.status) {
        
        case 200:
          if (callback) {
            callback(parsed)
          }
          if (onSuccess) {
            if (onSuccess.action) {
              if (!parsed.meta && !parsed.data) {
                dispatch(onSuccess.action)
              }
              if (parsed.meta) {
                dispatch(onSuccess.action(parsed))
              } else {
                dispatch(onSuccess.action(parsed.data))
              }
            }
            if (onSuccess.shouldShowMessage) toast.success(parsed.message)
            if (onSuccess.redirectTo) history.push(onSuccess.redirectTo)
          }
          break
          
        case 401:
          logout()
          break

        case 403:
          history.push(fallbackRoute ? fallbackRoute : '/panel/kokpit') 
          break

        case 404: 
          toast.error(parsed.message ? parsed.message : 'Nie znaleziono takiego rekordu!')
          if (onFailure.action) dispatch(onFailure.action())
          if (onFailure.redirectTo) history.push(onFailure.redirectTo)
          break
          
        case 419:
          toast.warn(parsed.message ? parsed.message : 'Dane na Twoim koncie zostały zmienione przez administratora, musisz się zalogować ponownie!')
          logout()
          break

        case 422:
          toast.error(parsed.message ? parsed.message : 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie.')
          if (onFormFailure.action) dispatch(onFormFailure.action(parsed.errors))
          if (onFormFailure.redirectTo) history.push(onFormFailure.redirectTo)
          break

        default: 
          toast.error(parsed.message ? parsed.message : 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie.')
          break
      }
      dispatch(onEndLoading())
    }
  } catch (err) {
    dispatch(onEndLoading())
    console.log(err)
    toast.error('Wystąpił nieoczekiwany błąd. Spróbuj ponownie.')
  }
}

export const callApi = (endpoint, queryParams = null) => {
  if (endpoint.method === 'GET') {
    return fetch(endpoint.url, {
      method: endpoint.method,
      headers: new Headers ({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      })
    });
  } else {
    return fetch(endpoint.url, {
      method: endpoint.method,
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }),
      body: JSON.stringify(queryParams)
    })
  }
}

export const callAuthApi = (endpoint, token, queryParams = null, pathParams = null) => {
  
  if (queryParams !== null && pathParams !== null) {
    let url = checkUrlType(endpoint.url, pathParams)
    return fetch(url, {
      method: endpoint.method,
      body: JSON.stringify(queryParams),
      headers: setHeaders(token)
    });
  } else if (queryParams !== null ) {
    return fetch(endpoint.url, {
      method: endpoint.method,
      body: JSON.stringify(queryParams),
      headers: setHeaders(token)
    })
  } else if (pathParams !== null) {
    let url = checkUrlType(endpoint.url, pathParams);
    return fetch(url, {
      method: endpoint.method,
      headers: setHeaders(token)
    });
  } else {
    return fetch(endpoint.url, {
      method: endpoint.method,
      headers: setHeaders(token)
    })
  }
}

export const checkUrlType = (url, pathParam) => {
  if (typeof url === 'function') {
    if (Array.isArray(pathParam)) {
      return url(...pathParam)
    } else {
      return url(pathParam)
    }
  } else {
    return `${url}/${pathParam}`
  }
}

const setHeaders = token => {
  return new Headers({
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': `Bearer ${token}`
  })
}
