import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Fade from 'shared/components/Fade'
import Blocks from 'shared/styled/Blocks'
import { PrimaryButton, OutlineButton, ButtonsWrapper } from 'shared/styled/Buttons'
import Forms from 'shared/styled/Forms'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import PercentBox from './PercentBox'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import { hasPermission } from 'utils/auth'
import theme from 'config/theme'
import { goBack } from 'utils/global'

const CodeConfigurator = ({ 
  loggedUser,
  isPageLoading,
  currentCode,
  updateCode,
  isFormSubmitting,
  formValidationErrors
}) => {

  const [beneficiaries, setBeneficiaries] = useState(null)
  const [errors, setErrors] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentCode) {
      setBeneficiaries(currentCode.beneficiaries)
    }
  }, [currentCode])

  useEffect(() => {
    setErrors(formValidationErrors)
  }, [formValidationErrors])
  
  const handlePercentChange = (field_name, value) => {
    setErrors(null)
    if (String(value).length <= 4) {
      const newBeneficiaries = beneficiaries.map(beneficiary => {
        if (beneficiary.field_name !== field_name) return beneficiary
          if (Number(value) > 100) {
            return {
              ...beneficiary,
              value: '100'
            }
          } else if (value < 0) {
            return {
              ...beneficiary,
              value: '0'
            }
          } else {
            return {
              ...beneficiary,
              value: value
            }
          }
      })
      setBeneficiaries(newBeneficiaries)
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    if (currentCode) {
      let values = {}
      beneficiaries.forEach(beneficiary => {
        values = {
          ...values,
          [beneficiary.field_name]: Number(beneficiary.value)
        }
      })
      dispatch(updateCode(values, currentCode.id))
    }
  }

  return (
    <Blocks.Block>
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <Blocks.Head>
            <Blocks.BackButton onClick={() => goBack('/panel/kody-rabatowe')}>
              <TiArrowLeftThick size={28} />
            </Blocks.BackButton>
            <BlockHeading>{ currentCode ? currentCode.code : 'Kod rabatowy' }</BlockHeading>
          </Blocks.Head>
          <Forms.Form noValidate onSubmit={onSubmit}>
            <BoxesWrapper>
              {
                beneficiaries && 
                beneficiaries.map(beneficiary => {
                  if (beneficiary) {
                    return (
                      <PercentBox 
                        errors={errors}
                        key={beneficiary.field_name} 
                        beneficiary={beneficiary}
                        handlePercentChange={handlePercentChange} 
                        hasEditPermission={hasPermission('code.update', loggedUser)} />
                    )
                  } else return null
                })
              }
            </BoxesWrapper>
            { hasPermission('code.update', loggedUser) &&
              <ButtonsWrapper>
                <PrimaryButton disabled={ isFormSubmitting } type='submit'>
                  { isFormSubmitting ? <ButtonLoader /> : 'Zaktualizuj' }
                </PrimaryButton>
                <OutlineButton type='button' onClick={() => goBack('/panel/kody-rabatowe')}>Anuluj</OutlineButton>
              </ButtonsWrapper>
            }
          </Forms.Form>
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default CodeConfigurator

const BoxesWrapper = styled.div`
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  @media (max-width: ${theme.breakpoints.md}) {
    flex-wrap: wrap;
  }
`