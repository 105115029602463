import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCode, updateCode, generateNewCode } from '../thunks'
import CodeConfigurator from '../components/CodeConfigurator'

const CodeConfiguratorContainer = ({ match }) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ codes }) => codes.isDataLoading)
  const isFormSubmitting = useSelector(({ codes }) => codes.isFormSubmitting)
  const formValidationErrors = useSelector(({ codes }) => codes.formValidationErrors)
  const currentCode = useSelector(({ codes }) => codes.currentCode)
  const dispatch = useDispatch()

  const codeId = match.params.code_id

  useEffect(() => {
    dispatch(getCode(codeId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CodeConfigurator
      loggedUser={loggedUser}
      isPageLoading={isDataLoading}
      updateCode={updateCode}
      generateNewCode={generateNewCode}
      currentCode={currentCode} 
      isFormSubmitting={isFormSubmitting} 
      formValidationErrors={formValidationErrors} />
  )
}

export default CodeConfiguratorContainer