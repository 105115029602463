import { BASE_API_URL } from './base'

export const URL_GET_FREELANCERS = {
  method: 'GET',
  url: `${BASE_API_URL}/freelancer`
}

export const URL_GET_FREELANCERS_FOR_SELECT = {
  method: 'GET',
  url: `${BASE_API_URL}/select/freelancer`
}

export const URL_GET_SINGLE_FREELANCER = {
  method: 'GET',
  url: id => `${BASE_API_URL}/freelancer/${id}`
}

export const URL_REMOVE_FREELANCER = {
  method: 'DELETE',
  url: id => `${BASE_API_URL}/freelancer/${id}`
}

export const URL_ADD_FREELANCER = {
  method: 'POST',
  url: `${BASE_API_URL}/freelancer`
}

export const URL_UPDATE_FREELANCER = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/freelancer/${id}`
}

export const URL_GET_OWN_FREELANCERS_STATISTICS = {
  method: 'GET',
  url: `${BASE_API_URL}/stats/freelancer-own`
}

export const URL_GET_FREELANCERS_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/freelancer/${id}`
}