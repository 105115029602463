import React from 'react'
import { Link } from 'react-router-dom'
import theme from 'config/theme'
import styled from 'styled-components'
import DataTable from 'shared/components/DataTable'
import Blocks from 'shared/styled/Blocks'
import Fade from 'shared/components/Fade'
import Loading from 'modules/PageLoader/components/Loader'
import roles from 'config/roles'
import { checkPermissions } from 'utils/auth'
import { FaExclamationCircle, FaExclamationTriangle } from 'react-icons/fa'
import { BlockHeading } from 'shared/styled/Headings'
import Filters from 'shared/components/Filters'

const RepresentativeNotifications = ({ 
  loggedUser,
  notifications, 
  getRepresentativeNotifications,
  areNotificationsLoading,
  filterNotifications,
  notificationsActiveFilters
}) => {

  const createProperNotificationText = notification => {
    const { type } = notification
    switch(type) {
      case 'shop_product_out':
        return createOutOfProductText(notification)
      case 'shop_product_low':
        return createLowProductAmountText(notification)
      case 'susp_st_sale_item':
        return createToBigSaleText(notification)
      default:
        return createOutOfProductText(notification)
    }
  }

  const createLowProductAmountText = notification => {
    return (
      <SingleNotification>
        <NotificationIconWrapper>
          <FaExclamationTriangle color={theme.colors.red} size={22} />
        </NotificationIconWrapper>
        <NotificationContent>
          W jednostce gosp. 
          { checkPermissions(['shop.view'], loggedUser) 
            ? <NotificationLink to={`/panel/jednostki/${notification.shop.id}`}>{ notification.shop.name }</NotificationLink> 
            : <NotificationBoldElement>{ notification.shop.name }</NotificationBoldElement>
          }
          &nbsp; może kończyć się produkt &nbsp;
          { checkPermissions(['product.view'], loggedUser) 
            ? <NotificationLink to={`/panel/produkty/${notification.stationary_sale_item.product_id}`}>{ notification.stationary_sale_item.name }</NotificationLink>
            : <NotificationBoldElement>{ notification.stationary_sale_item.name}</NotificationBoldElement>
          }
        </NotificationContent>
      </SingleNotification> 
    )
  }

  const createOutOfProductText = notification => {
    return (
      <SingleNotification>
        <NotificationIconWrapper>
          <FaExclamationTriangle color={theme.colors.yellow} size={22} />
        </NotificationIconWrapper>
        <NotificationContent>
          W jednostce gosp.&nbsp;
          { checkPermissions(['shop.view'], loggedUser) 
            ? <NotificationLink to={`/panel/jednostki/${notification.shop.id}`}>{ notification.shop.name }</NotificationLink> 
            : <NotificationBoldElement>{ notification.shop.name }</NotificationBoldElement>
          }
          &nbsp; oznaczono sprzedaż ostatniej sztuki &nbsp;
          { checkPermissions(['product.view'], loggedUser) 
            ? <NotificationLink to={`/panel/produkty/${notification.stationary_sale_item.product_id}`}>{ notification.stationary_sale_item.name }</NotificationLink>
            : <NotificationBoldElement>{ notification.stationary_sale_item.name}</NotificationBoldElement>
          }
        </NotificationContent>
      </SingleNotification> 
    )
  }

  const createToBigSaleText = notification => {
    const author = {
      name: notification.caused_by.name,
      type: notification.caused_by.type,
      id: notification.caused_by.id,
    }

    let authorLink = ''
    if (author.type === roles.shop.slug) {
      authorLink = `/panel/jednostki/${author.id}`
    } else {
      authorLink = `/panel/pracownicy/${author.id}`
    }

    return (
      <SingleNotification>
        <NotificationIconWrapper>
          <FaExclamationCircle color={theme.colors.red} size={22} />
        </NotificationIconWrapper>
        <NotificationContent>
          { checkPermissions(['shop.view, seller.view'], loggedUser) 
            ? <NotificationLink to={authorLink}>{ notification.caused_by.name }</NotificationLink> 
            : <NotificationBoldElement>{ notification.caused_by.name }</NotificationBoldElement>
          }
          &nbsp; oznaczył(a) sprzedaż { notification.percent_sold }% ({ notification.stationary_sale_item.amount } sztuk) &nbsp;
          { checkPermissions(['product.view'], loggedUser) 
            ? <NotificationLink to={`/panel/produkty/${notification.stationary_sale_item.product_id}`}>{ notification.stationary_sale_item.name }</NotificationLink>
            : <NotificationBoldElement>{ notification.stationary_sale_item.name}</NotificationBoldElement>
          }
          &nbsp; w jednostce gosp. { notification.shop.name }
        </NotificationContent>
      </SingleNotification> 
    )
  } 

  const filters = [
    {
      label: 'Typ powiadomienia:',
      options: [
        { label: 'Kończący się produkt', value: 'shop_product_low' },
        { label: 'Wyprzedany produkt', value: 'shop_product_out' },
        { label: 'Podejrzana sprzedaż', value: 'susp_st_sale_item' }
      ],
      query: 'types',
      isMulti: true,
    },
    {
      label: 'Pokaż z zakresu:',
      type: 'daterange',
      isClearable: true
    },
  ]

  return (
    <Blocks.Block>
      <Loading isPageLoading={areNotificationsLoading}>
        <Fade>
          <>
            <NotificationsHeader>Powiadomienia</NotificationsHeader>
            <Filters 
              loggedUser={loggedUser}
              filters={filters}
              activeFilters={notificationsActiveFilters}
              filterData={filterNotifications} />
            <DataTable
              callApi={getRepresentativeNotifications}
              data={notifications}
              columns={[
                { name: 'Treść powiadomienia', field: row => createProperNotificationText(row) },
                { name: 'Data wygenerowania', field: 'date' }
              ]} />
          </>
        </Fade>
      </Loading>
    </Blocks.Block>
  )
}

export default RepresentativeNotifications

const NotificationsHeader = styled(BlockHeading)`
  margin-bottom: 15px;
`

const NotificationLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.black};
  font-weight: bold;
`

const NotificationBoldElement = styled.span`
  font-weight: bold;
`

const NotificationIconWrapper = styled.div`
  margin-right: 10px;
  display: inline-block;
`

const SingleNotification = styled.div`
  display: flex;
  align-items: flex-start;
`

const NotificationContent = styled.div`
  display: inline-block;
`