import React from 'react'
import BalanceBar from './BalanceBar'
import BalanceHistory from './BalanceHistory'

const Balance = ({ 
  loggedUser,
  balance, 
  userBalance,
  getBalance,
  isDataLoading,
  activeFilters,
  filterBalanceHistory,
  transactionsTypes,
  balanceCodes,
  isOwnBalance
}) => {
  
  return (
    <>
      <BalanceBar 
        isOwnBalance={isOwnBalance}
        balance={isOwnBalance ? balance : userBalance}
        isDataLoading={isDataLoading} />
      <BalanceHistory 
        isOwnBalance={isOwnBalance}
        loggedUser={loggedUser}
        balance={isOwnBalance ? balance : userBalance}
        getBalance={getBalance}
        activeFilters={activeFilters}
        isDataLoading={isDataLoading}
        transactionsTypes={transactionsTypes}
        balanceCodes={balanceCodes}
        filterBalanceHistory={filterBalanceHistory} />
    </>
  )
}

export default Balance