export const SET_SHOPS = 'SET_SHOPS'
export const SET_SHOPS_FOR_SELECT = 'SET_SHOPS_FOR_SELECT'
export const SET_SHOPS_FOR_FILTER = 'SET_SHOPS_FOR_FILTER'
export const SET_SHOPS_ACTIVE_FILTERS = 'SET_SHOPS_ACTIVE_FILTERS'
export const SET_CURRENT_SHOP = 'SET_CURRENT_SHOP'
export const SET_SHOPS_FORM_SUBMIT = 'SET_SHOPS_FORM_SUBMIT'
export const SET_SHOPS_FORM_VALIDATION_ERRORS = 'SET_SHOPS_FORM_VALIDATION_ERRORS'
export const SET_SHOPS_LOADING = 'SET_SHOPS_LOADING'
export const SET_SHOPS_STATISTICS = 'SET_SHOPS_STATISTICS'
export const SET_SHOPS_STATISTICS_ACTIVE_FILTERS = 'SET_SHOPS_STATISTICS_ACTIVE_FILTERS'
export const SET_SHOPS_STATISTICS_PRODUCTS_FOR_FILTER = 'SET_SHOPS_STATISTICS_PRODUCTS_FOR_FILTER'
export const SET_SHOPS_STATISTICS_LOADING = 'SET_SHOPS_STATISTICS_LOADING'
