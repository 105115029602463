import * as types from './types'

export const setOrdersList = orders => {
  return {
    type: types.SET_ORDERS_LIST,
    payload: orders
  }
}

export const setOrderedProducts = (products = []) => {
  return {
    type: types.SET_ORDERED_PRODUCTS,
    payload: products
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_ORDERS_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_ORDERS_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_ORDERS_LOADING,
    payload: isLoading
  }
}

export const setActiveFilters = filters => {
  return {
    type: types.SET_ORDERS_ACTIVE_FILTERS,
    payload: filters
  }
}