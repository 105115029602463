import React from 'react'
import { useDispatch } from 'react-redux'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import SearchBanner from 'shared/components/SearchBanner'
import Loader from 'modules/PageLoader/components/Loader'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa'
import DataTableLink from 'shared/components/DataTable/DataTableLink'
import { DataTableAction, DataTableActionLink } from 'shared/components/DataTable/DataTableAction'
import { StatusIndicator, TableLinkWrapper } from 'shared/components/DataTable/DataTableElements'
import { hasPermission } from 'utils/auth'
import Filters from 'shared/components/Filters'
import roles from 'config/roles'
import Fade from 'shared/components/Fade'

const RepresentativesList = ({
  loggedUser,
  searchUrl,
  getRepresentatives,
  removeRepresentative,
  representatives,
  isPageLoading,
  enableModal,
  salesDistrictsForFilter,
  activeFilters,
  filterRepresentatives
}) => {

  const dispatch = useDispatch()
  const modalContent = {
    title: 'Czy na pewno chcesz usunąć tego przedstawiciela?',
    description: 'Operacja jest nieodwracalna',
    confirmButtonText: 'Usuń',
    cancelButtonText: 'Anuluj'
  }
  const filters = [
    {
      label: 'OS:',
      options: salesDistrictsForFilter,
      query: 'sales_districts_ids',
      role: roles.district.slug,
      isMulti: true,
      isClearable: true
    }
  ]

  return (
    <>
      <SearchBanner 
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={getRepresentatives}
        createPermission='representative.create'
        buttonText='Dodaj nowego'
        buttonLink='/panel/przedstawiciele/nowy'/>
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <Fade>
            <Filters 
              loggedUser={loggedUser}
              filters={filters}
              activeFilters={activeFilters}
              filterData={filterRepresentatives} />
            <DataTable
              callApi={getRepresentatives}
              data={representatives}
              columns={[
                { name: 'Imię i nazwisko', field: row => {
                  if (hasPermission('representative.view', loggedUser)) {
                    return (
                      <TableLinkWrapper>
                        <StatusIndicator active={row.status}/>
                        <DataTableLink to={`/panel/przedstawiciele/${row.id}`}>{row.name}</DataTableLink>
                      </TableLinkWrapper>
                    )
                  } else {
                    return (
                      <TableLinkWrapper>
                        <StatusIndicator active={row.status}/>
                        { row.name }
                      </TableLinkWrapper>
                    )
                  }
                }},
                { name: 'Okręg', field: 'sales_district_name' },
                { name: 'Data rozp. współpracy', field: 'partnership_since' },
                { name: 'JG / IP', field: row => <>{ row.shops_count } / { row.sellers_count }</> },
                { name: 'Akcje', field: row => (
                  <>
                    {
                      hasPermission('representative.update', loggedUser) &&
                      <DataTableActionLink 
                        tooltiptext='Edytuj'
                        to={`/panel/przedstawiciele/${row.id}`}>
                        <FaRegEdit size={18}/>
                      </DataTableActionLink>
                    }
                    {
                      hasPermission('representative.delete', loggedUser) &&
                      <DataTableAction
                        tooltiptext='Usuń'
                        onClick={() => {
                          dispatch(enableModal({
                            content: modalContent,
                            onConfirm: removeRepresentative,
                            onConfirmParams: [row.id]
                          }))
                        }}><FaRegTrashAlt size={18}/>
                      </DataTableAction>
                    }
                  </>
                )},
              ]}
            />
          </Fade>
        </Loader>
      </Blocks.Block>
    </>
  )
}

export default RepresentativesList
