import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { rem } from 'polished'
import Blocks from 'shared/styled/Blocks'
import theme from 'config/theme'
import { OutlineButtonStyles } from 'shared/styled/Buttons'

const InfoBox = ({ 
  value,
  title,
  buttonLink
}) => {

  return (
    <Wrapper>
      <Content>
        <Label>{ title }</Label>
        <Value>{ value }</Value>
      </Content>
      <StructureCountLink to={buttonLink}>Zarządzaj</StructureCountLink>
    </Wrapper>
  )
}

export default InfoBox

const Wrapper = styled(Blocks.Block)`
  width: auto;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${theme.breakpoints.md}) {
    margin: 10px;
  }
`

const Content = styled.div``

const Label = styled.div`
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
  margin: 0;
`

const Value = styled.div`
  color: ${theme.colors.black};
  font-size: ${rem('32px')};
  margin: 5px 0 15px 0;
  line-height: 1;
  font-weight: bold;
`

const StructureCountLink = styled(Link)`
  ${OutlineButtonStyles};
  display: inline-block;
`