import React from 'react'
import posed from 'react-pose'

const Fade = ({ children }) => {
  return (
    <FadeWrapper initialPose='hidden' pose='visible'>
      { children }
    </FadeWrapper>
  )
}

export default Fade

const onEnterAnimation = { 
  opacity: 1,
  x: 0
}

const onLeaveAnimation = { 
  opacity: 0,
  x: -30
}

const FadeWrapper = posed.div({
  visible: onEnterAnimation,
  hidden: onLeaveAnimation
})