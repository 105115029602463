import * as types from './types'

const initialState = {
  orders: null,
  chosenOrderedProducts: [],
  isFormSubmitting: false,
  formValidationErrors: null,
  activeFilters: null,
  isDataLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ORDERS_LIST:
      return {
        ...state,
        orders: action.payload
      }
    case types.SET_ORDERED_PRODUCTS:
      return {
        ...state,
        chosenOrderedProducts: action.payload
      }
    case types.SET_ORDERS_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_ORDERS_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_ORDERS_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_ORDERS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    default: 
      return state
  }
}
