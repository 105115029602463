import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'

// PAGES
import RepresentativesPage from './RepresentativesPage'
import NewRepresentativePage from './NewRepresentativePage'
import RepresentativeDetailsPage from './RepresentativeDetailsPage'

const RepresentativesRoutes = (match, loggedUser) => [
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/przedstawiciele`} 
    component={RepresentativesPage} 
    permissions={['representative.viewAny']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/przedstawiciele/nowy`} 
    component={NewRepresentativePage} 
    permissions={['representative.create']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/przedstawiciele/:representative_id`} 
    component={RepresentativeDetailsPage} 
    permissions={['representative.update', 'representative.view']} />
]

export default RepresentativesRoutes