export const SET_SELLERS = 'SET_SELLERS'
export const SET_CURRENT_SELLER = 'SET_CURRENT_SELLER'
export const SET_SELLERS_ACTIVE_FILTERS = 'SET_SELLERS_ACTIVE_FILTERS'
export const SET_SELLERS_FORM_SUBMIT = 'SET_SELLERS_FORM_SUBMIT'
export const SET_SELLERS_FORM_VALIDATION_ERRORS = 'SET_SELLERS_FORM_VALIDATION_ERRORS'
export const SET_SELLERS_LOADING = 'SET_SELLERS_LOADING'
export const SET_SELLERS_STATISTICS = 'SET_SELLERS_STATISTICS'
export const SET_SELLERS_STATISTICS_ACTIVE_FILTERS = 'SET_SELLERS_STATISTICS_ACTIVE_FILTERS'
export const SET_SELLERS_STATISTICS_PRODUCTS_FOR_FILTER = 'SET_SELLERS_STATISTICS_PRODUCTS_FOR_FILTER'
export const SET_SELLERS_STATISTICS_LOADING = 'SET_SELLERS_STATISTICS_LOADING'
