import * as types from './types'

const initialState = {
  profile: null,
  isFormSubmitting: false,
  formValidationErrors: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_PROFILE:
      return {
        ...state,
        profile: action.payload
      }
    case types.SET_PROFILE_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_PROFILE_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_PROFILE_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    default: 
      return state
  }
}
