import * as types from './types'

export const setSoldProducts = (products = []) => {
  return {
    type: types.SET_SOLD_PRODUCTS,
    payload: products
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_SALES_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_SALES_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}