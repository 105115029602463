import React from 'react'
import { useDispatch } from 'react-redux'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import SearchBanner from 'shared/components/SearchBanner'
import Loader from 'modules/PageLoader/components/Loader'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa'
import DataTableLink from 'shared/components/DataTable/DataTableLink'
import { DataTableAction, DataTableActionLink } from 'shared/components/DataTable/DataTableAction'
import { hasPermission } from 'utils/auth'

const SalesDistrictsList = ({
  loggedUser,
  searchUrl,
  getSalesDistricts,
  salesDistricts,
  removeSalesDistrict,
  isPageLoading,
  enableModal
}) => {

  const dispatch = useDispatch()
  const modalContent = {
    title: 'Czy na pewno chcesz usunąć ten okręg sprzedaży?',
    description: 'Operacja jest nieodwracalna',
    confirmButtonText: 'Usuń',
    cancelButtonText: 'Anuluj'
  }

  return (
    <>
      <SearchBanner 
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={getSalesDistricts}
        createPermission='salesDistrict.create'
        buttonText='Dodaj nowy'
        buttonLink='/panel/okregi/nowy' />
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <DataTable
            callApi={getSalesDistricts}
            data={salesDistricts}
            columns={[
              { name: 'Nazwa', field: row => {
                if (hasPermission('salesDistrict.view', loggedUser)) {
                  return <DataTableLink to={`/panel/okregi/${row.id}`}>{row.name}</DataTableLink>
                } else {
                  return row.name
                }
              }},
              { name: 'Ilość PH', field: 'representatives_count' },
              { name: 'Ilość JG', field: 'shops_count' },
              { name: 'Ilość IP', field: 'sellers_count' },
              { name: 'Akcje', field: row => (
                <>
                  {
                    hasPermission('salesDistrict.update', loggedUser) &&
                    <DataTableActionLink 
                      tooltiptext='Edytuj'
                      to={`/panel/okregi/${row.id}`}>
                      <FaRegEdit size={18}/>
                    </DataTableActionLink>
                  }
                  {
                    hasPermission('salesDistrict.delete', loggedUser) &&
                    <DataTableAction 
                      tooltiptext='Usuń'
                      onClick={() => {
                        dispatch(enableModal({
                          content: modalContent,
                          onConfirm: removeSalesDistrict,
                          onConfirmParams: [row.id]
                        }))
                    }}><FaRegTrashAlt size={18}/>
                  </DataTableAction>
                  }
                </>
              )},
            ]}
          />
        </Loader> 
      </Blocks.Block>
    </>
  )
}

export default SalesDistrictsList
