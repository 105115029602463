import React, { useEffect } from 'react'
import SellersList from '../components/SellersList'
import { useDispatch, useSelector } from 'react-redux'
import { getSellers, removeSeller, filterSellers } from '../thunks'
import { thunks as representativeThunks } from 'modules/Representatives'
import { thunks as shopsThunks } from 'modules/Shops'
import { setActiveFilters } from '../actions'
import { URL_GET_SELLERS } from 'config/api/sellers'
import { actions as modalActions} from 'modules/Modal'
import roles from 'config/roles'

const SellersListContainer = () => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const sellers = useSelector(({ sellers }) => sellers.sellers)
  const activeFilters = useSelector(({ sellers }) => sellers.activeFilters)
  const representativesForFilter = useSelector(({ representatives }) => representatives.representativesForFilter)
  const shopsForFilter = useSelector(({ shops }) => shops.shopsForFilter)
  const isDataLoading = useSelector(({ sellers }) => sellers.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSellers())
    if (loggedUser.account_type !== roles.representative.slug && loggedUser.account_type !== roles.shop.slug) {
      dispatch(representativeThunks.getRepresentativesForFilter())
    }
    if (loggedUser.account_type !== roles.shop.slug) {
      dispatch(shopsThunks.getShopsForFilter())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

   useEffect(() => {
    return () => dispatch(setActiveFilters())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SellersList
      loggedUser={loggedUser}
      searchUrl={URL_GET_SELLERS.url}
      sellers={sellers} 
      isPageLoading={isDataLoading}
      enableModal={modalActions.enableModal}
      getSellers={getSellers}
      removeSeller={removeSeller} 
      activeFilters={activeFilters}
      representativesForFilter={representativesForFilter}
      shopsForFilter={shopsForFilter}
      filterSellers={filterSellers} />
  )
}

export default SellersListContainer