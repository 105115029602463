import { BASE_API_URL } from './base'

export const URL_GET_ORDERS = {
  method: 'GET',
  url: `${BASE_API_URL}/wholesale-order`
}

export const URL_GET_SHOP_ORDERS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/shop/${id}/wholesale-order`
}

export const URL_REPORT_ORDER = {
  method: 'POST',
  url: `${BASE_API_URL}/wholesale-order`
}

export const URL_REPORT_ORDER_VIA_ADMIN = {
  method: 'POST',
  url: id => `${BASE_API_URL}/shop/${id}/wholesale-order`
}

export const URL_CHANGE_ORDER_STATUS = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/wholesale-order/${id}`
}

export const URL_REMOVE_ORDER = {
  method: 'DELETE',
  url: id => `${BASE_API_URL}/wholesale-order/${id}`
}

export const URL_SET_ORDERS_COMPLETED = {
  method: 'POST',
  url: `${BASE_API_URL}/wholesale-order/mass-status_change/completed`
}