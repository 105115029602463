import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_LOGS,
  URL_GET_USERS_FOR_FILTERS,
  URL_GET_TYPES_FOR_FILTERS
} from 'config/api/logs'
import queryString from 'query-string'

export const getLogs = (url = URL_GET_LOGS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_LOGS.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setLogsList }
    })
  }
}

export const getLogsUsersForFilter = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_USERS_FOR_FILTERS,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setLogsUsersForFilter }
    })
  }
}

export const getLogsTypesForFilter = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_TYPES_FOR_FILTERS,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setLogsTypesForFilter }
    })
  }
}

export const filterLogs = activeFilters => {
  return async dispatch => {
    const finalUrl = `${URL_GET_LOGS.url}?${queryString.stringify(activeFilters, { arrayFormat: 'bracket' })}`
    dispatch(actions.setActiveFilters(activeFilters))
    dispatch(getLogs(finalUrl))
  }
} 
