import React from 'react'
import { DashboardContainer } from 'modules/Dashboard'
import Fade from 'shared/components/Fade'

const DashboardPage = ({ match }) => {
  return (
    <Fade>
      <DashboardContainer match={match} />
    </Fade>
  )
}

export default DashboardPage