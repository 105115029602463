import React from 'react'
import { Link } from 'react-router-dom'
import { rem } from 'polished'
import theme from 'config/theme'
import styled from 'styled-components'
import { OutlineButtonStyles } from 'shared/styled/Buttons'
import { parsePrice } from 'utils/global'

const Balance = ({ dashboardInfo }) => {
  return (
     <BalanceWrapper>
      <Content>
        <BalanceText>Twoje saldo:</BalanceText>
        <BalanceValue>{ dashboardInfo && parsePrice(dashboardInfo.balance)} zł</BalanceValue>
      </Content>
      <BalanceButton to={'/panel/saldo'}>Szczegóły</BalanceButton>
    </BalanceWrapper>
  )
}

export default Balance

const BalanceWrapper = styled.div`
  padding: 25px;
  border-radius: 6px;
  box-shadow: 0px 2px 1px rgba(0,0,0,0.05);
  background: linear-gradient(90deg, rgba(64,115,203,1) 0%, rgb(102, 175, 202) 100%);
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${theme.breakpoints.xs}) {
    margin: 7px 15px;
  }
`

const Content = styled.div``

const BalanceText = styled.div`
  color: ${theme.colors.white};
  font-size: ${rem('14px')};
`

const BalanceValue = styled.div`
  color: ${theme.colors.white};
  font-size: ${rem('32px')};
  line-height: 1;
  font-weight: bold;
  margin: 5px 0 15px 0;
`

const BalanceButton = styled(Link)`
  ${OutlineButtonStyles};
  border-color: ${theme.colors.white};
  color: ${theme.colors.white};
  display: inline-block;
  &:hover {
    border-color: ${theme.colors.black};
    background-color: ${theme.colors.black};
  }
`
