import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getBeneficiariesWithNegativeBalance} from '../thunks'
import BeneficiariesWithNegativeBalance from '../components/BeneficiariesWithNegativeBalance'

const BeneficiariesWithNegativeBalanceContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ payouts }) => payouts.isDataLoading)
  const beneficiaries = useSelector(({ payouts }) => payouts.beneficiaries)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBeneficiariesWithNegativeBalance())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BeneficiariesWithNegativeBalance 
      loggedUser={loggedUser}
      isDataLoading={isDataLoading}
      beneficiaries={beneficiaries}
      getBeneficiariesWithNegativeBalance={getBeneficiariesWithNegativeBalance} />
  )
}

export default BeneficiariesWithNegativeBalanceContainer