import React from 'react'
import styled from 'styled-components'
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'

const CollapseArrow = ({ isCollapsed = false, text, isVisible }) => {

  return (
    <CollapseArrowWrapper>
      {
        isVisible
        ? (
          <CollapseArrowIndicator>
            { isCollapsed 
            ? <TiArrowSortedUp size={16} />
            : <TiArrowSortedDown size={16} />
            }
          </CollapseArrowIndicator>
        )
        : <ArrowPlaceholder />
      }
      <CollapseArrowText>{ text }</CollapseArrowText>
    </CollapseArrowWrapper>
  )
}

export default CollapseArrow

const CollapseArrowWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CollapseArrowIndicator = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`

const CollapseArrowText = styled.div``

const ArrowPlaceholder = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 23px;
`