import React, { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getProfile, resetPassword } from '../thunks'
import { setFormValidationErrors } from '../actions'
import Profile from '../components/Profile'

const ProfileContainer = () => {

  const profile = useSelector(({ profile }) => profile.profile)
  const isFormSubmitting = useSelector(({ profile }) => profile.isFormSubmitting)
  const formValidationErrors = useSelector(({ profile }) => profile.formValidationErrors)
  const isDataLoading = useSelector(({ profile }) => profile.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfile())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Profile 
      profile={profile} 
      resetPassword={resetPassword}
      isPageLoading={isDataLoading}
      isFormSubmitting={isFormSubmitting}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default ProfileContainer