import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from 'config/theme'
import { userMenu } from 'config/menu'
import history from 'config/history'
import { checkPermissions, hasAccess } from 'utils/auth'
import { MdMenu } from 'react-icons/md'

const UserMenu = ({ loggedUser }) => {

  const [sidebarVisible, toggleSidebarVisible] = useState(false)
  const wrapperRef = useRef(null)
  const hamburgerBtnRef = useRef(null)

  const toggleSidebar = () => {
    toggleSidebarVisible(!sidebarVisible)
  }

  const setActive = link => {
    const currentPath = history.location.pathname
    if (currentPath.startsWith(link)) {
      return 'true'
    }
  }

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !event.path.includes(hamburgerBtnRef.current)) {
      toggleSidebarVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => document.removeEventListener('click', handleClickOutside, false)
  }, [])

  return (
     <>
      <Hamburger type='button' onClick={() => toggleSidebar()} ref={hamburgerBtnRef}>
        <MdMenu size={28} />
      </Hamburger>
      {
        <UserMenuWrapper sidebarVisible={sidebarVisible} ref={wrapperRef}>
          <Logo>
            <LogoLink onClick={() => toggleSidebar()} to={'/panel'}>
              <LogoTopPart>ISS</LogoTopPart>
              <LogoBottomPart>KOD</LogoBottomPart>
            </LogoLink>
          </Logo>
          <MenuList>
            { userMenu.map(page => {
              if ((page.permissions && page.permissions.includes('all')) || checkPermissions(page.permissions, loggedUser) || hasAccess(page.available_roles, loggedUser)) {
                return (
                  <UserSubMenuItem onClick={toggleSidebar} active={setActive(page.link)} key={ page.id } to={ page.link }>{ page.label }</UserSubMenuItem>
                )
              } else return null
            })}
          </MenuList>
        </UserMenuWrapper>
      }
    </>
  )
}

export default UserMenu

const Hamburger = styled.button`
  outline: none;
  background: none;
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 15px;
  z-index: 20;
  top: 10px;
  cursor: pointer;
  display: none;
  transition: color .2s ease;
  &:hover {
    color: ${theme.colors.main};
  }
  @media (max-width: ${theme.breakpoints.md}) {
    display: block;
  }
`

const Logo = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  @media (max-width: ${theme.breakpoints.md}) {
    display: flex;
  }
`

const LogoLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.black};
  line-height: 0.9;
`

const LogoTopPart = styled.div`
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 1px;
`

const LogoBottomPart = styled.div`
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 1px;
`

const UserMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 30px;
  flex: 1;
  @media (max-width: ${theme.breakpoints.md}) {
    background-color: ${theme.colors.white};
    width: 80px;
    box-shadow: rgba(0,0,0,0.047) 2px 0px 10px 4px;
    height: 100vh;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 170px;
    transition: transform .2s ease;
    transform: ${props => props.sidebarVisible ? 'translate(0)' : 'translateX(-170px)'};
    padding: 0;
    display: block;
  }
`

const UserSubMenuItem = styled(Link)`
  text-decoration: none;
  padding: 8px 15px;
  font-weight: 600;
  color: ${props => props.active ? theme.colors.white : theme.colors.black};
  font-size: ${rem('14px')};
  background-color: ${props => props.active ? theme.colors.main : 'transparent'};
  border-radius: 5px;
  transition: background-color .2s ease;
  margin: 0 3px;
  &:hover,
  &.active {
    color: ${theme.colors.white};
    background-color: ${theme.colors.main};
  }
  @media (max-width: ${theme.breakpoints.md}) {
    min-height: 60px;
    display: block;
    margin: 0;
    border-radius: 0;
    padding: 15px 15px 15px 25px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${props => props.active ? theme.colors.mainHover : 'transparent'};
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
      background-color: ${theme.colors.main};
      display: ${props => props.active ? 'block' : 'none'};
    }
    &:first-of-type {
      margin-top: 25px;
    }
    &:hover {
      background-color: ${theme.colors.mainHover};
      &::before {
        display: block;
      }
    }
  }
`

const MenuList = styled.div`
  @media (max-width: ${theme.breakpoints.md}) { 
    overflow-y: auto;
  }
`