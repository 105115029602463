import * as types from './types'

const initialState = {
  visible: false,
  content: {
    title: '',
    description: '',
    confirmButtonText: '',
    cancelButtonText: ''
  },
  onConfirm: () => {},
  onConfirmParams: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ENABLE_MODAL:
      return {
        ...state,
        visible: true,
        content: action.payload.content,
        onConfirm: () => action.payload.onConfirm(...action.payload.onConfirmParams),
        onConfirmParams: action.payload.onConfirmParams ? action.payload.onConfirmParams : initialState.onConfirmParam,
      }
    case types.DISABLE_MODAL:
      return {
        ...state,
        visible: false
      }
    default:
      return state
  }
}
