import React, { useEffect } from 'react'
import AdministratorsList from '../components/AdministratorsList'
import { useDispatch, useSelector } from 'react-redux'
import { getAdministrators, removeAdministrator } from '../thunks'
import { URL_GET_ADMINISTRATORS } from 'config/api/administrators'
import { actions as modalActions} from 'modules/Modal'

const AdministratorsListContainer = () => {
  
  const loggedUser = useSelector(({user}) => user.loggedUser)
  const administrators = useSelector(({ administrators }) => administrators.administrators)
  const isDataLoading = useSelector(({ administrators }) => administrators.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAdministrators())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdministratorsList 
      loggedUser={loggedUser}
      searchUrl={URL_GET_ADMINISTRATORS.url}
      administrators={administrators} 
      isPageLoading={isDataLoading}
      enableModal={modalActions.enableModal}
      getAdministrators={getAdministrators}
      removeAdministrator={removeAdministrator} />
  )
}

export default AdministratorsListContainer