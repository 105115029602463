import React from 'react'
import { AdministratorsNewContainer } from 'modules/Administrators'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const NewAdministratorPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Administratorzy'}/>
      <AdministratorsNewContainer />
    </Fade>
  )
}

export default NewAdministratorPage