import { BASE_API_URL } from './base'

export const URL_GET_ADMINISTRATORS = {
  method: 'GET',
  url: `${BASE_API_URL}/operator`
}

export const URL_GET_SINGLE_ADMINISTRATOR = {
  method: 'GET',
  url: id => `${BASE_API_URL}/operator/${id}`
}

export const URL_REMOVE_ADMINISTRATOR = {
  method: 'DELETE',
  url: id => `${BASE_API_URL}/operator/${id}`
}

export const URL_ADD_ADMINISTRATOR = {
  method: 'POST',
  url: `${BASE_API_URL}/operator`
}

export const URL_UPDATE_ADMINISTRATOR = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/operator/${id}`
}

export const URL_GET_ADMINISTRATORS_PERMISSIONS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/permission/operator/${id}`
}

export const URL_UPDATE_ADMINISTRATORS_PERMISSIONS = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/permission/operator/${id}`
}

export const URL_GET_OWN_ADMINISTRATORS_STATISTICS = {
  method: 'GET',
  url: `${BASE_API_URL}/stats/operator-own`
}

export const URL_GET_ADMINISTRATORS_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/operator/${id}`
}