import React, { useEffect } from 'react'
import LogsList from '../components/LogsList'
import { useDispatch, useSelector } from 'react-redux'
import { getLogs, filterLogs, getLogsUsersForFilter, getLogsTypesForFilter } from '../thunks'
import { setActiveFilters } from '../actions'
import { URL_GET_LOGS } from 'config/api/logs'

const LogsListContainer = () => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const logs = useSelector(({ logs }) => logs.logs)
  const activeFilters = useSelector(({ logs }) => logs.activeFilters)
  const usersForFilter = useSelector(({ logs }) => logs.usersForFilter)
  const typesForFilter = useSelector(({ logs }) => logs.typesForFilter)
  const isDataLoading = useSelector(({ logs }) => logs.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLogs())
    dispatch(getLogsUsersForFilter())
    dispatch(getLogsTypesForFilter())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setActiveFilters(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LogsList
      loggedUser={loggedUser}
      logs={logs} 
      isPageLoading={isDataLoading}
      getLogs={getLogs}
      filterLogs={filterLogs} 
      activeFilters={activeFilters}
      usersForFilter={usersForFilter}
      typesForFilter={typesForFilter}
      searchUrl={URL_GET_LOGS.url}/>
  )
}

export default LogsListContainer