import React from 'react'
import Blocks from 'shared/styled/Blocks'
import DistrictForm from './DistrictForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import { goBack } from 'utils/global'

const SalesDistrictsNew = ({ 
  history,
  addNewDistrict,
  isFormSubmitting,
  formValidationErrors,
  setFormValidationErrors
}) => {
  return (
    <Blocks.Block>
      <Blocks.Head>
        <Blocks.BackButton onClick={() => goBack('/panel/okregi')}>
          <TiArrowLeftThick size={28} />
        </Blocks.BackButton>
        <BlockHeading>Dodaj nowy okręg sprzedaży</BlockHeading>
      </Blocks.Head>
      <DistrictForm 
        hasPermission={true}
        submitBtnText={'Dodaj okręg'}
        isFormSubmitting={isFormSubmitting}
        callApi={addNewDistrict}
        formValidationErrors={formValidationErrors}
        setFormValidationErrors={setFormValidationErrors} />
    </Blocks.Block>
  )
}

export default SalesDistrictsNew