import React from 'react'
import Blocks from 'shared/styled/Blocks'
import RepresentativesForm from './RepresentativesForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import { goBack } from 'utils/global'

const RepresentativesNew = ({ 
  history,
  isPageLoading,
  salesDistrictsForSelect,
  addNewRepresentative,
  isFormSubmitting,
  formValidationErrors,
  setFormValidationErrors
}) => {

  return (
    <Blocks.Block>
      <Blocks.Head>
        <Blocks.BackButton onClick={() => goBack('/panel/przedstawiciele')}>
          <TiArrowLeftThick size={28} />
        </Blocks.BackButton>
        <BlockHeading>Dodaj nowego przedstawiciela</BlockHeading>
      </Blocks.Head>
      <Loader isPageLoading={isPageLoading}>
        <RepresentativesForm 
          hasPermission={true}
          salesDistrictsForSelect={salesDistrictsForSelect}
          submitBtnText={'Dodaj'}
          isFormSubmitting={isFormSubmitting}
          callApi={addNewRepresentative}
          formValidationErrors={formValidationErrors}
          setFormValidationErrors={setFormValidationErrors} />
      </Loader>
    </Blocks.Block>
  )
}

export default RepresentativesNew