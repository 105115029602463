import React, { useEffect } from 'react'
import RepresentativesList from '../components/RepresentativesList'
import { useDispatch, useSelector } from 'react-redux'
import { getRepresentatives, removeRepresentative, filterRepresentatives } from '../thunks'
import { setActiveFilters } from '../actions'
import { thunks as districtThunks } from 'modules/SalesDistricts'
import { URL_GET_REPRESENTATIVES } from 'config/api/representatives'
import { actions as modalActions} from 'modules/Modal'
import roles from 'config/roles'

const RepresentativesListContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const activeFilters = useSelector(({ representatives }) => representatives.activeFilters)
  const representatives = useSelector(({ representatives }) => representatives.representatives)
  const salesDistrictsForFilter = useSelector(({ sales_districts }) => sales_districts.salesDistrictsForFilter)
  const isDataLoading = useSelector(({ representatives }) => representatives.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRepresentatives())
    if (loggedUser.account_type !== roles.district.slug) {
      dispatch(districtThunks.getSalesDistrictsForFilter())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => dispatch(setActiveFilters())
  }, [dispatch])

  return (
    <RepresentativesList
      loggedUser={loggedUser}
      searchUrl={URL_GET_REPRESENTATIVES.url}
      representatives={representatives} 
      isPageLoading={isDataLoading}
      enableModal={modalActions.enableModal}
      getRepresentatives={getRepresentatives}
      removeRepresentative={removeRepresentative} 
      activeFilters={activeFilters} 
      filterRepresentatives={filterRepresentatives}
      salesDistrictsForFilter={salesDistrictsForFilter} />
  )
}

export default RepresentativesListContainer