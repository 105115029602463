import * as actions from './actions'
import { request } from 'utils/api'

export const getPermissions = (url, objectId) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: url, 
      pathParams: objectId,
      onStartLoading: actions.enableLoadingPermissions,
      onEndLoading: actions.disableLoadingPermissions,
      onSuccess: { action: actions.setPermissions }
    })
  }
}

export const updatePermissions = (permissions, objectId, url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: url, 
      queryParams: permissions,
      pathParams: objectId,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { shouldShowMessage: true }
    })
  }
}