import React from 'react'
import styled from 'styled-components'
import theme from 'config/theme'
import { rem } from 'polished'
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md'
import Forms from 'shared/styled/Forms'

const PercentBox = ({ 
  beneficiary,
  handlePercentChange,
  errors,
  hasEditPermission
}) => {

  const incrementPercent = (field_name, value) => {
    if (Number(value) < 100) {
      handlePercentChange(field_name, String((Number(value) + 0.1).toFixed(1)))
    }
  }

  const decrementPercent = (field_name, value) => {
    if (Number(value) > 0) {
      handlePercentChange(field_name, String((Number(value) - 0.1).toFixed(1)))
    }
  }

  return (
    <Box>
      <Role>{ beneficiary.beneficiary.disp_name }</Role>
      <Name>{ beneficiary.beneficiary.name }</Name>
      <PercentText>Naliczaj % bonifikaty</PercentText>
      { hasEditPermission
        ? (
          <>
            <InputWrapper>
              <ChangePercentButton onClick={() => incrementPercent(beneficiary.field_name, beneficiary.value)} type='button'><MdAddCircleOutline size={28} /></ChangePercentButton>
              <PercentInput onChange={e => handlePercentChange(beneficiary.field_name, e.target.value)} type='number' max='100' min='0' step='0.1' value={beneficiary.value} />
              <ChangePercentButton onClick={() => decrementPercent(beneficiary.field_name, beneficiary.value)} type='button'><MdRemoveCircleOutline size={28} /></ChangePercentButton>
            </InputWrapper>
            { errors && errors[beneficiary.field_name]
              ? <Forms.InputError>{ errors[beneficiary.field_name][0] }</Forms.InputError>
              : null
            }
          </>
        )
        : <DiscountValue>{ beneficiary.value }%</DiscountValue>
      }
    </Box>
  )
}

export default PercentBox

const Box = styled.div`
  padding: 25px;
  border-radius: 10px;
  background-color: ${theme.colors.lightGray};
  border: 1px solid ${theme.colors.light};
  width: 25%;
  max-width: 350px;
  margin: 10px;
  @media (max-width: ${theme.breakpoints.md}) {
    flex-wrap: wrap;
    max-width: 100%;
    width: calc(50% - 20px);
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-wrap: wrap;
    padding: 20px;
    max-width: 600px;
    width: calc(100% - 20px);
  }
`

const Role = styled.p`
  font-size: ${rem('14px')};
  margin: 0;
`

const Name = styled.p`
  font-size: ${rem('20px')};
  font-weight: bold;
  margin: 0 0 20px 0;
  @media (max-width: ${theme.breakpoints.sm}) {
    margin-top: 5px;
    font-size: ${rem('18px')};
  }
`

const PercentText = styled.p`
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
  margin: 5px 0;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`

const PercentInput = styled.input`
  border: 1px solid ${theme.colors.mediumGray};
  font-size: ${rem('16px')};
  border-radius: 5px;
  background: none;
  padding: 4px 0 4px 10px;
  height: 35px;
  width: 62px;
  outline: none;
  margin: 0 10px;
  font-family: ${theme.font.family};
`

const ChangePercentButton = styled.button`
  background: none;
  outline: none;
  border: none;
  padding: 0;
  color: ${theme.colors.main};
  cursor: pointer;
  transition: color .2s ease;
  &:hover {
    color: ${theme.colors.black};
  }
`

const DiscountValue = styled.div`
  font-size: ${rem('16px')};
  font-weight: bold;
  color: ${theme.colors.black};
  border: 1px solid ${theme.colors.mediumGray};
  padding: 5px 10px;
  text-align: center;
  height: 35px;
  min-width: 62px
  display: inline-block;
  border-radius: 5px;
`