import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'

// PAGES
import FreelancersPage from './FreelancersPage'
import NewFreelancerPage from './NewFreelancerPage'
import FreelancerDetailsPage from './FreelancerDetailsPage'

const FreelancerRoutes = (match, loggedUser) => [
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/operatorzy`} 
    component={FreelancersPage} 
    permissions={['freelancer.viewAny']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/operatorzy/nowy`} 
    component={NewFreelancerPage} 
    permissions={['freelancer.create']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/operatorzy/:freelancer_id`} 
    component={FreelancerDetailsPage} 
    permissions={['freelancer.update', 'freelancer.view']} />
]

export default FreelancerRoutes