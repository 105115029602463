import React from 'react'
import Payouts from 'modules/Payouts/components/Payouts'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const PayoutsPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Rozliczenia'}/>
      <Payouts />
    </Fade>
  )
}

export default PayoutsPage