import React, { useEffect } from 'react'
import OrdersList from '../components/OrdersList'
import {
  getOrders,
  getShopOrders,
  filterOrders,
  changeOrderStatus,
  removeOrder,
  setOrdersCompleted,
  searchShopOrders,
  getShopOrdersFromPagination
} from '../thunks'
import { useDispatch, useSelector } from 'react-redux'
import { actions as modalActions} from 'modules/Modal'
import { setActiveFilters } from '../actions'
import { URL_GET_ORDERS, URL_GET_SHOP_ORDERS } from 'config/api/orders'

const OrdersListContainer = ({ shopId = null, areOwnOrders = true }) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const orders = useSelector(({ orders }) => orders.orders)
  const activeFilters = useSelector(({ orders }) => orders.activeFilters)
  const isDataLoading = useSelector(({ orders }) => orders.isDataLoading)
  const isStatusChanging = useSelector(({ orders }) => orders.isStatusChanging)

  const dispatch = useDispatch()
  useEffect(() => {
    areOwnOrders ? dispatch(getOrders()) : dispatch(getShopOrders(shopId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setActiveFilters())
    }
  }, [dispatch])

  return (
    <OrdersList
      getOrders={areOwnOrders ? getOrders : params => getShopOrders(shopId, params)}
      searchOrders={areOwnOrders ? getOrders : searchShopOrders}
      loggedUser={loggedUser}
      orders={orders}
      isDataLoading={isDataLoading}
      enableModal={modalActions.enableModal}
      activeFilters={activeFilters}
      searchUrl={areOwnOrders ? URL_GET_ORDERS.url : URL_GET_SHOP_ORDERS.url(shopId)}
      filterOrders={props => filterOrders(props, areOwnOrders, shopId)}
      changeOrderStatus={changeOrderStatus}
      removeOrder={removeOrder}
      setOrdersCompleted={setOrdersCompleted}
      isStatusChanging={isStatusChanging}
      areOwnOrders={areOwnOrders}
      getShopOrdersFromPagination={getShopOrdersFromPagination}/>
  )
}

export default OrdersListContainer
