import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDashboardInfo } from '../thunks'
import Dashboard from '../components/Dashboard'
import AdminDashboard from '../components/AdminDashboard'
import { thunks as codesThunks } from 'modules/Codes'
import roles from 'config/roles'

const DashboardContainer = () => {
  
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ dashboard }) => dashboard.isDataLoading)
  const isFormSubmitting = useSelector(({ codes }) => codes.isFormSubmitting)
  const dashboardInfo = useSelector(({ dashboard }) => dashboard.dashboardInfo)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDashboardInfo())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <> 
      { loggedUser.account_type === roles.admin.slug
        ? (
          <AdminDashboard 
            loggedUser={loggedUser}
            isPageLoading={isDataLoading}
            generateCodePDF={codesThunks.generateCodePDF} 
            isFormSubmitting={isFormSubmitting} 
            dashboardInfo={dashboardInfo} />
        )
        : (
          <Dashboard 
            loggedUser={loggedUser}
            isPageLoading={isDataLoading}
            generateCodePDF={codesThunks.generateCodePDF} 
            isFormSubmitting={isFormSubmitting} 
            dashboardInfo={dashboardInfo} />
        )
      }
    </>
  )
}

export default DashboardContainer