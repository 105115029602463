import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { checkPermissions } from 'utils/auth'

const RoleRoute = ({
  component: Component,
  ...rest
}) => (
  <Route {...rest} render={(props) => {
    return (
      <>
        {
          rest.loggedUser &&
          (
            (
              rest.availableFor &&
              rest.availableFor.includes(rest.loggedUser.account_type)
            ) ||
            (
              rest.permissions && 
              checkPermissions(rest.permissions, rest.loggedUser) 
            )
          )
          ? <Component {...rest} {...props} /> 
          : <Redirect to={'/panel/kokpit'}/>
        }
      </>
    )}} />
  );

export default RoleRoute