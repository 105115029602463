import React from 'react'
import theme from 'config/theme'
import roles from 'config/roles'
import styled from 'styled-components'
import Balance from './Balance'
import CurrentCode from './CurrentCode'
import CurrentRepresentative from './CurrentRepresentative'
import Loader from 'modules/PageLoader/components/Loader'
import Fade from 'shared/components/Fade'
import { ReportSaleContainer } from 'modules/Sales'
import { ReportReturnContainer } from 'modules/Returns'
import { ReportOrderContainer } from 'modules/Orders'
import Tabs from 'shared/components/Tabs'
import { SellersStatisticsContainer } from 'modules/Sellers'
import { ShopsStatisticsContainer } from 'modules/Shops'
import { RepresentativesStatisticsContainer } from 'modules/Representatives'
import { FreelancersStatisticsContainer } from 'modules/Freelancers'
import { RepresentativesNotificationsContainer } from 'modules/Representatives'

const Dashboard = ({ 
  generateCodePDF,
  isPageLoading,
  isFormSubmitting,
  dashboardInfo,
  loggedUser
}) => {

  const generateProperStatistics = () => {
    const loggedUserRole = loggedUser && loggedUser.account_type

    switch(loggedUserRole) {
      case roles.seller.slug:
        return <SellersStatisticsContainer />
      case roles.shop.slug:
        return <ShopsStatisticsContainer />
      case roles.representative.slug:
        return <RepresentativesStatisticsContainer />
      case roles.freelancer.slug:
        return <FreelancersStatisticsContainer />
      default:
        return <SellersStatisticsContainer />
    }
  }

  return (
    <Wrapper>
      <Aside>
        <Loader isPageLoading={isPageLoading}>
          <Fade>
            <Balance dashboardInfo={dashboardInfo} />
            { dashboardInfo && dashboardInfo.last_active_code &&
              <CurrentCode 
                generateCodePDF={generateCodePDF} 
                isFormSubmitting={isFormSubmitting} 
                dashboardInfo={dashboardInfo}/>
            }
            { loggedUser.account_type === roles.seller.slug ||
              loggedUser.account_type === roles.shop.slug
              ? <CurrentRepresentative dashboardInfo={dashboardInfo} />
              : null
            }
          </Fade>
        </Loader>
      </Aside>
      <Content>
        { generateProperStatistics() }
        { loggedUser.account_type === roles.representative.slug && 
          <RepresentativesNotificationsContainer />
        }
        { loggedUser.account_type === roles.shop.slug ||
          loggedUser.account_type === roles.seller.slug
          ? 
            <Tabs horizontal>
              { loggedUser.account_type === roles.shop.slug ? <ReportOrderContainer tabLabel={'Zrób zamówienie'}/> : <ReportSaleContainer tabLabel={'Zgłoś sprzedaż'}/>}
              { loggedUser.account_type === roles.shop.slug ? <ReportSaleContainer tabLabel={'Zgłoś sprzedaż'}/> : <ReportOrderContainer tabLabel={'Zrób zamówienie'}/>}
              <ReportReturnContainer tabLabel={'Zgłoś zwrot'}/>
            </Tabs>
          : null
        }
      </Content>
    </Wrapper>
  )
}

export default Dashboard

const Wrapper = styled.div`
  display: flex;
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
  }
`

const Aside = styled.div`
  width: 30%;
  @media (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;
    > div {
      width: calc(50% - 30px);
      margin: 10px;
    }
  }
  @media (max-width: 600px) {
    > div {
      width: calc(100% - 30px);
    }
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    flex-direction: column;
  }
`

const Content = styled.div`
  width: 70%;
  @media (max-width: ${theme.breakpoints.md}) {
    width: 100%;
  }
`