
import React, { forwardRef } from 'react'
import Forms from 'shared/styled/Forms'
import DatePicker, { registerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
import 'react-datepicker/dist/react-datepicker.css'
import { PickerWrapper } from 'shared/styled/DatePicker'

registerLocale('pl', pl)

const Datepicker = ({ field, value, setDate, isDisabled, minDate }) => {

  const handleChange = date => {
    setDate(field.name, date)
  }

  return (
    <PickerWrapper>
      <DatePicker  
        dateFormat="yyyy-MM-dd"
        locale="pl"
        selected={value}
        onChange={handleChange}
        minDate={minDate}
        customInput={<CustomPicker label={field.label} isDisabled={isDisabled} />} />
    </PickerWrapper>
  )
}

export default Datepicker

const CustomPicker = forwardRef((props, ref) => {
  return (
    <>
      <Forms.Input
        darken
        type="text"
        autocomplete="off"  
        required
        value={props.value}
        onChange={props.onClick}
        onClick={props.onClick} 
        disabled={props.isDisabled} />
      <Forms.InputBar />
      <Forms.Label darken disabled={props.isDisabled}>{ props.label }</Forms.Label>
    </>
  )
})

