import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_BALANCE,
  URL_GET_USER_BALANCE,
  URL_GET_TRANSACTIONS_TYPES_FOR_FILTER,
  URL_GET_USER_TRANSACTIONS_TYPES_FOR_FILTER,
  URL_GET_BALANCE_CODES_FOR_FILTER,
  URL_GET_USER_BALANCE_CODES_FOR_FILTER
} from 'config/api/balance'
import queryString from 'query-string'

export const getBalance = (isOwnBalance, userData, url = isOwnBalance ? URL_GET_BALANCE.url : URL_GET_USER_BALANCE.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: isOwnBalance ? URL_GET_BALANCE.method : URL_GET_USER_BALANCE.method, url }, 
      pathParams: userData,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: isOwnBalance ? actions.setBalance : actions.setUserBalance }
    })
  }
}

export const getTransactionsTypesForFilter = (isOwnBalance, userData) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: isOwnBalance ? URL_GET_TRANSACTIONS_TYPES_FOR_FILTER : URL_GET_USER_TRANSACTIONS_TYPES_FOR_FILTER,
      pathParams: userData,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setTransactionTypesForFilter }
    })
  }
}

export const getBalanceCodesForFilter = (isOwnBalance, userData) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: isOwnBalance ? URL_GET_BALANCE_CODES_FOR_FILTER : URL_GET_USER_BALANCE_CODES_FOR_FILTER,
      pathParams: userData,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setBalanceCodesForFilter }
    })
  }
}

export const filterBalanceHistory = (isOwnBalance, userData, activeFilters) => {
  return async dispatch => {
    const baseUrl = isOwnBalance ? URL_GET_BALANCE.url : URL_GET_USER_BALANCE.url(...userData)
    const finalUrl = `${baseUrl}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setActiveFilters(activeFilters))
    dispatch(getBalance(isOwnBalance, null, finalUrl))
  }
}
  