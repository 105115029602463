import * as types from './types'

const initialState = {
  beneficiaries: null,
  selectedBeneficiaries: [],
  activeFilters: null,
  isDataLoading: false,
  isPayoutGenerating: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BENEFICIARIES:
      return {
        ...state,
        beneficiaries: action.payload
      }
    case types.SET_SELECTED_BENEFICIARIES:
      return {
        ...state,
        selectedBeneficiaries: action.payload
      }
    case types.SET_PAYOUT_GENERATING:
      return {
        ...state,
        isPayoutGenerating: action.payload
      }
    case types.SET_PAYOUTS_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_PAYOUTS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    default: 
      return state
  }
}
