import React from 'react'
import Blocks from 'shared/styled/Blocks'
import AdministratorForm from './AdministratorForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import Tabs from 'shared/components/Tabs'
import { PermissionsContainer } from 'modules/Permissions'
import { URL_GET_ADMINISTRATORS_PERMISSIONS, URL_UPDATE_ADMINISTRATORS_PERMISSIONS } from 'config/api/administrators'
import { hasPermission } from 'utils/auth'
import { UserCodesContainer } from 'modules/Codes'
import roles from 'config/roles'
import { goBack } from 'utils/global'
import Fade from 'shared/components/Fade'
import { BalanceContainer } from 'modules/Balance'
import AdministratorsStatisticsContainer from '../containers/AdministratorsStatisticsContainer'

const AdministratorDetails = ({
  loggedUser,
  editing,
  isPageLoading,
  salesDistrictsForSelect,
  currentAdministrator,
  isFormSubmitting,
  updateAdministrator,
  formValidationErrors,
  setFormValidationErrors
}) => {

  const objectId = currentAdministrator ? currentAdministrator.id : null

  return (
    <Blocks.Block>
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <Blocks.Head>
            <Blocks.BackButton onClick={() => goBack('/panel/administratorzy')}>
              <TiArrowLeftThick size={28} />
            </Blocks.BackButton>
            <BlockHeading>{ currentAdministrator ? currentAdministrator.full_name : 'Administrator' }</BlockHeading>
          </Blocks.Head>
          {
            hasPermission('operator.update', loggedUser)
            ? (
              <Tabs>
                <AdministratorForm
                  hasPermission={hasPermission('operator.update', loggedUser)}
                  loggedUser={loggedUser}
                  tabLabel={'Ustawienia'}
                  editing
                  salesDistrictsForSelect={salesDistrictsForSelect}
                  submitBtnText={'Zaktualizuj'}
                  currentAdministrator={currentAdministrator}
                  isFormSubmitting={isFormSubmitting}
                  callApi={updateAdministrator}
                  formValidationErrors={formValidationErrors}
                  setFormValidationErrors={setFormValidationErrors} />
                <PermissionsContainer 
                  objectId={objectId}
                  getPermissionsUrl={URL_GET_ADMINISTRATORS_PERMISSIONS}
                  updatePermissionsUrl={URL_UPDATE_ADMINISTRATORS_PERMISSIONS}
                  tabLabel={'Uprawnienia'} />
                <BalanceContainer 
                  tabLabel={'Saldo'}
                  userRole={roles.admin.slug}
                  userId={currentAdministrator ? currentAdministrator.id : ''} />
                <AdministratorsStatisticsContainer 
                  tabLabel={'Statystyki'}
                  areOwnStatistics={false}
                  administratorId={currentAdministrator ? currentAdministrator.id : ''} />
                  {
                    currentAdministrator && currentAdministrator.last_active_code 
                    ? <UserCodesContainer 
                      tabLabel={'Kody rabatowe'} 
                      currentUser={currentAdministrator}
                      redirectUrl={currentAdministrator ? `/panel/administratorzy/${currentAdministrator.id}` : ``}
                      currentRole={roles.admin.slug} />
                    : null
                  }
              </Tabs>
            ) 
            : (
              <Tabs>
                <AdministratorForm
                  hasPermission={hasPermission('operator.update', loggedUser)}
                  loggedUser={loggedUser}
                  tabLabel={'Ustawienia'}
                  editing
                  salesDistrictsForSelect={salesDistrictsForSelect}
                  submitBtnText={'Zaktualizuj'}
                  currentAdministrator={currentAdministrator}
                  isFormSubmitting={isFormSubmitting}
                  callApi={updateAdministrator}
                  formValidationErrors={formValidationErrors}
                  setFormValidationErrors={setFormValidationErrors} />
                {
                  currentAdministrator && currentAdministrator.last_active_code &&
                  <UserCodesContainer 
                    currentUser={currentAdministrator}
                    redirectUrl={currentAdministrator ? `/panel/administratorzy/${currentAdministrator.id}` : ``}
                    currentRole={roles.admin.slug}
                    tabLabel={'Kody rabatowe'} />
                }
              </Tabs>
            )
          }
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default AdministratorDetails