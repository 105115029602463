import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'config/theme'
import UserMenu from 'shared/components/UserMenu'
import LoggedUser from 'shared/components/LoggedUser'
import { hasAccess } from 'utils/auth'
import roles from 'config/roles'

const Topbar = ({ 
  loggedUser,
  toggleSidebar,
  sidebarVisible
}) => {

  const isAdmin = hasAccess([roles.admin.slug], loggedUser)

  return (
    <StyledTopbar isAdmin={isAdmin}>
      <Logo>
        { !isAdmin && 
          <LogoLink to={'/panel'}>
            <LogoTopPart>ISS</LogoTopPart>
            <LogoBottomPart>KOD</LogoBottomPart>
          </LogoLink>
        }
      </Logo>
      { !isAdmin && 
        <UserMenu 
          loggedUser={loggedUser} 
          toggleSidebar={toggleSidebar} 
          sidebarVisible={sidebarVisible} /> 
      }
      <LoggedUser userName={'Admin'}/>
    </StyledTopbar>
  )
}

export default Topbar

const Logo = styled.div`
  display: flex;
  visibility: visible;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  @media (max-width: ${theme.breakpoints.md}) {
    visibility: hidden;
  }
`

const LogoLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.black};
  line-height: 0.9;
`

const LogoTopPart = styled.div`
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 1px;
`

const LogoBottomPart = styled.div`
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 1px;
`

const StyledTopbar = styled.div`
  height: 60px;
  padding: 0 20px;
  width: 100%;
  background-color: ${theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.047) 0px 3px 3px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.md}) {
    justify-content: ${props => props.isAdmin ? 'space-between' : 'flex-end'}
  }
`