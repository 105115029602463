import React from 'react'
import styled from 'styled-components'
import Blocks from 'shared/styled/Blocks'
import theme from 'config/theme'
import { rem } from 'polished'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import Fade from 'shared/components/Fade'
import ProfileForm from './ProfileForm'

const Profile = ({ 
  profile, 
  isPageLoading, 
  isFormSubmitting,
  formValidationErrors, 
  resetPassword, 
  setFormValidationErrors 
}) => {

  return (
    <Fade>
      <Wrapper>
        <ProfileBlock>
          <ProfileHeader>Zmiana hasła</ProfileHeader>
          <BlockContent>
            <ProfileForm 
              onSubmit={resetPassword}
              formValidationErrors={formValidationErrors}
              setFormValidationErrors={setFormValidationErrors}
              isFormSubmitting={isFormSubmitting} />
          </BlockContent>
        </ProfileBlock>
        <ProfileBlock>
          <Loader isPageLoading={isPageLoading}>
            <Fade>
              <ProfileHeader>Twoje dane</ProfileHeader>
              <BlockContent>
                { 
                  profile &&
                  Object.keys(profile).map(key => {
                    return (
                      <ProfileElement key={key}>
                        <ProfileElementLabel>{ key }</ProfileElementLabel>
                        { 
                          Array.isArray(profile[key]) 
                          ? profile[key].map(value => {
                            return (
                              <ProfileElementValue key={value}>
                                { value }
                              </ProfileElementValue>
                            )})
                          : <ProfileElementValue>
                              { profile[key] }
                            </ProfileElementValue>
                        }
                      </ProfileElement>
                    )
                  })
                }
              </BlockContent>
            </Fade>
          </Loader>
        </ProfileBlock>
      </Wrapper>
    </Fade>
  )
}

export default Profile

const Wrapper = styled.div`
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const ProfileHeader = styled(BlockHeading)`
  padding: 0;
`

const ProfileBlock = styled(Blocks.Block)`
  margin: 10px;
  @media (max-width: ${theme.breakpoints.sm}) {
    width: auto;
  }
`

const BlockContent = styled.div`
  margin: 30px 0;
`

const ProfileElement = styled.div`
  margin: 15px 0;
`

const ProfileElementLabel = styled.div`
  color: ${theme.colors.black};
  font-weight: bold;
  font-size: ${rem('14px')};
`

const ProfileElementValue = styled.div`
  font-size: ${rem('14px')};
  color: ${theme.colors.black};
  display: flex;
  align-items: center;
`