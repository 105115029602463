import React from 'react'
import ConfirmModal from '../components/ConfirmModal'
import { useSelector } from 'react-redux'
import { disableModal } from '../actions'

const ModalContainer = () => {

  const visible = useSelector(({modal}) => modal.visible)
  const modalContent = useSelector(({modal}) => modal.content)
  const onConfirm = useSelector(({modal}) => modal.onConfirm)
  const onConfirmParams = useSelector(({modal}) => modal.onConfirmParams)

  return (
    <ConfirmModal
      disableModal={disableModal}
      visible={visible}
      modalContent={modalContent}
      onConfirm={onConfirm}
      onConfirmParams={onConfirmParams}
    />
  )
}

export default ModalContainer
