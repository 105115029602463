import React from 'react'
import moment from 'moment'
import DateFilter from 'shared/components/DateFilter'
import { FilterBlock, FilterLabel, DatePickerWrapper, DateSeparator } from 'shared/styled/Filters'

const DateRange = ({
  filter,
  handleFiltersChange,
  activeFilters,
  isClearable,
  zIndex = 1
}) => {

  return (
    <>
      { filter && 
        <FilterBlock zIndex={zIndex} key={filter.query}>
          <FilterLabel>{ filter.label }</FilterLabel>
            <DatePickerWrapper>
              <DateFilter 
                placeholder={'Data od'}
                value={activeFilters && activeFilters['date_from'] ? new Date(activeFilters['date_from']) : ''}
                maxDate={activeFilters && activeFilters['date_to'] ? new Date(activeFilters['date_to']) : ''}
                onChange={e => handleFiltersChange(moment(e).format('YYYY-MM-DD'), 'date_from', false)}
                onClear={() => handleFiltersChange('', 'date_from', false)} 
                isClearable={isClearable}/>
            </DatePickerWrapper>
            <DateSeparator />
            <DatePickerWrapper>
              <DateFilter 
                minDate={activeFilters && activeFilters['date_from'] ? new Date(activeFilters['date_from']) : ''}
                placeholder={'Data do'}
                value={activeFilters && activeFilters['date_to'] ? new Date(activeFilters['date_to']) : ''}
                onChange={e => handleFiltersChange(moment(e).format('YYYY-MM-DD'), 'date_to', false)}
                onClear={() => handleFiltersChange('', 'date_to', false)}
                isClearable={isClearable} />
            </DatePickerWrapper>
        </FilterBlock>
      }
    </>
  )
}

export default DateRange