import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import theme from 'config/theme'
import { rem } from 'polished'
import Fade from 'shared/components/Fade'
import { PrimaryButton } from 'shared/styled/Buttons'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import { hasPermission } from 'utils/auth'
import Table from 'shared/styled/Table'
import NoRecords from 'shared/components/DataTable/NoRecords'
import { BlockHeading } from 'shared/styled/Headings'

const UserCodes = ({ 
  loggedUser, 
  currentUser, 
  currentRole,
  generateNewCode,
  enableModal,
  isFormSubmitting,
  redirectUrl
}) => {
  
  const code = currentUser && currentUser.last_active_code ? currentUser.last_active_code.code : ''
  const userId = currentUser && currentUser.id ? currentUser.id : ''
  const dispatch = useDispatch()
  const modalContent = {
    title: 'Czy na pewno chcesz wygenerować nowy kod dla tego użytkownika?',
    description: 'Operacja jest nieodwracalna',
    confirmButtonText: 'Wygeneruj',
    cancelButtonText: 'Anuluj'
  }

  return (
    <Fade>
      <Wrapper>
        <CurrentCodeWrapper>
          <CurrentCodeText>Aktualny kod rabatowy</CurrentCodeText>
          <CurrentCode>{ code }</CurrentCode>
        </CurrentCodeWrapper>
        { hasPermission(`${currentRole}.update`, loggedUser) &&
          <PrimaryButton 
            disabled={ isFormSubmitting } 
            onClick={() => {
              dispatch(enableModal({
                content: modalContent,
                onConfirm: generateNewCode,
                onConfirmParams: [currentRole, userId, redirectUrl]
              }))
            }}>
            { isFormSubmitting ? <ButtonLoader /> : 'Wygeneruj nowy kod' }
          </PrimaryButton>
        }
      </Wrapper>
      <UserCodesListWrapper>
        <BlockHeading>Wszystkie kody rabatowe</BlockHeading>
        <UserCodesList>
          { !currentUser && !currentUser.owner_codes.length
            ? <NoRecords>Brak rekordów do wyświetlenia!</NoRecords>
            : (
              <Table.TableWrapper>
                <Table.Table cellspacing='0'>
                  <Table.TableHead>
                    <Table.TableRow>
                      <Table.TableHeadCell width={60}>#</Table.TableHeadCell>
                      <Table.TableHeadCell>Indywidualny kod</Table.TableHeadCell>
                      <Table.TableHeadCell>Data utworzenia</Table.TableHeadCell>
                    </Table.TableRow>
                  </Table.TableHead>
                  <Table.TableBody twoColors={true}>
                    { currentUser.owner_codes.map((row, rowIndex) => {
                      const number = rowIndex + 1
                      return (
                        <Fragment key={number} >
                          <Table.AnimatedRow 
                            i={rowIndex} 
                            initialPose='hidden' 
                            pose='visible'>
                            <Table.TableCell width={60}>{number}.</Table.TableCell>
                            <Table.TableCell>{ row.code }</Table.TableCell>
                            <Table.TableCell>{ row.created_at }</Table.TableCell>
                          </Table.AnimatedRow>
                        </Fragment>
                      )
                    })}
                  </Table.TableBody>
                </Table.Table>
              </Table.TableWrapper>
            )
          }
        </UserCodesList>
      </UserCodesListWrapper>
    </Fade>
  )
}

export default UserCodes

const Wrapper = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0;
  }
`

const CurrentCodeWrapper = styled.div`
  padding: 13px 20px;
  border-radius: 10px;
  background-color: ${theme.colors.lightGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.colors.black};
  min-width: 230px;
  margin-right: 15px;
  border: 1px solid ${theme.colors.light};
  @media (max-width: ${theme.breakpoints.sm}) {
    margin-right: 0;
  }
`

const CurrentCodeText = styled.span`
  font-size: ${rem('14px')};
  padding-right: 12px;
`

const CurrentCode = styled.span`
  font-size: ${rem('26px')};
  font-weight: bold;
  padding-left: 12px;
`

const UserCodesListWrapper = styled.div`
  padding-top: 30px;
`

const UserCodesList = styled.div`
  padding: 30px 0 15px 0;
`