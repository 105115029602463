import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import Blocks from 'shared/styled/Blocks'
import { BlockHeading, SubHeading } from 'shared/styled/Headings'
import Fade from 'shared/components/Fade'
import Loader from 'modules/PageLoader/components/Loader'
import ChosenProducts from './ChosenProducts'
import { PrimaryButton } from 'shared/styled/Buttons'
import { GraySelectStyles } from 'shared/styled/Select'
import theme from 'config/theme'

const ReportProducts = ({
  isDataLoading,
  products,
  setChosenProducts,
  reportApiCall,
  chosenProducts,
  isFormSubmitting,
  formValidationErrors,
  setFormValidationErrors,
  enableModal,
  reportTitle,
  reportSubTitle,
  modalContent,
  isOrder = false,
  message,
  setMessage
}) => {

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [errors, setErrors] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    setErrors(formValidationErrors)
  }, [formValidationErrors])

  useEffect(() => {
    return () => {
      dispatch(setFormValidationErrors())
      dispatch(setChosenProducts())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const chooseProduct = () => {
    setErrors(null)
    if (selectedProduct) {
      let chosen = [...chosenProducts]
      if (products) {
        products.data.forEach(product => {
          if (product.id === selectedProduct.value) {
            chosen = [...chosen, {
              id: product.id,
              name: product.name,
              amount: 0,
              thumbnail: product.default_image_link_small,
              price: product.calculated_wholesale_price,
              stock: isOrder ? '' : product.stock
            }]
          }
        })
      }
      dispatch(setChosenProducts(chosen))
      setSelectedProduct(null)
    }
  }

  const handleAmountChange = (id, value) => {
    setErrors(null)
    let chosen = [...chosenProducts]
    chosen.forEach(product => {
      if (product.id === id) {
        product.amount = value
      }
    })
    dispatch(setChosenProducts(chosen))
  }

  const handleMessage = value => {
    setErrors(null)
    setMessage(value)
  }

  const removeProduct = id => {
    const chosen = chosenProducts.filter(product => product.id !== id)
    dispatch(setChosenProducts(chosen))
  }

  const productsOptions = products && products.data
  .map(product => {
    return {
      label: product.name,
      value: product.id
    }
  })
  .filter(option => {
    if (chosenProducts.length) {
      return chosenProducts.every(chosenProduct => chosenProduct.id !== option.value)
    } else {
      return option
    }
  })

  return (
    <Blocks.Block>
      <Loader isPageLoading={isDataLoading}>
        <Fade>
          <BlockHeading>{ reportTitle }</BlockHeading>
          <SubHeading>{ reportSubTitle }</SubHeading>
          <ChooseProductWrapper>
            <ProductSelect
              styles={{container: (base, state) => {
                return ({
                  ...base,
                  zIndex: state.isFocused ? "999" : "1"
                })
              }}}
              placeholder='Wybierz...'
              className='select'
              classNamePrefix='select'
              options={productsOptions}
              value={selectedProduct}
              onChange={e => setSelectedProduct(e)}
              noOptionsMessage={() => 'Brak wyników...'}/>
            <PrimaryButton type='button' onClick={chooseProduct}>Dodaj produkt</PrimaryButton>
          </ChooseProductWrapper>
          <ChosenProducts
            isFormSubmitting={isFormSubmitting}
            handleAmountChange={handleAmountChange}
            chosenProducts={chosenProducts}
            removeProduct={removeProduct}
            errors={errors}
            enableModal={enableModal}
            reportApiCall={reportApiCall}
            modalContent={modalContent}
            isOrder={isOrder}
            message={message}
            handleMessage={handleMessage}
          />
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default ReportProducts

const ChooseProductWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${theme.breakpoints.xs}) {
    flex-direction: column;
  }
`

const ProductSelect = styled(ReactSelect)`
  ${GraySelectStyles};
  margin-right: 10px;
  flex: 1;
  max-width: 300px;
  @media (max-width: ${theme.breakpoints.sm}) {
    max-width: 200px;
  }
`
