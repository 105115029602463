import React from 'react'
import { useDispatch } from 'react-redux'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import SearchBanner from 'shared/components/SearchBanner'
import Loader from 'modules/PageLoader/components/Loader'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa'
import DataTableLink from 'shared/components/DataTable/DataTableLink'
import { DataTableAction, DataTableActionLink } from 'shared/components/DataTable/DataTableAction'
import { StatusIndicator, TableLinkWrapper } from 'shared/components/DataTable/DataTableElements'
import { hasPermission } from 'utils/auth'
import Filters from 'shared/components/Filters'
import roles from 'config/roles'
import Fade from 'shared/components/Fade'
import { Badge } from 'shared/components/DataTable/DataTableElements'
import theme from 'config/theme'

const ShopsList = ({
  loggedUser,
  searchUrl,
  getShops,
  removeShop,
  shops,
  isPageLoading,
  enableModal,
  activeFilters,
  filterShops,
  representativesForFilter
}) => {

  const dispatch = useDispatch()
  const modalContent = {
    title: 'Czy na pewno chcesz usunąć tę jednostkę gospodarczą?',
    description: 'Operacja jest nieodwracalna',
    confirmButtonText: 'Usuń',
    cancelButtonText: 'Anuluj'
  }
  const filters = [
    {
      label: 'Stan magazynowy',
      options: [
        { label: 'Niski', value: 'low' },
        { label: 'Wysoki', value: 'high' },
        { label: 'Brak', value: 'out_of_stock' },
      ],
      query: 'stock',
      isMulti: false,
      isClearable: true
    },
    {
      label: 'PH:',
      options: representativesForFilter,
      query: 'representatives_ids',
      role: roles.representative.slug,
      isMulti: true,
      isClearable: true
    }
  ]

  return (
    <>
      <SearchBanner 
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={getShops}
        buttonText='Dodaj nową'
        createPermission='shop.create'
        buttonLink='/panel/jednostki/nowy'/>
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <Fade>
            <Filters 
              loggedUser={loggedUser}
              filters={filters}
              activeFilters={activeFilters}
              filterData={filterShops} />
            <DataTable
              callApi={getShops}
              data={shops}
              columns={[
                { name: 'Nazwa', field: row => {
                  if (hasPermission('shop.view', loggedUser)) {
                    return (
                      <TableLinkWrapper>
                        <StatusIndicator active={row.status}/>
                        <DataTableLink to={`/panel/jednostki/${row.id}`}>{row.company_name}</DataTableLink>
                      </TableLinkWrapper>
                    )
                  } else {
                    return (
                      <TableLinkWrapper>
                        <StatusIndicator active={row.status}/>
                        { row.company_name }
                      </TableLinkWrapper>
                    )
                  }
                }},
                { name: 'Data rozp. współpracy', field: 'partnership_since' },
                { name: 'Przedstawiciel handlowy', field: 'representative' },
                { name: 'IP', field: 'sellers_count' },
                { name: 'Stan magazynowy', field: row => {
                  if (row.stock === 'Niski') return <Badge textColor={theme.colors.black} color={theme.colors.orange}>{ row.stock }</Badge> 
                  else if (row.stock === 'Brak') return <Badge textColor={theme.colors.white} color={theme.colors.red}>{ row.stock }</Badge> 
                  else return <Badge textColor={theme.colors.white} color={theme.colors.green}>{ row.stock }</Badge> 
                }},
                { name: 'Akcje', field: row => (
                  <>
                    {
                      hasPermission('shop.update', loggedUser) &&
                      <DataTableActionLink 
                        tooltiptext='Edytuj'
                        to={`/panel/jednostki/${row.id}`}>
                        <FaRegEdit size={18}/>
                      </DataTableActionLink>
                    }
                    {
                      hasPermission('shop.delete', loggedUser) &&
                      <DataTableAction 
                        tooltiptext='Usuń'
                        onClick={() => {
                          dispatch(enableModal({
                            content: modalContent,
                            onConfirm: removeShop,
                            onConfirmParams: [row.id]
                          }))
                        }}><FaRegTrashAlt size={18}/>
                      </DataTableAction>
                    }
                  </>
                )},
              ]}
            />
          </Fade>
        </Loader>
      </Blocks.Block>
    </>
  )
}

export default ShopsList
