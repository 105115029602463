import React, { useEffect } from 'react'
import { useSelector , useDispatch} from 'react-redux'
import history from 'config/history'
import { getShop, updateShop } from '../thunks'
import { thunks as representativeThunks } from 'modules/Representatives'
import { setFormValidationErrors } from '../actions'
import ShopsDetails from '../components/ShopsDetails'
import { hasPermission } from 'utils/auth'

const ShopsDetailsContainer = ({ match }) => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ shops }) => shops.isDataLoading)
  const currentShop = useSelector(({ shops }) => shops.currentShop)
  const representativesForSelect = useSelector(({ representatives }) => representatives.representativesForSelect)
  const isFormSubmitting = useSelector(({ shops }) => shops.isFormSubmitting)
  const formValidationErrors = useSelector(({ shops }) => shops.formValidationErrors)
  const dispatch = useDispatch()

  const shopId = match.params.shop_id

  useEffect(() => {
    dispatch(getShop(shopId))
    if (hasPermission('shop.update', loggedUser)) {
      dispatch(representativeThunks.getRepresentativesForSelect())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ShopsDetails
      loggedUser={loggedUser}
      editing
      isPageLoading={isDataLoading}
      history={history}
      representativesForSelect={representativesForSelect}
      currentShop={currentShop}
      isFormSubmitting={isFormSubmitting}
      updateShop={updateShop}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default ShopsDetailsContainer

