import styled from 'styled-components'
import theme from 'config/theme'
import { rem } from 'polished'
import { PickerWrapper } from 'shared/styled/DatePicker'

export const FilterBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  position: relative;
  z-index: ${props => props.zIndex};
  margin-bottom: 10px;
  @media (max-width: ${theme.breakpoints.sm}) {
    margin-left: 10px;
  }
`

export const FilterLabel = styled.div`
  font-size: ${rem('13px')};
  color: ${theme.colors.gray};
  margin-right: 10px;
  flex-shrink: 0;
`

export const FiltersWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${theme.breakpoints.sm}) {
    justify-content: center;
  }
`

export const DatePickerWrapper = styled(PickerWrapper)`
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    left: 50%;
  }
  .react-datepicker__input-container {
    input {
      caret-color: transparent;
      cursor: pointer;
      font-family: ${theme.font.family};
      font-size: ${rem('13px')};
      &::placeholder {
        font-family: ${theme.font.family};
        color: #929292;
        font-size: ${rem('13px')};
      }
    }
  }
`

export const DateSeparator = styled.div`
  display: inline-block;
  width: 7px;
  height: 1px;
  margin: 0 5px;
  background-color: ${theme.colors.gray};
`