import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Blocks from 'shared/styled/Blocks'
import Loader from 'modules/PageLoader/components/Loader'
import Fade from 'shared/components/Fade'
import { Badge } from 'shared/components/DataTable/DataTableElements'
import { FiCheckSquare } from 'react-icons/fi'
import { MdDoNotDisturb } from 'react-icons/md'
import { GoPackage } from 'react-icons/go'
import { DataTableAction } from 'shared/components/DataTable/DataTableAction'
import { hasPermission } from 'utils/auth'
import theme from 'config/theme'
import { statuses } from 'config/orders'
import shortid from 'shortid'
import Filters from 'shared/components/Filters'
import OrdersTable from './OrdersTable'
import { statusesIds } from 'config/orders'
import SearchBanner from 'shared/components/SearchBanner'

const OrdersList = ({
  loggedUser,
  isDataLoading,
  getOrders,
  orders,
  enableModal,
  activeFilters,
  filterOrders,
  changeOrderStatus,
  removeOrder,
  setOrdersCompleted,
  setOrderPartiallyCompleted,
  searchUrl,
  searchOrders,
  areOwnOrders,
  getShopOrdersFromPagination
}) => {

  const dispatch = useDispatch()
  const modalContent = {
    title: 'Czy na pewno chcesz zmienić status tego zamówienia?',
    description: 'Niektóre operacje są nieodwracalne',
    confirmButtonText: 'Zmień status',
    cancelButtonText: 'Anuluj'
  }
  const filters = [
    {
      id: shortid.generate(),
      label: 'Pokaż z zakresu:',
      type: 'daterange',
      isClearable: true
    },
    {
      id: shortid.generate(),
      label: 'Status:',
      options: statuses.map(status => { 
        return { label: status.name, value: status.id.toString() }
      }),
      query: 'status',
      isMulti: false,
      isClearable: true
    }
  ]
  const columns = [
    { name: 'Numer', field: 'no', withCollapse: true},
    { name: 'Status', field: row => <Badge>{ row.status_label }</Badge>},
    { name: 'Data utworzenia', field: 'created_at' },
    { name: 'Zamawiający', field: 'creator_name' },
    { name: 'Zmień status', field: row => (
      <>
        {
          hasPermission('wholesaleOrder.update', loggedUser) && row.status !== statusesIds.done && 
          <DataTableAction 
            color={theme.colors.green}
            tooltiptext='Zrealizowane'
            onClick={e => {
              e.stopPropagation()
              dispatch(enableModal({
                content: modalContent,
                onConfirm: changeOrderStatus,
                onConfirmParams: [row.id, { status: statusesIds.done }, orders.meta.current_page, activeFilters]
              }))
            }}>
            <FiCheckSquare size={20} />
          </DataTableAction>
        }
        {
          hasPermission('wholesaleOrder.update', loggedUser) && row.status === statusesIds.waiting && 
          <DataTableAction
            color={theme.colors.black} 
            tooltiptext='Do realizacji'
            onClick={e => {
              e.stopPropagation()
              dispatch(enableModal({
                content: modalContent,
                onConfirm: changeOrderStatus,
                onConfirmParams: [row.id, { status: statusesIds.in_progress }, orders.meta.current_page, activeFilters]
              }))
            }}>
            <GoPackage size={22} />
          </DataTableAction>
        }
        {
          row.can_cancel &&
          <DataTableAction
            color={theme.colors.red}
            tooltiptext='Anuluj'
            onClick={e => {
              e.stopPropagation()
              dispatch(enableModal({
                content: modalContent,
                onConfirm: removeOrder,
                onConfirmParams: [row.id, orders.meta.current_page, activeFilters]
              }))
            }}>
            <MdDoNotDisturb size={22} />
          </DataTableAction>
        }
      </>
    )}
  ]

  return (
    <OrdersListWrapper areOwnOrders={areOwnOrders}>
      <SearchBanner 
        dark={!areOwnOrders}
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={searchOrders} />
      <Blocks.Block>
        <Loader isPageLoading={isDataLoading}>
          <Fade>
            <>
              <Filters 
                loggedUser={loggedUser}
                filters={filters}
                activeFilters={activeFilters}
                filterData={filterOrders} />
              <OrdersTable
                loggedUser={loggedUser}
                activeFilters={activeFilters}
                enableModal={enableModal}
                withGroupActions={true}
                orders={orders} 
                columns={columns}
                getOrders={getOrders}
                setOrdersCompleted={setOrdersCompleted}
                getShopOrdersFromPagination={getShopOrdersFromPagination} />
            </>
          </Fade>
        </Loader>
      </Blocks.Block>
    </OrdersListWrapper>
  )
}

export default OrdersList

const OrdersListWrapper = styled.div`
  > ${Blocks.Block} {
    box-shadow: ${props => props.areOwnOrders ? '0px 2px 1px rgba(0,0,0,0.05)' : 'none'};
    padding: ${props => props.areOwnOrders ? '25px' : '25px 0'};;
  }
`
