import React, { useEffect } from 'react'
import { useSelector , useDispatch} from 'react-redux'
import history from 'config/history'
import { getSeller, updateSeller } from '../thunks'
import { thunks as shopsThunks } from 'modules/Shops'
import { setFormValidationErrors } from '../actions'
import SellersDetails from '../components/SellersDetails'
import { hasPermission } from 'utils/auth'

const SellersDetailsContainer = ({ match }) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ sellers }) => sellers.isDataLoading)
  const currentSeller = useSelector(({ sellers }) => sellers.currentSeller)
  const shopsForSelect = useSelector(({ shops }) => shops.shopsForSelect)
  const isFormSubmitting = useSelector(({ sellers }) => sellers.isFormSubmitting)
  const formValidationErrors = useSelector(({ sellers }) => sellers.formValidationErrors)
  const dispatch = useDispatch()

  const sellerId = match.params.seller_id

  useEffect(() => {
    if (hasPermission('seller.update', loggedUser) || hasPermission('seller.view', loggedUser)) {
      dispatch(getSeller(sellerId))
    }
    if (hasPermission('seller.update', loggedUser)) {
      dispatch(shopsThunks.getShopsForSelect())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SellersDetails
      sellerId={sellerId}
      loggedUser={loggedUser}
      editing
      isPageLoading={isDataLoading}
      history={history}
      shopsForSelect={shopsForSelect}
      currentSeller={currentSeller}
      isFormSubmitting={isFormSubmitting}
      updateSeller={updateSeller}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default SellersDetailsContainer

