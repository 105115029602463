import React from 'react'
import { ProfileContainer } from 'modules/Profile'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const ProfilePage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Ustawienia'}/>
      <ProfileContainer />
    </Fade>
  )
}

export default ProfilePage