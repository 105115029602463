import { BASE_API_URL } from './base'

export const URL_GET_CODES = {
  method: 'GET',
  url: `${BASE_API_URL}/code`
}

export const URL_GET_SINGLE_CODE = {
  method: 'GET',
  url: id => `${BASE_API_URL}/code/${id}`
}

export const URL_UPDATE_CODE = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/code/${id}`
}

export const URL_GENERATE_NEW_CODE = {
  method: 'POST',
  url: `${BASE_API_URL}/code/generate_new`
}

export const URL_GENERATE_PDF = {
  method: 'POST',
  url: id => `${BASE_API_URL}/profile/generate_code_pdf_link/${id}`
}