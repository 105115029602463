import React from 'react'
import { ShopsNewContainer } from 'modules/Shops'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const NewRepresentativePage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Jednostki gospodarcze'}/>
      <ShopsNewContainer />
    </Fade>
  )
}

export default NewRepresentativePage