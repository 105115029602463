import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import history from 'config/history'
import { addNewRepresentative } from '../thunks'
import { setFormValidationErrors } from '../actions'
import { thunks as districtThunks } from 'modules/SalesDistricts'
import RepresentativesNew from '../components/RepresentativesNew'

const RepresentativesNewContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isFormSubmitting = useSelector(({ representatives }) => representatives.isFormSubmitting)
  const isDataLoading = useSelector(({ representatives }) => representatives.isDataLoading)
  const formValidationErrors = useSelector(({ representatives }) => representatives.formValidationErrors)
  const salesDistrictsForSelect = useSelector(({ sales_districts }) => sales_districts.salesDistrictsForSelect)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(districtThunks.getSalesDistrictsForSelect())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RepresentativesNew
      loggedUser={loggedUser}
      history={history}
      isPageLoading={isDataLoading}
      salesDistrictsForSelect={salesDistrictsForSelect}
      isFormSubmitting={isFormSubmitting}
      addNewRepresentative={addNewRepresentative}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default RepresentativesNewContainer

