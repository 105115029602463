import { BASE_API_URL } from './base'

export const URL_GET_BENEFICIARIES = {
  method: 'GET',
  url: `${BASE_API_URL}/payout/create`
}

export const URL_GET_CONFIRMED_BENEFICIARIES = {
  method: 'GET',
  url: `${BASE_API_URL}/payout/invoices`
}

export const URL_GENERATE_PAYOUTS_FOR_INVOICES = {
  method: 'POST',
  url: `${BASE_API_URL}/payout/invoices`
}

export const URL_GET_HISTORY_PAYOUTS = {
  method: 'GET',
  url: `${BASE_API_URL}/payout`
}

export const URL_GENERATE_PAYOUTS = {
  method: 'POST',
  url: `${BASE_API_URL}/payout`
}

export const URL_DOWNLOAD_PAYOUT_PACKAGE = {
  method: 'POST',
  url: id => `${BASE_API_URL}/payout/generate-pli-link/${id}`
}

export const URL_GET_BENEFICIARIES_WITH_NEGATIVE_BALANCE = {
  method: 'GET',
  url: `${BASE_API_URL}/payout/balance`
}