import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from 'config/theme'
import { Link } from 'react-router-dom'
import { mainMenu } from 'config/menu'
import { checkPermissions } from 'utils/auth'
import { setFirstAvailableLink } from 'utils/global'
import history from 'config/history'
import { MdMenu } from 'react-icons/md'

const AdminSidebar = ({ loggedUser }) => {

  const [sidebarVisible, toggleSidebarVisible] = useState(false)
  const wrapperRef = useRef(null)
  const hamburgerBtnRef = useRef(null)

  const toggleSidebar = () => {
    toggleSidebarVisible(!sidebarVisible)
  }

  const setActive = link => {
    const currentPath = history.location.pathname
    let shouldBeActive = false
    if (Array.isArray(link)) {
      for (let i=0; i<link.length; i++) {
        if (currentPath.startsWith(link[i].link)) {
          shouldBeActive = true
          break
        } 
      }
      return shouldBeActive
    } else {
      if (currentPath.startsWith(link)) {
        shouldBeActive = true
        return shouldBeActive
      }
    }
  }

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !event.path.includes(hamburgerBtnRef.current)) {
      toggleSidebarVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => document.removeEventListener('click', handleClickOutside, false)
  }, [])

  return (
    <>
      <Hamburger type='button' onClick={() => toggleSidebar()} ref={hamburgerBtnRef}>
        <MdMenu size={28} />
      </Hamburger>
      <StyledSidebar sidebarVisible={sidebarVisible} ref={wrapperRef}>
        <Logo>
          <LogoLink onClick={() => toggleSidebar()} to={'/panel'}>
            <LogoTopPart>ISS</LogoTopPart>
            <LogoBottomPart>KOD</LogoBottomPart>
          </LogoLink>
        </Logo>
        <MenuList>
          { mainMenu.map(item => {
            if (item.permissions.includes('all') || checkPermissions(item.permissions, loggedUser)) {
              if (item.link) {
                return (
                  <MenuLinkWrapper onClick={() => toggleSidebar()} active={setActive(item.link)} key={item.id}>
                    <MenuLink to={item.link}>
                      { item.icon }
                    </MenuLink>
                    <MenuLabel>{ item.label }</MenuLabel>
                  </MenuLinkWrapper>
                )
              } else {
                return (
                  <MenuLinkWrapper onClick={() => toggleSidebar()} active={setActive(item.links)} key={item.id}>
                    <MenuLink to={setFirstAvailableLink(item.links, loggedUser)}>
                      { item.icon }
                    </MenuLink>
                    <MenuLabel>{ item.label }</MenuLabel>
                  </MenuLinkWrapper>
                )
              }
            } else return null
          })}
        </MenuList>
      </StyledSidebar>
    </>
  )
}

export default AdminSidebar

const StyledSidebar = styled.div`
  background-color: ${theme.colors.white};
  width: 70px;
  box-shadow: rgba(0,0,0,0.047) 2px 0px 3px 1px;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 200px;
    transition: transform .2s ease;
    transform: ${props => props.sidebarVisible ? 'translate(0)' : 'translateX(-200px)'};
  }
`

const Hamburger = styled.button`
  outline: none;
  background: none;
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 15px;
  z-index: 20;
  top: 10px;
  cursor: pointer;
  display: none;
  transition: color .2s ease;
  &:hover {
    color: ${theme.colors.main};
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    display: block;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`

const LogoLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.black};
  line-height: 0.9;
`

const LogoTopPart = styled.div`
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 1px;
`

const LogoBottomPart = styled.div`
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 1px;
`

const MenuList = styled.div`
  padding: 20px 0;
  @media (max-width: ${theme.breakpoints.sm}) {
    overflow: auto;
    height: 100%;
  }
`

const MenuLabel = styled.div`
  padding: 5px 8px;
  background-color: ${theme.colors.main};
  color: ${theme.colors.white};
  position: absolute;
  z-index: 10;
  left: 85%;
  min-width: 110px;
  top: 18px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: ${rem('11px')};
  /* box-shadow: 0 0 4px 1px rgba(0,0,0,.15); */
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, visibility .2s ease;
  @media (max-width: ${theme.breakpoints.sm}) {
    opacity: 1;
    visibility: visible;
    left: 45px;
    top: 18px;
    box-shadow: none;
    background: none;
    color: ${theme.colors.black};
  }
`

const MenuLink = styled(Link)`
  padding: 10px 7px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.black};
  @media (max-width: ${theme.breakpoints.sm}) {
    position: relative;
    padding-left: 15px;
    justify-content: flex-start;
    position: relative;
    z-index: 15;
  }
`

const MenuLinkWrapper = styled.div`
  padding: 0px 7px;
  width: 100%;
  height: 60px;
  position: relative;
  transition: background-color .2s ease;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: ${theme.colors.main};
    display: ${props => props.active ? 'block' : 'none'};
  }
  &:hover {
    ${MenuLabel} {
      opacity: 1;
      visibility: visible;
    }
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    height: 55px;
    &:hover {
      background-color: ${theme.colors.mainHover};
      &:before {
        display: block;
      }
      ${MenuLabel} {
        color: ${theme.colors.white};
      }
      svg {
        stroke: white;
        fill: white;
      }
    }
  }
`