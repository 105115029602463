import React from 'react'
import { SellersNewContainer } from 'modules/Sellers'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const NewSellerPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Pracownicy'}/>
      <SellersNewContainer />
    </Fade>
  )
}

export default NewSellerPage