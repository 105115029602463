import React from 'react'
import { RepresentativesNewContainer } from 'modules/Representatives'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const NewRepresentativePage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Przedstawiciele handlowi'}/>
      <RepresentativesNewContainer />
    </Fade>
  )
}

export default NewRepresentativePage