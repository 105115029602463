import * as types from './types'

const initialState = {
  administrators: null,
  currentAdministrator: null,
  isFormSubmitting: false,
  isDataLoading: false,
  formValidationErrors: null,
  areStatisticsLoading: false,
  statistics: null,
  statisticsActiveFilters: {
    type: 'sales_value'
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ADMINISTRATORS:
      return {
        ...state,
        administrators: action.payload
      }
    case types.SET_CURRENT_ADMINISTRATOR:
      return {
        ...state,
        currentAdministrator: action.payload
      }
    case types.SET_ADMIN_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_ADMIN_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_ADMINISTRATORS_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_ADMINISTRATORS_STATISTICS_LOADING:
      return {
        ...state,
        areStatisticsLoading: action.payload
      }
    case types.SET_ADMINISTRATORS_STATISTICS:
      return {
        ...state,
        statistics: action.payload
      }
    case types.SET_ADMINISTRATORS_STATISTICS_ACTIVE_FILTERS: 
      return {
        ...state,
        statisticsActiveFilters: action.payload
      }
    default: 
      return state
  }
}
