import styled, { css } from 'styled-components'
import theme from 'config/theme'

export const PrimaryButtonStyles = css`
  min-width: 110px;
  min-height: 34px;
  text-align: center;
  font-size: 13px;
  margin: 10px 0;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  opacity: ${props => props.disabled ? 0.75 : 1};
  outline: none;
  padding: 6px 15px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.main};
  color: ${theme.colors.white};
  background-color: ${theme.colors.main};
  font-family: ${theme.font.family};
  font-weight: 500;
  text-decoration: none;
  transition: border-color .15s ease, background-color .15s ease;
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
  flex-shrink: 0;
  line-height: 1.6;
  &:hover, &:focus {
    border-color: ${theme.colors.mainHover};
    background-color: ${theme.colors.mainHover};
  }
  @media (max-width: ${theme.breakpoints.md}) {
    font-size: 12px;
  }
`

export const PrimaryButton = styled.button`
  ${PrimaryButtonStyles};
`

export const OutlineButtonStyles = css`
  ${PrimaryButtonStyles};
  background-color: transparent;
  border-color: ${props => props.dark ? theme.colors.black : theme.colors.main };
  color: ${props => props.dark ? theme.colors.black : theme.colors.main };
  transition: background-color .15s ease, color .15s ease, border-color .15s ease;
  &:hover, &:focus {
    border-color: ${props => props.dark ? theme.colors.black : theme.colors.main};
    background-color: ${props => props.dark ? theme.colors.black : theme.colors.main};
    color: ${theme.colors.white};
  }
`

export const OutlineButton = styled(PrimaryButton)`
  ${OutlineButtonStyles};
`

export const ButtonsWrapper = styled.div`
  margin-left: -5px;
  margin-right: -5px;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  > button {
    margin: 10px 5px;
  }
`
