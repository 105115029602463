import React, { useState } from 'react'
import ForgotPasswordForm from './components/ForgotPasswordForm'
import SignIn from 'shared/styled/SignIn'
import { callApi } from 'utils/api'
import { URL_FORGOT_PASSWORD } from 'config/api/auth'
import { FRONT_URL } from 'config/basic'

const ForgotPassword = () => {

  const [logging, setLogging] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)
  const resetPasswordUrl = `${FRONT_URL}resetuj-haslo`

  const sendEmail = async formData => { 
    setLogging(true)
    
    const data = {
      ...formData,
      base_url: resetPasswordUrl
    }
    
    try {
      const response = await callApi(URL_FORGOT_PASSWORD, data)
      const parsed = await response.json()
      setLogging(false)

      if (response.status === 200) {
        setApiResponse({
          isError: false,
          errors: null,
          message: parsed.message
        })
      } else if (response.status === 422 ) {
        setApiResponse({
          isError: true,
          errors: parsed.errors,
          message: parsed.message
        })
      } else {
        setApiResponse({
          isError: false,
          errors: null,
          message: parsed && parsed.message ? parsed.message : 'Wystąpił nieoczekiwany błąd, spróbuj ponownie.'
        })
      }
    } catch (err) {
      setApiResponse({
        isError: true,
        message: 'Wystąpił nieoczekiwany błąd, spróbuj ponownie.'
      })
      setLogging(false)
      console.log(err)
    }
  }

  return (
    <SignIn.SignWrapper>
      <SignIn.HeaderWrapper>
        <SignIn.Header>Przypomnij hasło</SignIn.Header>
      </SignIn.HeaderWrapper>
      <ForgotPasswordForm 
        logging={logging} 
        sendEmail={sendEmail} 
        apiResponse={apiResponse}
        setApiResponse={setApiResponse} />
    </SignIn.SignWrapper>
  )
}

export default ForgotPassword;
