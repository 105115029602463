import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ReportProducts from 'shared/components/ReportProducts'
import { thunks as productsThunks } from 'modules/Products'
import { setSoldProducts, setFormValidationErrors } from '../actions'
import { addNewSale } from '../thunks'
import { useSelector } from 'react-redux'
import { actions as modalActions} from 'modules/Modal'
import { URL_GET_PRODUCTS_FOR_SALE_REPORT } from 'config/api/products'

const ReportSaleContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const products = useSelector(({ products }) => products.products)
  const chosenSoldProducts = useSelector(({ sales }) => sales.chosenSoldProducts)
  const isProductsDataLoading = useSelector(({ products }) => products.isDataLoading)
  const isFormSubmitting = useSelector(({ sales }) => sales.isFormSubmitting)
  const formValidationErrors = useSelector(({ sales }) => sales.formValidationErrors)
  const dispatch = useDispatch()

  useEffect(() => {
    const url = `${URL_GET_PRODUCTS_FOR_SALE_REPORT.url}?limit=10000000`
    dispatch(productsThunks.getProductsForSale(url))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const modalContent = {
    title: `Czy na pewno chcesz zgłosić sprzedaż tych produktów?`,
    description: 'Operacja jest nieodwracalna',
    confirmButtonText: 'Zgłaszam',
    cancelButtonText: 'Anuluj'
  }
  
  return (
    <ReportProducts
      products={products}
      chosenProducts={chosenSoldProducts}
      setChosenProducts={setSoldProducts}
      reportApiCall={addNewSale}
      loggedUser={loggedUser}
      formValidationErrors={formValidationErrors}
      isFormSubmitting={isFormSubmitting}
      isDataLoading={isProductsDataLoading}
      setFormValidationErrors={setFormValidationErrors} 
      enableModal={modalActions.enableModal}
      modalContent={modalContent} 
      reportTitle='Zgłoś sprzedaż'
      reportSubTitle='Poinformuj nas jeśli udało Ci się sprzedać nasze produkty! To się opłaca!' />
  )
}

export default ReportSaleContainer