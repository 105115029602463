import * as types from './types'

export const setSalesDistricts = salesDistricts => {
  return {
    type: types.SET_SALES_DISTRICTS,
    payload: salesDistricts
  }
}

export const setSalesDistrictsForSelect = salesDistricts => {
  return {
    type: types.SET_SALES_DISTRICTS_FOR_SELECT,
    payload: salesDistricts
  }
}

export const setSalesDistrictsForFilter = salesDistricts => {
  return {
    type: types.SET_SALES_DISTRICTS_FOR_FILTER,
    payload: salesDistricts
  }
}

export const setCurrentDistrict = salesDistrict => {
  return {
    type: types.SET_CURRENT_DISTRICT,
    payload: salesDistrict
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_DISTRICTS_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_DISTRICTS_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_DISTRICTS_LOADING,
    payload: isLoading
  }
}

export const setStatisticsLoading = isLoading => {
  return {
    type: types.SET_DISTRICTS_STATISTICS_LOADING,
    payload: isLoading
  }
}

export const setStatistics = statistics => {
  return {
    type: types.SET_DISTRICTS_STATISTICS,
    payload: statistics
  }
}

export const setStatisticsActiveFilters = filters => {
  return {
    type: types.SET_DISTRICTS_STATISTICS_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setStatisticsProductsForFilter = products => {
  return {
    type: types.SET_DISTRICTS_STATISTICS_PRODUCTS_FOR_FILTER,
    payload: products
  }
}