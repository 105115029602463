import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_PRODUCTS,
  URL_GET_SINGLE_PRODUCT,
  URL_UPDATE_PRODUCT,
  URL_GET_PRODUCTS_FOR_SALE_REPORT,
  URL_GET_PRODUCTS_FOR_RETURN
} from 'config/api/products'

export const getProducts = (url = URL_GET_PRODUCTS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_PRODUCTS.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setProductsList }
    })
  }
}

export const getProductsForSale = (url = URL_GET_PRODUCTS_FOR_SALE_REPORT) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_PRODUCTS_FOR_SALE_REPORT.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setProductsList }
    })
  }
}

export const getProductsForReturn = (url = URL_GET_PRODUCTS_FOR_RETURN) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_PRODUCTS_FOR_RETURN.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setProductsList }
    })
  }
}

export const getProduct = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SINGLE_PRODUCT, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setCurrentProduct }
    })
  }
}

export const updateProduct = (percent, id) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_UPDATE_PRODUCT, 
      queryParams: {
        wholesale_lower_by_percent: percent
      },
      pathParams: id,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: `/panel/produkty/${id}`, shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}