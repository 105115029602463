import * as types from './types'

export const setSellersList = sellers => {
  return {
    type: types.SET_SELLERS,
    payload: sellers
  }
}

export const setCurrentSeller = seller => {
  return {
    type: types.SET_CURRENT_SELLER,
    payload: seller
  }
}

export const setActiveFilters = filters => {
  return {
    type: types.SET_SELLERS_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_SELLERS_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_SELLERS_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_SELLERS_LOADING,
    payload: isLoading
  }
}

export const setStatisticsLoading = isLoading => {
  return {
    type: types.SET_SELLERS_STATISTICS_LOADING,
    payload: isLoading
  }
}

export const setStatistics = statistics => {
  return {
    type: types.SET_SELLERS_STATISTICS,
    payload: statistics
  }
}

export const setStatisticsActiveFilters = filters => {
  return {
    type: types.SET_SELLERS_STATISTICS_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setStatisticsProductsForFilter = products => {
  return {
    type: types.SET_SELLERS_STATISTICS_PRODUCTS_FOR_FILTER,
    payload: products
  }
}