import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import LoginForm from './components/LoginForm'
import SignIn from 'shared/styled/SignIn'
import { callApi } from 'utils/api'
import { URL_SIGN_IN } from 'config/api/auth'
import history from 'config/history'
import { saveUser } from 'utils/auth'
import { actions as userActions } from 'modules/App'

const Login = () => {

  const [logging, setLogging] = useState(false)
  const [apiErrors, setApiErrors] = useState(null)
  const dispatch = useDispatch()

  const signIn = async data => { 
    setLogging(true)
    try {
      const response = await callApi(URL_SIGN_IN, data)
      const parsed = await response.json()
      setLogging(false)

      if (response.status === 200) {
        saveUser(parsed)
        dispatch(userActions.setLoggedUser(parsed.data))
        
        const redirectUrl = localStorage.getItem('redirectUrl')
        if (redirectUrl) {
          history.push(redirectUrl)
          localStorage.removeItem('redirectUrl')
        } else {
          history.push('/panel')
        }
      } else if (response.status === 400) {
        setApiErrors({
          errors: parsed.errors,
          message: parsed.message ? parsed.message : 'Wystąpił nieoczekiwany błąd, spróbuj ponownie.'
        })
      } else {
        setApiErrors({
          errors: parsed.errors,
          message: parsed.message ? parsed.message : 'Wystąpił nieoczekiwany błąd, spróbuj ponownie.'
        })
      }
    } catch (err) {
      setApiErrors({
        errors: null,
        message: 'Wystąpił nieoczekiwany błąd, spróbuj ponownie.'
      })
      setLogging(false)
      console.log(err)
    }
  }
  

  return (
    <SignIn.SignWrapper>
      <SignIn.HeaderWrapper>
        <SignIn.Header>Logowanie</SignIn.Header>
      </SignIn.HeaderWrapper>
      <LoginForm 
        logging={logging} 
        signIn={signIn} 
        apiErrors={apiErrors} 
        setApiErrors={setApiErrors} />
    </SignIn.SignWrapper>
  )
}

export default Login
