import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from 'config/theme'
import { FiSettings, FiPower } from 'react-icons/fi'
import { logout } from 'utils/auth'

const ActionsDropdown = ({ isOpen }) => {

  return (
    <Wrapper isOpen={isOpen}>
      <List>
        <ActionLink to={'/panel/ustawienia'}>
          <SettingsIcon size={20} color={theme.colors.gray} />
          <ActionName>Ustawienia</ActionName>
        </ActionLink>
        <Action onClick={logout}>
          <PowerIcon size={20} color={theme.colors.gray} />
          <ActionName>Wyloguj się</ActionName>
        </Action>
      </List>
    </Wrapper>
  )
}

export default ActionsDropdown

const Wrapper = styled.div`
  padding: 7px 15px;
  min-width: 150px;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  position: absolute;
  z-index: 10;
  transition: all .15s ease;
  top: ${props => props.isOpen ? '60px' : '50px'};
  right: 25px;
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  opacity: ${props => props.isOpen ? 1 : 0};
`

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const ActionName = styled.span`
  margin-left: 10px;
  font-family: ${theme.font.family};
`

const SettingsIcon = styled(FiSettings)``

const PowerIcon = styled(FiPower)``

const Action = styled.button`
  text-decoration: none;  
  cursor: pointer;
  outline: none;
  width: 100%;
  display: block;
  background-color: ${theme.colors.white};
  border: none;
  padding: 10px 7px;
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
  display: flex;
  align-items: center;
  &:hover {
    color: ${theme.colors.main};
    ${SettingsIcon} {
      color: ${theme.colors.main};
    }
    ${PowerIcon} {
      color: ${theme.colors.main};
    }
  }
`

const ActionLink = Action.withComponent(Link)