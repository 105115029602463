import * as types from './types'

const initialState = {
  loggedUser: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOGGED_USER:
      return {
        ...state,
        loggedUser: action.payload
      }
    default: 
      return state
  }
}
