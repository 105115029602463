import * as types from './types';

export const enableModal = modal => {
  return {
    type: types.ENABLE_MODAL,
    payload: modal
  }
}

export const disableModal = () => {
  return {
    type: types.DISABLE_MODAL
  }
}
