import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Forms from 'shared/styled/Forms'
import { PrimaryButton } from 'shared/styled/Buttons'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import { useFormik } from 'formik'
import { goBack } from 'utils/global'

const ProfileForm = ({ 
  onSubmit, 
  formValidationErrors, 
  isFormSubmitting,
  setFormValidationErrors 
}) => {

  
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    setErrors(formValidationErrors)
  }, [formValidationErrors])

  useEffect(() => {
    return () => dispatch(setFormValidationErrors())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch()
  const initialData = {
    current_password: '',
    password: '',
    password_confirmation: ''
  }

  const updatePassword = formData => {
    dispatch(onSubmit(formData))
    dispatch(setFormValidationErrors(null))
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: (data, form) => {
      form.resetForm()
      updatePassword(data)
    }
  })

  return (
    <Forms.FormWrapper>
      <Forms.Form noValidate onSubmit={formik.handleSubmit} onChange={() => setErrors(null)}>
        <Forms.FormContent>
          <Forms.FormGroup wide>
            <Forms.Input 
              autocomplete='off'  
              required
              type='password'
              name='current_password' 
              value={formik.values.current_password} 
              onChange={formik.handleChange}/>
            <Forms.InputBar />
            <Forms.Label>Obecne hasło</Forms.Label>
            { errors && errors.current_password
              ? <Forms.InputError>
                { errors && errors.current_password &&
                  errors.current_password[0] 
                }
              </Forms.InputError>
              : null
            }
          </Forms.FormGroup>
          <Forms.FormGroup wide>
            <Forms.Input 
              autocomplete='off'  
              required
              type='password'
              name='password' 
              value={formik.values.password} 
              onChange={formik.handleChange}/>
            <Forms.InputBar />
            <Forms.Label>Nowe hasło</Forms.Label>
            { errors && errors.password
              ? <Forms.InputError>
                { errors && errors.password &&
                  errors.password[0] 
                }
              </Forms.InputError>
              : null
            }
          </Forms.FormGroup>
          <Forms.FormGroup wide>
            <Forms.Input 
              autocomplete='off'  
              required
              type='password'
              name='password_confirmation'
              value={formik.values.password_confirmation} 
              onChange={formik.handleChange} />
            <Forms.InputBar />
            <Forms.Label>Powtórz hasło</Forms.Label>
            { errors && errors.password_confirmation
              ? <Forms.InputError>
                { errors && errors.password_confirmation &&
                  errors.password_confirmation[0] 
                }
              </Forms.InputError>
              : null
            }
          </Forms.FormGroup>
          <Forms.FormButtonsWrapper>
            <PrimaryButton disabled={ isFormSubmitting } type='submit'>
              { isFormSubmitting ? <ButtonLoader /> : 'Zaktualizuj' }
            </PrimaryButton>
            <Forms.CancelButton type='button' onClick={() => goBack()}>Anuluj</Forms.CancelButton>
          </Forms.FormButtonsWrapper>
        </Forms.FormContent>
      </Forms.Form>
    </Forms.FormWrapper>
  )
}

export default ProfileForm