export default {
  admin: {
    slug: 'operator',
    name: 'Administrator'
  },
  district: {
    slug: 'sales_district',
    name: 'Okręg sprzedaży'
  },
  representative: {
    slug: 'representative',
    name: 'Przedstawiciel handlowy'
  },
  shop: {
    slug: 'shop',
    name: 'Jednostka gospodarcza'
  },
  seller: {
    slug: 'seller',
    name: 'Pracownik'
  },
  freelancer: {
    slug: 'freelancer',
    name: 'Operator'
  }
}