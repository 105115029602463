import React from 'react'
import { SalesDistrictsNewContainer } from 'modules/SalesDistricts'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const AddDistrictPage = () => {
  return (
     <Fade>
      <Breadcrumbs linkLabel={'Okręgi sprzedaży'}/>
      <SalesDistrictsNewContainer />
    </Fade>
  )
}

export default AddDistrictPage 