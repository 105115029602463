import React from 'react'
import { useDispatch } from 'react-redux'
import Form from 'shared/components/Form'
import shortid from 'shortid'
import Fade from 'shared/components/Fade'

const DistrictForm = ({ 
  hasPermission,
  loggedUser,
  currentDistrict, 
  isFormSubmitting, 
  callApi, 
  formValidationErrors, 
  setFormValidationErrors,
  submitBtnText,
  editing = false
}) => {
  const onSubmit = formData => {
    if (editing) {
      dispatch(callApi(formData, currentDistrict.id))
    } else {
      dispatch(callApi(formData))
    }
    dispatch(setFormValidationErrors(null))
  }

  const dispatch = useDispatch()
  const initialData = currentDistrict 
    ? {
      ...currentDistrict
    } : {
      name: '',
      percent_web_sales_district: '',
      percent_web_sales_district_from_sub: '',
      percent_web_representative: '',
      percent_web_representative_from_sub: '',
      percent_web_shop: '',
      percent_web_seller: '',
      percent_stationary_sales_district: '',
      percent_stationary_representative: '',
      percent_stationary_shop: '',
      percent_stationary_seller: '',
      percent_stationary_shop_seller: '',
      bank_account_number: '',
      optima_warehouse_nr: ''
    }

  const formLayout = [
    {
      key: shortid.generate(),
      title: 'Dane podstawowe',
      fields: [
        {
          name: 'name',
          type: 'text',
          label: 'Nazwa okręgu sprzedaży*'
        },
      ]
    },
    {
      key: shortid.generate(),
      title: 'Wysokość bonifikaty w sprzedaży internetowej',
      fields: [
        {
          name: 'percent_web_sales_district',
          type: 'number',
          label: 'Okręg sprzedaży - kod własny*'
        },
        {
          name: 'percent_web_sales_district_from_sub',
          type: 'number',
          label: 'Okręg sprzedaży - kod PH,JG lub IP w OS*'
        },
        {
          name: 'percent_web_representative',
          type: 'number',
          label: 'Przedstawiciel handlowy - kod własny*'
        },
        {
          name: 'percent_web_representative_from_sub',
          type: 'number',
          label: 'Przedstawiciel handlowy - kod JG lub IP*'
        },
        {
          name: 'percent_web_shop',
          type: 'number',
          label: 'Jednostka gospodarcza*'
        },
        {
          name: 'percent_web_seller',
          type: 'number',
          label: 'Indywidualny pracownik lub sprzedawca*'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Wysokość bonifikaty w sprzedaży stacjonarnej',
      fields: [
        {
          name: 'percent_stationary_sales_district',
          type: 'number',
          label: 'Okręg sprzedaży*'
        },
        {
          name: 'percent_stationary_representative',
          type: 'number',
          label: 'Przedstawiciel handlowy*'
        },
        {
          name: 'percent_stationary_shop',
          type: 'number',
          label: 'Jednostka gospodarcza*'
        },
        {
          name: 'percent_stationary_seller',
          type: 'number',
          label: 'Indywidualny pracownik*'
        },
        {
          name: 'percent_stationary_shop_seller',
          type: 'number',
          label: 'Jednostka gospodarcza po sprzedaży indywidualnego pracownika*'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Dane rozliczeniowe',
      fields: [
        {
          name: 'bank_account_number',
          type: 'text',
          label: 'Numer konta do wypłat',
          mask: 'P\L99999999999999999999999999' // eslint-disable-line
        },
      ]
    },
    {
      key: shortid.generate(),
      title: 'Dane integracji',
      fields: [
        {
          name: 'optima_warehouse_nr',
          type: 'text',
          label: 'Magazyn, do którego przypisywane będą zamówienia hurtowe, jeśli JG nie jest obsługiwana przez PH',
        },
      ]
    }
  ]

  return (
    <Fade>
      <Form 
        hasPermission={hasPermission}
        loggedUser={loggedUser}
        updatePermission='salesDistrict.update'
        initialData={initialData}
        formLayout={formLayout}
        isFormSubmitting={isFormSubmitting} 
        onSubmit={onSubmit}
        submitBtnText={submitBtnText}
        setFormValidationErrors={setFormValidationErrors}
        formValidationErrors={formValidationErrors} />
    </Fade>
  )
}

export default DistrictForm