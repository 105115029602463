import React from 'react'
import { useFormik } from 'formik'
import Form from 'shared/styled/Forms'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import SignIn from 'shared/styled/SignIn'

const LoginForm = ({ logging, signIn, apiErrors, setApiErrors }) => {

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: formData => {
      setApiErrors(null);
      signIn(formData)
    }
  })

  return (
    <SignIn.InnerWrapper>
      <Form.Form small noValidate onSubmit={formik.handleSubmit} onChange={() => setApiErrors(null)}>
        { apiErrors && apiErrors.message && <Form.Message error>{ apiErrors.message }</Form.Message> }
        <Form.FormGroup wide>
          <Form.Input 
            type="text" 
            name="email" 
            darken 
            autocomplete="off" 
            required 
            onChange={formik.handleChange} 
            value={formik.values.email} />
          <Form.InputBar />
          <Form.Label darken>Email</Form.Label>
          { apiErrors
            ? <Form.InputError>{ apiErrors && apiErrors.errors && apiErrors.errors.email ? apiErrors.errors.email[0] : '' }</Form.InputError>
            : null
          }
        </Form.FormGroup>
        <Form.FormGroup wide>
          <Form.Input 
            type="password" 
            name="password" 
            darken 
            autocomplete="off" 
            required
            onChange={formik.handleChange} 
            value={formik.values.password} />
          <Form.InputBar />
          <Form.Label darken>Hasło</Form.Label>
          { apiErrors
            ? <Form.InputError>{ apiErrors && apiErrors.errors && apiErrors.errors.password ? apiErrors.errors.password[0] : '' }</Form.InputError>
            : null
          }
        </Form.FormGroup>
        <SignIn.SubmitButtonWrapper>
          <SignIn.RouteLink to={'/przypomnij-haslo'}>Zapomniałeś hasła?</SignIn.RouteLink>
          <SignIn.SubmitButton disabled={ logging || (formik.values.email.length === 0 || formik.values.password.length === 0)} type="submit">
            { logging ? 
              <ButtonLoader /> : 'Zaloguj się' 
            }
          </SignIn.SubmitButton>
        </SignIn.SubmitButtonWrapper>
      </Form.Form>
    </SignIn.InnerWrapper>
  )
}

export default LoginForm
