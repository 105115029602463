import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getBalance, getTransactionsTypesForFilter, getBalanceCodesForFilter, filterBalanceHistory } from '../thunks'
import Balance from '../components/Balance'
import { setActiveFilters, setUserBalance } from '../actions'

const BalanceContainer = ({ 
  isOwnBalance = false,
  userRole = null,
  userId = null
}) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const balance = useSelector(({ balance }) => balance.balance)
  const userBalance = useSelector(({ balance }) => balance.userBalance)
  const transactionsTypes = useSelector(({ balance }) => balance.transactionsTypes)
  const balanceCodes = useSelector(({ balance }) => balance.balanceCodes)
  const activeFilters = useSelector(({ balance }) => balance.activeFilters)
  const isDataLoading = useSelector(({ balance }) => balance.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOwnBalance) {
      dispatch(getBalance(isOwnBalance)) 
      dispatch(getBalanceCodesForFilter(isOwnBalance)) 
      dispatch(getTransactionsTypesForFilter(isOwnBalance))
    } else {
      if (userRole && userId) {
        dispatch(getBalance(isOwnBalance, [userRole, userId]))
        dispatch(getBalanceCodesForFilter(isOwnBalance, [userRole, userId]))
        dispatch(getTransactionsTypesForFilter(isOwnBalance, [userRole, userId]))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setActiveFilters())
      dispatch(setUserBalance(null))
    }
  }, [dispatch])

  return (
    <Balance 
      isOwnBalance={isOwnBalance}
      loggedUser={loggedUser}
      balance={balance} 
      userBalance={userBalance}
      isDataLoading={isDataLoading}
      getBalance={getBalance} 
      activeFilters={activeFilters}
      transactionsTypes={transactionsTypes}
      balanceCodes={balanceCodes}
      filterBalanceHistory={param => filterBalanceHistory(isOwnBalance, [userRole, userId], param)} />
  )
}

export default BalanceContainer