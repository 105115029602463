import React, { Fragment } from 'react'
import Pagination from '../Pagination'
import Table from 'shared/styled/Table'
import NoRecords from './NoRecords'

const DataTable = ({
  columns, 
  data, 
  callApi,
  visibleCounter = true,
  twoColors = true,
  collapsible = false
}) => {

  return (
    <>
      { !data || !data.data.length
        ? <NoRecords>Brak rekordów do wyświetlenia!</NoRecords>
        : (
          <Table.TableWrapper>
            <Table.Table cellspacing='0'>
              <Table.TableHead>
                <Table.TableRow>
                  { visibleCounter && <Table.TableHeadCell width={60}>#</Table.TableHeadCell> }
                  { columns.map((column, i) => (
                    <Table.TableHeadCell key={i}>{column.name}</Table.TableHeadCell>
                  ))}
                </Table.TableRow>
              </Table.TableHead>
              <Table.TableBody collapsible={collapsible} twoColors={twoColors}>
                { data.data.map((row, rowIndex) => {
                  const number = data.meta.from + rowIndex
                  return (
                    <Fragment key={number} >
                      <Table.AnimatedRow 
                        i={rowIndex} 
                        initialPose='hidden' 
                        pose='visible'>
                        { visibleCounter && <Table.TableCell width={60}>{number}.</Table.TableCell> }
                        { columns.map((column, columnIndex) => (
                          <Table.TableCell key={columnIndex}>
                            { column.field instanceof Function ? column.field(row, rowIndex, columnIndex) : row[column.field]}
                          </Table.TableCell>
                        ))}
                      </Table.AnimatedRow>
                    </Fragment>
                  )
                })}
              </Table.TableBody>
            </Table.Table>
          </Table.TableWrapper>
        )
      }
      {
        data && data.links && data.meta.total > 0 &&
        <Pagination 
          callApi={callApi}
          links={data.links}
          from={data.meta.from}
          to={data.meta.to}
          total={data.meta.total} />
      }
    </>
  )
}

export default DataTable