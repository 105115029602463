import React from 'react'
import { RepresentativesListContainer } from 'modules/Representatives'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import SubMenu from 'shared/components/SubMenu'
import Fade from 'shared/components/Fade'

const RepresentativesPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Przedstawiciele handlowi'}/>
      <SubMenu />
      <RepresentativesListContainer />
    </Fade>
  )
}

export default RepresentativesPage