import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'

// PAGES
import PayoutsPage from './PayoutsPage'

const PayoutsRoutes = (match, loggedUser) => [
  <PermissionRoute
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/rozliczenia`} 
    component={PayoutsPage}  
    permissions={['wholesaleOrder.viewAny']} />
]

export default PayoutsRoutes