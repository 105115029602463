import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_REPORT_RETURN
} from 'config/api/returns'

export const addNewReturn = chosenProducts => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REPORT_RETURN, 
      queryParams: {
        products: chosenProducts.map(product => {
          return {
            id: product.id,
            amount: Number(product.amount)
          }
        })
      },
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { action: () => actions.setReturnedProducts([]), shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}
