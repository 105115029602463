
import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import theme from 'config/theme'
import { rem } from 'polished'
import Fade from 'shared/components/Fade'
import Checkbox from 'shared/components/Checkbox'
import Loading from 'modules/PageLoader/components/Loader'
import Forms from 'shared/styled/Forms'
import { PrimaryButton } from 'shared/styled/Buttons'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import { goBack } from 'utils/global'

const Permissions = ({ 
  objectId,
  isFormSubmitting, 
  isLoadingPermissions, 
  updatePermissionsUrl,
  updatePermissions,
  permissions,
  sendPermissions
}) => {

  const [activePermissions, setActivePermissions] = useState([])
  const [isSuperAdmin, setSuperAdmin] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (permissions && permissions.permissions) {
      const permissionsList = permissions.permissions
      let activePermissions = []

      permissionsList.forEach(perm => {
        perm.values.forEach(elem => {
          if (elem.checked) {
            activePermissions = [...activePermissions, elem.value]
          }
        })
      })
      setActivePermissions(activePermissions)

      if (permissions.is_super_admin) {
        setSuperAdmin(true)
      }
    }
  }, [permissions])
  
  const changePermissions = perm => {
    let newPermissions = activePermissions
    if (newPermissions.includes(perm)) {
      newPermissions = activePermissions.filter(elem => elem !== perm)
      setActivePermissions(newPermissions)
    } else {
      newPermissions = [...newPermissions, perm]
      setActivePermissions(newPermissions)
    }
  }

  const shouldBeChecked = permission => {
    if (activePermissions.includes(permission)) {
      return true
    } else {
      return false
    }
  }

  const onFormSubmit = e => {
    e.preventDefault()
    const formData = permissions.is_super_admin === undefined
    ? {
      permissions: activePermissions
    }
    : {
      is_super_admin: isSuperAdmin,
      permissions: activePermissions
    }
    dispatch(updatePermissions(formData, objectId, updatePermissionsUrl))
  }

  const toggleSuperAdmin = () => {
    setSuperAdmin(!isSuperAdmin)
  }

  return (
    <Loading isPageLoading={isLoadingPermissions}>
      <Fade>
        <Forms.Form onSubmit={onFormSubmit}>
          <Forms.FormContent darken>
            <PermissionsWrapper>
              {
                permissions && 
                permissions.is_super_admin !== undefined
                ? (
                  <CheckboxGroup>
                    <CheckboxField>
                      <Checkbox
                        isEditable={permissions.can_change_super_admin} 
                        handleChange={toggleSuperAdmin} 
                        label={'Prawa Super Administratora'} 
                        checked={isSuperAdmin}/>
                    </CheckboxField>
                  </CheckboxGroup>
                ) : null
              }
              <PermissionsGroup>
                {
                  !isSuperAdmin
                  ? (
                    <Fade>
                      {
                        permissions && permissions.permissions &&
                        permissions.permissions.map(permissionBlock => {
                          return (
                            <Fragment key={permissionBlock.label}>
                              <CheckboxGroupHeader>{ permissionBlock.label }</CheckboxGroupHeader>
                              <CheckboxGroup>
                                {
                                  permissionBlock.values &&
                                  permissionBlock.values.map(permission => {
                                    return (
                                      <CheckboxField key={permission.value}>
                                        <Checkbox
                                          handleChange={() => changePermissions(permission.value)} 
                                          label={permission.label} 
                                          value={permission.value} 
                                          checked={shouldBeChecked(permission.value)}
                                          isEditable={permission.editable} />
                                      </CheckboxField>
                                    )
                                  })
                                }
                              </CheckboxGroup>
                            </Fragment>
                          )
                        })
                      }
                    </Fade>
                  ) : null
                }
              </PermissionsGroup>
              <Forms.FormButtonsWrapper>
                <PrimaryButton disabled={ isFormSubmitting } type="submit">
                  { isFormSubmitting ? <ButtonLoader /> : 'Zaktualizuj' }
                </PrimaryButton>
                <Forms.CancelButton type="button" onClick={() => goBack()}>Anuluj</Forms.CancelButton>
              </Forms.FormButtonsWrapper>
            </PermissionsWrapper>
          </Forms.FormContent>
        </Forms.Form>
      </Fade>
    </Loading>
  )
}

export default Permissions

const PermissionsWrapper = styled.div`
  width: 100%;
`

const PermissionsGroup = styled.div``

const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

const CheckboxGroupHeader = styled.h3`
  font-size: ${rem('14px')};
  font-weight: bold;
  margin: 10px 0 25px 0;
  padding: 0 10px;
`

const CheckboxField = styled.div`
  flex: 0 1 50%;
  margin: 8px 0px;
  padding: 0 10px;
  @media (max-width: ${theme.breakpoints.md}) {
    flex: 0 1 100%;
  }
`