import React from 'react'
import Tabs from 'shared/components/Tabs'
import GenerateInvoicesNotificationsContainer from '../containers/GenerateInvoicesNotificationsContainer'
import GenerateAgreementPayoutsContainer from '../containers/GenerateAgreementPayoutsContainer'
import InvoicesConfirmationsContainer from '../containers/InvoicesConfirmationsContainer'
import PayoutsHistoryContainer from '../containers/PayoutsHistoryContainer'
import BeneficiariesWithNegativeBalanceContainer from '../containers/BeneficiariesWithNegativeBalanceContainer'

const Payouts = () => {
  return (
    <Tabs horizontal>
      <GenerateInvoicesNotificationsContainer tabLabel={'Faktury'} />
      <GenerateAgreementPayoutsContainer tabLabel={'Umowy'} />
      <InvoicesConfirmationsContainer tabLabel={'Potwierdzenia faktur'} />
      <PayoutsHistoryContainer tabLabel={'Historia'} />
      <BeneficiariesWithNegativeBalanceContainer tabLabel={'Z ujemnym saldem'} />
    </Tabs>
  )
}

export default Payouts