import React from 'react'
import { FreelancersDetailsContainer } from 'modules/Freelancers'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const FreelancerDetailsPage = ({ match }) => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Niezależni operatorzy'}/>
      <FreelancersDetailsContainer match={match}/>
    </Fade>
  )
}

export default FreelancerDetailsPage