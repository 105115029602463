import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import history from 'config/history'
import { addNewShop } from '../thunks'
import { setFormValidationErrors } from '../actions'
import { thunks as representativeThunks } from 'modules/Representatives'
import ShopsNew from '../components/ShopsNew'

const ShopsNewContainer = () => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isFormSubmitting = useSelector(({ shops }) => shops.isFormSubmitting)
  const isDataLoading = useSelector(({ shops }) => shops.isDataLoading)
  const formValidationErrors = useSelector(({ shops }) => shops.formValidationErrors)
  const representativesForSelect = useSelector(({ representatives }) => representatives.representativesForSelect)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(representativeThunks.getRepresentativesForSelect())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ShopsNew
      loggedUser={loggedUser}
      history={history}
      isPageLoading={isDataLoading}
      representativesForSelect={representativesForSelect}
      isFormSubmitting={isFormSubmitting}
      addNewShop={addNewShop}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default ShopsNewContainer

