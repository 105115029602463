import React, { useEffect } from 'react'
import { useSelector , useDispatch} from 'react-redux'
import history from 'config/history'
import { getFreelancer, updateFreelancer } from '../thunks'
import { setFormValidationErrors } from '../actions'
import FreelancersDetails from '../components/FreelancersDetails'

const FreelancersDetailsContainer = ({ match }) => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ freelancers }) => freelancers.isDataLoading)
  const currentFreelancer = useSelector(({ freelancers }) => freelancers.currentFreelancer)
  const isFormSubmitting = useSelector(({ freelancers }) => freelancers.isFormSubmitting)
  const formValidationErrors = useSelector(({ freelancers }) => freelancers.formValidationErrors)
  const dispatch = useDispatch()

  const freelancerId = match.params.freelancer_id

  useEffect(() => {
    dispatch(getFreelancer(freelancerId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FreelancersDetails
      loggedUser={loggedUser}
      editing
      isPageLoading={isDataLoading}
      history={history}
      currentFreelancer={currentFreelancer}
      isFormSubmitting={isFormSubmitting}
      updateFreelancer={updateFreelancer}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default FreelancersDetailsContainer