import React from 'react'
import { useSelector } from 'react-redux'
import history from 'config/history'
import { addNewDistrict } from '../thunks'
import { setFormValidationErrors } from '../actions'
import SalesDistrictsNew from '../components/SalesDistrictsNew'

const SalesDistrictsNewContainer = () => {

  const loggedUser = useSelector(({user}) => user.loggedUser)
  const isFormSubmitting = useSelector(({sales_districts}) => sales_districts.isFormSubmitting)
  const formValidationErrors = useSelector(({sales_districts}) => sales_districts.formValidationErrors)

  return (
    <SalesDistrictsNew
      loggedUser={loggedUser}
      history={history}
      isFormSubmitting={isFormSubmitting}
      addNewDistrict={addNewDistrict}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default SalesDistrictsNewContainer

