import React from 'react'
import styled from 'styled-components'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import SearchBanner from 'shared/components/SearchBanner'
import Loader from 'modules/PageLoader/components/Loader'
import { FaRegEdit } from 'react-icons/fa'
import DataTableLink from 'shared/components/DataTable/DataTableLink'
import { DataTableActionLink } from 'shared/components/DataTable/DataTableAction'
import { StatusIndicator } from 'shared/components/DataTable/DataTableElements'
import { hasPermission } from 'utils/auth'
import Fade from 'shared/components/Fade'


const ProductsList = ({
  loggedUser,
  searchUrl,
  getProducts,
  removeProduct,
  products,
  isPageLoading,
  enableModal
}) => {

  return (
    <>
      <SearchBanner 
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={getProducts} />
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <Fade>
            <DataTable
              callApi={getProducts}
              data={products}
              columns={[
                { name: 'Zdjęcie', field: row => <ProductThumbnail src={row.default_image_link_small} alt={row.name} />},
                { name: 'Nazwa', field: row => {
                  if (hasPermission('product.view', loggedUser)) {
                    return <DataTableLink to={`/panel/produkty/${row.id}`}>{row.name}</DataTableLink>
                  } else {
                    return row.name
                  }
                }},
                { name: 'Status', field: row => <StatusIndicator active={row.active}/> },
                { name: 'Cena detaliczna netto', field: row => `${row.price} zł` },
                { name: '% ceny', field: 'wholesale_lower_by_percent' },
                { name: 'Akcje', field: row => (
                  <>
                    {
                      hasPermission('product.update', loggedUser) &&
                      <DataTableActionLink 
                        tooltiptext='Edytuj'
                        to={`/panel/produkty/${row.id}`}>
                        <FaRegEdit size={18}/>
                      </DataTableActionLink>
                    }
                  </>
                )},
              ]}
            />
          </Fade>
        </Loader>
      </Blocks.Block>
    </>
  )
}

export default ProductsList

const ProductThumbnail = styled.img`
  max-width: 50px;
`
