import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from 'config/theme'
import { FaUserCircle } from 'react-icons/fa'
import { MdArrowDropDown } from 'react-icons/md'
import ActionsDropdown from 'shared/components/ActionsDropdown'

const LoggedUser = ({ userName }) => {

  const loggedUser = useSelector(({user}) => user.loggedUser)
  const [isOpen, setIsOpen] = useState(false)
  const toggleDropdown = () => setIsOpen(!isOpen)
  const wrapperRef = useRef(null)

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  return (
    <ClickableWrapper onClick={toggleDropdown} ref={wrapperRef}>
      <FaUserCircle size={25} color={theme.colors.gray} />
      <Name>{ loggedUser && loggedUser.display_name }</Name>
      <ArrowIcon size={25} color={theme.colors.gray} />
      <ActionsDropdown isOpen={isOpen} />
    </ClickableWrapper>
  )
}

export default LoggedUser

const ClickableWrapper = styled.div`
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
`

const ArrowIcon = styled(MdArrowDropDown)`
  margin-left: 10px;
`

const Name = styled.div`
  margin-left: 10px;
  font-size: ${rem('14px')};
  color: ${theme.colors.black}
`