import styled from 'styled-components'
import { rem } from 'polished'
import theme from '../../config/theme'
import { OutlineButton } from 'shared/styled/Buttons'

const Form = styled.form`
  max-width: ${props => props.small ? '400px' : '100%'};
  width: 100%;
`

const FormGroup = styled.div`
  position: relative;
  margin: ${props => props.wide ? '15px 0' : '20px'};
  width: ${props => props.wide ? '100%' : 'calc(50% - 40px)'};
  @media (max-width: ${theme.breakpoints.md}) {
    margin: ${props => props.wide ? '25px 0' : '20px'};
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`

const InputBar = styled.span`
  position: relative;
  display: block;
  width: 100%;
  &:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background-color: ${theme.colors.main};
    transition: 0.2s ease all;
    left: 0%;
  }
`

const Input = styled.input`
  background: none;
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
  padding: 10px 10px 10px 0;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid ${props => props.darken ? theme.colors.inputGray : theme.colors.inputGray};
  font-family: ${theme.font.family};
  &:focus {
    outline: none;
  }
  &:focus ~ label,
  &:valid ~ label {
    top: -12px;
    font-size: ${rem('12px')};
  }
  &:focus ~ ${InputBar}:before {
    width: 100%;
  }
`

const Label = styled.label`
  color: ${props => props.darken ? theme.colors.inputText : theme.colors.inputText};
  font-size: ${props => props.disabled ? rem('12px') : rem('14px')};
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: ${props => props.disabled ? '-12px' : '10px'};
  font-family: ${theme.font.family};
  text-align: left;
  line-height: 1;
  transition: 0.2s ease all;
`

const Message = styled.div`
  padding: 10px 16px;
  border: 2px solid ${props => props.error ? theme.colors.red : theme.colors.green};
  color: ${props => props.error ? theme.colors.red : theme.colors.green};
  border-radius: 7px;
  font-size: ${rem('14px')};
  margin: 25px 0;
`

const InputError = styled.div`
  padding: 5px 0;
  font-size: 12px;
  text-align: left;
  color: ${theme.colors.red};
`

const FormWrapper = styled.div``

const FormContent = styled.div`
  width: 100%;
  background-color: ${props => props.darken ? theme.colors.lightGray : theme.colors.white};
  border: 1px solid ${props => props.darken ? theme.colors.light : theme.colors.white};
  border-radius: 7px;
  padding: ${props => props.darken ? '20px' : '5px 0'};
`

const FieldsWrapper = styled.div`
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
`

const LeftPart = styled.div`
  flex: 1;
  padding-right: 25px;
`

const RightPart = styled.div`
  flex: 1;
  padding-left: 25px;
`

const Preheader = styled.h3`
  font-size: ${rem('15px')};
  margin: 10px 0;
`

const FormButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  & > button {
    margin: 5px;
  }
`

const CancelButton = styled(OutlineButton)``

const FieldInfo = styled.div`
  font-size: ${rem('12px')};
  text-align: left;
  padding-top: 7px;
  color: ${theme.colors.inputText};
  font-style: italic;
`

const Required = styled.span`
  margin-left: 3px;
  color: ${theme.colors.red};
`

const RoundedInput = styled.input`
  border-radius: 5px;
  border: 2px solid ${theme.colors.mediumGray};
  padding: 4px 0px 4px 4px;
  max-width: 55px;
  background: none;
  outline: none;
  margin-right: 10px;
  font-family: ${theme.font.family};
`

export default {
  FormWrapper,
  FormContent,
  Form,
  FormGroup,
  Input,
  InputBar,
  Label,
  Message,
  FieldsWrapper,
  LeftPart,
  RightPart,
  FieldInfo,
  Preheader,
  InputError,
  FormButtonsWrapper,
  CancelButton,
  Required,
  RoundedInput
}