import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { rem } from 'polished'
import { subMenu } from 'config/menu'
import theme from 'config/theme'
import history from 'config/history'
import roles from 'config/roles'
import { checkPermissions } from 'utils/auth'

const SubMenu = () => {

  const loggedUser = useSelector(({user}) => user.loggedUser)

  const setActive = link => {
    const currentPath = history.location.pathname
    if (currentPath === link) {
      return 'true'
    }
  }

  return (
    <>
      {
        loggedUser && loggedUser.account_type === roles.admin.slug &&
        <AdminMenuWrapper>
        { subMenu.map(menuItem => {
          if (checkPermissions(menuItem.permissions, loggedUser)) {
            return (
              <AdminSubMenuItem active={setActive(menuItem.link)} key={ menuItem.id } to={ menuItem.link }>{ menuItem.label }</AdminSubMenuItem>
            )
          } else return null
        })}
        </AdminMenuWrapper>
      }
    </>
  )
}

export default SubMenu

const AdminMenuWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: -15px;
  @media (max-width: ${theme.breakpoints.md}) {
    margin-top: 10px;
  }
`

const AdminSubMenuItem = styled(Link)`
  padding: 10px 0 14px 0;
  text-decoration: none;
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
  /* text-transform: uppercase; */
  font-weight: ${props => props.active === 'true' ? 600 : 400};
  position: relative;
  margin-right: 30px;
  &:first-of-type {
    padding-left: 0;
  }
  &::after {
    opacity: ${props => props.active === 'true' ? 1 : 0};
    visibility: ${props => props.active === 'true' ? 'visible' : 'hidden'};
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${theme.colors.main};
    transition: opacity .2s ease, visibility .2s ease;
  }
  &:hover {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 8px 0 13px 0;
    font-size: ${rem('14px')};
  }
`

