import React from 'react'
import shortid from 'shortid'
import { AiOutlinePieChart, AiOutlinePercentage, AiOutlineHistory } from 'react-icons/ai'
import { FiUsers, FiBox, FiShoppingCart } from 'react-icons/fi'
import { FaRegCreditCard, FaRegMoneyBillAlt } from 'react-icons/fa'
import roles from 'config/roles'

export const mainMenu = [
  {
    id: shortid.generate(),
    label: 'Struktury',
    links: [
      { link: '/panel/okregi', permission: 'salesDistrict.viewAny' },
      { link: '/panel/przedstawiciele', permission: 'representative.viewAny'},
      { link: '/panel/jednostki', permission: 'shop.viewAny' },
      { link: '/panel/pracownicy', permission: 'seller.viewAny' },
      { link: '/panel/operatorzy', permission: 'freelancer.viewAny' }
    ],
    icon: <AiOutlinePieChart size={23} />,
    permissions: [
      'salesDistrict.viewAny',
      'representative.viewAny',
      'shop.viewAny',
      'seller.viewAny',
      'freelancer.viewAny'
    ]
  },
 {
    id: shortid.generate(),
    label: 'Saldo',
    link: '/panel/saldo',
    icon: <FaRegCreditCard size={21} />,
    permissions: [
      'all'
    ]
  },
  {
    id: shortid.generate(),
    label: 'Administratorzy',
    link: '/panel/administratorzy',
    icon: <FiUsers size={21} />,
    permissions: [
      'operator.viewAny'
    ]
  },
  {
    id: shortid.generate(),
    label: 'Produkty',
    link: '/panel/produkty',
    icon: <FiBox size={23} />,
    permissions: [
      'product.viewAny'
    ]
  },
  {
    id: shortid.generate(),
    label: 'Zamówienia',
    link: '/panel/zamowienia',
    icon: <FiShoppingCart size={23} />,
    permissions: [
      'wholesaleOrder.viewAny'
    ]
  },
  { 
    id: shortid.generate(),
    label: 'Rozliczenia',
    link: '/panel/rozliczenia',
    icon: <FaRegMoneyBillAlt size={23} />,
    permissions: [
      'payout.viewAny'
    ]
  },
  {
    id: shortid.generate(),
    label: 'Kody rabatowe',
    link: '/panel/kody-rabatowe',
    icon: <AiOutlinePercentage size={23} />,
    permissions: [
      'code.viewAny'
    ]
  },
  {
    id: shortid.generate(),
    label: 'Historia zmian',
    link: '/panel/historia',
    icon: <AiOutlineHistory size={23} />,
    permissions: [
      'history.viewAny'
    ]
  }
]

export const subMenu = [
  {
    id: shortid.generate(),
    label: 'Okręgi sprzedaży',
    link: '/panel/okregi',
    permissions: [
      'salesDistrict.viewAny'
    ],
    available_roles: [
      roles.admin.slug
    ]
  },
  {
    id: shortid.generate(),
    label: 'Przedstawiciele handlowi',
    link: '/panel/przedstawiciele',
    permissions: [
      'representative.viewAny'
    ],
    available_roles: [
      roles.admin.slug,
      roles.district.slug
    ]
  },
  {
    id: shortid.generate(),
    label: 'Jednostki gospodarcze',
    link: '/panel/jednostki',
    permissions: [
      'shop.viewAny'
    ]
  },
  {
    id: shortid.generate(),
    label: 'Pracownicy',
    link: '/panel/pracownicy',
    permissions: [
      'seller.viewAny'
    ],
    available_roles: [
      roles.shop.slug
    ]
  },
  {
    id: shortid.generate(),
    label: 'Operatorzy',
    link: '/panel/operatorzy',
    permissions: [
      'freelancer.viewAny'
    ],
    available_roles: [
      roles.admin.slug
    ]
  }
]

export const userMenu = [
  {
    id: shortid.generate(),
    label: 'Kokpit',
    link: '/panel/kokpit',
    permissions: [
      'all'
    ]
  },
  {
    id: shortid.generate(),
    label: 'Saldo',
    link: '/panel/saldo',
    permissions: [
      'all'
    ]
  },
  {
    id: shortid.generate(),
    label: 'Okręgi sprzedaży',
    link: '/panel/okregi',
    permissions: [
      'salesDistrict.viewAny'
    ],
    available_roles: [
      roles.admin.slug
    ]
  },
  {
    id: shortid.generate(),
    label: 'Przedstawiciele handlowi',
    link: '/panel/przedstawiciele',
    permissions: [
      'representative.viewAny'
    ],
    available_roles: [
      roles.admin.slug,
      roles.district.slug
    ]
  },
  {
    id: shortid.generate(),
    label: 'Jednostki gospodarcze',
    link: '/panel/jednostki',
    permissions: [
      'shop.viewAny'
    ]
  },
  {
    id: shortid.generate(),
    label: 'Pracownicy',
    link: '/panel/pracownicy',
    permissions: [
      'seller.viewAny'
    ],
    available_roles: [
      roles.shop.slug
    ]
  },
  {
    id: shortid.generate(),
    label: 'Operatorzy',
    link: '/panel/operatorzy',
    permissions: [
      'freelancer.viewAny'
    ],
    available_roles: [
      roles.admin.slug
    ]
  },
  {
    id: shortid.generate(),
    label: 'Zamówienia',
    link: '/panel/zamowienia',
    permissions: [
      'wholesaleOrder.viewAny'
    ],
    available_roles: [
      roles.shop.slug,
      roles.seller.slug
    ]
  },
  {
    id: shortid.generate(),
    label: 'Magazyn',
    link: '/panel/magazyn',
    available_roles: [
      roles.shop.slug,
      roles.seller.slug
    ]
  }
]
