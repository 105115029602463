import React, { useEffect } from 'react'
import { useSelector , useDispatch} from 'react-redux'
import history from 'config/history'
import { getAdministrator, updateAdministrator } from '../thunks'
import { thunks as districtThunks } from 'modules/SalesDistricts'
import { setFormValidationErrors } from '../actions'
import AdministratorDetails from '../components/AdministratorDetails'
import { hasPermission } from 'utils/auth'

const AdministratorsDetailsContainer = ({ match }) => {

  const loggedUser = useSelector(({user}) => user.loggedUser)
  const isDataLoading = useSelector(({ administrators }) => administrators.isDataLoading)
  const currentAdministrator = useSelector(({administrators}) => administrators.currentAdministrator)
  const salesDistrictsForSelect = useSelector(({sales_districts}) => sales_districts.salesDistrictsForSelect)
  const isFormSubmitting = useSelector(({administrators}) => administrators.isFormSubmitting)
  const formValidationErrors = useSelector(({administrators}) => administrators.formValidationErrors)
  const dispatch = useDispatch()

  const administratorId = match.params.administrator_id

  useEffect(() => {
    dispatch(getAdministrator(administratorId))
    if (hasPermission('operator.update', loggedUser)) {
      dispatch(districtThunks.getSalesDistrictsForSelect())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdministratorDetails
      loggedUser={loggedUser}
      editing
      isPageLoading={isDataLoading}
      history={history}
      salesDistrictsForSelect={salesDistrictsForSelect}
      currentAdministrator={currentAdministrator}
      isFormSubmitting={isFormSubmitting}
      updateAdministrator={updateAdministrator}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default AdministratorsDetailsContainer

