import styled from 'styled-components'
import { rem } from 'polished'
import posed from 'react-pose'
import theme from 'config/theme'

const onEnterAnimation = { 
  opacity: 1,
  x: 0,
  delay: ({i}) => i * 30
}

const onLeaveAnimation = { 
  opacity: 0,
  x: -10
}

const PosedRow = posed.tr({
  visible: onEnterAnimation,
  hidden: onLeaveAnimation
})

const AnimatedRow = styled(PosedRow)`
  &:hover {
    cursor: ${props => props.collapsible ? 'pointer' : 'default'};
    /* transition: background-color .2s ease;
    background-color: ${props => props.collapsible ? theme.colors.lightGray: 'none'}; */
  }
`

const TableWrapper = styled.div`
  width: 100%;
  @media (max-width: ${theme.breakpoints.sm}) {
    overflow: auto;
  }
`

const Table = styled.table`
  width: 100%;
  min-width: 500px;
  border-collapse: collapse;
	border-spacing: 0;
`

const TableHead = styled.thead`
  border-bottom: 1px solid ${theme.colors.gray};
`

const TableHeadCell = styled.th`
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  color: ${theme.colors.textOnWhite};
  font-size: ${rem('11px')};
  padding: 0 15px 10px 15px;
  letter-spacing: 1px;
  max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  border-bottom: 1px solid ${theme.colors.lightGray};
  &:last-of-type {
    text-align: right;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 8px;
  }
`

const TableRow = styled.tr``

const TableCell = styled.td`
  max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  min-width: ${props => props.minWidth ? `${props.minWidth}px` : 'unset'};
  padding: 9px 15px;
  font-size: ${rem('14px')};
  color: ${theme.colors.black};
  border-bottom: 1px solid ${theme.colors.lightGray};
  word-break: ${props => props.wrapText ? 'break-all' : 'normal'};
  &:last-of-type {
    text-align: right;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 8px;
    font-size: ${rem('14px')};
  }
`

const CollapsedRow = styled.tr``

const CollapsedRowCell = styled.td`
  padding: 0;
`

const TableBody = styled.tbody`
  tr {
    &:nth-of-type(2n+2) {
      background-color: ${props => props.twoColors && !props.collapsible ? theme.colors.lightGray : theme.colors.white}
    }
    &:last-of-type {
      ${TableCell} {
        border-bottom: 0;
      }
    }
  }
`

const NoRecords = styled.div`
  font-size: ${rem('14px')};
`

const TableFooter = styled.div`
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.spacedOut ? 'space-between' : 'flex-end'};
  > div {
    padding-top: 0;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const TableGroupActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const TableGroupActionsText = styled.div`
  font-size: ${rem('14px')};
  color: ${theme.colors.black};
  margin-right: 15px;
`

export default {
  PosedRow,
  AnimatedRow,
  TableWrapper,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TableBody,
  NoRecords,
  CollapsedRow,
  CollapsedRowCell,
  TableFooter,
  TableGroupActionsWrapper,
  TableGroupActionsText
}