import React from 'react'
import shortid from 'shortid'
import RoleRoute from 'hoc/RoleRoute'
import roles from 'config/roles'

// PAGES
import WarehousePage from './WarehousePage'

const WarehouseRoutes = (match, loggedUser) => [
  <RoleRoute
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/magazyn`} 
    component={WarehousePage}  
    availableFor={[roles.shop.slug, roles.seller.slug]} />
]

export default WarehouseRoutes