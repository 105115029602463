import { BASE_API_URL } from './base'

export const URL_GET_SELLERS = {
  method: 'GET',
  url: `${BASE_API_URL}/seller`
}

export const URL_GET_SINGLE_SELLER = {
  method: 'GET',
  url: id => `${BASE_API_URL}/seller/${id}`
}

export const URL_REMOVE_SELLER = {
  method: 'DELETE',
  url: id => `${BASE_API_URL}/seller/${id}`
}

export const URL_ADD_SELLER = {
  method: 'POST',
  url: `${BASE_API_URL}/seller`
}

export const URL_UPDATE_SELLER = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/seller/${id}`
}

export const URL_GET_OWN_SELLER_STATISTICS = {
  method: 'GET',
  url: `${BASE_API_URL}/stats/seller-own`
}

export const URL_GET_SELLER_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/seller/${id}`
}

export const URL_GET_PRODUCTS_FOR_SELLER_OWN_STATISTICS = {
  method: 'GET',
  url: `${BASE_API_URL}/stats/seller-own/filter/products`
}

export const URL_GET_PRODUCTS_FOR_SELLER_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/seller/${id}/filter/products`
}