import * as types from './types'

const initialState = {
  warehouseProducts: null,
  isDataLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_WAREHOUSE_PRODUCTS:
      return {
        ...state,
        warehouseProducts: action.payload
      }
    case types.SET_WAREHOUSE_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    default: 
      return state
  }
}
