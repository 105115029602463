import React from 'react'
import { Formik } from 'formik'
import Form from 'shared/styled/Forms'
import { ButtonLoader } from 'shared/styled/ButtonLoader'
import SignIn from 'shared/styled/SignIn'

const ResetPasswordForm = ({ logging, sendEmail, apiResponse, setApiResponse }) => {

  return (
    <SignIn.InnerWrapper>
      <Formik
        initialValues={{
          password: '',
          password_confirmation: ''
        }}
        onSubmit={(formData, {resetForm}) => {
          setApiResponse(null)
          sendEmail(formData)
          resetForm({})
        }}>
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange
        }) => (
          <Form.Form small noValidate onSubmit={handleSubmit} onChange={() => setApiResponse(null)}>
            { apiResponse 
              ? apiResponse.isError ? <Form.Message error>{ apiResponse.message }</Form.Message> : <Form.Message>{ apiResponse.message }</Form.Message>
              : null
            }
            <Form.FormGroup wide>
              <Form.Input 
                type="password" 
                name="password" 
                darken 
                autocomplete="off" 
                required 
                onChange={handleChange} 
                value={values.password} />
              <Form.InputBar />
              <Form.Label darken>Nowe hasło</Form.Label>
              { apiResponse &&
                <Form.InputError>{apiResponse && apiResponse.errors && apiResponse.errors ? apiResponse.errors.password[0] : ''}</Form.InputError>
              }
            </Form.FormGroup>
            <Form.FormGroup wide>
              <Form.Input 
                type="password" 
                name="password_confirmation" 
                darken 
                autocomplete="off" 
                required 
                onChange={handleChange} 
                value={values.password_confirmation} />
              <Form.InputBar />
              <Form.Label darken>Powtórz hasło</Form.Label>
              { apiResponse &&
                <Form.InputError>{apiResponse && apiResponse.errors && apiResponse.errors.password_confirmation ? apiResponse.errors.password_confirmation : ''}</Form.InputError>
              }
            </Form.FormGroup>
            <SignIn.SubmitButtonWrapper>
              <SignIn.RouteLink to={'/logowanie'}>Zaloguj się</SignIn.RouteLink>
              <SignIn.SubmitButton disabled={ logging || (values.email && values.password_confirmation) } type="submit">
                { logging ? <ButtonLoader /> : 'Wyślij' }
              </SignIn.SubmitButton>
            </SignIn.SubmitButtonWrapper>
          </Form.Form>
        )}
      </Formik>
    </SignIn.InnerWrapper>
  )
}

export default ResetPasswordForm