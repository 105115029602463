import { BASE_API_URL } from './base'

export const URL_GET_SHOPS = {
  method: 'GET',
  url: `${BASE_API_URL}/shop`
}

export const URL_GET_SHOPS_FOR_SELECT = {
  method: 'GET',
  url: `${BASE_API_URL}/select/shops`
}

export const URL_GET_SINGLE_SHOP = {
  method: 'GET',
  url: id => `${BASE_API_URL}/shop/${id}`
}

export const URL_REMOVE_SHOP = {
  method: 'DELETE',
  url: id => `${BASE_API_URL}/shop/${id}`
}

export const URL_ADD_SHOP = {
  method: 'POST',
  url: `${BASE_API_URL}/shop`
}

export const URL_UPDATE_SHOP = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/shop/${id}`
}

export const URL_GET_SHOPS_FOR_FILTER = {
  method: 'GET',
  url: `${BASE_API_URL}/filter/shops`
}

export const URL_GET_OWN_SHOP_STATISTICS = {
  method: 'GET',
  url: `${BASE_API_URL}/stats/shop-own`
}

export const URL_GET_SHOP_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/shop/${id}`
}

export const URL_GET_PRODUCTS_FOR_SHOP_OWN_STATISTICS = {
  method: 'GET',
  url: `${BASE_API_URL}/stats/shop-own/filter/products`
}

export const URL_GET_PRODUCTS_FOR_SHOP_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/shop/${id}/filter/products`
}