import * as types from './types'

export const setDashboardInfo = dashboardInfo => {
  return {
    type: types.SET_DASHBOARD_INFO,
    payload: dashboardInfo
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_DASHBOARD_LOADING,
    payload: isLoading
  }
}