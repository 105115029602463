import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_SHOPS,
  URL_GET_SHOPS_FOR_SELECT,
  URL_GET_SINGLE_SHOP,
  URL_REMOVE_SHOP,
  URL_ADD_SHOP,
  URL_UPDATE_SHOP,
  URL_GET_SHOPS_FOR_FILTER,
  URL_GET_OWN_SHOP_STATISTICS,
  URL_GET_SHOP_STATISTICS,
  URL_GET_PRODUCTS_FOR_SHOP_OWN_STATISTICS,
  URL_GET_PRODUCTS_FOR_SHOP_STATISTICS
} from 'config/api/shops'
import moment from 'moment'
import queryString from 'query-string'

export const getShops = (url = URL_GET_SHOPS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_SHOPS.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setShopsList }
    })
  }
}

export const getShopsForSelect = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SHOPS_FOR_SELECT, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setShopsForSelect }
    })
  }
}

export const getShopsForFilter = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SHOPS_FOR_FILTER,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setShopsForFilter }
    })
  }
}

export const getShop = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SINGLE_SHOP, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setCurrentShop }
    })
  }
}

export const addNewShop = formData => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_ADD_SHOP, 
      queryParams: {
        ...formData,
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null,
        representative: formData.representative ? formData.representative : null
      },
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/jednostki', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const updateShop = (formData, id) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_UPDATE_SHOP, 
      queryParams: {
        ...formData,
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null,
        representative: formData.representative ? formData.representative : null
      },
      pathParams: id,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/jednostki', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const removeShop = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REMOVE_SHOP, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: getShops, shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}
  
export const filterShops = activeFilters => {
  return async dispatch => {
    const finalUrl = `${URL_GET_SHOPS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setActiveFilters(activeFilters))
    dispatch(getShops(finalUrl))
  }
}

export const getShopStatistics = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: 'GET', url},
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatistics }
    })
  }
}

export const getProductsForShopOwnStatistics = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_PRODUCTS_FOR_SHOP_OWN_STATISTICS,
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatisticsProductsForFilter }
    })
  }
}

export const getProductsForShopStatistics = shopId => {
  return async dispatch => {
    request({
      dispatch,
      pathParams: shopId,
      endpoint: URL_GET_PRODUCTS_FOR_SHOP_STATISTICS,
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatisticsProductsForFilter }
    })
  }
}

export const filterStatistics = (activeFilters, areOwnStatistics, shopId = null) => {
  return async dispatch => {
    const finalUrl = areOwnStatistics 
                      ? `${URL_GET_OWN_SHOP_STATISTICS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
                      : `${URL_GET_SHOP_STATISTICS.url(shopId)}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setStatisticsActiveFilters(activeFilters))
    dispatch(getShopStatistics(finalUrl))
  }
}