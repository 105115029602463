import React from 'react'
import Blocks from 'shared/styled/Blocks'
import ShopsForm from './ShopsForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import { goBack } from 'utils/global'

const ShopsNew = ({ 
  history,
  isPageLoading,
  representativesForSelect,
  addNewShop,
  isFormSubmitting,
  formValidationErrors,
  setFormValidationErrors
}) => {

  return (
    <Blocks.Block>
      <Blocks.Head>
        <Blocks.BackButton onClick={() => goBack('/panel/jednostki')}>
          <TiArrowLeftThick size={28} />
        </Blocks.BackButton>
        <BlockHeading>Dodaj nową jednostkę gospodarczą</BlockHeading>
      </Blocks.Head>
      <Loader isPageLoading={isPageLoading}>
        <ShopsForm 
          hasPermission={true}
          representativesForSelect={representativesForSelect}
          submitBtnText={'Dodaj'}
          isFormSubmitting={isFormSubmitting}
          callApi={addNewShop}
          formValidationErrors={formValidationErrors}
          setFormValidationErrors={setFormValidationErrors} />
      </Loader>
    </Blocks.Block>
  )
}

export default ShopsNew