import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_DASHBOARD_INFO
} from 'config/api/dashboard'

export const getDashboardInfo = (url = URL_GET_DASHBOARD_INFO.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_DASHBOARD_INFO.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setDashboardInfo }
    })
  }
}