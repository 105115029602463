import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_REPRESENTATIVES,
  URL_GET_REPRESENTATIVES_FOR_SELECT,
  URL_GET_SINGLE_REPRESENTATIVE,
  URL_REMOVE_REPRESENTATIVE,
  URL_ADD_REPRESENTATIVE,
  URL_UPDATE_REPRESENTATIVE,
  URL_GET_REPRESENTATIVES_FOR_FILTER,
  URL_GET_OWN_REPRESENTATIVES_STATISTICS,
  URL_GET_REPRESENTATIVES_STATISTICS,
  URL_GET_PRODUCTS_FOR_REPRESENTATIVES_OWN_STATISTICS,
  URL_GET_PRODUCTS_FOR_REPRESENTATIVES_STATISTICS,
  URL_GET_REPRESENTATIVES_OWN_NOTIFICATIONS,
  URL_GET_REPRESENTATIVES_NOTIFICATIONS
} from 'config/api/representatives'
import queryString from 'query-string'
import moment from 'moment'

export const getRepresentatives = (url = URL_GET_REPRESENTATIVES.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_REPRESENTATIVES.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setRepresentativesList }
    })
  }
}

export const getRepresentativesForSelect = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_REPRESENTATIVES_FOR_SELECT, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setRepresentativesForSelect }
    })
  }
}

export const getRepresentativesForFilter = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_REPRESENTATIVES_FOR_FILTER,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setRepresentativesForFilter }
    })
  }
}

export const getRepresentative = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SINGLE_REPRESENTATIVE, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setCurrentRepresentative }
    })
  }
}

export const addNewRepresentative = formData => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_ADD_REPRESENTATIVE, 
      queryParams: {
        ...formData,
        sales_district: formData.sales_district ? formData.sales_district : [],
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null
      },
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/przedstawiciele', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const updateRepresentative = (formData, id) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_UPDATE_REPRESENTATIVE, 
      queryParams: {
        ...formData,
        sales_district: formData.sales_district ? formData.sales_district : [],
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null
      },
      pathParams: id,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/przedstawiciele', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const removeRepresentative = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REMOVE_REPRESENTATIVE, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: getRepresentatives, shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const filterRepresentatives = activeFilters => {
  return async dispatch => {
    const finalUrl = `${URL_GET_REPRESENTATIVES.url}?${queryString.stringify(activeFilters, { arrayFormat: 'bracket' })}`
    dispatch(actions.setActiveFilters(activeFilters))
    dispatch(getRepresentatives(finalUrl))
  }
} 

export const getRepresentativeStatistics = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: 'GET', url},
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatistics }
    })
  }
}

export const getProductsForRepresentativeOwnStatistics = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_PRODUCTS_FOR_REPRESENTATIVES_OWN_STATISTICS,
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatisticsProductsForFilter }
    })
  }
}

export const getProductsForRepresentativeStatistics = representativeId => {
  return async dispatch => {
    request({
      dispatch,
      pathParams: representativeId,
      endpoint: URL_GET_PRODUCTS_FOR_REPRESENTATIVES_STATISTICS,
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatisticsProductsForFilter }
    })
  }
}

export const filterStatistics = (activeFilters, areOwnStatistics, representativeId = null) => {
  return async dispatch => {
    const finalUrl = areOwnStatistics 
                      ? `${URL_GET_OWN_REPRESENTATIVES_STATISTICS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
                      : `${URL_GET_REPRESENTATIVES_STATISTICS.url(representativeId)}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setStatisticsActiveFilters(activeFilters))
    dispatch(getRepresentativeStatistics(finalUrl))
  }
}

export const getRepresentativeNotifications = (url = URL_GET_REPRESENTATIVES_OWN_NOTIFICATIONS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_REPRESENTATIVES_OWN_NOTIFICATIONS.method, url },
      onStartLoading: () => actions.setNotificationsLoading(true),
      onEndLoading: () => actions.setNotificationsLoading(false),
      onSuccess: { action: actions.setNotificationsList }
    })
  }
}

export const getSingleRepresentativeNotifications = id => {
  return async dispatch => {
    request({
      dispatch,
      pathParams: id,
      endpoint: URL_GET_REPRESENTATIVES_NOTIFICATIONS,
      onStartLoading: () => actions.setNotificationsLoading(true),
      onEndLoading: () => actions.setNotificationsLoading(false),
      onSuccess: { action: actions.setNotificationsList }
    })
  }
}

export const filterNotifications = (activeFilters, areOwnNotifications, representativeId = null) => {
  return async dispatch => {
    const finalUrl = `${areOwnNotifications 
                      ? URL_GET_REPRESENTATIVES_OWN_NOTIFICATIONS.url 
                      : URL_GET_REPRESENTATIVES_NOTIFICATIONS.url(representativeId)}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setNotificationsActiveFilters(activeFilters))
    dispatch(getRepresentativeNotifications(finalUrl))
  }
}