import React from 'react'
import theme from 'config/theme'
import { rem } from 'polished'
import styled from 'styled-components'
import { parsePrice } from 'utils/global'
import { IoIosArrowForward } from 'react-icons/io'

const BalanceHistoryElement = ({ info }) => {

  if (!info) return null

  return (
    <Wrapper>
      <Box>
        <Turnover>
          <Label>Bonifikata wyliczona na podstawie obrotu w kwocie: </Label>
          <BoldValue>{ parsePrice(info.base_value) } zł</BoldValue>
        </Turnover>
        <Multipliers>
          <Header>Mnożniki:</Header>
          { info.multipliers && 
            info.multipliers.map(multiplier => {
              return (
                <Multiplier key={multiplier.name}>
                  <MultiplierIcon size={15} />
                  <Label>{ multiplier.name }:</Label>
                  <BoldValue>{ multiplier.percent_value }%</BoldValue>
                </Multiplier>
              )
            }) 
          }
        </Multipliers>
      </Box>
    </Wrapper>
  )
}

export default BalanceHistoryElement

const Wrapper = styled.div`
  padding: 10px 10px 10px 50px;
`

const Box = styled.div`
  background-color: ${theme.colors.lightGray};
  border: 1px solid ${theme.colors.light};
  padding: 25px;
  border-radius: 7px;
  display: inline-block;
  font-size: ${rem('14px')};
`

const Turnover = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`

const Label = styled.div`
  margin-right: 5px;
`

const BoldValue = styled.div`
  font-weight: bold;
  font-size: ${rem('15px')};
`

const Header = styled.div`
  font-weight: bold;
  margin-bottom: 7px;
`

const Multiplier = styled.div`
  display: flex;
`

const Multipliers = styled.div`
  font-size: ${rem('14px')};
`

const MultiplierIcon = styled(IoIosArrowForward)`
  margin-right: 5px;
`
