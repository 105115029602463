import React from 'react'
import { FreelancersListContainer } from 'modules/Freelancers'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import SubMenu from 'shared/components/SubMenu'
import Fade from 'shared/components/Fade'

const FreelancersPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Niezależni operatorzy'}/>
      <SubMenu />
      <FreelancersListContainer />
    </Fade>
  )
}

export default FreelancersPage