import React from 'react'
import { LogsListContainer } from 'modules/Logs'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const LogsPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Historia zmian'}/>
      <LogsListContainer />
    </Fade>
  )
}

export default LogsPage