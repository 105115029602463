import React, { useEffect } from 'react'
import ShopsList from '../components/ShopsList'
import { useDispatch, useSelector } from 'react-redux'
import { getShops, removeShop, filterShops } from '../thunks'
import { thunks as representativeThunks } from 'modules/Representatives'
import { setActiveFilters } from '../actions'
import { URL_GET_SHOPS } from 'config/api/shops'
import { actions as modalActions} from 'modules/Modal'
import roles from 'config/roles'

const ShopsListContainer = () => {
  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const activeFilters = useSelector(({ shops }) => shops.activeFilters)
  const shops = useSelector(({ shops }) => shops.shops)
  const representativesForFilter = useSelector(({ representatives }) => representatives.representativesForFilter)
  const isDataLoading = useSelector(({ shops }) => shops.isDataLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getShops())
    if (loggedUser.account_type !== roles.representative.slug) {
      dispatch(representativeThunks.getRepresentativesForFilter())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => dispatch(setActiveFilters())
  }, [dispatch])

  return (
    <ShopsList
      loggedUser={loggedUser}
      searchUrl={URL_GET_SHOPS.url}
      shops={shops} 
      isPageLoading={isDataLoading}
      enableModal={modalActions.enableModal}
      getShops={getShops}
      removeShop={removeShop} 
      activeFilters={activeFilters}
      filterShops={filterShops}
      representativesForFilter={representativesForFilter} />
  )
}

export default ShopsListContainer