import { css } from 'styled-components'
import { rem } from 'polished'
import theme from 'config/theme'

export const GraySelectStyles = css`
  max-width: 200px;
  min-width: 170px;
  width: 100%;
  height: 100%;
  z-index: 2;
  @media (max-width: ${theme.breakpoints.sm}) {
    max-width: 150px;
  }
  .select {
    &__menu {
      z-index: 99999;
    }
    &__multi {
      &-value {
        &__remove {
          cursor: pointer;
          &:hover {
            background-color: #ff7b5b;
            color: #ffffff;
          }
        }
      }
    }
    &__indicators {
      display: ${props => props.isDisabled ? 'none' : 'flex'}
    }
    &__control {
      border-radius: 5px;
      background-color: ${theme.colors.lightGray};
      border: 1px solid ${theme.colors.sub};
      min-height: 30px;
       cursor: pointer;
      &:hover {
        border-color: ${theme.colors.main};
      }
      &--is-focused {
        border-color: ${theme.colors.main};
        box-shadow: none;
      }
    }
    &__placeholder {
      font-size: ${rem('13px')};
      color: ${theme.colors.black};
      font-family: ${theme.font.family};
    }
    &__value {
      &-container {
        padding: 0;   
        padding-left: 10px;
        overflow-x: hidden;
        &--has-value {
          color: ${theme.colors.main};
          & ~ div.select-label {
            font-size: ${rem('12px')};
            top: -13px;
          }
        }
      }
    }
    &__input {
      font-size: ${rem('13px')};
      input {
        font-family: ${theme.font.family};
      }
    }
    &__menu {
      border-radius: 0;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      border: 0;
      * {
        font-size: ${rem('13px')};
      }
    }
    &__single-value {
      font-size: ${rem('13px')};
      margin: 0;
      color: ${theme.colors.black};
    }
    &__option {
      text-align: left;
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.main};
        color: ${theme.colors.white};
      }
      &--is-selected {
        background-color: ${theme.colors.main};
        color: ${theme.colors.white};
      }
      &--is-focused {
        background-color: ${theme.colors.white};
        color: ${theme.colors.black};
      }
    }
  }
`