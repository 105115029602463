import * as actions from './actions'
import { request } from 'utils/api'
import { 
  URL_GET_SELLERS,
  URL_GET_SINGLE_SELLER,
  URL_REMOVE_SELLER,
  URL_ADD_SELLER,
  URL_UPDATE_SELLER,
  URL_GET_OWN_SELLER_STATISTICS,
  URL_GET_SELLER_STATISTICS,
  URL_GET_PRODUCTS_FOR_SELLER_OWN_STATISTICS,
  URL_GET_PRODUCTS_FOR_SELLER_STATISTICS
} from 'config/api/sellers'
import moment from 'moment'
import queryString from 'query-string'

export const getSellers = (url = URL_GET_SELLERS.url) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: URL_GET_SELLERS.method, url }, 
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setSellersList }
    })
  }
}

export const getSeller = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_SINGLE_SELLER, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: actions.setCurrentSeller }
    })
  }
}

export const addNewSeller = formData => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_ADD_SELLER, 
      queryParams: {
        ...formData,
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null,
        shop: formData.shop ? formData.shop : null
      },
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/pracownicy', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const updateSeller = (formData, id) => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_UPDATE_SELLER, 
      queryParams: {
        ...formData,
        partnership_since: formData.partnership_since ? moment(formData.partnership_since).format('YYYY-MM-DD') : null,
        shop_id: formData.shop ? formData.shop.value : null
      },
      pathParams: id,
      onStartLoading: () => actions.setFormSubmiting(true),
      onEndLoading: () => actions.setFormSubmiting(false),
      onSuccess: { redirectTo: '/panel/pracownicy', shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const removeSeller = id => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_REMOVE_SELLER, 
      pathParams: id,
      onStartLoading: () => actions.setDataLoading(true),
      onEndLoading: () => actions.setDataLoading(false),
      onSuccess: { action: getSellers, shouldShowMessage: true},
      onFormFailure: { action: actions.setFormValidationErrors }
    })
  }
}

export const filterSellers = activeFilters => {
  return async dispatch => {
    const finalUrl = `${URL_GET_SELLERS.url}?${queryString.stringify(activeFilters, { arrayFormat: 'bracket' })}`
    dispatch(actions.setActiveFilters(activeFilters))
    dispatch(getSellers(finalUrl))
  }
} 

export const getSellerStatistics = url => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: { method: 'GET', url},
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatistics }
    })
  }
}

export const getProductsForSellerOwnStatistics = () => {
  return async dispatch => {
    request({
      dispatch,
      endpoint: URL_GET_PRODUCTS_FOR_SELLER_OWN_STATISTICS,
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatisticsProductsForFilter }
    })
  }
}

export const getProductsForSellerStatistics = sellerId => {
  return async dispatch => {
    request({
      dispatch,
      pathParams: sellerId,
      endpoint: URL_GET_PRODUCTS_FOR_SELLER_STATISTICS,
      onStartLoading: () => actions.setStatisticsLoading(true),
      onEndLoading: () => actions.setStatisticsLoading(false),
      onSuccess: { action: actions.setStatisticsProductsForFilter }
    })
  }
}

export const filterStatistics = (activeFilters, areOwnStatistics, sellerId = null) => {
  return async dispatch => {
    const finalUrl = areOwnStatistics 
                      ? `${URL_GET_OWN_SELLER_STATISTICS.url}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
                      : `${URL_GET_SELLER_STATISTICS.url(sellerId)}?${queryString.stringify(activeFilters, {arrayFormat: 'bracket'})}`
    dispatch(actions.setStatisticsActiveFilters(activeFilters))
    dispatch(getSellerStatistics(finalUrl))
  }
}