import { BASE_API_URL } from './base'

export const URL_GET_WAREHOUSE_PRODUCTS = {
  method: 'GET',
  url: `${BASE_API_URL}/warehouse`
}

export const URL_GET_SHOP_WAREHOUSE_PRODUCTS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/shop/${id}/warehouse`
}