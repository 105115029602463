import * as types from './types'

const initialState = {
  logs: null,
  activeFilters: null,
  usersForFilter: [],
  typesForFilter: [],
  isDataLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOGS:
      return {
        ...state,
        logs: action.payload
      }
    case types.SET_LOGS_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_LOGS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    case types.SET_LOGS_USERS_FOR_FILTER:
      return {
        ...state,
        usersForFilter: action.payload
      }
    case types.SET_LOGS_TYPES_FOR_FILTER:
      return {
        ...state,
        typesForFilter: action.payload
      }
    default: 
      return state
  }
}
