import * as types from './types'

export const setReturnedProducts = (products = []) => {
  return {
    type: types.SET_RETURNED_PRODUCTS,
    payload: products
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_RETURNS_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_RETURNS_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}