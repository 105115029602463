import React from 'react'
import { ShopsDetailsContainer } from 'modules/Shops'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const ShopDetailsPage = ({ match }) => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Jednostki gospodarcze'}/>
      <ShopsDetailsContainer match={match}/>
    </Fade>
  )
}

export default ShopDetailsPage