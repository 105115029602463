import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from '../../../config/theme'
import { Link } from 'react-router-dom'
import { MdHome } from 'react-icons/md'

const Breadcrumbs = ({ linkLabel }) => {

  return (
    <Wrapper>
      <List>
        <ListItem>
          <ListItemLink to={'/panel'}>
            <MdHome size={20} color={theme.colors.gray}/>
          </ListItemLink>
        </ListItem>
        <ListItem>{ linkLabel }</ListItem>
        {/* { bc.list.map(item => {
          return ( 
            <ListItem key={item.label}>
              <ListItemLink to={item.link}>{ item.label }</ListItemLink>
            </ListItem> 
          )
        })} */}
      </List>
    </Wrapper>
  )
}

export default Breadcrumbs

const Wrapper = styled.div``

const List = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.gray};
  text-transform: uppercase;
  font-size: ${rem('11px')};
  letter-spacing: 1px;
  &::after {
    content: '';
    display: block;
    height: 15px;
    width: 1px;
    margin: 0 10px;
    background-color: ${theme.colors.gray};
  }
  &:last-of-type {
    &::after {
      display: none;
    }
  }
`

const ListItemLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.gray};
`
