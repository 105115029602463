import React from 'react'
import Blocks from 'shared/styled/Blocks'
import SellersForm from './SellersForm'
import { TiArrowLeftThick } from 'react-icons/ti'
import { BlockHeading } from 'shared/styled/Headings'
import Loader from 'modules/PageLoader/components/Loader'
import Tabs from 'shared/components/Tabs'
import { UserCodesContainer } from 'modules/Codes'
import { hasPermission } from 'utils/auth'
import roles from 'config/roles'
import { goBack } from 'utils/global' 
import Fade from 'shared/components/Fade'
import { BalanceContainer } from 'modules/Balance'
import SellersStatisticsContainer from '../containers/SellersStatisticsContainer'

const SellersDetails = ({
  sellerId,
  loggedUser,
  editing,
  isPageLoading,
  shopsForSelect,
  currentSeller,
  isFormSubmitting,
  updateSeller,
  formValidationErrors,
  setFormValidationErrors
}) => {

  return (
    <Blocks.Block>
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <Blocks.Head>
            <Blocks.BackButton onClick={() => goBack('/panel/pracownicy')}>
              <TiArrowLeftThick size={28} />
            </Blocks.BackButton>
            <BlockHeading>{ currentSeller ? currentSeller.full_name : 'Pracownik' }</BlockHeading>
          </Blocks.Head>
          <Tabs
            stylesForChildren={{
              padding: '25px 0',
              boxShadow: 'none'
            }}>
            { loggedUser.account_type !== roles.shop.slug &&
              <UserCodesContainer 
                tabLabel={'Kody rabatowe'} 
                currentUser={currentSeller}
                redirectUrl={currentSeller ? `/panel/pracownicy/${currentSeller.id}` : ``}
                currentRole={roles.seller.slug} />
            }
            { loggedUser.account_type !== roles.shop.slug &&
              <SellersForm 
                hasPermission={hasPermission('seller.update', loggedUser)}
                loggedUser={loggedUser}
                tabLabel={'Ustawienia'}
                editing
                submitBtnText={'Zaktualizuj'}
                shopsForSelect={shopsForSelect}
                currentSeller={currentSeller}
                isFormSubmitting={isFormSubmitting}
                callApi={updateSeller}
                formValidationErrors={formValidationErrors}
                setFormValidationErrors={setFormValidationErrors} />
            }
            { loggedUser.account_type !== roles.shop.slug &&
              <BalanceContainer 
                tabLabel={'Saldo'}
                userRole={roles.seller.slug}
                userId={currentSeller ? currentSeller.id : ''} />
            }
            <SellersStatisticsContainer 
              tabLabel={'Statystyki'}
              areOwnStatistics={false}
              sellerId={sellerId ? sellerId : ''} />
          </Tabs>
        </Fade>
      </Loader>
    </Blocks.Block>
  )
}

export default SellersDetails