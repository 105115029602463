export const SET_SALES_DISTRICTS = 'SET_SALES_DISTRICTS'
export const SET_SALES_DISTRICTS_FOR_SELECT = 'SET_SALES_DISTRICTS_FOR_SELECT'
export const SET_SALES_DISTRICTS_FOR_FILTER = 'SET_SALES_DISTRICTS_FOR_FILTER'
export const SET_CURRENT_DISTRICT = 'SET_CURRENT_DISTRICT'
export const SET_DISTRICTS_FORM_SUBMIT = 'SET_DISTRICTS_FORM_SUBMIT'
export const SET_DISTRICTS_FORM_VALIDATION_ERRORS = 'SET_DISTRICTS_FORM_VALIDATION_ERRORS'
export const SET_DISTRICTS_LOADING = 'SET_DISTRICTS_LOADING'
export const SET_DISTRICTS_STATISTICS = 'SET_DISTRICTS_STATISTICS'
export const SET_DISTRICTS_STATISTICS_ACTIVE_FILTERS = 'SET_DISTRICTS_STATISTICS_ACTIVE_FILTERS'
export const SET_DISTRICTS_STATISTICS_PRODUCTS_FOR_FILTER = 'SET_DISTRICTS_STATISTICS_PRODUCTS_FOR_FILTER'
export const SET_DISTRICTS_STATISTICS_LOADING = 'SET_DISTRICTS_STATISTICS_LOADING'
