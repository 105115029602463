import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ReportProducts from 'shared/components/ReportProducts'
import { thunks as productsThunks } from 'modules/Products'
import { setReturnedProducts, setFormValidationErrors } from '../actions'
import { addNewReturn } from '../thunks'
import { useSelector } from 'react-redux'
import { actions as modalActions} from 'modules/Modal'
import { URL_GET_PRODUCTS_FOR_RETURN } from 'config/api/products'

const ReportReturnContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const products = useSelector(({ products }) => products.products)
  const chosenReturnedProducts = useSelector(({ returns }) => returns.chosenReturnedProducts)
  const isProductsDataLoading = useSelector(({ products }) => products.isDataLoading)
  const isFormSubmitting = useSelector(({ returns }) => returns.isFormSubmitting)
  const formValidationErrors = useSelector(({ returns }) => returns.formValidationErrors)
  const dispatch = useDispatch()

  useEffect(() => {
    const url = `${URL_GET_PRODUCTS_FOR_RETURN.url}?limit=10000000`
    dispatch(productsThunks.getProductsForReturn(url))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const modalContent = {
    title: `Czy na pewno chcesz zgłosić zwrot tych produktów?`,
    description: 'Operacja jest nieodwracalna',
    confirmButtonText: 'Zgłaszam',
    cancelButtonText: 'Anuluj'
  }

  return (
    <ReportProducts
      products={products}
      chosenProducts={chosenReturnedProducts}
      setChosenProducts={setReturnedProducts}
      reportApiCall={addNewReturn}
      loggedUser={loggedUser}
      formValidationErrors={formValidationErrors}
      isFormSubmitting={isFormSubmitting}
      isDataLoading={isProductsDataLoading}
      setFormValidationErrors={setFormValidationErrors} 
      enableModal={modalActions.enableModal}
      modalContent={modalContent} 
      reportTitle='Zgłoś zwrot'
      reportSubTitle='Poinformuj nas, jeśli klient dokonał zwrotu!' />
  )
}

export default ReportReturnContainer