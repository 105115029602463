import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Blocks from 'shared/styled/Blocks'
import theme from 'config/theme'
import { parsePrice } from 'utils/global'
import Loader from 'modules/PageLoader/components/Loader'
import Fade from 'shared/components/Fade'

const BalanceBar = ({ 
  balance,
  isDataLoading,
  isOwnBalance
}) => {
  return (
    <BalanceBarBlock> 
      <Loader light isPageLoading={!balance && isDataLoading}>
        <Fade>
          <BalanceBarContent>
            <CurrentBalance>
              <BalanceInfoLabel>{ isOwnBalance ? 'Aktualne saldo' : 'Aktualne saldo użytkownika' }</BalanceInfoLabel>
              <CurrentBalanceValue>{ balance && parsePrice(balance.balance_info.current) } zł</CurrentBalanceValue>
            </CurrentBalance>
            <RestBalanceInfoWrapper>
              <RestBalanceInfo>
                <BalanceInfoLabel>Do najbliższej wypłaty</BalanceInfoLabel>
                <BalanceInfoValue>{ balance && balance.balance_info.days_to_next_payout } dni</BalanceInfoValue>
              </RestBalanceInfo>
              <RestBalanceInfo>
                <BalanceInfoLabel>{ isOwnBalance ? 'Z nami zarobiłeś już' : 'Suma wszystkich bonifikat'}</BalanceInfoLabel>
                <BalanceInfoValue>{ balance && parsePrice(balance.balance_info.total) } zł</BalanceInfoValue>
              </RestBalanceInfo>
            </RestBalanceInfoWrapper>
          </BalanceBarContent>
        </Fade>
      </Loader>
    </BalanceBarBlock>
  )
}

export default BalanceBar

const BalanceBarBlock = styled(Blocks.Block)`
  background: linear-gradient(90deg, rgba(64,115,203,1) 0%, rgb(102, 175, 202) 100%);
  color: ${theme.colors.white};
  padding: 30px;
  min-height: 120px;
  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 20px;
    > div {
      margin-bottom: 20px;
    }
  }
`

const BalanceBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;
    > div {
      margin-bottom: 20px;
    }
  }
`

const CurrentBalance = styled.div``

const BalanceInfoLabel = styled.div`
  font-size: ${rem('14px')};
  margin-bottom: 2px;
`

const CurrentBalanceValue = styled.h2`
  font-size: ${rem('38px')};
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
`

const BalanceInfoValue = styled.h3`
  font-size: ${rem('22px')};
  margin: 0;
  line-height: 1.5;
`

const RestBalanceInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
`

const RestBalanceInfo = styled.div`
  margin: 0 20px;
  @media (max-width: ${theme.breakpoints.xs}) {
    margin-bottom: 20px;
  }
`