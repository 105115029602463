import * as types from './types'

export const setFreelancersList = freelancers => {
  return {
    type: types.SET_FREELANCERS,
    payload: freelancers
  }
}

export const setFreelancersForSelect = freelancers => {
  return {
    type: types.SET_FREELANCERS_FOR_SELECT,
    payload: freelancers
  }
}

export const setCurrentFreelancer = freelancer => {
  return {
    type: types.SET_CURRENT_FREELANCER,
    payload: freelancer
  }
}

export const setFormSubmiting = shouldSubmit => {
  return {
    type: types.SET_FREELANCERS_FORM_SUBMIT,
    payload: shouldSubmit
  }
}

export const setFormValidationErrors = errors => {
  return {
    type: types.SET_FREELANCERS_FORM_VALIDATION_ERRORS,
    payload: errors
  }
}

export const setDataLoading = isLoading => {
  return {
    type: types.SET_FREELANCERS_LOADING,
    payload: isLoading
  }
}

export const setStatisticsLoading = isLoading => {
  return {
    type: types.SET_FREELANCERS_STATISTICS_LOADING,
    payload: isLoading
  }
}

export const setStatistics = statistics => {
  return {
    type: types.SET_FREELANCERS_STATISTICS,
    payload: statistics
  }
}

export const setStatisticsActiveFilters = filters => {
  return {
    type: types.SET_FREELANCERS_STATISTICS_ACTIVE_FILTERS,
    payload: filters
  }
}

export const setStatisticsProductsForFilter = products => {
  return {
    type: types.SET_FREELANCERS_STATISTICS_PRODUCTS_FOR_FILTER,
    payload: products
  }
}