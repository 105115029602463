import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getPermissions, updatePermissions } from '../thunks'
import { setFormValidationErrors } from '../actions'
import Permissions from '../components/Permissions'

const PermissionsContainer = ({ 
  getPermissionsUrl, 
  updatePermissionsUrl,
  objectId 
}) => {

  const isFormSubmitting = useSelector(({permissions}) => permissions.isFormSubmitting2)
  const isLoadingPermissions = useSelector(({permissions}) => permissions.isLoadingPermissions)
  const formValidationErrors = useSelector(({permissions}) => permissions.formValidationErrors)
  const permissions = useSelector(({permissions}) => permissions.permissions)
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (objectId) {
      dispatch(getPermissions(getPermissionsUrl, objectId))
    }
  }, [dispatch, getPermissionsUrl, objectId])

  return (
    <Permissions
      objectId={objectId}
      permissions={permissions}
      updatePermissionsUrl={updatePermissionsUrl}
      updatePermissions={updatePermissions}
      isLoadingPermissions={isLoadingPermissions}
      isFormSubmitting={isFormSubmitting}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default PermissionsContainer