import React from 'react'
import { SellersListContainer } from 'modules/Sellers'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import SubMenu from 'shared/components/SubMenu'
import Fade from 'shared/components/Fade'

const SellersPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Pracownicy'}/>
      <SubMenu />
      <SellersListContainer />
    </Fade>
  )
}

export default SellersPage