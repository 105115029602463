import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterStatistics, getProductsForDistrictStatistics } from '../thunks'
import Statistics from 'shared/components/Statistics'

const SalesDistrictsStatisticsContainer = ({
  districtId = null
}) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const statisticsActiveFilters = useSelector(({ sales_districts }) => sales_districts.statisticsActiveFilters)
  const statistics = useSelector(({ sales_districts }) => sales_districts.statistics)
  const statisticsProducts = useSelector(({ sales_districts }) => sales_districts.statisticsProducts)
  const areStatisticsLoading = useSelector(({ sales_districts }) => sales_districts.areStatisticsLoading)
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(filterStatistics(statisticsActiveFilters, districtId)) 
    dispatch(getProductsForDistrictStatistics(districtId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const filters = [
    {
      label: 'Typ danych:',
      options: [
        { label: 'Wygenerowany obrót', value: 'sales_value' },
        { label: 'Uzyskanych bonifikat', value: 'bonus' }
      ],
      query: 'type',
      isMulti: false,
      dependentFilters: {
        products: { defaultValue: [], mainFilterDependentValues: ['sales_value'] }
      }
    },
    {
      label: 'Rodzaj sprzedaży:',
      options: [
        { label: 'Stacjonarna', value: 'stationary' },
        { label: 'Internetowa', value: 'shop' },
        { label: 'Wszystkie', value: 'all' },
      ],
      query: 'scope',
      isMulti: false,
      dependentFilters: {
        products: { defaultValue: [], mainFilterDependentValues: ['stationary'] },
        source: { defaultValue: 'all', mainFilterDependentValues: ['shop', 'all'] }
      }
    },
    {
      label: 'Źrodło:',
      options: [
        { label: 'Własne', value: 'own' },
        { label: 'Podwładnych', value: 'sub' },
        { label: 'Wszystkie', value: 'all' },
      ],
      query: 'source',
      isMulti: false,
      visibleIf: {
        scope: ['shop', 'all'],
      },
    },
    {
      label: 'Produkty:',
      options: statisticsProducts,
      query: 'products',
      isMulti: true,
      isClearable: true,
      visibleIf: {
        type: ['sales_value'],
        scope: ['stationary']
      }
    },
  ]

  return (
    <Statistics 
      loggedUser={loggedUser} 
      statistics={statistics}
      isDataLoading={areStatisticsLoading}
      activeFilters={statisticsActiveFilters}
      areOwnStatistics={false}
      filters={filters}
      filterStatistics={chosenFilters => filterStatistics(chosenFilters, districtId)} />
  )
}

export default SalesDistrictsStatisticsContainer