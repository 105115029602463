import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'

// PAGES
import AdministratorsPage from './AdministratorsPage'
import AdministratorsNewPage from './NewAdministratorPage'
import AdministratorDetailsPage from './AdministratorDetailsPage'

const AdministratorRoutes = (match, loggedUser) => [
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/administratorzy`} 
    component={AdministratorsPage} 
    permissions={['operator.viewAny']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/administratorzy/nowy`} 
    component={AdministratorsNewPage} 
    permissions={['operator.create']} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()}
    path={`${match.url}/administratorzy/:administrator_id`} 
    component={AdministratorDetailsPage} 
    permissions={['operator.update', 'operator.view']} />
]

export default AdministratorRoutes