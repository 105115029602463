import * as types from './types'

const initialState = {
  shops: null,
  shopsForSelect: [],
  shopsForFilter: [],
  currentShop: null,
  activeFilters: null,
  isFormSubmitting: false,
  formValidationErrors: null,
  isDataLoading: false,
  areStatisticsLoading: false,
  statistics: null,
  statisticsProducts: [],
  statisticsActiveFilters: {
    type: 'sales_value',
    scope: 'all',
    source: 'all'
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SHOPS:
      return {
        ...state,
        shops: action.payload
      }
    case types.SET_SHOPS_FOR_SELECT:
      return {
        ...state,
        shopsForSelect: action.payload
      }
    case types.SET_SHOPS_FOR_FILTER:
      return {
        ...state,
        shopsForFilter: action.payload
      }
    case types.SET_SHOPS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    case types.SET_CURRENT_SHOP:
      return {
        ...state,
        currentShop: action.payload
      }
    case types.SET_SHOPS_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_SHOPS_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_SHOPS_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_SHOPS_STATISTICS_LOADING:
      return {
        ...state,
        areStatisticsLoading: action.payload
      }
    case types.SET_SHOPS_STATISTICS:
      return {
        ...state,
        statistics: action.payload
      }
    case types.SET_SHOPS_STATISTICS_ACTIVE_FILTERS: 
      return {
        ...state,
        statisticsActiveFilters: action.payload
      }
    case types.SET_SHOPS_STATISTICS_PRODUCTS_FOR_FILTER:
      return {
        ...state,
        statisticsProducts: action.payload
      }
    default: 
      return state
  }
}
