import React from 'react'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import { CodesListContainer } from 'modules/Codes'
import Fade from 'shared/components/Fade'

const CodesPage = () => {
  return (
     <Fade>
      <Breadcrumbs linkLabel={'Kody rabatowe'}/>
      <CodesListContainer />
    </Fade>
  )
}

export default CodesPage 