import * as types from './types'

const initialState = {
  codes: null,
  currentCode: null,
  activeFilters: null,
  isDataLoading: false,
  isFormSubmitting: false,
  formValidationErrors: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CODES:
      return {
        ...state,
        codes: action.payload
      }
    case types.SET_CURRENT_CODE:
      return {
        ...state,
        currentCode: action.payload
      }
    case types.SET_CODES_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    case types.SET_CODES_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_CODES_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_CODES_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    default: 
      return state
  }
}
