import * as types from './types'

const initialState = {
  salesDistricts: null,
  salesDistrictsForSelect: [],
  salesDistrictsForFilter: [],
  currentDistrict: null,
  isFormSubmitting: false,
  formValidationErrors: null,
  isDataLoading: false,
  areStatisticsLoading: false,
  statistics: null,
  statisticsProducts: [],
  statisticsActiveFilters: {
    type: 'sales_value',
    scope: 'all',
    source: 'all'
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SALES_DISTRICTS:
      return {
        ...state,
        salesDistricts: action.payload
      }
    case types.SET_SALES_DISTRICTS_FOR_SELECT: 
      return {
        ...state,
        salesDistrictsForSelect: action.payload
      }
    case types.SET_SALES_DISTRICTS_FOR_FILTER:
      return {
        ...state,
        salesDistrictsForFilter: action.payload
      }
    case types.SET_CURRENT_DISTRICT:
      return {
        ...state,
        currentDistrict: action.payload
      }
    case types.SET_DISTRICTS_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting: action.payload
      }
    case types.SET_DISTRICTS_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    case types.SET_DISTRICTS_LOADING:
      return {
        ...state,
        isDataLoading: action.payload
      }
    case types.SET_DISTRICTS_STATISTICS_LOADING:
      return {
        ...state,
        areStatisticsLoading: action.payload
      }
    case types.SET_DISTRICTS_STATISTICS:
      return {
        ...state,
        statistics: action.payload
      }
    case types.SET_DISTRICTS_STATISTICS_ACTIVE_FILTERS: 
      return {
        ...state,
        statisticsActiveFilters: action.payload
      }
    case types.SET_DISTRICTS_STATISTICS_PRODUCTS_FOR_FILTER:
      return {
        ...state,
        statisticsProducts: action.payload
      }
    default: 
      return state
  }
}
