import React, { useEffect } from 'react'
import { useSelector , useDispatch} from 'react-redux'
import history from 'config/history'
import { getRepresentative, updateRepresentative } from '../thunks'
import { thunks as districtThunks } from 'modules/SalesDistricts'
import { setFormValidationErrors } from '../actions'
import RepresentativesDetails from '../components/RepresentativesDetails'
import { hasPermission } from 'utils/auth'

const RepresentativesDetailsContainer = ({ match }) => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isDataLoading = useSelector(({ representatives }) => representatives.isDataLoading)
  const currentRepresentative = useSelector(({ representatives }) => representatives.currentRepresentative)
  const salesDistrictsForSelect = useSelector(({ sales_districts }) => sales_districts.salesDistrictsForSelect)
  const isFormSubmitting = useSelector(({ representatives }) => representatives.isFormSubmitting)
  const formValidationErrors = useSelector(({ representatives }) => representatives.formValidationErrors)
  const dispatch = useDispatch()

  const representativeId = match.params.representative_id


  useEffect(() => {
    dispatch(getRepresentative(representativeId))
    if (hasPermission('representative.update', loggedUser)) {
      dispatch(districtThunks.getSalesDistrictsForSelect())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RepresentativesDetails
      loggedUser={loggedUser}
      editing
      isPageLoading={isDataLoading}
      history={history}
      salesDistrictsForSelect={salesDistrictsForSelect}
      currentRepresentative={currentRepresentative}
      isFormSubmitting={isFormSubmitting}
      updateRepresentative={updateRepresentative}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default RepresentativesDetailsContainer

