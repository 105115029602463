import React from 'react'
import shortid from 'shortid'
import PermissionRoute from 'hoc/PermissionRoute'
import RoleRoute from 'hoc/RoleRoute'
import roles from 'config/roles'

// PAGES
import SellersPage from './SellersPage'
import NewSellerPage from './NewSellerPage'
import SellerDetailsPage from './SellerDetailsPage'

const SellersRoutes = (match, loggedUser) => [
  <RoleRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    exact 
    path={`${match.url}/pracownicy`} 
    component={SellersPage} 
    permissions={['seller.viewAny']} 
    availableFor={[roles.shop.slug]} />,
  <PermissionRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/pracownicy/nowy`} 
    component={NewSellerPage} 
    permissions={['seller.create']} />,
  <RoleRoute 
    loggedUser={loggedUser} 
    key={shortid.generate()} 
    path={`${match.url}/pracownicy/:seller_id`} 
    component={SellerDetailsPage} 
    permissions={['seller.update', 'seller.view']} 
    availableFor={[roles.shop.slug]} />
]

export default SellersRoutes