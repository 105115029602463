import React from 'react'
import { ProductsListContainer } from 'modules/Products'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const ProductsPage = () => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Produkty'}/>
      <ProductsListContainer />
    </Fade>
  )
}

export default ProductsPage