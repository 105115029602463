import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { BarChart, Bar, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts'
import barTypes from './bartypes'
import Fade from 'shared/components/Fade'
import Blocks from 'shared/styled/Blocks'
import { parsePrice } from 'utils/global'
import theme from 'config/theme'

const Chart = ({ statistics }) => {

  const bars = statistics && statistics.dataKeys
    .map(key => barTypes[key])
    .map((bar, i) => 
      <Bar key={bar.dataKey} name={bar.name} dataKey={bar.dataKey} fill={bar.fill} stackId={bar.dataKey} unit={bar.unit} />
    )

  return (
    <Fade>
      { statistics && statistics.data &&
        <ChartContainer width={"100%"} height={280} >
          <BarChart data={statistics.data} stackOffset="sign" >
            <CartesianGrid strokeDasharray="10 5"/>
            <XAxis dataKey="name" dy={10} />
            <YAxis dx={-10} type="number" domain={['auto', dataMax=>dataMax * 1.2 + 10]} />
            <Tooltip content={<ChartTooltip dataKeys={statistics.dataKeys} />} isAnimationActive={false} />
            <Legend wrapperStyle={{ paddingTop: "20px"}} />
            <ReferenceLine y={0} stroke={theme.colors.lightGray} />
            { bars }
          </BarChart>
        </ChartContainer>
      }
    </Fade>
  )
}

export default Chart

const ChartTooltip = ({ active, payload, label, dataKeys }) => {

  if (!active) return null

  const sum = dataKeys.map(key => payload[0].payload[key] * barTypes[key].sumModifier).reduce((total,value) => total + 1 * value, 0).toFixed(2)

  return (
    <TooltipWrapper>
      <TooltipTitle>{ label }</TooltipTitle>
      { payload &&
        payload.map(bar => {
          return (
            <BarElement key={bar.dataKey}>
              <BarName>{ bar.name }:</BarName>
              <BarValueWrapper>
                <BarValueColor color={bar.color}/>
                <BarValue>{ parsePrice(bar.value ? bar.value * barTypes[bar.dataKey].sumModifier : '') } { bar.unit}</BarValue>
              </BarValueWrapper>
            </BarElement>
          )
        })
      }
      { dataKeys.length > 1 &&
        <BarElement>
          <BarName>Łącznie:</BarName>
          <BarValueWrapper>
            <BarValue>{parsePrice(sum ? sum : '')} {sum && 'zł'} </BarValue>
          </BarValueWrapper>
        </BarElement>
      }
    </TooltipWrapper>
  );
}

const TooltipWrapper = styled(Blocks.Block)`
  padding: 20px;
  max-width: 250px;
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.08);
`

const TooltipTitle = styled.h3`
  font-size: ${rem('15px')};
  font-weight: bold;
  margin: 0 0 15px 0;
  text-transform: capitalize;
`

const BarElement = styled.div`
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const BarValueWrapper = styled.div`
  display: flex;
  align-items: center;
`

const BarName = styled.div`
  font-size: ${rem('13px')};
  line-height: 1.1;
  margin-bottom: 5px;
`

const BarValue = styled.div`
  font-size: ${rem('14px')};
  font-weight: bold;
`

const BarValueColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 6px;
  flex-shrink: 0;
`

const ChartContainer = styled(ResponsiveContainer)`
  margin: 40px 0 0 0;
  svg.recharts-surface tspan {
    font-size: 0.8rem !important;
    padding-top: 5px;
  }

  span.recharts-legend-item-text {
    font-size: 0.8rem !important;
    color: black !important;
  }
  .recharts-cartesian-grid-horizontal,
  .recharts-yAxis,
  .recharts-xAxis {
    line {
      stroke: ${theme.colors.lightGray};
    }
  }
  .recharts-cartesian-grid-vertical {
    line {
      display: none;
    }
  }
`