import React, { Fragment } from 'react'
import theme from 'config/theme'
import roles from 'config/roles'
import styled from 'styled-components'
import Balance from './Balance'
import CurrentCode from './CurrentCode'
import CurrentRepresentative from './CurrentRepresentative'
import InfoBox from './InfoBox'
import Loader from 'modules/PageLoader/components/Loader'
import Fade from 'shared/components/Fade'
import { AdministratorsStatisticsContainer } from 'modules/Administrators'
import { hasPermission } from 'utils/auth'

const AdminDashboard = ({ 
  generateCodePDF,
  isPageLoading,
  isFormSubmitting,
  dashboardInfo,
  loggedUser
}) => {

  const structuresCount = [
    {
      count: dashboardInfo ? dashboardInfo.sales_districts_count : '',
      title: 'Okręgi sprzedaży',
      buttonLink: '/panel/okregi',
      permission: 'salesDistrict.viewAny'
    },
    {
      count: dashboardInfo ? dashboardInfo.representatives_count : '',
      title: 'Przedstawiciele handlowi',
      buttonLink: '/panel/przedstawiciele',
      permission: 'representative.viewAny'
    },
    {
      count: dashboardInfo ? dashboardInfo.shops_count : '',
      title: 'Jednostki gospodarcze',
      buttonLink: '/panel/jednostki',
      permission: 'shop.viewAny'
    },
    {
      count: dashboardInfo ? dashboardInfo.sellers_count : '',
      title: 'Pracownicy',
      buttonLink: '/panel/pracownicy',
      permission: 'seller.viewAny'
    }
  ]

  return (
    <Wrapper>
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <BasicInfo>
            <Balance dashboardInfo={dashboardInfo} />
            { dashboardInfo && dashboardInfo.last_active_code &&
              <CurrentCode 
                generateCodePDF={generateCodePDF} 
                isFormSubmitting={isFormSubmitting} 
                dashboardInfo={dashboardInfo}/>
            }
            { loggedUser.account_type === roles.seller.slug ||
              loggedUser.account_type === roles.shop.slug
              ? <CurrentRepresentative dashboardInfo={dashboardInfo} />
              : null
            }
            { hasPermission('wholesaleOrder.viewAny', loggedUser) &&
              <InfoBox 
                value={dashboardInfo && dashboardInfo.new_orders_count}
                title={'Nowe zamówienia'}
                buttonLink={'/panel/zamowienia'} />
            }
          </BasicInfo>
        </Fade>
      </Loader>
      <AdministratorsStatisticsContainer />
      <Loader isPageLoading={isPageLoading}>
        <Fade>
          <StructuresCountWrapper>
            { loggedUser.account_type === roles.admin.slug &&
              <>
                { structuresCount.map(structure => {
                  return (
                    <Fragment key={structure.title}>
                      { hasPermission(structure.permission, loggedUser) &&
                        <InfoBox 
                          value={structure.count}
                          title={structure.title}
                          buttonLink={structure.buttonLink} />
                      }
                    </Fragment>
                  )
                })
                }
              </>
            }
          </StructuresCountWrapper>
        </Fade>
      </Loader>
    </Wrapper>
  )
}

export default AdminDashboard

const Wrapper = styled.div``

const BasicInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  > div {
    width: calc(25% - 20px);
    margin: 10px;
  }
  @media (max-width: ${theme.breakpoints.lg}) {
    > div {
      width: calc(50% - 20px);
    }
  }
  @media (max-width: 600px) {
    > div {
      width: calc(100% - 20px);
    }
  }
`

const StructuresCountWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  > div {
    width: calc(25% - 20px);
    margin: 10px
  }
  @media (max-width: ${theme.breakpoints.md}) {
    > div {
      width: calc(50% - 20px);
    }
  }
  @media (max-width: 600px) {
    > div {
      width: calc(100% - 20px);
    }
  }
`