import React from 'react'
import { useDispatch } from 'react-redux'
import Form from 'shared/components/Form'
import shortid from 'shortid'
import Fade from 'shared/components/Fade'

const SellersForm = ({ 
  hasPermission,
  loggedUser,
  currentSeller, 
  shopsForSelect,
  isFormSubmitting, 
  callApi, 
  formValidationErrors, 
  setFormValidationErrors,
  submitBtnText,
  editing = false
}) => {
  const dispatch = useDispatch()

  const onSubmit = formData => {
    if (editing) {
      dispatch(callApi(formData, currentSeller.id))
    } else {
      dispatch(callApi(formData))
    }
    dispatch(setFormValidationErrors(null))
  }

  const currentShopsData = shopsForSelect ? shopsForSelect : []

  const initialData = {
    full_name: currentSeller && currentSeller.full_name ? currentSeller.full_name : '',
    user_email: currentSeller && currentSeller.user_email ? currentSeller.user_email : '',
    password: currentSeller && currentSeller.password ? currentSeller.password : '',
    password_confirmation: currentSeller && currentSeller.password_confirmation ? currentSeller.password_confirmation : '',
    phone: currentSeller && currentSeller.phone ? currentSeller.phone : '',
    bank_account_number: currentSeller && currentSeller.bank_account_number ? currentSeller.bank_account_number : '',
    status: currentSeller && currentSeller.status ? currentSeller.status : false,
    partnership_since: currentSeller && currentSeller.partnership_since ? new Date(currentSeller.partnership_since) : '',
    city: currentSeller && currentSeller.city ? currentSeller.city : '',
    postcode: currentSeller && currentSeller.postcode ? currentSeller.postcode : '',
    street: currentSeller && currentSeller.street ? currentSeller.street : '',
    suite_number: currentSeller && currentSeller.suite_number ? currentSeller.suite_number : '',
    shop: currentSeller && currentSeller.shop ? currentSeller.shop : null,
    agreement_number: currentSeller && currentSeller.agreement_number ? currentSeller.agreement_number : '',
    pesel: currentSeller && currentSeller.pesel ? currentSeller.pesel : ''
  }

  const switcher = {
    title: 'Status aktywności',
    field_name: 'status',
    activeText: 'Aktywny',
    inactiveText: 'Nieaktywny'
  }

  const formLayout = [
    {
      key: shortid.generate(),
      title: 'Dane podstawowe',
      fields: [
        {
          name: 'full_name',
          type: 'text',
          label: 'Imię i nazwisko*'
        },
        {
          name: 'user_email',
          type: 'text',
          label: 'Adres email*'
        },
        {
          name: 'password',
          type: 'password',
          label: 'Hasło*'
        },
        {
          name: 'password_confirmation',
          type: 'password',
          label: 'Powtórz hasło*'
        },
        {
          name: 'pesel',
          type: 'text',
          label: 'PESEL*'
        },
        {
          name: 'phone',
          type: 'text',
          label: 'Numer telefonu*'
        },
        {
          name: 'shop',
          type: 'select',
          label: 'Jednostka gospodarcza*',
          options: currentShopsData,
          warningText: '* Przy zmianie jednostki gospodarczej zostanie wygenerowany nowy kod dla tego pracownika'
        },
        {
          name: 'partnership_since',
          type: 'date',
          label: 'Data rozpoczęcia współpracy*'
        },
        {
          name: 'agreement_number',
          type: 'text',
          label: 'Numer umowy'
        },
      ]
    },
    {
      key: shortid.generate(),
      title: 'Adres',
      fields: [
        {
          name: 'street',
          type: 'text',
          label: 'Ulica*'
        },
        {
          name: 'suite_number',
          type: 'text',
          label: 'Numer lokalu*'
        },
        {
          name: 'postcode',
          type: 'text',
          label: 'Kod pocztowy*'
        },
        {
          name: 'city',
          type: 'text',
          label: 'Miejscowość*'
        }
      ]
    },
    {
      key: shortid.generate(),
      title: 'Dane rozliczeniowe',
      fields: [
        {
          name: 'bank_account_number',
          type: 'text',
          label: 'Numer konta bankowego*',
          mask: "P\L99999999999999999999999999" // eslint-disable-line
        }
      ]
    }
  ]

  return (
    <Fade>
      <Form
        editing={editing}
        loggedUser={loggedUser}
        hasPermission={hasPermission}
        switcher={switcher}
        initialData={initialData}
        formLayout={formLayout}
        isFormSubmitting={isFormSubmitting} 
        onSubmit={onSubmit}
        submitBtnText={submitBtnText}
        setFormValidationErrors={setFormValidationErrors}
        formValidationErrors={formValidationErrors} />
    </Fade>
  )
}

export default SellersForm