import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import history from 'config/history'
import { addNewAdministrator } from '../thunks'
import { thunks as districtThunks } from 'modules/SalesDistricts'
import { setFormValidationErrors } from '../actions'
import AdministratorsNew from '../components/AdministratorNew'

const AdministratorsNewContainer = () => {

  const loggedUser = useSelector(({ user }) => user.loggedUser)
  const isFormSubmitting = useSelector(({ administrators }) => administrators.isFormSubmitting)
  const formValidationErrors = useSelector(({ administrators }) => administrators.formValidationErrors)
  const salesDistrictsForSelect = useSelector(({ sales_districts }) => sales_districts.salesDistrictsForSelect)
  const isDataLoading = useSelector(({ administrators }) => administrators.isPageLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(districtThunks.getSalesDistrictsForSelect())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdministratorsNew
      loggedUser={loggedUser}
      history={history}
      isPageLoading={isDataLoading}
      salesDistrictsForSelect={salesDistrictsForSelect}
      isFormSubmitting={isFormSubmitting}
      addNewAdministrator={addNewAdministrator}
      formValidationErrors={formValidationErrors}
      setFormValidationErrors={setFormValidationErrors} />
  )
}

export default AdministratorsNewContainer

