import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { rem } from 'polished'
import theme from '../../../config/theme'
import { MdSearch, MdClear, MdAdd } from 'react-icons/md'
import Blocks from 'shared/styled/Blocks'
import { useDispatch } from 'react-redux'
import { hasPermission } from 'utils/auth'
import { PrimaryButtonStyles } from 'shared/styled/Buttons'

const SearchBanner = ({ 
  searchUrl, 
  callApi, 
  buttonText, 
  buttonLink,
  loggedUser,
  createPermission,
  dark = false
}) => {

  const [searchValue, setSearchValue] = useState('')
  const url =`${searchUrl}?search=${searchValue}`
  const dispatch = useDispatch()

  const searchByName = e => {
    e.preventDefault()
    dispatch(callApi(url))
  }

  const clearSearch = () => {
    setSearchValue('')
    dispatch(callApi(searchUrl))
  }

  return (
    <SearchOuterWrapper dark={dark}>
      <SearchInputWrapper>
        <SearchForm onSubmit={searchByName}>
          <SearchInput 
            dark={dark}
            onChange={e => setSearchValue(e.target.value)} 
            value={searchValue} 
            type='text' 
            placeholder='Wyszukaj...'/>
          {
            searchValue.length > 0 &&
            <ActionButton onClick={clearSearch} type='button'>
              <MdClear size={22}/>
            </ActionButton>
          }
          <ActionButton type='submit'>
            <MdSearch size={22}/>
          </ActionButton>
        </SearchForm>
      </SearchInputWrapper>
      { 
        hasPermission(createPermission, loggedUser) && buttonLink &&
        <AddNewObjectBtn to={buttonLink}>
          <AddIcon size={'22px'} />
          <ButtonText>{ buttonText }</ButtonText>
        </AddNewObjectBtn> 
      }
    </SearchOuterWrapper>
  )
}

export default SearchBanner

const SearchForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
`

const SearchOuterWrapper = styled(Blocks.Block)`
  margin: 15px 0 5px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px !important;
  background: ${props => props.dark ? theme.colors.lightGray : theme.colors.white};
`

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: 10px;
  padding: 10px 0;
`

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`

const SearchInput = styled.input`
  border: 0;
  width: 100%;
  padding: 10px 0;
  font-size: ${rem('14px')};
  outline: none;
  font-family: ${theme.font.family};
  background: ${props => props.dark ? theme.colors.lightGray : theme.colors.white};
  &::placeholder {
    color: ${props => props.dark ? theme.colors.black : theme.colors.textOnWhite};
    font-size: ${rem('14px')};
  }
`

const AddNewObjectBtn = styled(Link)`
  ${PrimaryButtonStyles};
  display: flex;
  align-items: center;
  border: none;
  @media (max-width: ${theme.breakpoints.xs}) {
    min-width: 35px;
    min-height: 35px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`

const AddIcon = styled(MdAdd)`
  display: block;
  margin-right: 5px;
  @media (max-width: ${theme.breakpoints.xs}) {
    margin-right: 0;
  }
`

const ButtonText = styled.span`
  display: block;
  @media (max-width: ${theme.breakpoints.xs}) {
    display: none;
  }
`