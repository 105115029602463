import React from 'react'
import { AdministratorsDetailsContainer } from 'modules/Administrators'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import Fade from 'shared/components/Fade'

const NewAdministratorPage = ({ match }) => {
  return (
    <Fade>
      <Breadcrumbs linkLabel={'Administratorzy'}/>
      <AdministratorsDetailsContainer match={match}/>
    </Fade>
  )
}

export default NewAdministratorPage