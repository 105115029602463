import * as types from './types'

const initialState = {
  permissions: null,
  isLoadingPermissions: false,
  isFormSubmitting2: false,
  formValidationErrors: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      }
    case types.ENABLE_LOADING_PERMISSIONS:
      return {
        ...state,
        isLoadingPermissions: true
      }
    case types.DISABLE_LOADING_PERMISSIONS:
      return {
        ...state,
        isLoadingPermissions: false
      }
    case types.SET_FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitting2: action.payload
      }
    case types.SET_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        formValidationErrors: action.payload
      }
    default: 
      return state
  }
}
