import { BASE_API_URL } from './base'

export const URL_GET_PROFILE = {
  method: 'GET',
  url: `${BASE_API_URL}/profile`
}

export const URL_RESET_PASSWORD = {
  method: 'POST',
  url: `${BASE_API_URL}/password/change`
}
