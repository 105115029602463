import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import theme from 'config/theme'
import { rem } from 'polished'
import Blocks from 'shared/styled/Blocks'
import shortId from 'shortid'

const Tabs = ({ 
  children, 
  horizontal = false,
  stylesForChildren = null
}) => {

  const [activeTab, setActiveTab] = useState()

  useEffect(() => {
    if (children && Array.isArray(children)) {
      for (let i=0; i<children.length; i++) {
        if (children[i]) {
          setActiveTab(children[i].props.tabLabel)
          break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  return (
    <Wrapper horizontal={horizontal}>
      <TabsWrapper horizontal={horizontal}>
        { children && Array.isArray(children)
          ? children.map(child => {
              return (
                <Fragment key={shortId.generate()}> 
                  { child
                    ? (
                      horizontal 
                      ? (
                        <BorderedTab 
                          horizontal={horizontal}
                          active={child.props.tabLabel === activeTab} 
                          onClick={() => setActiveTab(child.props.tabLabel)}>
                          { child.props.tabLabel }
                        </BorderedTab>
                      ) : (
                        <RoundedTab 
                          horizontal={horizontal}
                          active={child.props.tabLabel === activeTab} 
                          onClick={() => setActiveTab(child.props.tabLabel)}>
                          { child.props.tabLabel }
                        </RoundedTab>
                      )
                    )
                    : null
                  }
                </Fragment>
              )
            })
          : (
            horizontal 
            ? <BorderedTab active>{ children.props.tabLabel }</BorderedTab>
            : <RoundedTab active>{ children.props.tabLabel }</RoundedTab>
          )
        }
      </TabsWrapper>
      <ChildrenWrapper horizontal={horizontal} stylesForChildren={stylesForChildren}>
        { children && Array.isArray(children)
          ? children.map(child => {
              return child && child.props.tabLabel === activeTab 
              ? child
              : null
            })
          : children
        }
      </ChildrenWrapper>
    </Wrapper>
  )
}

export default Tabs

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.horizontal ? 'column' : 'row'};
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
  }
`

const TabsWrapper = styled.div`
  width: ${props => props.horizontal ? '100%;' : '15%'};
  min-width: 160px;
  padding: ${props => props.horizontal ? '0' : '0 15px 0 0'};
  flex-wrap: wrap;
  display: ${props => props.horizontal ? 'flex' : 'block'};
  align-items: ${props => props.horizontal ? 'center' : 'flex-start'};
  margin-bottom: ${props => props.horizontal ? '-15px' : '0'};
  @media (max-width: ${theme.breakpoints.md}) {
    display: flex;
    align-items: center;
    margin: ${props => props.horizontal ? '0 -7.5px -15px -7.5px' : '0 -7.5px 15px -7.5px'}; 
    padding: ${props => props.horizontal ? '0 7.5px' : '0'};
    width: 100%;
  }
`

const ChildrenWrapper = styled.div`
  width: ${props => props.horizontal ? '100%;' : '85%'}; 
  padding-left: ${props => props.horizontal ? '0' : '15px' };
  @media (max-width: ${theme.breakpoints.md}) {
    padding-left: 0;
    width: 100%;
  }
  > ${Blocks.Block} {
    ${props => {
      if (!props.horizontal) {
        return {
          boxShadow: 'none',
          margin: '0'
        }
      }
      if (props.stylesForChildren) {
        return props.stylesForChildren
      }
    }}
  }
`

const RoundedTab = styled.button`
  display: block;
  margin: ${props => props.horizontal ? '12px' : '12px 0'};
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${props => props.active ? theme.colors.main : theme.colors.white};
  color: ${props => props.active ? theme.colors.white : theme.colors.black};
  text-shadow: ${props => props.active ? `0px 0px 0px ${theme.colors.white}` : 'none'};
  border-radius: 5px;
  padding: 7px 14px;
  font-size: ${rem('14px')};
  font-family: ${theme.font.family};
  transition: background-color .2s ease, font-weight .2s ease, color .2s ease, border-color .2s ease;
  &:first-of-type {
    margin-top: ${props => props.horizontal ? '12px': '0'};
    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 6px;
    }
  }
  &:hover {
    background-color: ${theme.colors.main};
    color: ${theme.colors.white};
    text-shadow: 0px 0px 0px ${theme.colors.white};
    border-color: ${theme.colors.main};
  }
  @media (max-width: ${theme.breakpoints.md}) {
    margin: 6px;
    border: 1px solid ${props => props.active ? theme.colors.main : theme.colors.light};
  }
`

const BorderedTab = styled.div`
  padding: 10px 0 14px 0;
  text-decoration: none;
  color: ${theme.colors.black};
  font-size: ${rem('14px')};
  /* text-transform: uppercase; */
  font-weight: ${props => props.active ? 600 : 400};
  position: relative;
  margin-right: 30px;
  cursor: pointer;
  &:first-of-type {
    padding-left: 0;
  }
  &::after {
    opacity: ${props => props.active ? 1 : 0};
    visibility: ${props => props.active ? 'visible' : 'hidden'};
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${theme.colors.main};
    transition: opacity .2s ease, visibility .2s ease;
  }
  &:hover {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 8px 0 13px 0;
    font-size: ${rem('14px')};
  }
`

