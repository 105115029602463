import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isUser } from '../utils/auth'

const Authorize = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isUser() ? 
      <Component {...rest} {...props} /> : <Redirect to={'/logowanie'} />   
  )} />
);

export default Authorize
