import React from 'react'
import { useDispatch } from 'react-redux'
import Blocks from 'shared/styled/Blocks'
import DataTable from 'shared/components/DataTable'
import SearchBanner from 'shared/components/SearchBanner'
import Loader from 'modules/PageLoader/components/Loader'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa'
import DataTableLink from 'shared/components/DataTable/DataTableLink'
import { DataTableAction, DataTableActionLink } from 'shared/components/DataTable/DataTableAction'
import { StatusIndicator, TableLinkWrapper } from 'shared/components/DataTable/DataTableElements'
import { hasPermission } from 'utils/auth'
import Filters from 'shared/components/Filters'
import roles from 'config/roles'
import Fade from 'shared/components/Fade'

const SellersList = ({
  loggedUser,
  searchUrl,
  getSellers,
  removeSeller,
  sellers,
  isPageLoading,
  enableModal,
  activeFilters,
  representativesForFilter,
  shopsForFilter,
  filterSellers
}) => {

  const dispatch = useDispatch()
  const modalContent = {
    title: 'Czy na pewno chcesz usunąć tego pracownika?',
    description: 'Operacja jest nieodwracalna',
    confirmButtonText: 'Usuń',
    cancelButtonText: 'Anuluj'
  }
  const filters = [
    {
      label: 'PH:',
      options: representativesForFilter,
      query: 'representatives_ids',
      role: roles.representative.slug,
      isMulti: true,
      isClearable: true
    },
    {
      label: 'JG:',
      options: shopsForFilter,
      query: 'shops_ids',
      role: roles.shop.slug,
      isMulti: true,
      isClearable: true
    }
  ]

  return (
    <>
      <SearchBanner 
        loggedUser={loggedUser}
        searchUrl={searchUrl} 
        callApi={getSellers}
        createPermission='seller.create'
        buttonText='Dodaj nowego'
        buttonLink='/panel/pracownicy/nowy'/>
      <Blocks.Block>
        <Loader isPageLoading={isPageLoading}>
          <Fade>
            { loggedUser.account_type !== roles.representative.slug && loggedUser.account_type !== roles.shop.slug &&
              <Filters 
                loggedUser={loggedUser}
                filters={filters}
                activeFilters={activeFilters}
                filterData={filterSellers} />
            }
            <DataTable
              callApi={getSellers}
              data={sellers}
              columns={[
                { name: 'Imię i nazwisko', field: row => {
                  if (hasPermission('seller.view', loggedUser) || loggedUser.account_type === roles.shop.slug) {
                    return (
                      <TableLinkWrapper>
                        <StatusIndicator active={row.status}/>
                        <DataTableLink to={`/panel/pracownicy/${row.id}`}>{row.full_name}</DataTableLink>
                      </TableLinkWrapper>
                    )
                  } else {
                    return (
                      <TableLinkWrapper>
                        <StatusIndicator active={row.status}/>
                        { row.full_name } 
                      </TableLinkWrapper>
                    )
                  }
                }},
                { name: 'Data rozp. współpracy', field: 'partnership_since' },
                { name: 'Jednostka gosp.', field: 'shop_company_name' },
                { name: 'Akcje', field: row => (
                  <>
                    {
                      hasPermission('seller.update', loggedUser) &&
                      <DataTableActionLink 
                        tooltiptext='Edytuj'
                        to={`/panel/pracownicy/${row.id}`}>
                        <FaRegEdit size={18}/>
                      </DataTableActionLink>
                    }
                    {
                      hasPermission('seller.delete', loggedUser) &&
                      <DataTableAction 
                        tooltiptext='Usuń'
                        onClick={() => {
                          dispatch(enableModal({
                            content: modalContent,
                            onConfirm: removeSeller,
                            onConfirmParams: [row.id]
                          }))
                        }}><FaRegTrashAlt size={18}/>
                      </DataTableAction>
                    }
                  </>
                )},
              ]}
            />
          </Fade>
        </Loader>
      </Blocks.Block>
    </>
  )
}

export default SellersList
