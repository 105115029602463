import { BASE_API_URL } from './base'

export const URL_GET_SALES_DISTRICTS = {
  method: 'GET',
  url: `${BASE_API_URL}/sales_district`
}

export const URL_GET_SALES_DISTRICTS_FOR_SELECT = {
  method: 'GET',
  url: `${BASE_API_URL}/select/sales_districts`
}

export const URL_REMOVE_SALES_DISTRICT = {
  method: 'DELETE',
  url: id => `${BASE_API_URL}/sales_district/${id}`
}

export const URL_ADD_SALES_DISTRICT = {
  method: 'POST',
  url: `${BASE_API_URL}/sales_district`
}

export const URL_GET_SINGLE_DISTRICT = {
  method: 'GET',
  url: id => `${BASE_API_URL}/sales_district/${id}`
}

export const URL_UPDATE_SINGLE_DISTRICT = {
  method: 'PUT',
  url: id => `${BASE_API_URL}/sales_district/${id}`
}

export const URL_GET_SALES_DISTRICTS_FOR_FILTER = {
  method: 'GET',
  url: `${BASE_API_URL}/filter/sales_districts`
}

export const URL_GET_DISTRICTS_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/sales_district/${id}`
}

export const URL_GET_PRODUCTS_FOR_DISTRICTS_STATISTICS = {
  method: 'GET',
  url: id => `${BASE_API_URL}/stats/sales_district/${id}/filter/products`
}